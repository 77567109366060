import { UUID } from '../app/basicModels';
import { EntityState } from 'redux-ngrx-entity';
import { Organization } from '../models';
import { ServiceProvider } from '../serviceProviders/models';

export interface Company {
  id?: UUID;
  name: string;
  provider?: ServiceProvider | null;
}

export interface CompanyState extends EntityState<Company> {
  isLoading: boolean;
  error: string | null;
  orgsControleCerto: Organization[];
  selected: UUID | null;
}

export interface LicensingStatusAccountResponse {
  readonly id: UUID;
  readonly name: string;
}
export interface LicensingStatusAppsResponse {
  readonly contract: LicensingStatusAppsContractResponse;
  readonly id: UUID;
  readonly plan: LicensingStatusAppsPlanResponse;
  readonly quotas: ReadonlyArray<number>;
}
export interface LicensingStatusOrgResponse {
  readonly id: UUID;
  readonly name: string;
}

export interface LicensingStatusResponse {
  readonly account: LicensingStatusAccountResponse;
  readonly apps: ReadonlyArray<LicensingStatusAppsResponse>;
  readonly org: LicensingStatusOrgResponse;
}

export enum UserPlans {
  PRO_UK = 'Pro UK',
  PRO_DE = 'Pro DE',
  PRO_FR = 'PRO FR'
}

export enum Entitlements {
  BORER_RISK = 'has_sugarcane_pest_mngt'
}

export interface LicensingStatusAppsContractResponse {
  readonly end: string;
  readonly id: string;
  readonly notes: string;
  readonly start: string;
}
export interface LicensingStatusAppsPlanResponse {
  readonly entitlements: ReadonlyArray<LicensingStatusAppsPlanEntitlementResponse>;
  readonly name: UserPlans;
}
export interface LicensingStatusAppsPlanEntitlementResponse {
  readonly key: Entitlements;
  readonly name: string;
}
export const templateCompanyId = '00000000-0000-0000-0000-000000000001'

export interface PropertyUnifyingValue {
  readonly pid: UUID;
  readonly value: number;
}
