import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PageableQuery, UUID } from '../app/basicModels';
import { FixedPoint, FixedPointDemeterPage } from './models';
import CONFIG from '../../settings';
import { concatMap, map } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';

const PLUVIOMETER_PAGE_SIZE = 1000;
const TRAP_PAGE_SIZE = 1000;
const GENERIC_PAGE_SIZE = 1000;
const fixedPointUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}/api/v1/static-point`;
const trapUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}/api/v1/trap`;
const trapTalosUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}/v1/trap`;
const pluviometerUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}/api/v1/pluviometer`;

export const getFixedPoint = (fixedPointId: UUID): AxiosObservable<FixedPoint> => {
  return axios.get<FixedPoint>(`${fixedPointUrl}/${fixedPointId}`);
};

export const saveFixedPoint = (fixedPoint: FixedPoint, isNew: boolean): AxiosObservable<FixedPoint> => {
  if (isNew) {
    return createFixedPoint(fixedPoint);
  }
  return updateFixedPoint(fixedPoint);
};

export const createFixedPoint = (fixedPoint: any): AxiosObservable<FixedPoint> => {
  if (fixedPoint.payload.class_name === 'generic') {
    return axios.post<FixedPoint>(`${fixedPointUrl}`, fixedPoint);
  }
  if (fixedPoint.payload.class_name === 'trap') {
    return axios.post<FixedPoint>(`${trapUrl}`, fixedPoint);
  }
  return axios.post<FixedPoint>(`${pluviometerUrl}`, fixedPoint);
};

export const updateFixedPoint = (fixedPoint: any): AxiosObservable<FixedPoint> => {
  if (fixedPoint.payload.class_name === 'generic') {
    return axios.put<FixedPoint>(`${fixedPointUrl}?ignore_diff=true`, fixedPoint);
  }
  if (fixedPoint.payload.class_name === 'trap') {
    return axios.put<FixedPoint>(`${trapUrl}?ignore_diff=true`, fixedPoint);
  }
  return axios.put<FixedPoint>(`${pluviometerUrl}?ignore_diff=true`, fixedPoint);
};

export const deleteFixedPoint = (fixedPoint: any): AxiosObservable<FixedPoint> => {
  if (fixedPoint.payload.class_name === 'generic') {
    return axios.put<FixedPoint>(`${fixedPointUrl}/remove`, fixedPoint);
  }
  if (fixedPoint.payload.class_name === 'trap') {
    return axios.put<FixedPoint>(`${trapUrl}/remove`, fixedPoint);
  }
  return axios.put<FixedPoint>(`${pluviometerUrl}/remove`, fixedPoint);
};

export const cloneTrapToCompanies = (fixedPoint: any): AxiosObservable<FixedPoint> => {
  return axios.post<FixedPoint>(`${trapTalosUrl}/clone-to-companies`, fixedPoint);
};

export const getAllPluviometers = (_query: PageableQuery): Observable<FixedPoint[]> => {
  const size = PLUVIOMETER_PAGE_SIZE;
  return getPluviometerPage({ pageable: { page: 0, size } }).pipe(
    concatMap(({ content, total_pages }) => {
      const pages = Array.from({ length: total_pages - 1 }, (_, i) => i + 1);
      const observables = pages.map(p =>
        getPluviometerPage({ pageable: { page: p, size } }).pipe(concatMap(pluviometerPage => of(pluviometerPage.content)))
      );
      return forkJoin([of(content), ...observables]);
    }),
    map(pluviometers => pluviometers.flat())
  );
};

const getPluviometerPage = _query => {
  return axios
    .get<FixedPointDemeterPage>(`${pluviometerUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}`)
    .pipe(map(m => m.data));
};

export const getAllTraps = (_query, only_template) => {
  const size = TRAP_PAGE_SIZE;
  return getTrapPage({ pageable: { page: 0, size } }, only_template).pipe(
    concatMap(({ content, total_pages }) => {
      const pages = Array.from({ length: total_pages - 1 }, (_, i) => i + 1);
      const observables = pages.map(p =>
        getTrapPage({ pageable: { page: p, size } }, only_template).pipe(concatMap(trapPage => of(trapPage.content)))
      );
      return forkJoin([of(content), ...observables]);
    }),
    map(traps => traps.flat())
  );
};

const getTrapPage = (_query: PageableQuery, only_template: boolean) => {
  return axios
    .get<FixedPointDemeterPage>(`${trapUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&only_template=${only_template}`)
    .pipe(map(m => m.data));
};

export const getFixedPoints = (_query: PageableQuery, only_generic = false) => {
  const size = GENERIC_PAGE_SIZE;
  return getFixedPointsPage({ pageable: { page: 0, size } }, only_generic).pipe(
    concatMap(({ content, total_pages }) => {
      const pages = Array.from({ length: total_pages - 1 }, (_, i) => i + 1);
      const observables = pages.map(p =>
        getFixedPointsPage({ pageable: { page: p, size } }, only_generic).pipe(concatMap(fp => of(fp.content)))
      );
      return forkJoin([of(content), ...observables]);
    }),
    map(fps => fps.flat())
  );
};

const getFixedPointsPage = (_query: PageableQuery, only_generic: boolean) => {
  return axios
    .get<FixedPointDemeterPage>(`${fixedPointUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&only_generic=${only_generic}`)
    .pipe(map(m => m.data));
};
