import React from 'react';

const itITA = () => (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 12" width="16" height="12">
  <rect x="0" y="0" width="5.333" height="12" fill="#008C45" />
  <rect x="5.333" y="0" width="5.333" height="12" fill="#FFFFFF" />
  <rect x="10.667" y="0" width="5.333" height="12" fill="#CE2B37" />
</svg>
);

export default itITA;
