import actions from './actions';
import { Season, SeasonState } from './models';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';

const entity: EntityAdapter<Season> = createEntityAdapter<Season>();

const initialState: SeasonState = entity.getInitialState({
  seasonsTree: [],
  seasonsTreeLoading: false,
  isLoading: false,
  error: null
});

export default (state = initialState, action): SeasonState => {
  switch (action.type) {
    case actions.LOAD_SEASONS_TREE:
      return {
        ...state,
        seasonsTree: [],
        seasonsTreeLoading: true
      };

    case actions.LOAD_SEASONS_TREE_SUCCESS:
      return {
        ...state,
        seasonsTree: [...action.payload],
        seasonsTreeLoading: false,
        error: null
      };

    case actions.LOAD_SEASONS_TREE_FAILURE:
      return {
        ...state,
        seasonsTreeLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectSeasonIds = selectIds;
export const selectSeasonEntities = selectEntities;
export const selectAllCompanies = selectAll;
export const selectSeasonTotal = selectTotal;
