class UrlBuilder {
  private url: string;

  private hasQuery = false;

  private hasFalsyParams = false;

  constructor(url: string) {
    this.url = url;
  }

  removeFalsy(): UrlBuilder {
    this.hasFalsyParams = true;

    return this;
  }

  query(key: string, value: string): UrlBuilder {
    if (this.hasFalsyParams && !value) {
      return this;
    }
    if (this.hasQuery) {
      this.url += '&';
    } else {
      this.url += '?';
      this.hasQuery = true;
    }

    this.url += `${key}=${value}`;

    return this;
  }

  path(value: string): UrlBuilder {
    if (this.hasQuery) {
      throw new Error(
        'Cannot insert new path params after query params are specified',
      );
    }
    this.url += `/${value}`;
    return this;
  }

  build(): string {
    return this.url;
  }
}

export const urlBuilder = (baseUrl = '') => new UrlBuilder(baseUrl);
