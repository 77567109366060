import React from 'react';

const TrashGlyph = ({ color }) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.0002 4C14.3684 4 14.6668 4.29848 14.6668 4.66667C14.6668 5.03486 14.3684 5.33333 14.0002 5.33333H13.3335V12.6667C13.3335 13.7712 12.4381 14.6667 11.3335 14.6667H4.66683C3.56226 14.6667 2.66683 13.7712 2.66683 12.6667V5.33333H2.00016C1.63197 5.33333 1.3335 5.03486 1.3335 4.66667C1.3335 4.29848 1.63197 4 2.00016 4H5.3335V2.88667C5.36606 1.99807 6.11147 1.30334 7.00016 1.33333H9.00016C9.88885 1.30334 10.6343 1.99807 10.6668 2.88667V4H14.0002ZM6.66683 2.88667V4H9.3335V2.88667C9.3335 2.78 9.1935 2.66667 9.00016 2.66667H7.00016C6.80683 2.66667 6.66683 2.78 6.66683 2.88667ZM12.0002 5.33333V12.6667C12.0002 13.0349 11.7017 13.3333 11.3335 13.3333H4.66683C4.29864 13.3333 4.00016 13.0349 4.00016 12.6667V5.33333H12.0002ZM6.00016 11.3333C5.63197 11.3333 5.3335 11.0349 5.3335 10.6667V8C5.3335 7.63181 5.63197 7.33333 6.00016 7.33333C6.36835 7.33333 6.66683 7.63181 6.66683 8V10.6667C6.66683 11.0349 6.36835 11.3333 6.00016 11.3333ZM9.3335 10.6667C9.3335 11.0349 9.63197 11.3333 10.0002 11.3333C10.3684 11.3333 10.6668 11.0349 10.6668 10.6667V8C10.6668 7.63181 10.3684 7.33333 10.0002 7.33333C9.63197 7.33333 9.3335 7.63181 9.3335 8V10.6667Z'
      fill={color || '#515253'}
    />
  </svg>
);

export default TrashGlyph;
