import React from 'react';
import { useDispatch } from 'react-redux';
import TranslateGlyph from '../../../../assets/icons/Glyph/TranslateGlyph';
import InputItem from '../inputItem/inputItem';
import TranslatableTooltip from '../../../tooltip/translatableTooltip';
import { Actions } from '../../../../redux/actions';
import { calculateTranslationField } from '../../../../redux/language/utils';
import TextareaItem from '../textareaItem/textareaItem';

const { toggleDrawer } = Actions;

const TranslatableItem = ({ input, control, area_input = false }) => {
  const dispatch = useDispatch();
  return (
    <>
      <TranslatableTooltip placement='left' languages={input.translatableObject ? calculateTranslationField(input.translatableObject) : []}>
        <div className='translate-icon' onClick={() => dispatch(toggleDrawer(''))}>
          <TranslateGlyph />
        </div>
      </TranslatableTooltip>
      {area_input ? <TextareaItem {...input} control={control} /> : <InputItem {...input} control={control} />}
    </>
  );
};

export default TranslatableItem;
