import actions from './actions';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';
import { MethodologyDeep, MethodologyDeepState } from './models';

const entity: EntityAdapter<MethodologyDeep> = createEntityAdapter<MethodologyDeep>();

const initialState: MethodologyDeepState = entity.getInitialState({
  isLoading: false,
  redirectToMethodologyDeep: false,
  customVariables: [],
  error: null,
  isSaving: false,
  isCloning: false,
  methodologyDeep: null,
  addPhenomModalActive: false,
  addExtraDimensionModalActive: false,
  inspectionGroupId: null,
  addInspectionGroupModalActive: false,
  inspectionGroupName: null,
  tabActive: '1',
  addCharacteristicIndicatorActive: false,
  cloneCharacteristicIndicatorActive: false,
  phenomenonInfo: null,
  addMethodologyModalActive: false,
  cloneModal: false,
  cloneMethodology: undefined,
  clonedVariables: 0,
  clonedIndicators: 0,
  clonedPhenomena: 0
});

export default (state = initialState, action): MethodologyDeepState => {
  switch (action.type) {
    case actions.LOAD_METHODOLOGY_DEEP:
      return {
        ...state,
        isLoading: true,
        redirectToMethodologyDeep: action.payload.redirect,
        methodologyDeep: null,
        clonedVariables: 0,
        clonedIndicators: 0,
        clonedPhenomena: 0
      };
    case actions.METHODOLOGY_DEEP_REDIRECTED: {
      return {
        ...state,
        redirectToMethodologyDeep: false
      };
    }

    case actions.LOAD_METHODOLOGY_DEEP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCloning: false,
        // addMethodologyModalActive: false,
        methodologyDeep: action.payload
      };

    case actions.LOAD_METHODOLOGY_DEEP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case actions.LOAD_CUSTOM_VARIABLES_SUCCESS: {
      return {
        ...state,
        customVariables: action.payload
      };
    }
    case actions.REORDER_METHODOLOGY_DEEP_SUCCESS:
    case actions.UPDATE_COLLAPSED_METHODOLOGY_DEEP_SUCCESS:
    case actions.UPDATE_METHODOLOGY_DEEP:
    case actions.TOGGLE_DEFAULT_METHODOLOGY_DEEP_SUCCESS:
    case actions.DELETE_CHARACTERISTIC_INDICATOR_FROM_METHODOLOGY_DEEP_SUCCESS:
    case actions.ADD_PHENOMENA_TO_METHODOLOGY_DEEP_SUCCESS:
    case actions.ADD_EXTRA_DIMENSION_TO_METHODOLOGY_DEEP_SUCCESS:
    case actions.ADD_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP_SUCCESS:
    case actions.DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP_SUCCESS:
    case actions.REMOVE_MULT_PHENON_INDICATOR_FROM_METHODOLOGY_DEEP_SUCCESS:
      return {
        ...state,
        methodologyDeep: action.payload
      };
    case actions.DELETE_INDEPENDENT_VARIABLE_FROM_METHODOLOGY_DEEP_SUCCESS:
      return {
        ...state,
        methodologyDeep: action.payload
      };
    case actions.REORDER_METHODOLOGY_DEEP_FAILURE:
    case actions.UPDATE_COLLAPSED_METHODOLOGY_DEEP_FAILURE:
    case actions.TOGGLE_DEFAULT_METHODOLOGY_DEEP_FAILURE:
    case actions.DELETE_CHARACTERISTIC_INDICATOR_FROM_METHODOLOGY_DEEP_FAILURE:
    case actions.ADD_PHENOMENA_TO_METHODOLOGY_DEEP_FAILURE:
    case actions.UPSERT_INSPECTION_GROUP_FAILURE:
    case actions.DELETE_INSPECTION_GROUP_FAILURE:
    case actions.DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP_FAILURE:
    case actions.ADD_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP_FAILURE:
    case actions.REMOVE_MULT_PHENON_INDICATOR_FROM_METHODOLOGY_DEEP_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case actions.CLONE_METHODOLOGY_FAILURE:
      return {
        ...state,
        isCloning: false,
        isSaving: false,
        error: action.payload
      };

    case actions.SAVE_METHODOLOGY_DEEP:
      return {
        ...state,
        isSaving: true
      };

    case actions.CLONE_METHODOLOGY_SUCCESS:
      return {
        ...state,
        isCloning: false,
        isSaving: false
      };

    case actions.CLONE_METHODOLOGY:
      return {
        ...state,
        isCloning: true,
        isSaving: true
      };

    case actions.SAVE_METHODOLOGY_DEEP_FAILURE:
      return {
        ...state,
        isSaving: false,
        redirectToMethodologyDeep: !!action.payload.methodologyDeep
      };

    case actions.SAVE_METHODOLOGY_DEEP_SUCCESS:
      return {
        ...state,
        methodologyDeep: { ...action.payload, isNew: false },
        redirectToMethodologyDeep: true,
        isSaving: false
      };

    case actions.TOGGLE_ADD_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP:
      return {
        ...state,
        addCharacteristicIndicatorActive: !state.addCharacteristicIndicatorActive,
        phenomenonInfo: action.payload ? action.payload : null
      };

    case actions.TOGGLE_CLONE_CHARACTERISTIC_INDICATOR_METHODOLOGY_DEEP:
      return {
        ...state,
        cloneCharacteristicIndicatorActive: !state.cloneCharacteristicIndicatorActive,
        phenomenonInfo: action.payload ? action.payload : null
      };

    case actions.TOGGLE_ADD_PHENOM_MODAL_METHODOLOGY_DEEP:
      return {
        ...state,
        addPhenomModalActive: !state.addPhenomModalActive,
        inspectionGroupId: action.payload ? action.payload : null
      };

    case actions.TOGGLE_ADD_EXTRA_DIMENSION_MODAL_METHODOLOGY_DEEP:
      return {
        ...state,
        addExtraDimensionModalActive: !state.addExtraDimensionModalActive,
        inspectionGroupId: action.payload ? action.payload : null
      };

    case actions.TOGGLE_INSPECTION_GROUP_MODAL_METHODOLOGY_DEEP:
      return {
        ...state,
        addInspectionGroupModalActive: !state.addInspectionGroupModalActive,
        inspectionGroupId: action.payload && action.payload.inspectionGroupId ? action.payload.inspectionGroupId : null,
        inspectionGroupName: action.payload && action.payload.inspectionGroupName ? action.payload.inspectionGroupName : null
      };

    case actions.TOGGLE_ADD_METHODOLOGY_DEEP_MODAL:
      return {
        ...state,
        cloneModal: action.payload.cloneModal,
        cloneMethodology: action.payload.cloneMethodology,
        addMethodologyModalActive: !state.addMethodologyModalActive
      };

    case actions.CHANGE_TAB_ACTIVE:
      return {
        ...state,
        tabActive: action.payload
      };

    case actions.UPDATE_CLONED_PHENOMENA_TOTALS:
      return {
        ...state,
        clonedVariables: state.clonedVariables + action.payload.clonedVariables,
        clonedIndicators: state.clonedIndicators + action.payload.clonedIndicators,
        clonedPhenomena: state.clonedPhenomena + action.payload.clonedPhenomena
      };
    case actions.RESET_CLONED_PHENOMENA_TOTALS:
      return {
        ...state,
        clonedVariables: 0,
        clonedIndicators: 0,
        clonedPhenomena: 0
      };

    case actions.REORDER_METHODOLOGY_DEEP:
    case actions.UPDATE_COLLAPSED_METHODOLOGY_DEEP:
    case actions.TOGGLE_DEFAULT_METHODOLOGY_DEEP:
    case actions.DELETE_CHARACTERISTIC_INDICATOR_FROM_METHODOLOGY_DEEP:
    case actions.DELETE_ONLY_INDICATOR_FROM_METHODOLOGY_DEEP:
    case actions.UPSERT_INSPECTION_GROUP:
    case actions.DELETE_PHENOMENON_FROM_METHODOLOGY_DEEP:
    case actions.REMOVE_MULT_PHENON_INDICATOR_FROM_METHODOLOGY_DEEP:
    case actions.TOGGLE_SHOW_ON_INSPECTION_LAYOUT:
    case actions.TOGGLE_SHOW_ON_INSPECTION_LAYOUT_FAILURE:
    case actions.TOGGLE_SHOW_ON_TIMELINE:
    case actions.TOGGLE_SHOW_ON_TIMELINE_FAILURE:
    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectMethodologyDeepIds = selectIds;
export const selectMethodologyDeepEntities = selectEntities;
export const selectAllMethodologiesDeep = selectAll;
export const selectMethodologyDeepTotal = selectTotal;
export const selectSelectedMethodologyDeep = state => state.methodologyDeep;
