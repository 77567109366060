import { Indicator, LoadIndicatorType, IndicatorPage } from './models';
import { UUID, PageableQuery } from '../app/basicModels';

const actions = {
  TOGGLE_INDICATOR_EDIT_MODAL: '[Indicator] Toggle indicator edit modal',
  LOAD_INDICATORS: '[Indicator] Load Indicators',
  LOAD_INDICATORS_SUCCESS: '[Indicator] Load Indicators Success',
  LOAD_INDICATORS_FAILURE: '[Indicator] Load Indicators Failure',

  LOAD_INDICATORS_FOR_COMPANY: '[Indicator] Load Indicators for company',
  LOAD_INDICATORS_FOR_COMPANY_SUCCESS: '[Indicator] Load Indicators for company Success',
  LOAD_INDICATORS_FOR_COMPANY_FAILURE: '[Indicator] Load Indicators for company Failure',

  SAVE_INDICATOR: '[Indicator] Save Indicator',
  SAVE_INDICATOR_SUCCESS: '[Indicator] Save Indicator Success',
  SAVE_INDICATOR_FAILURE: '[Indicator] Save Indicator Failure',
  LOAD_INDICATOR: '[Indicator] Load Indicator',
  LOAD_INDICATOR_SUCCESS: '[Indicator] Load Indicator Success',
  LOAD_INDICATOR_FAILURE: '[Indicator] Load Indicator Failure',
  DELETE_INDICATOR: '[Indicator] Delete Indicator',
  DELETE_INDICATOR_SUCCESS: '[Indicator] Delete Indicator Success',
  DELETE_INDICATOR_FAILURE: '[Indicator] Delete Indicator Failure',
  UNLOAD_INDICATORS: '[Indicator] Unload Indicators',
  CLEAR_INDICATOR_SELECT: '[Indicator] Clear Indicator Select',
  CLEAR_INDICATOR_ERROR: '[Indicator] Clear Indicator Error',

  loadIndicators: (pageableQuery: PageableQuery, showCanonicals: boolean = true, companyId: string | null | undefined = null) => ({
    type: actions.LOAD_INDICATORS,
    payload: {
      pageableQuery,
      companyId,
      showCanonicals
    }
  }),

  loadIndicatorsSuccess: (indicatorPage: IndicatorPage) => ({
    type: actions.LOAD_INDICATORS_SUCCESS,
    payload: indicatorPage
  }),

  loadIndicatorsFailure: (error: string) => ({
    type: actions.LOAD_INDICATORS_FAILURE,
    payload: error
  }),

  loadIndicatorsForCompany: (pageableQuery: PageableQuery, companyId: UUID) => ({
    type: actions.LOAD_INDICATORS_FOR_COMPANY,
    payload: {
      page: pageableQuery,
      companyId: companyId
    }
  }),

  loadIndicatorsForCompanySuccess: (Indicators: Indicator[]) => ({
    type: actions.LOAD_INDICATORS_FOR_COMPANY_SUCCESS,
    payload: Indicators
  }),

  loadIndicatorsForCompanyFailure: (error: string) => ({
    type: actions.LOAD_INDICATORS_FOR_COMPANY_FAILURE,
    payload: error
  }),

  saveIndicator: (indicator: Indicator, methodologyId?: UUID, orderedComponentId?: UUID, orderedPhenomenonId?: UUID) => ({
    type: actions.SAVE_INDICATOR,
    payload: {
      indicator,
      methodologyId,
      orderedComponentId,
      orderedPhenomenonId
    }
  }),

  saveIndicatorSuccess: (indicator: Indicator) => ({
    type: actions.SAVE_INDICATOR_SUCCESS,
    payload: indicator
  }),

  saveIndicatorFailure: (error: string) => ({
    type: actions.SAVE_INDICATOR_FAILURE,
    payload: error
  }),

  loadIndicator: (id: UUID) => ({
    type: actions.LOAD_INDICATOR,
    payload: id
  }),

  loadIndicatorSuccess: (indicator: Indicator) => ({
    type: actions.LOAD_INDICATOR_SUCCESS,
    payload: indicator
  }),

  loadIndicatorFailure: (error: string) => ({
    type: actions.LOAD_INDICATOR_FAILURE,
    payload: error
  }),

  deleteIndicator: (indicator: Indicator) => ({
    type: actions.DELETE_INDICATOR,
    payload: indicator
  }),

  deleteIndicatorSuccess: (deleted: boolean) => ({
    type: actions.DELETE_INDICATOR_SUCCESS,
    payload: deleted
  }),

  deleteIndicatorFailure: (error: string) => ({
    type: actions.DELETE_INDICATOR_FAILURE,
    payload: error
  }),

  unloadIndicators: () => ({
    type: actions.UNLOAD_INDICATORS
  }),

  clearIndicatorSelect: () => ({
    type: actions.CLEAR_INDICATOR_SELECT
  }),

  toggleIndicatorEditModal: () => ({
    type: actions.TOGGLE_INDICATOR_EDIT_MODAL
  }),

  clearError: () => ({
    type: actions.CLEAR_INDICATOR_ERROR
  })
};

export default actions;

export const IndicatorActions = actions;
