import actions from './actions';

const initialState = {
  requests: {}
};

export default (state = initialState, action): any => {
  const { type, payload, metadata } = action;

  switch (type) {
    case actions.REQUEST_STARTED:
      const loadingState = { ...state };
      loadingState.requests[metadata.id] = {
        status: 'LOADING',
        ...metadata
      };
      return loadingState;
    case actions.REQUEST_SUCCEDED:
      const successState = { ...state };
      successState.requests[metadata.id] = {
        status: 'SUCCESS',
        response: payload,
        ...metadata
      };
      return successState;
    case actions.REQUEST_FAILED:
      const failState = { ...state };
      failState.requests[metadata.id] = {
        status: 'ERROR',
        error: payload,
        ...metadata
      };
      return failState;
    case actions.CLEAR_REQUEST_DATA:
      const stateCopy = { ...state };
      stateCopy.requests[metadata.id] = undefined;
      return stateCopy;
    default:
      return state;
  }
};
