import React, { FC } from 'react';
import EditGlyph from '../../assets/icons/Glyph/EditGlyph';
import CloseGlyph from '../../assets/icons/Glyph/CloseGlyph';
import MoveGlyph from '../../assets/icons/Glyph/MoveGlyph';
import classNames from 'classnames';
import GenericPopConfirm from '../genericPopConfirm/genericPopConfirm';
import STTypo from '../typo/STTypo';
import { ActionButtons } from '../actionButtons/actionButtons';
import './extraDimensions.style.scss';
import GlyphRedirect from '../button/glyphRedirect';
import { useSelector, shallowEqual } from 'react-redux';
import EyeGlyph from '../../assets/icons/Glyph/EyeGlyph';
import { ExtraDimensionsProps } from './models/extraDimensions.model';
import { AppState } from '../../redux/redux.model';

enum colors {
  active = '#515253',
  inactive = '#C1C5C8'
}

export const ExtraDimensions: FC<ExtraDimensionsProps> = ({ id, deleteFn, editFn, ...props }) => {
  const methodologyDeep = useSelector((state: AppState) => state.MethodologyDeep.methodologyDeep, shallowEqual);
  const handleDelete = () => deleteFn && deleteFn(id);

  const handleEdit = () =>
    editFn && methodologyDeep && editFn(id, methodologyDeep.company && methodologyDeep.company.id, methodologyDeep.id);

  const { canEditCustomCharacteristic, canEditCustomIndicator } = useSelector((state: AppState) => state.App.accessFlags);

  const canEdit = canEditCustomCharacteristic || canEditCustomIndicator;

  return (
    <div className='content-box'>
      <div className='content-box__title'>
        <div className='content-box__title__name'>
          <STTypo>
            <>{props.title} </>
          </STTypo>
        </div>
      </div>
      <div className={classNames('content-box__actions')}>
        <ActionButtons>
          {editFn && (
            <GlyphRedirect
              onClick={() => handleEdit()}
              testId='charac-indicator-edit'
              textId={canEdit ? 'button.edit' : 'button.details'}
              glyph={canEdit ? <EditGlyph /> : <EyeGlyph />}
              dot={canEdit}
            />
          )}
          {canEdit && (
            <span>
              <GenericPopConfirm onConfirm={handleDelete} title='Are you sure to remove this resource?'>
                <CloseGlyph color='#515253' />
              </GenericPopConfirm>
            </span>
          )}
          <span className={classNames('content-box__action-buttons__move-button', props.hideMove)}>
            <MoveGlyph color='#D7DDE6' />
          </span>
        </ActionButtons>
      </div>
    </div>
  );
};

export default ExtraDimensions;
