import React from 'react';

const CircleGlyph = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315ZM12.5256 12.5255C11.3253 13.7257 9.69746 14.4 8.00008 14.4C4.46545 14.4 1.60008 11.5346 1.60008 8C1.60008 4.46538 4.46545 1.6 8.00008 1.6C11.5347 1.6 14.4001 4.46538 14.4001 8C14.4001 9.69738 13.7258 11.3252 12.5256 12.5255Z'
      fill='#707374'
    />
  </svg>
);

export default CircleGlyph;
