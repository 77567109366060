import { Characteristic, CharacteristicPage, CharacteristicCategory } from './models';
import { UUID, PageableQuery } from '../app/basicModels';
import { SaveCharacteristicParams } from '../models';

const actions = {
  CLEAR_CHARACTERISTIC_SELECT: '[Characteristic] Clear Characteristic Select',
  TOGGLE_CHARACTERISTIC_EDIT_MODAL: '[Characteristic] Toggle characteristic edit modal',
  LOAD_CHARACTERISTICS: '[Characteristic] Load Characteristics',
  LOAD_CHARACTERISTICS_SUCCESS: '[Characteristic] Load Characteristics Success',
  LOAD_CHARACTERISTICS_FAILURE: '[Characteristic] Load Characteristics Failure',
  SAVE_CHARACTERISTIC: '[Characteristic] Save Characteristic',
  SAVE_CHARACTERISTIC_SUCCESS: '[Characteristic] Save Characteristic Success',
  SAVE_CHARACTERISTIC_FAILURE: '[Characteristic] Save Characteristic Failure',
  LOAD_CHARACTERISTIC: '[Characteristic] Load Characteristic',
  LOAD_CHARACTERISTIC_SUCCESS: '[Characteristic] Load Characteristic Success',
  LOAD_CHARACTERISTIC_FAILURE: '[Characteristic] Load Characteristic Failure',
  LOAD_INDEPENDENT_VARIABLES: '[Characteristic] Load Independent Variables',
  LOAD_INDEPENDENT_VARIABLES_SUCCESS: '[Characteristic] Load Independent Variables Success',
  LOAD_INDEPENDENT_VARIABLES_FAILURE: '[Characteristic] Load Independent Variables Failure',
  DELETE_CHARACTERISTIC: '[Characteristic] Delete Characteristic',
  DELETE_CHARACTERISTIC_SUCCESS: '[Characteristic] Delete Characteristic Success',
  DELETE_CHARACTERISTIC_FAILURE: '[Characteristic] Delete Characteristic Failure',
  UNLOAD_CHARACTERISTICS: '[Characteristic] Unload Characteristics',
  FIND_ALL_CHARACTERISTICS: '[Characteristic] Find All Characteristics',
  FIND_ALL_CHARACTERISTICS_SUCCESS: '[Characteristic] Find All Characteristics Success',
  FIND_ALL_CHARACTERISTICS_FAILURE: '[Characteristic] Find All Characteristics Failure',
  FIND_ALL_CHARACTERISTICS_CANONICALS: '[Characteristic] Find All Characteristics Canonicals',
  FIND_ALL_CHARACTERISTICS_CANONICALS_SUCCESS: '[Characteristic] Find All Characteristics Canonicals Success',
  FIND_ALL_CHARACTERISTICS_CANONICALS_FAILURE: '[Characteristic] Find All Characteristics Canonicals Failure',
  TOGGLE_CATEGORICAL_MODAL: '[Characteristic] Toggle categorical modal',

  toggleCharacteristicEditModal: (canonic?: boolean) => ({
    type: actions.TOGGLE_CHARACTERISTIC_EDIT_MODAL,
    payload: canonic
  }),

  loadIndependentVariables: (pageableQuery: PageableQuery, companyId: UUID) => ({
    type: actions.LOAD_INDEPENDENT_VARIABLES,
    payload: {
      pageableQuery,
      companyId
    }
  }),

  loadIndependentVariablesSuccess: (variablesPage: CharacteristicPage) => ({
    type: actions.LOAD_INDEPENDENT_VARIABLES_SUCCESS,
    payload: variablesPage
  }),

  loadIndependentVariablesFailure: (error: string) => ({
    type: actions.LOAD_INDEPENDENT_VARIABLES_FAILURE,
    payload: error
  }),

  loadCharacteristics: (pageableQuery: PageableQuery, phenomenon_id: number) => ({
    type: actions.LOAD_CHARACTERISTICS,
    payload: {
      pageableQuery,
      phenomenon_id
    }
  }),

  loadCharacteristicsSuccess: (characteristicPage: CharacteristicPage) => ({
    type: actions.LOAD_CHARACTERISTICS_SUCCESS,
    payload: characteristicPage
  }),

  loadCharacteristicsFailure: (error: string) => ({
    type: actions.LOAD_CHARACTERISTICS_FAILURE,
    payload: error
  }),

  saveCharacteristic: (payloadToSave: SaveCharacteristicParams) => ({
    type: actions.SAVE_CHARACTERISTIC,
    payload: payloadToSave
  }),

  saveCharacteristicSuccess: (characteristic: Characteristic) => ({
    type: actions.SAVE_CHARACTERISTIC_SUCCESS,
    payload: characteristic
  }),

  saveCharacteristicFailure: (error: string) => ({
    type: actions.SAVE_CHARACTERISTIC_FAILURE,
    payload: error
  }),

  loadCharacteristic: (id: UUID) => ({
    type: actions.LOAD_CHARACTERISTIC,
    payload: id
  }),

  loadCharacteristicSuccess: (characteristic: Characteristic) => ({
    type: actions.LOAD_CHARACTERISTIC_SUCCESS,
    payload: characteristic
  }),

  loadCharacteristicFailure: (error: string) => ({
    type: actions.LOAD_CHARACTERISTIC_FAILURE,
    payload: error
  }),

  deleteCharacteristic: (characteristic: Characteristic) => ({
    type: actions.DELETE_CHARACTERISTIC,
    payload: characteristic
  }),

  deleteCharacteristicSuccess: (deleted: boolean) => ({
    type: actions.DELETE_CHARACTERISTIC_SUCCESS,
    payload: deleted
  }),

  deleteCharacteristicFailure: (error: string) => ({
    type: actions.DELETE_CHARACTERISTIC_FAILURE,
    payload: error
  }),

  unloadCharacteristics: () => ({
    type: actions.UNLOAD_CHARACTERISTICS
  }),

  findAllCharacteristics: (
    pageableQuery: PageableQuery,
    showCanonicals = true,
    companyId: string | null | undefined = null,
    disableCache = false
  ) => ({
    type: actions.FIND_ALL_CHARACTERISTICS,
    payload: {
      pageableQuery,
      showCanonicals,
      companyId,
      disableCache
    }
  }),

  findAllCharacteristicsSuccess: (characteristics: Characteristic[]) => ({
    type: actions.FIND_ALL_CHARACTERISTICS_SUCCESS,
    payload: characteristics
  }),

  findAllCharacteristicsFailure: (error: string) => ({
    type: actions.FIND_ALL_CHARACTERISTICS_FAILURE,
    payload: error
  }),

  findAllCharacteristicsCanonicals: (pageableQuery: PageableQuery) => ({
    type: actions.FIND_ALL_CHARACTERISTICS_CANONICALS,
    payload: pageableQuery
  }),

  findAllCharacteristicsCanonicalsSuccess: (characteristics: Characteristic[]) => ({
    type: actions.FIND_ALL_CHARACTERISTICS_CANONICALS_SUCCESS,
    payload: characteristics
  }),

  findAllCharacteristicsCanonicalsFailure: (error: string) => ({
    type: actions.FIND_ALL_CHARACTERISTICS_CANONICALS_FAILURE,
    payload: error
  }),

  clearCharacteristicSelect: () => ({
    type: actions.CLEAR_CHARACTERISTIC_SELECT
  }),

  toggleCategoricalModal: (category?: CharacteristicCategory) => ({
    type: actions.TOGGLE_CATEGORICAL_MODAL,
    payload: category
  })
};

export default actions;

export const CharacteristicActions = actions;
