import axios from 'axios-observable';
import { ErrorMsg } from './models/Error';

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('striderToken');
    if (token && !(config.headers && config.headers['X-Auth-Token'])) {
      return {
        ...config,
        headers: {
          ...config.headers,
          'Accept-Language': 'pt-BR',
          Authorization: `Bearer ${JSON.parse(token).access_token}`
        }
      };
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// Try to refresh the token whenever a request is unauthorized
let isRefreshing = false;
let subscribers: ReadonlyArray<(token: string) => void> = [];
const WITHOUT_STATUS_CODE = 441;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    const { config, response, message, error, details, data } = err;
    const status = response ? response.status : WITHOUT_STATUS_CODE;

    const errorMsg: ErrorMsg = {
      status,
      message: message || error || details || data.error_description,
      response: err.response
    };

    if (status === 401 && err.data && err.data.error_description !== 'Invalid user credentials') {
      if (!isRefreshing) {
        isRefreshing = true;
      }
      const requestSubscribers = new Promise(resolve => {
        subscribeTokenRefresh((token: string) => {
          const originalRequest = {
            ...config,
            headers: {
              ...config.headers,
              'Accept-Language': 'pt-BR',
              Authorization: `Bearer ${token}`
            }
          };
          resolve(new axios(originalRequest));
        });
      });
      return requestSubscribers;
    }

    return Promise.reject(errorMsg);
  }
);

(window as any).axios = axios;

const subscribeTokenRefresh = (cb: (token: string) => void) => {
  subscribers = [...subscribers, cb];
};

const onRefreshed = (token: string) => {
  subscribers.map(cb => cb(token));
};
