import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import CONFIG from '../../settings';

const { logoutRequest } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    const content = (
      <TopbarDropdownWrapper className='isoUserDropdown'>
        <a className='isoDropdownLink' onClick={this.props.logoutRequest} href='# '>
          <IntlMessages id='topbar.logout' />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger='click'
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement='bottomLeft'>
        <div className='isoImgWrapper'>
          {this.props.currentUser ? (
            <img
              alt='user'
              style={{ borderRadius: '50%' }}
              src={'https://' + CONFIG.baseS3BucketName + '.s3.amazonaws.com/user-' + this.props.currentUser.id}
              onError={e => {
                e.target.onerror = null;
                e.target.src = 'https://' + CONFIG.baseS3BucketName + '.s3.amazonaws.com/user-anonymous.png';
              }}
            />
          ) : (
            <img
              alt='user'
              style={{ borderRadius: '50%' }}
              src={'https://' + CONFIG.baseS3BucketName + '.s3.amazonaws.com/user-anonymous.png'}
            />
          )}
        </div>
      </Popover>
    );
  }
}
export default connect(
  state => ({
    currentUser: state.User.currentUser
  }),
  { logoutRequest }
)(TopbarUser);
