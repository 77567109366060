import { Characteristic } from '../characteristic/models';
import uuid from 'uuid/v4';
import { FeatureFlagEnum, Indicator } from './models';
import { UUID } from '../app/basicModels';
import { cloneDeep } from 'lodash';
import { DefaultIndicatorForm } from '../../containers/Characteristic/models/characteristic.models';
import { mapperToFeatureFlags } from '../../containers/Indicator/utils';
import { buildIndicatorDeep, buildWrongIndicatorDeep } from '../methodologyDeep/services';
import { ComponentDeep, IndicatorDeep } from '../methodologyDeep/models';

export function updateIndicatorInPhenomenonsTree(indicator: Indicator, phenomenonsTree: any) {
  const newPhenomenonsTree = cloneDeep(phenomenonsTree);
  if (indicator.phenomenon_ids && indicator.phenomenon_ids.length > 0) {
    newPhenomenonsTree.forEach(phenomenon => {
      if (indicator.phenomenon_ids!.includes(phenomenon.id)) {
        if (!phenomenon.indicators) phenomenon.indicators = [];
        const indicatorIndex = phenomenon.indicators.findIndex(i => i.id === indicator.id);
        if (indicatorIndex !== -1) {
          phenomenon.indicators[indicatorIndex] = indicator;
        } else {
          phenomenon.indicators.push(indicator);
        }
      }
    });
  }

  return newPhenomenonsTree;
}

export const createDefaultIndicatorForCharacteristic = (
  indicatorParameters: Indicator,
  characteristic: Characteristic,
  companyId: UUID
): Indicator => {
  const input_definition = characteristic.input_definition || {};
  return {
    ...indicatorParameters,
    id: characteristic.default_indicator_id ? characteristic.default_indicator_id : uuid(),
    characteristic_id: characteristic.id,
    uom: input_definition.localized_unit,
    description: {
      localized_strings: {}
    },
    short_description: characteristic.tip,
    phenomenon_ids: [characteristic.phenomenon_id!],
    default: true,
    company_id: companyId
  };
};

export const getFeatureFlagsToInsertDefaultIndicator = (indicator: Indicator, formData: DefaultIndicatorForm): string[] => {
  const feature_flags_mapped = mapperToFeatureFlags(formData?.featureFlags) ?? [];

  const featureFlags = indicator?.feature_flags?.includes(FeatureFlagEnum.POSITIVE_INDICATOR)
    ? [...indicator.feature_flags, ...feature_flags_mapped]
    : feature_flags_mapped;

  if (featureFlags.includes(FeatureFlagEnum.POSITIVE_INDICATOR) && indicator.diagnostics.length === 0) {
    return featureFlags.filter(featureFlag => featureFlag !== FeatureFlagEnum.POSITIVE_INDICATOR);
  }
  return featureFlags;
};

export const insertDefaultIndicatorDataOnIndicator = (indicator: Indicator, formData: DefaultIndicatorForm, currentLanguage: string) => {
  return {
    ...indicator,
    name: {
      ...indicator.name,
      localized_strings: {
        ...(indicator.name || {}).localized_strings,
        [currentLanguage]: formData && formData.name
      }
    },
    feature_flags: Array.from(new Set(getFeatureFlagsToInsertDefaultIndicator(indicator, formData))),
    expression: {
      ...indicator.expression,
      type: formData && formData.expressionType
    },
    expression_type: formData && formData.expressionType,
    decimal_places: parseInt(formData && formData.decimalPlaces)
  };
};

export const fixIndicatorPayload = indicator => {
  return {
    ...indicator,
    id: indicator.id || uuid(),
    expression: {
      ...indicator.expression,
      name: indicator.expression && indicator.expression.name,
      type: indicator.expression_type || indicator.expression.type
    },
    default: indicator.default || false,
    cohese: true
  };
};

export const getIndicatorDeep = (indicator: Indicator, orderedComponentId?: UUID, isWrong?: boolean, inspectionLayoutShow?: boolean) => {
  return isWrong
    ? buildWrongIndicatorDeep(indicator, orderedComponentId)
    : buildIndicatorDeep(indicator, orderedComponentId, inspectionLayoutShow);
};

export const getIndicatorCharacteristics = (indicator: Indicator) => {
  return (indicator.expression && indicator.expression.characteristics) || [];
};

export const isSingleIndicator = (indicator: Indicator | IndicatorDeep) => {
  if (indicator.hasOwnProperty('phenomenonIds')) {
    const indicatorDeep = indicator as IndicatorDeep;
    return indicatorDeep.phenomenonIds && indicatorDeep.phenomenonIds.length === 1;
  }
  const indicatorObject = indicator as Indicator;
  return indicatorObject.phenomenon_ids && indicatorObject.phenomenon_ids.length === 1;
};

export const isMultPhenonIndicator = (indicator: Indicator | IndicatorDeep) => {
  if (indicator.hasOwnProperty('phenomenonIds')) {
    const indicatorDeep = indicator as IndicatorDeep;
    return indicatorDeep.phenomenonIds && indicatorDeep.phenomenonIds.length > 1;
  }
  const indicatorObject = indicator as Indicator;
  return indicatorObject.phenomenon_ids && indicatorObject.phenomenon_ids.length > 1;
};

export const filterIndicatorsInCharacteristicComponents = (
  characteristicComponents: ComponentDeep[],
  indicatorsComponents: ComponentDeep[]
) => {
  const validIndicators = indicatorsComponents.filter(indicatorComponent => {
    if (indicatorComponent.indicator?.characteristicsIds?.length) {
      return indicatorComponent.indicator.characteristicsIds.some(charId =>
        characteristicComponents.some(
          characteristicComponent =>
            characteristicComponent.characteristic && characteristicComponent.characteristic.characteristicId === charId
        )
      );
    }
    return false;
  });
  return validIndicators.length > 0 ? validIndicators : [];
};
