import axios from 'axios-observable';
import CONFIG from '../../settings';
import { UUID } from '../app/basicModels';
import { Field } from './models';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';

const baseUrl = CONFIG.apiUrl + ':' + CONFIG.basePort;

export const getFieldsByProperty = (id: UUID): AxiosObservable<Field[]> => {
  return axios.get(`${baseUrl}/v2/properties/${id}/fields`);
};
