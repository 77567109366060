import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PageableQuery, UUID } from '../app/basicModels';
import { VendorPage, Vendor } from './models';
import CONFIG from '../../settings';

const vendorUrl = CONFIG.apiUrl + ':' + CONFIG.demeterPort + '/api/v1/vendors';

export const getVendors = (_query: PageableQuery, companyId: UUID, showCanonicals: boolean): AxiosObservable<VendorPage> => {
  if (companyId) {
    return axios.get<VendorPage>(
      `${vendorUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&companyId=${companyId}&showCanonicals=${showCanonicals}`
    );
  } else {
    return axios.get<VendorPage>(`${vendorUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&showCanonicals=${showCanonicals}`);
  }
};

export const getVendor = (vendorId: UUID): AxiosObservable<Vendor> => {
  return axios.get<Vendor>(`${vendorUrl}/${vendorId}`);
};

export const saveVendor = (vendor: Vendor, isNew: boolean): AxiosObservable<Vendor> => {
  if (isNew) {
    return createVendor(vendor);
  }
  return updateVendor(vendor);
};

export const createVendor = (vendor: any): AxiosObservable<Vendor> => {
  return axios.post<Vendor>(`${vendorUrl}`, vendor);
};

export const updateVendor = (vendor: any): AxiosObservable<Vendor> => {
  return axios.put<Vendor>(`${vendorUrl}`, vendor);
};

export const deleteVendor = (vendor: Vendor): AxiosObservable<Vendor> => {
  return axios.put<Vendor>(`${vendorUrl}/remove`, vendor);
};
