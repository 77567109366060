import React, { ReactNode } from 'react';
import STTypo from '../typo/STTypo';
import { ColorOptions } from '../typo/STTypo.models';
import { IntlMessages } from '../../helpers';
import './button.style.scss';

export interface GlyphRedirectProps {
  onClick: Function;
  testId: string;
  textId: string;
  glyph: ReactNode;
  dot?: boolean;
  textColor?: string;
  disabled?: boolean;
}

const GlyphRedirect = ({ onClick, testId, textId, glyph, dot, textColor = ColorOptions.PRIMARY, disabled = false }: GlyphRedirectProps) => {
  return (
    <div className='glyph-redirect'>
      <div
        className={disabled ? 'glyph-redirect__click__disabled' : 'glyph-redirect__click'}
        onClick={() => onClick()}
        data-testid={testId}>
        {glyph}
        {textId && (
          <STTypo nowrap color={textColor} capitalize>
            <IntlMessages id={textId} />
          </STTypo>
        )}
      </div>
      {dot && (
        <STTypo bold color={'#C1C5C8'}>
          •
        </STTypo>
      )}
    </div>
  );
};

export default GlyphRedirect;
