import defaultLocale from "antd/lib/locale/en_US";

export const findParentById = (array, id) => array.find(item => item.id === id);

export const getAllParentsAsString = (array, node, path: string[] = []) => {
  const defaultName = [node.name, ...path]

  if (node.parentId === null) {
    return defaultName.join(' / ');
  }

  const parent = findParentById(array, node.parentId);

  if (!parent) {
    return defaultName.join(' / ');
  }

  return getAllParentsAsString(array, parent, defaultName);
};
