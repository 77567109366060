import axios from 'axios-observable';
import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import CONFIG from '../../settings';
import { UUID } from '../app/basicModels';
import { RegionState } from './models';

const baseUrl = `${CONFIG.apiUrl}:${CONFIG.basePort}`;

const propertyRegionsUrl = (propertyId: UUID) => `${baseUrl}/v1/properties/${propertyId}/regions`;

export const getRegionsByProperty = (propertyId: UUID) => {
  return axios.get<ReadonlyArray<RegionState>>(propertyRegionsUrl(propertyId)).pipe(concatMap(r => of(r.data)));
};
