import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Divider, Input, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ModeEnum, SizeEnum } from '../../models';

const { Option } = Select;
interface SelectItemProps {
  name: string;
  icon?: ReactElement | false;
  initialValue?: string | Array<string>;
  size?: SizeEnum;
  mode?: ModeEnum;
  disabled?: boolean;
  options?: ReadonlyArray<{ id; name }>;
  control: any;
  placeholder?: string;
  loading?: boolean;
  onChange?: Function;
  addItem?: Function;
  integerOnChange?: (item: Array<string>) => void;
}

const SelectItem = ({
  name,
  icon,
  initialValue,
  mode,
  size,
  disabled,
  options,
  control,
  onChange,
  placeholder,
  loading,
  addItem,
  integerOnChange
}: SelectItemProps) => {
  const [newName, setNewName] = useState<any>();
  const [stayOpened, setStayOpened] = useState<boolean>(false);
  const selectRef = useRef<any>();
  const selectInputRef = useRef<any>();
  const addVendorInputRef = useRef<any>();
  const handleClick = e => {
    if (selectRef.current.contains(e.target)) {
      return;
    }
    setStayOpened(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className={`${icon ? 'input-icon' : ''}`}>
      {icon && <span className='icon'>{icon}</span>}
      <div ref={selectRef}>
        <Controller
          data-testid={`input-select-${name}`}
          getPopupContainer={trigger => trigger.parentNode}
          as={
            <Select
              id={name}
              showSearch
              loading={loading}
              disabled={disabled}
              mode={mode}
              size={size as any}
              filterOption={(input, option) =>
                option && option.props && option.props.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              defaultValue={initialValue}
              placeholder={placeholder}
              {...(integerOnChange && { onBlur: e => integerOnChange([...e]) })}
              onSelect={() => selectInputRef.current.blur() && addVendorInputRef.current.blur()}
              ref={selectInputRef}
              maxTagCount={10}
              {...(addItem && {
                onFocus: () => setStayOpened(true),
                open: stayOpened,
                dropdownRender: menu => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input
                        ref={addVendorInputRef}
                        style={{ flex: 'auto' }}
                        value={newName}
                        onFocus={() => {
                          setTimeout(() => {
                            setStayOpened(true);
                          }, 50);
                        }}
                        onChange={e => {
                          setNewName(e.target.value);
                        }}
                      />
                      <a
                        style={{
                          flex: 'none',
                          padding: '8px',
                          display: 'block',
                          cursor: 'pointer',
                          color: '#198746',
                          width: '100px'
                        }}
                        onMouseDown={e => {
                          if (newName && /\S/.test(newName)) addItem(newName);
                          setNewName('');
                          e.preventDefault();
                          setStayOpened(false);
                        }}>
                        <PlusOutlined /> Add item
                      </a>
                    </div>
                  </div>
                )
              })}>
              {options &&
                [...options].map(object => (
                  <Option value={object.id} key={object.id}>
                    {object.name}
                  </Option>
                ))}
            </Select>
          }
          name={name}
          control={control}
          defaultValue={initialValue}
          {...(onChange && { onChange: () => onChange })}
        />
      </div>
    </div>
  );
};

export default SelectItem;
