import { ofType } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { catchError, concatMap, delay, map, withLatestFrom } from 'rxjs/operators';
import { notification } from '../../components';
import { Action, UUID } from '../app/basicModels';
import authActions from '../auth/actions';
import actions from './actions';
import appActions from '../app/actions';
import { EditUserRequestParams, Subscription, InviteUserRequestParams, NewAuthority } from './models';
import {
  createUserRequestParams,
  deleteUser,
  editUser,
  getSubscriptionsByUser,
  getUserEditRequestParams,
  getUserMe,
  getUserOrgsList,
  postUser,
  postControleCertoUser,
  putControleCertoUser,
  removeSubscriptionOfCompanyByUser,
  sendAccountInvitation,
  getApiRoles,
  getOrgUserByEmail,
  putUserPassword,
  getRolesRBACByUser,
  removeRolesRBACByUser,
  getOrgUserByEmailV2
} from './service';
import { hasPermission, UserPermissions, PermissionScope } from '../../helpers/userAuth/userAuth';
import { getAccessFlags } from './utils';
import { formatAllRolesReceivedToRolesToSend, processOperation } from '../../querys/rbac/rbac.functions';
import {
  PayloadDeleteUserRBACProps,
  RBACQueryType,
  RBACActions,
  RBACPermissionTypesEnum,
  RBACWorkspaceOperations
} from '../../querys/rbac/rbac.query.model';
import { getWorkspaceOperationsObservable } from '../../querys/rbac/rbac.query';
import { protectorAppId } from '../cropwisePlans/models';
import { trackingIdentify } from '../../helpers/useTrackingEvents';

const { ADMIN_ACCESS } = UserPermissions;
const { READ } = PermissionScope;

const defaultResource = `crn:app:${protectorAppId}`;

const loadUserOperations = [
  {
    action: `${RBACActions.ADMIN_ACCESS}:${RBACPermissionTypesEnum.READ}`,
    resource: defaultResource
  },
  {
    action: `${RBACActions.CANONICAL_CHARACTERISTICS}:${RBACPermissionTypesEnum.WRITE}`,
    resource: defaultResource
  },
  { action: `${RBACActions.CANONICAL_INDICATORS}:${RBACPermissionTypesEnum.WRITE}`, resource: defaultResource },
  { action: `${RBACActions.CANONICAL_PHENOMENON}:${RBACPermissionTypesEnum.WRITE}`, resource: defaultResource },
  {
    action: `${RBACActions.CUSTOM_CHARACTERISTICS}:${RBACPermissionTypesEnum.WRITE}`,
    resource: defaultResource
  },
  { action: `${RBACActions.CUSTOM_INDICATORS}:${RBACPermissionTypesEnum.WRITE}`, resource: defaultResource },
  { action: `${RBACActions.CUSTOM_PHENOMENON}:${RBACPermissionTypesEnum.WRITE}`, resource: defaultResource },
  { action: `${RBACActions.METHODOLOGY}:${RBACPermissionTypesEnum.WRITE}`, resource: defaultResource },
  { action: `${RBACActions.CANONICAL_CROP}:${RBACPermissionTypesEnum.WRITE}`, resource: defaultResource },
  { action: `${RBACActions.CUSTOM_CROPS}:${RBACPermissionTypesEnum.WRITE}`, resource: defaultResource },
  {
    action: `${RBACActions.CANONICAL_MULTI_PHENOMENON_INDICATOR}:${RBACPermissionTypesEnum.WRITE}`,
    resource: defaultResource
  },
  { action: `${RBACActions.CANONICAL_PRODUCT}:${RBACPermissionTypesEnum.WRITE}`, resource: defaultResource },
  {
    action: `${RBACActions.CANONICAL_PRODUCT_SET}:${RBACPermissionTypesEnum.WRITE}`,
    resource: defaultResource
  },
  {
    action: `${RBACActions.CANONICAL_PHENOMENON_CATEGORY}:${RBACPermissionTypesEnum.WRITE}`,
    resource: defaultResource
  },
  { action: `${RBACActions.CANONICAL_VENDOR}:${RBACPermissionTypesEnum.WRITE}`, resource: defaultResource },
  { action: `${RBACActions.FIXED_POINTS}:${RBACPermissionTypesEnum.WRITE}`, resource: defaultResource }
];

export const handleLoadUserMe = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_USER_ME, authActions.CHECK_AUTHORIZATION, authActions.LOGIN_SUCCESS),
    map((action: Action) => action.payload),
    delay(1),
    withLatestFrom(
      state$.pipe(map((state: any) => state.Auth.isLogged)),
      state$.pipe(map((state: any) => state.App.systemFlags?.P40_29585_integrate_web_panel_with_RBAC))
    ),
    concatMap(([isLogged, isUsingRbac]) => {
      if (isLogged) {
        return getUserMe().pipe(
          map(response => response.data),
          concatMap((allUser: any) => {
            if (!hasPermission(allUser, ADMIN_ACCESS, READ) && !isUsingRbac) return of(actions.loadUserMeFailure('User is not Authorized'));

            trackingIdentify(allUser.id);

            if (allUser.is_using_rbac && isUsingRbac) {
              return concat(
                getRolesRBACByUser(allUser.id).pipe(
                  map(response => response.data),
                  concatMap(allRoles => {
                    return of(actions.loadRolesUserLoggedSuccess(allRoles.content));
                  })
                ),
                getWorkspaceOperationsObservable({
                  operations: loadUserOperations
                }).pipe(
                  map(response => response.data),
                  concatMap((permissions: any) => {
                    const appPermissions = permissions.allowed_operations?.reduce(processOperation, {} as RBACWorkspaceOperations)[
                      `crn:app:${protectorAppId}`
                    ];
                    if (appPermissions.admin_access?.length) {
                      return concat([
                        actions.loadUserMeSuccess(allUser),
                        appActions.setAccessFlags(getAccessFlags(allUser, true, appPermissions))
                      ]);
                    }
                    return of(actions.loadUserMeFailure('User is not Authorized'));
                  })
                )
              );
            }

            return concat([actions.loadUserMeSuccess(allUser), appActions.setAccessFlags(getAccessFlags(allUser, false, []))]);
          }),
          catchError((error: string) => {
            notification('error', 'Error loading current user!');
            return of(actions.loadUserMeFailure(error));
          })
        );
      }
      return of();
    })
  );

export const handleLoadUserMeFailure = action$ =>
  action$.pipe(
    ofType(actions.LOAD_USER_ME_FAILURE),
    concatMap(() => {
      notification('error', 'User is not Authorized');
      return of(authActions.logoutRequest());
    })
  );

export const handleLoadOrgUsersList = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_ORG_USERS),
    map((action: any) => action.payload),
    concatMap(payload =>
      getUserOrgsList(payload).pipe(
        map(response => {
          const users = response.data && response.data.content ? response.data.content : response.data;
          return actions.loadOrgUsersSuccess(users);
        }),
        catchError((error: string) => {
          notification('error', 'Error loading users!');
          return of(actions.loadOrgUsersFailure(error));
        })
      )
    )
  );

const deleteUserPipe = (id: UUID, orgId: UUID) => {
  return deleteUser(id, orgId).pipe(
    map(response => {
      notification('success', 'User deleted!');
      return actions.deleteUserSuccess(id);
    }),
    catchError(error => {
      notification('error', 'Error deleting user!');
      return of(actions.deleteUserFailure(error));
    })
  );
};

export const handleDeleteUser = action$ =>
  action$.pipe(
    ofType(actions.DELETE_USER),
    map((action: Action<object>) => action.payload),
    concatMap((payload: any) => deleteUserPipe(payload.orgUser, payload.companyId))
  );

export const handleDeleteUserRBAC = action$ =>
  action$.pipe(
    ofType(actions.DELETE_USER_RBAC),
    map((action: Action<object>) => action.payload),
    concatMap(({ orgID, workspaceID, userID, queryClient, userRoles }: PayloadDeleteUserRBACProps) => {
      const formattedRoles = formatAllRolesReceivedToRolesToSend(userRoles, orgID, workspaceID);
      const data = {
        updates: [
          {
            operation: 'REMOVE',
            roles: formattedRoles
          }
        ]
      };

      return removeRolesRBACByUser(data, userID).pipe(
        concatMap(() => {
          notification('success', 'User removed!');
          queryClient.invalidateQueries({ queryKey: [RBACQueryType.GET_USERS_BY_ORGID, orgID] });
          return of(actions.deleteUserSuccess(userID));
        }),
        catchError(error => {
          notification('error', 'Error removing subscription!');
          return of(actions.deleteUserFailure(error));
        })
      );
    }),
    catchError(error => {
      notification('error', 'Error get subscriptions');
      return of(actions.deleteUserFailure(error));
    })
  );

export const handleRemoveSubscriptionUserByCompany = action$ =>
  action$.pipe(
    ofType(actions.REMOVE_SUBSCRIPTION_USER_BY_COMPANY),
    map((action: Action<object>) => action.payload),
    concatMap((payload: any) =>
      getSubscriptionsByUser(payload.userId).pipe(
        map(response => response.data),
        map((subscriptions: Subscription[]) => subscriptions.find(s => s.companyId === payload.companyId)),
        concatMap((subscription: Subscription | undefined) =>
          removeSubscriptionOfCompanyByUser(payload.userId, subscription!.id).pipe(
            concatMap(() => {
              notification('success', 'User removed!');
              return of(actions.deleteUserSuccess(payload.userId));
            }),
            catchError(error => {
              notification('error', 'Error removing subscription!');
              return of(actions.deleteUserFailure(error));
            })
          )
        )
      )
    ),
    catchError(error => {
      notification('error', 'Error get subscriptions');
      return of(actions.deleteUserFailure(error));
    })
  );

export const handleInviteUserByEmail = action$ =>
  action$.pipe(
    ofType(actions.INVITE_USER_BY_EMAIL),
    map((action: any) => action.payload),
    concatMap((payload: { user: InviteUserRequestParams; orgId: UUID }) =>
      sendAccountInvitation(payload.user, payload.orgId).pipe(
        map(user => {
          notification('success', 'Invitation sent successfully.');
          return actions.inviteUserByEmailSuccess(payload.user);
        }),
        catchError(error => {
          const { response } = error;
          response && response.data.message === 'User cannot invite himself'
            ? notification('error', 'User cannot invite himself')
            : notification('error', actions.INVITE_USER_BY_EMAIL_FAILURE);

          return of(actions.inviteUserByEmailFailure(error));
        })
      )
    )
  );

export const createUser = (authorities: NewAuthority[], basicInfoUser, orgId) => {
  const userRequestParams = createUserRequestParams(basicInfoUser, authorities);

  return postUser(userRequestParams, orgId).pipe(
    concatMap(response => {
      const user = response.data;
      notification('success', 'User created successfully!');
      return of(actions.saveUserSuccess(user));
    })
  );
};

export const handleCreateUser = (action$, state$) =>
  action$.pipe(
    ofType(actions.CREATE_USER),
    map((action: Action<any>) => action.payload),
    concatMap(({ basicInfoUser, authorities, orgId }) => {
      return createUser(authorities, basicInfoUser, orgId);
    }),
    catchError((error, caught) => {
      const { response } = error;
      notification('error', response && response.data.message);
      return merge(of(actions.saveUserFailure(error)), caught);
    })
  );

export const handleCreateControleCertoUser = action$ =>
  action$.pipe(
    ofType(actions.CREATE_CONTROLE_CERTO_USER),
    map((action: Action<any>) => action.payload),
    concatMap(userInfo =>
      postControleCertoUser(userInfo).pipe(
        concatMap(response => {
          notification('success', 'User created successfully!');
          return of(actions.saveControleCertoUserSuccess(response.data));
        })
      )
    ),
    catchError((error, caught) => {
      const { response } = error;
      notification('error', response && response.data.message);
      return merge(of(actions.saveUserFailure(error)), caught);
    })
  );

const editUserPipe = (userEditParams: EditUserRequestParams) => {
  return editUser(userEditParams).pipe(
    concatMap(response => {
      const user = response.data;
      notification('success', 'User edited successfully!');
      return of(actions.saveUserSuccess(user));
    }),
    catchError(error => {
      const { response } = error;
      const message =
        response && response.data.status === 401 ? 'You do not have authorization to do this operation.' : response.data.message;
      notification('error', message);
      return of(actions.saveUserFailure(error));
    })
  );
};

export const handleEditControleCertoUser = action$ =>
  action$.pipe(
    ofType(actions.EDIT_CONTROLE_CERTO_USER),
    map((action: Action<any>) => action.payload),
    concatMap(({ userInfo, companyId }) =>
      putControleCertoUser(userInfo).pipe(
        concatMap(response => {
          notification('success', 'User edited successfully!');
          return merge(of(actions.saveControleCertoUserSuccess(response.data), companyId && actions.loadOrgUsers(companyId)));
        })
      )
    ),
    catchError((error, caught) => {
      const { response } = error;
      notification('error', response && response.data.message);
      return merge(of(actions.saveUserFailure(error)), caught);
    })
  );

export const handleEditUser = action$ =>
  action$.pipe(
    ofType(actions.EDIT_USER),
    map((action: Action<any>) => action.payload),
    concatMap(({ basicInfoData, authorities, userId }) => {
      const userEditParams = getUserEditRequestParams(basicInfoData, authorities, userId);
      return editUserPipe(userEditParams);
    })
  );

export const loadRoles = action$ =>
  action$.pipe(
    ofType(actions.LOAD_ROLES),
    map((action: Action<any>) => action.payload),
    concatMap(() => {
      return getApiRoles().pipe(
        map(response => response.data),
        map(response => {
          const roleNames = response.content.map(role => role.name);
          return actions.loadRolesSuccess(roleNames);
        }),
        catchError(error => {
          const { response } = error;
          notification('error', response && response.data.message);
          return of(actions.loadRolesFailure(error));
        })
      );
    })
  );

export const loadOrgUserByEmail = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_USER_BY_EMAIL),
    map((action: Action<object>) => action.payload),
    concatMap((email: UUID) =>
      getOrgUserByEmail(email).pipe(
        map(response => actions.loadOrgUserByEmailSuccess(response.data)),
        catchError(error => {
          const { response } = error;
          if (response && response.data.status === 401) {
            notification('error', response.data.message);
          }
          return of(actions.loadOrgUserByEmailFailure(response && response.data.status ? response.data.status : error));
        })
      )
    )
  );

export const loadOrgUserByEmailV2 = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_USER_BY_EMAILV2),
    map((action: Action<object>) => action.payload),
    concatMap((email: UUID) =>
      getOrgUserByEmailV2(email).pipe(
        map(response => actions.loadOrgUserByEmailSuccess(response.data)),
        catchError(error => {
          const { response } = error;
          if (response && response.data.status === 401) {
            notification('error', response.data.message);
          }
          return of(actions.loadOrgUserByEmailFailure(response && response.data.status ? response.data.status : error));
        })
      )
    )
  );

export const updateUserPassword = action$ =>
  action$.pipe(
    ofType(actions.UPDATE_USER_PASSWORD),
    map((action: Action<any>) => action.payload),
    concatMap(({ userId, password }) =>
      putUserPassword(userId, password).pipe(
        concatMap(response => {
          return of(actions.updateUserPasswordSuccess());
        })
      )
    ),
    catchError((error, caught) => {
      return merge(of(actions.updateUserPasswordFailure(error)), caught);
    })
  );
