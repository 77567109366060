import { DatePicker } from 'antd';
import { Moment } from 'moment';
import React from 'react';
import { Controller } from 'react-hook-form';
import CalendarGlyph from '../../../../assets/icons/Glyph/CalendarGlyph';
import { DateFormat } from '../../models';

const { RangePicker } = DatePicker;

interface InputItemProps {
  name: string;
  disabled?: boolean;
  size?: 'small' | 'default' | 'large';
  initialValue?: Moment[];
  control: any;
  inputType?: string;
  placeholder?: string;
}

const DateItem = ({ name, disabled, size, initialValue, control, placeholder }: InputItemProps) => (
  <Controller
    name={name}
    as={
      <RangePicker
        id={name}
        name={name}
        disabled={disabled}
        format={DateFormat.DD_MMM_YY}
        size={size}
        suffixIcon={<CalendarGlyph />}
        allowClear={false}
        popupStyle={{ borderRadius: '20px' }}
      />
    }
    control={control}
    defaultValue={initialValue}
  />
);

export default DateItem;
