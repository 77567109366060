import { ofType } from 'redux-observable';
import { concat, forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { notification } from '../../components';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';
import { FieldActions, PhenomenonActions, UserActions } from '../actions';
import { Action, initialPageableQuery } from '../app/basicModels';
import { getPhenomenonsTreeByCompany } from '../company/service';
import { getFieldsByProperty } from '../field/repository';
import { RegionActions } from '../region/actions';
import { getRegionsByProperty } from '../region/service';
import { getUsersByOrg } from '../user/service';
import actions from './actions';
import { DeleteMeasurementRequestParams, LoadMeasurementsRequestParams, Measurement, Monitoring } from './models';
import { deleteMeasurement, editMeasurementValue, getMeasurements } from './repository';
import { buildMeasurements, getEditMeasurementRequest } from './service';
import { AppState } from '../redux.model';

export const handleLoadMeasurements = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_MEASUREMENTS_BY_PROPERTY),
    map((action: Action<LoadMeasurementsRequestParams>) => action.payload),
    concatMap((payload: LoadMeasurementsRequestParams) => {
      return getMeasurements(payload).pipe(
        map(response => response.data),
        map((monitorings: { monitorings: Monitoring[] }) => {
          return monitorings;
        }),
        withLatestFrom(
          state$.pipe(map((state: AppState) => state.Field.entities)),
          state$.pipe(map((state: AppState) => state.User.entities)),
          state$.pipe(map((state: AppState) => state.Phenomenon.phenomenonsTree)),
          state$.pipe(map((state: AppState) => state.Measurement.filterVisible)),
          state$.pipe(map((state: AppState) => state.Phenomenon.lastCompanyId)),
          state$.pipe(map((state: AppState) => state.Field.lastPropertyId)),
          state$.pipe(map((state: AppState) => state.User.lastOrgId)),
          state$.pipe(map((state: AppState) => state.Measurement.isLoading))
        ),
        concatMap(
          ([
            result,
            fieldsState,
            usersListState,
            phenomenonsTreeState,
            filterVisible,
            lastCompanyLoadPhenomenonsTree,
            lastPropertyLoadFields,
            lastOrgLoadUsers,
            isLoading
          ]: any) => {
            const requests: any = [];
            if (!fieldsState || Object.values(fieldsState).length === 0 || lastPropertyLoadFields !== payload.propertyId) {
              requests.push(getFieldsByProperty(payload.propertyId));
            } else {
              requests.push(of(fieldsState));
            }
            if (!usersListState || Object.values(usersListState).length === 0 || lastOrgLoadUsers !== payload.companyId) {
              requests.push(getUsersByOrg({ orgId: payload.companyId }));
            } else {
              requests.push(of(usersListState));
            }
            if (phenomenonsTreeState.length === 0 || lastCompanyLoadPhenomenonsTree !== payload.companyId) {
              requests.push(getPhenomenonsTreeByCompany({ ...initialPageableQuery }, payload.companyId));
            } else {
              requests.push(of(phenomenonsTreeState));
            }
            requests.push(getRegionsByProperty(payload.propertyId));

            return forkJoin([of(result), of(filterVisible), of(isLoading), ...requests]);
          }
        ),
        concatMap(([result, filterVisible, isLoading, fieldsList, usersList, phenomenonsTreeList, regionsList]: any) => {
          const rangeKey = result.range_key;
          const arrayActions: any = [];

          let users;
          if (usersList.data) {
            users = usersList.data.content;
            arrayActions.push(UserActions.loadOrgUsersSuccess(users));
          } else {
            users = Object.values(usersList);
          }

          let phenomenonsTree;
          if (phenomenonsTreeList.data) {
            phenomenonsTree = phenomenonsTreeList.data.content;
            arrayActions.push(PhenomenonActions.loadPhenomenonsTreeSuccess(phenomenonsTree));
          } else {
            phenomenonsTree = phenomenonsTreeList;
          }

          let fields;
          if (fieldsList.data) {
            fields = fieldsList.data.content;
            arrayActions.push(FieldActions.loadFieldsByPropertySuccess(fields));
          } else {
            fields = Object.values(fieldsList);
          }

          let regions;
          if (regionsList) {
            regions = regionsList;
            arrayActions.push(RegionActions.loadRegionsSuccess(regions));
          } else {
            regions = Object.values(regionsList);
          }

          const mutableMeasurements = buildMeasurements(result.monitorings, phenomenonsTree, users, fields, payload.propertyId, regions);

          if (!rangeKey || mutableMeasurements.length > 0) {
            arrayActions.push(actions.setRangeKey(rangeKey));
            if (payload.rangeKey) {
              arrayActions.push(actions.upsertMeasurementsByProperty(mutableMeasurements));
            } else {
              arrayActions.push(actions.loadMeasurementsByPropertySuccess(mutableMeasurements));
            }
          }

          if (filterVisible && !isLoading) {
            arrayActions.push(actions.toggleFilterModal());
          }

          arrayActions.push(UserActions.setLastOrgIdLoadUsers(payload.companyId));
          arrayActions.push(PhenomenonActions.setLastCompanyIdLoadPhenomenonsTree(payload.companyId));
          arrayActions.push(FieldActions.setLastPropertyIdLoadFields(payload.propertyId));

          return concat([...arrayActions]);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading Measurements!');
          return of(actions.loadMeasurementsByPropertyFailure(errorMsg));
        })
      );
    })
  );

export const handleEditValueMeasurements = action$ =>
  action$.pipe(
    ofType(actions.EDIT_MEASUREMENT_VALUE),
    map((action: Action<Measurement>) => action.payload),
    concatMap(
      (measurement: Measurement): Observable<any> =>
        editMeasurementValue(getEditMeasurementRequest(measurement)).pipe(
          concatMap(() => {
            notification('success', 'Measurement was edited successfully!');
            return of(actions.editMeasurementValueSuccess(measurement), actions.toggleMeasurementModal(measurement.id));
          }),
          catchError((error: any) => {
            const errorMsg = notifyErrorOrFallback(error, 'Error editing measurement!');
            return of(actions.editMeasurementValueFailure(errorMsg));
          })
        )
    )
  );

export const handleDeleteMeasurement = action$ =>
  action$.pipe(
    ofType(actions.DELETE_MEASUREMENT),
    map((action: Action<DeleteMeasurementRequestParams>) => action.payload),
    concatMap((deleteMeasurementRequestParams: DeleteMeasurementRequestParams) =>
      deleteMeasurement(deleteMeasurementRequestParams).pipe(
        concatMap(response => {
          notification('success', 'Measurement was deleted successfully!');
          const { measurementId } = deleteMeasurementRequestParams;
          return of(actions.deleteMeasurementSuccess(measurementId), actions.toggleMeasurementModal(measurementId));
        }),
        catchError((error: any) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error deleting measurement!');
          return of(actions.deleteMeasurementFailure(errorMsg));
        })
      )
    )
  );
