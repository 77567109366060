import { UUID } from '../models';
import { Databases, Mayday, MaydayUser, DevicesPage } from './models';
import { GetDevicesPayload } from './services';

const actions = {
  LOAD_DEVICES: '[Mayday - Devices] Load Devices',
  LOAD_DEVICES_SUCCESS: '[Mayday - Devices] Load Devices Success',
  LOAD_DEVICES_FAILURE: '[Mayday - Devices] Load Devices Failure',
  LOAD_DATABASES: '[Mayday - Databases] Load Databases',
  LOAD_DATABASES_SUCCESS: '[Mayday - Databases] Load Databases Success',
  LOAD_DATABASES_FAILURE: '[Mayday - Databases] Load Databases Failure',
  SEND_MAYDAY: '[Mayday] Send Mayday',
  SEND_MAYDAY_SUCCESS: '[Mayday] Send Mayday Success',
  SEND_MAYDAY_FAILURE: '[Mayday] Send Mayday Failure',
  TOGGLE_MAYDAY_MODAL: '[Mayday] Toggle Mayday Modal',

  loadDevices: ({ userId, size, page }: GetDevicesPayload) => ({
    type: actions.LOAD_DEVICES,
    payload: {
      userId,
      page,
      size
    }
  }),

  loadDevicesSuccess: (success: DevicesPage) => {
    return {
      type: actions.LOAD_DEVICES_SUCCESS,
      payload: success
    };
  },

  loadDevicesFailure: (error: string) => ({
    type: actions.LOAD_DEVICES_FAILURE,
    payload: error
  }),

  loadDatabases: (userId: UUID) => ({
    type: actions.LOAD_DATABASES,
    payload: userId
  }),

  loadDatabasesSuccess: (success: Databases) => ({
    type: actions.LOAD_DATABASES_SUCCESS,
    payload: success
  }),

  loadDatabasesFailure: (error: string) => ({
    type: actions.LOAD_DATABASES_FAILURE,
    payload: error
  }),

  sendMayday: (mayday: Mayday) => ({
    type: actions.SEND_MAYDAY,
    payload: mayday
  }),

  sendMaydaySuccess: (success: string) => ({
    type: actions.SEND_MAYDAY_SUCCESS,
    payload: success
  }),

  sendMaydayFailure: (error: string) => ({
    type: actions.SEND_MAYDAY_FAILURE,
    payload: error
  }),

  toggleMaydayModal: (user?: MaydayUser) => ({
    type: actions.TOGGLE_MAYDAY_MODAL,
    payload: user
  })
};

export default actions;
export const MaydayActions = actions;
