import { UUID } from '../models';
import { Field } from './models';

const actions = {
  LOAD_FIELDS_BY_PROPERTY: '[Field] Load Fields By Property',
  LOAD_FIELDS_BY_PROPERTY_SUCCESS: '[Field] Load Fields By Property Success',
  LOAD_FIELDS_BY_PROPERTY_FAILURE: '[Field] Load Fields By Property Failure',
  SET_LAST_PROPERTY_ID_LOAD_FIELDS: '[Field] Set Last Property id',

  loadFieldsByProperty: (propertyId: UUID) => ({
    type: actions.LOAD_FIELDS_BY_PROPERTY,
    payload: propertyId
  }),

  loadFieldsByPropertySuccess: (fields: Field[]) => ({
    type: actions.LOAD_FIELDS_BY_PROPERTY_SUCCESS,
    payload: fields
  }),

  loadFieldsByPropertyFailure: (error: string) => ({
    type: actions.LOAD_FIELDS_BY_PROPERTY_FAILURE,
    payload: error
  }),

  setLastPropertyIdLoadFields: (propertyId: UUID) => ({
    type: actions.SET_LAST_PROPERTY_ID_LOAD_FIELDS,
    payload: propertyId
  })
};

export default actions;

export const FieldActions = actions;
