import { PageableQuery, Action, UUID } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map, concatMap, catchError, withLatestFrom, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import actions from './actions';
import { getFixedPoint, cloneTrapToCompanies, getAllTraps } from '../fixedPoint/service';

import { FixedPoint } from '../fixedPoint/models';
import { notification } from '../../components';
import { injectMetadata } from '../request-control';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';
import { TypesOfTraps } from '../../containers/FixedPointCategories/BasicInfo/basicInfoForm';
import { LoadFixedPointTrapModel } from './models';

export const handleLoadTraps = action$ =>
  action$.pipe(
    ofType(actions.LOAD_TRAPS),
    map((action: Action<PageableQuery>) => action.payload),
    exhaustMap((query: LoadFixedPointTrapModel) =>
      getAllTraps(query.pageableQuery, query.only_template).pipe(
        map((fixedPoints: FixedPoint[]) => actions.loadTrapsSuccess(fixedPoints)),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading fixed points!');
          return of(actions.loadTrapsFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadTrap = action$ =>
  action$.pipe(
    ofType(actions.LOAD_TRAP),
    map((action: Action<UUID>) => action.payload),
    concatMap((fixedPointId: UUID) =>
      getFixedPoint(fixedPointId).pipe(
        map(response => response.data),
        map((fixedPoint: any) => {
          let fixedPointFilter = fixedPoint;
          if (fixedPoint.single_use) {
            fixedPointFilter = { ...fixedPoint, sampling_behaviour: TypesOfTraps.SINGLE_USE_ID };
          }
          return actions.loadTrapSuccess(fixedPointFilter);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading fixed point trap!');
          return of(actions.loadTrapFailure(errorMsg));
        })
      )
    )
  );

export const handleCloneTrapToCompanies = (action$, state$) =>
  action$.pipe(
    ofType(actions.CLONE_TRAP_TO_COMPANIES),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      )
    ),
    concatMap(([action, currentUser]: any) => {
      let { fixedPoint } = action.payload;
      const companies = action.payload.companies_id;
      const fixedPointToSave = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          companies_id: companies,
          clone_trap_template_dto: {
            ...fixedPoint,
            cohese: true,
            inspection_layout: {
              ...fixedPoint.inspection_layout_dto
            }
          }
        }
      };
      fixedPoint = fixedPointToSave;
      return cloneTrapToCompanies(fixedPoint).pipe(
        map(response => {
          fixedPoint.payload.isNew = false;
          const cloneTrapSuccess = injectMetadata(actions.cloneTrapToCompaniesSuccess, { ...action.metadata, response });
          notification('success', 'Fixed Point Trap saved!');
          return cloneTrapSuccess(response.data);
        }),
        catchError((error: any) => {
          let msg;
          let cloneTrapFailure;
          if (error.message.length > 0) {
            msg = error.message;
            notification('error', error.message, error.status);
            cloneTrapFailure = injectMetadata(actions.cloneTrapToCompaniesFailure, {
              ...action.metadata,
              response: error.message
            });
          } else {
            msg = error.response && error.response.data ? error.response.data.message : '';
            notification('error', msg || 'Error saving fixed point trap!');
            cloneTrapFailure = injectMetadata(actions.cloneTrapToCompaniesFailure, {
              ...action.metadata,
              response: error.response
            });
          }
          return of(cloneTrapFailure(msg));
        })
      );
    })
  );
