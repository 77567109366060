import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';
import DashApp from './dashApp';
import CONFIG from './settings';
import './settings/interceptors';
import './settings/themes/generalThemes.style.scss';
import 'antd/dist/antd.css';

CONFIG.env === 'production' &&
  Sentry.init({
    dsn: 'https://83e0d242c453480da78f1a1b456ff7f3@o49647.ingest.sentry.io/2045550',
    integrations: [new Integrations.BrowserTracing()]
  });

ReactDOM.render(
  <BrowserRouter>
    <DashApp />
  </BrowserRouter>,
  document.getElementById('root')
);

const clearCache = async (registration): Promise<void> => {
  const keys = await window.caches.keys();
  await Promise.all(keys.map(key => caches.delete(key))).then(() => {
    registration.unregister();
    window.location.reload(true);
  });
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration, isFirstVerification) => {
    if (isFirstVerification) {
      clearCache(registration);
    }
  }
});
