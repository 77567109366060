import React from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { User } from '../../redux/models';
import { useSelector, shallowEqual } from 'react-redux';
import { notification } from '../../components';
import { AppState } from '../../redux/redux.model';
import { isEmpty } from 'lodash';
import { usePagePermissions, routesByPath } from './AppRouter.hooks';
import { Spin } from 'antd';

const AppRouter = (props: any) => {
  const { location } = props;

  const currentUser: User | null = useSelector((state: AppState) => state.User.currentUser, shallowEqual);

  const routes = usePagePermissions();

  if (!currentUser || isEmpty(routes))
    return (
      <div className='app-router__spin-container'>
        <Spin spinning />
      </div>
    );

  return (
    <Switch location={location}>
      {routes.map(singleRoute => {
        const { path, canAccessPermission } = singleRoute;

        return (
          <Route
            exact
            key={path}
            path={`/${path}`}
            render={args => {
              if (canAccessPermission) {
                const Component = routesByPath[path];
                return <Component {...args} />;
              }

              notification('error', 'You cannot access this route!');
              return <Redirect to='/' />;
            }}
          />
        );
      })}
    </Switch>
  );
};

export default withRouter(AppRouter);
