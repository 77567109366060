import React, { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Actions } from '../../redux/actions';
import { Company, UUID, Threshold } from '../../redux/models';
import { CharacteristicsIndicators } from './characteristicsIndicators.component';
import './characteristicsIndicators.style.scss';
import { SimpleCharacteristicsIndicatorsProps } from './models/characteristicsIndicators.model';
import { getPathEdit } from './utils';

const {
  deleteCharacteristicIndicatorFromMethodologyDeep,
  deleteOnlyIndicatorFromMethodologyDeep,
  toggleShowOnTimeline,
  toggleShowOnInspectionLayout
} = Actions;

export interface CharacteristicsIndicatorsProps {
  id: UUID;
  title: string;
  canonical: boolean;
  timeline: boolean;
  inspectionLayout: boolean;
  indicatorId?: UUID;
  characteristicId?: UUID;
  multiphenomenon?: boolean;
  phenomenonId: UUID;
  company: Company;
  methodologyId: UUID;
  thresholds: Threshold[];
  wrongConfiguration?: boolean;
  deleteCharacteristicIndicatorFromMethodologyDeep: Function;
  deleteOnlyIndicatorFromMethodologyDeep: (indicator: SimpleCharacteristicsIndicatorsProps) => void;
}

export const CharacteristicsIndicatorsClass: FC<CharacteristicsIndicatorsProps> = ({
  id,
  indicatorId,
  characteristicId,
  title,
  canonical,
  timeline,
  inspectionLayout,
  multiphenomenon,
  phenomenonId,
  company,
  methodologyId,
  thresholds,
  wrongConfiguration,
  ...props
}) => {
  const history = useHistory();

  const remove = () => {
    props.deleteCharacteristicIndicatorFromMethodologyDeep({
      id,
      indicatorId,
      characteristicId,
      phenomenonId
    });
  };

  const removeIndicator = () => {
    props.deleteOnlyIndicatorFromMethodologyDeep({
      id,
      indicatorId,
      characteristicId,
      phenomenonId
    });
  };

  const edit = () => {
    const instanceId = characteristicId || indicatorId;
    history.push(getPathEdit(company.id!, phenomenonId, instanceId, methodologyId, id, !characteristicId));
  };

  const dispatch = useDispatch();

  const toggleTimeline = () => indicatorId && dispatch(toggleShowOnTimeline(indicatorId));

  const toggleLayout = () => indicatorId && dispatch(toggleShowOnInspectionLayout(indicatorId));

  return (
    <CharacteristicsIndicators
      id={id}
      indicatorId={indicatorId}
      characteristicId={characteristicId}
      title={title}
      canonical={canonical}
      timeline={timeline}
      inspectionLayout={inspectionLayout}
      phenomenonId={phenomenonId}
      deleteFn={remove}
      deleteIndicatorFn={removeIndicator}
      editFn={edit}
      thresholds={thresholds}
      onLayoutClick={toggleLayout}
      onTimelineClick={toggleTimeline}
      wrongConfiguration={wrongConfiguration}
    />
  );
};

const mapStateToProps = state => ({
  company: state.MethodologyDeep.methodologyDeep.company
});

const mapDispatchToProps = {
  deleteCharacteristicIndicatorFromMethodologyDeep,
  deleteOnlyIndicatorFromMethodologyDeep
};

export default connect(mapStateToProps, mapDispatchToProps)(CharacteristicsIndicatorsClass);
