import React from 'react';

export interface EditGlyphProps {
  readonly color?: string;
}

const EditGlyph = (props: EditGlyphProps) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      d='M11.9152 2.34013L13.6277 4.05883C14.1065 4.51658 14.126 5.27676 13.6715 5.75871L12.4239 7.00965L8.98326 3.56904L10.2527 2.29622C10.7376 1.88508 11.4525 1.90396 11.9152 2.34013Z'
      fill={props.color ? props.color : '#707374'}
    />
    <path
      d='M7.57092 4.98513L2.62764 9.94158C2.42561 10.146 2.29982 10.414 2.27138 10.7006L2.00263 13.3163C1.98558 13.5023 2.05203 13.6862 2.18388 13.8181C2.30183 13.9355 2.46151 14.0009 2.62764 14H2.68389L5.29016 13.7616C5.57567 13.7331 5.84269 13.6068 6.04642 13.4041L11.0115 8.42574L7.57092 4.98513Z'
      fill={props.color ? props.color : '#707374'}
    />
  </svg>
);

export default EditGlyph;
