import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PageableQuery, UUID } from '../app/basicModels';
import { Methodology, MethodologyDefault, MethodologyPage } from './models';
import CONFIG from '../../settings';
import { CommandRequest } from '../../helpers/request/models';
import { MethodologyUpdatePayload } from '../methodologyDeep/models';
import { PhenomenonSingle } from '../models';
import { concatMap, map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { notification } from '../../components';
import { downloadCSV } from './utils';

const METHODOLOGIES_PAGE_SIZE = 300; // cannot be greater than 300
const METHODOLOGIES_BY_COMPANY_PAGE_SIZE = 10;
const demeterMethodologyUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}/api/v1/methodologies`;
const baseMethodologyUrl = `${CONFIG.apiUrl}:${CONFIG.basePort}/v1/methodologies`;
const protectorUrl = CONFIG.protectorApiUrl;
export const cropwiseURL = process.env.REACT_APP_CROPWISE_API_URL || 'http://localhost:8080';

export const getMethodologies = (_query: PageableQuery) => {
  const size = METHODOLOGIES_PAGE_SIZE;
  return getMethodologiesPage({ pageable: { page: 0, size } }).pipe(
    concatMap(({ content, total_pages }) => {
      const pages = Array.from({ length: total_pages - 1 }, (_, i) => i + 1);
      const observables = pages.map(p =>
        getMethodologiesPage({
          pageable: {
            page: p,
            size
          }
        }).pipe(concatMap(methodologiesPage => of(methodologiesPage.content)))
      );
      return forkJoin([of(content), ...observables]);
    }),
    map(methodologies => ({ data: { content: methodologies.flat() } }))
  );
};

export const getMethodologiesPage = (_query: PageableQuery) => {
  return axios
    .get<MethodologyPage>(`${demeterMethodologyUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&shallow=true`)
    .pipe(map(m => m.data));
};

const getMethodologyByCompanyPage = (companyId: UUID, page = 0) => {
  const url = `${protectorUrl}/api/v1/methodologies/by-company/${companyId}?page=${page}&size=${METHODOLOGIES_BY_COMPANY_PAGE_SIZE}&show_deleted=false`;

  return axios.get<MethodologyPage>(url, { headers: { 'X-Company-Id': companyId } }).pipe(map(m => m.data));
};

export const getMethodologiesByCompanyPaginated = (companyId: UUID) => {
  return getMethodologyByCompanyPage(companyId).pipe(
    concatMap(({ content, total_pages }) => {
      const pages = Array.from({ length: total_pages - 1 }, (_, i) => i + 1);

      const observables = pages?.map(currentPageNumber =>
        getMethodologyByCompanyPage(companyId, currentPageNumber).pipe(concatMap(methodologiesPage => of(methodologiesPage?.content)))
      );
      return forkJoin([of(content), ...observables]);
    }),
    map(methodologies => methodologies?.flat())
  );
};

export const getMethodology = (methodologyId: UUID): AxiosObservable<Methodology> => {
  return axios.get<Methodology>(`${demeterMethodologyUrl}/${methodologyId}`);
};

export const saveMethodology = (methodology: any, isNew: boolean): AxiosObservable<Methodology> => {
  if (isNew) {
    return createMethodology(methodology);
  }
  return updateMethodology(methodology);
};

export const cloneMethodology = (
  methodology: any,
  methodologyId: UUID,
  companyId: UUID,
  enableCottonGrowth?: boolean
): AxiosObservable<Methodology> => {
  return enableCottonGrowth
    ? axios.post<Methodology>(`${baseMethodologyUrl}/${methodologyId}/clone-to-company/${companyId}`, methodology)
    : axios.post<Methodology>(`${demeterMethodologyUrl}/${methodologyId}/clone-to-company/${companyId}`, methodology);
};

export const createMethodology = (methodology: CommandRequest<Methodology>): AxiosObservable<Methodology> => {
  return axios.post<Methodology>(`${demeterMethodologyUrl}`, methodology);
};

export const saveCloneMethodology = (methodology: any, id: UUID, company_id_old: UUID): AxiosObservable<Methodology> => {
  return axios.post<Methodology>(`${demeterMethodologyUrl}/${id}/duplicated`, methodology);
};

export const updateMethodology = (methodology: CommandRequest<MethodologyUpdatePayload>): AxiosObservable<Methodology> => {
  return axios.put<Methodology>(`${demeterMethodologyUrl}`, methodology);
};

export const deleteMethodology = (methodology: CommandRequest<Methodology>): AxiosObservable<Methodology> => {
  return axios.put<Methodology>(`${demeterMethodologyUrl}/remove`, methodology);
};

export const deleteMethodologyOnBase = (methodologyId: string): AxiosObservable<Methodology> => {
  return axios.delete<Methodology>(`${baseMethodologyUrl}/${methodologyId}`);
};

export const getMethodologyOnBase = (methodologyId: UUID): AxiosObservable<Methodology> => {
  return axios.get<Methodology>(`${baseMethodologyUrl}/${methodologyId}`);
};

export const saveMethodologyOnBase = (methodology: Methodology): AxiosObservable<Methodology> => {
  const seasonIds = methodology.season_ids;
  const seasonPropertyIds = methodology.season_property_ids;
  const seasonAreaIds = methodology.season_area_ids;
  const cropId = methodology.crop_id;

  return axios.post<Methodology>(`${baseMethodologyUrl}`, {
    companyId: methodology.company_id,
    methodologyId: methodology.id,
    cropId,
    seasonIds,
    seasonPropertyIds,
    seasonAreaIds,
    defaultMethodology: methodology.default_methodology
  });
};

export const getMethodologyDefault = (cropId?: UUID, companyId?: UUID): AxiosObservable<MethodologyDefault> => {
  return axios.get<MethodologyDefault>(`${baseMethodologyUrl}?companyId=${companyId}&cropId=${cropId}`);
};

export const getPhenomenaByMethodology = (methodologyId: UUID): AxiosObservable<PhenomenonSingle[]> => {
  return axios.get<PhenomenonSingle[]>(`${demeterMethodologyUrl}/${methodologyId}/phenomenon`);
};

export const getExtraDimensionsByMethodology = (methodologyId: UUID): AxiosObservable<any> => {
  return axios.get<any>(`${demeterMethodologyUrl}/${methodologyId}/extra-dimensions`);
};

const downloadMethodologyById = (methodologyId: UUID): AxiosObservable<any> => {
  const configs = { headers: { 'content-type': 'text/csv' }, data: {} };
  return axios.get<any>(`${CONFIG.apiUrl}/api/v1/methodologies/${methodologyId}/with-references`, configs);
};

export const fetchDownloadMethodologyById = methodologyId => {
  downloadMethodologyById(methodologyId)
    .toPromise()
    .then(response => {
      downloadCSV(response.data);
    })
    .catch(err => {
      console.error(err);
      notification('error', 'Não foi possivel fazer o download. Verifique a metodologia');
    });
};

export const getOrgsThatUserHasAccessPage = (_query: PageableQuery) => {
  const params = {
    page: _query.pageable.page,
    size: _query.pageable.size
  };

  return axios
    .get(`${cropwiseURL}/v2/orgs`, {
      params
    })
    .pipe(map(response => response.data));
};

export const getOrgsThatUserHasAccess = (_query: PageableQuery) => {
  const size = 100;
  return getOrgsThatUserHasAccessPage({ pageable: { page: 0, size } }).pipe(
    concatMap(({ content, total_pages }) => {
      const pages = Array.from({ length: total_pages - 1 }, (_, i) => i + 1);

      const observables = pages.map(p =>
        getOrgsThatUserHasAccessPage({
          pageable: {
            page: p,
            size
          }
        }).pipe(concatMap(orgsPage => of(orgsPage.content)))
      );

      return forkJoin([of(content), ...observables]);
    }),
    map(methodologies => ({ data: { content: methodologies.flat() } }))
  );
};
