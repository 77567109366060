import { Controller } from 'react-hook-form';
import React from 'react';
import TextArea from 'antd/lib/input/TextArea';

interface TextareaItemProps {
  name: string;
  disabled?: boolean;
  initialValue?: string;
  control: any;
  placeholder?: string;
  allowClear?: boolean;
}

const TextareaItem = ({ name, disabled, initialValue, control, placeholder, allowClear = false }: TextareaItemProps) => (
  <Controller
    name={name}
    as={
      <TextArea id={name} name={name} disabled={disabled} defaultValue={initialValue} placeholder={placeholder} allowClear={allowClear} />
    }
    control={control}
    defaultValue={initialValue}
  />
);

export default TextareaItem;
