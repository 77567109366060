import { InputForm, InputType } from './models';

interface CustomElementWrapper {
  name: string;
  type: string;
}

interface ValidationOptionsWrapper {
  required?: string | boolean;
  min?: number | string;
  max?: number | string;
  maxLength?: number | string;
  minLength?: number | string;
  pattern?: RegExp;
  validate?: Function;
}

/**
 * This method is a utilitary for register inputs on FormBuilder FC. the custom validations are the validations like Regex pattern, maxLength, etc...
 * Try migrate the register for this function. The function of register is beign duplicated anywhere of project.
 * @param {ReadonlyArray<InputForm>} inputs - Inputs of form.
 * @param {Function} register - Register function of useForm() by ReactHook form.
 * @param {JSON} customValidations - A JSON that contains the name of field and the validations for this field. 
 * @example customValidations:{
      inspectionGroup: {
        required: "Inspection Group is required!",
        pattern: {
          value: /[A-Za-z]{1}/,
          message: "Inspection Group cant be a blank space",
        },
      },
    }
 */
export const registerInputs = (
  inputs: InputForm[],
  register: (ref: CustomElementWrapper, validationOptions?: ValidationOptionsWrapper) => void,
  customValidations?: Object
) => {
  inputs.forEach(input => {
    if (input && input.name && input.type !== InputType.EMPTY) {
      const ref = {
        name: input.name,
        type: 'custom'
      };
      const required = input.hasOwnProperty('required') ? input.required : true;
      const inputChildren = input.label && input.label.props.children;
      const childrenMessage = typeof inputChildren === 'object' ? input.name : inputChildren;
      const validation =
        customValidations && customValidations[input.name]
          ? customValidations[input.name]
          : required
          ? { required: childrenMessage + ' is required!' }
          : {};
      register(ref, validation);
    }
  });
};
