import actions from './actions';
import { ServiceProvider, ServiceProviderState } from './models';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';
const entity: EntityAdapter<ServiceProvider> = createEntityAdapter<ServiceProvider>();

export const initialState: ServiceProviderState = entity.getInitialState({
  isLoading: false,
  isLoadingCompaniesByServiceProvider: false,
  isSaving: false,
  error: null,
  options: null,
  companies: null,
  serviceProvidersModal: false,
  selectedServiceProvider: null
});

export default (state = initialState, action): ServiceProviderState => {
  switch (action.type) {
    case actions.LOAD_SERVICE_PROVIDERS:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_SERVICE_PROVIDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        options: action.payload
      };

    case actions.LOAD_SERVICE_PROVIDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.LOAD_COMPANIES_BY_SERVICE_PROVIDER:
      return {
        ...state,
        isLoading: true,
        isLoadingCompaniesByServiceProvider: true
      };

    case actions.LOAD_COMPANIES_BY_SERVICE_PROVIDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companies: action.payload,
        isLoadingCompaniesByServiceProvider: false
      };

    case actions.LOAD_COMPANIES_BY_SERVICE_PROVIDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingCompaniesByServiceProvider: false,
        error: action.payload
      };

    case actions.TOGGLE_SERVICE_PROVIDERS_MODAL:
      return {
        ...state,
        serviceProvidersModal: !state.serviceProvidersModal,
        selectedServiceProvider: !state.serviceProvidersModal && action.payload ? action.payload : null
      };

    case actions.DELETE_COMPANY_FROM_SERVICE_PROVIDER_COMPANIES:
      return {
        ...state,
        companies: (state.companies && state.companies.filter(c => c !== action.payload)) || []
      };

    case actions.ADD_COMPANY_IN_SERVICE_PROVIDER_COMPANIES:
      return {
        ...state,
        companies: state.companies ? [...state.companies, action.payload] : [action.payload]
      };

    case actions.CLEAR_SERVICE_PROVIDER_COMPANIES:
      return {
        ...state,
        companies: []
      };

    case actions.CREATE_SERVICE_PROVIDER:
      return {
        ...state,
        isSaving: true
      };

    case actions.CREATE_SERVICE_PROVIDER_SUCCESS:
      return {
        ...state,
        isSaving: false,
        options: state.options ? [...state.options, action.payload] : [action.payload],
        selectedServiceProvider: action.payload
      };

    case actions.CREATE_SERVICE_PROVIDER_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case actions.UPDATE_SERVICE_PROVIDER:
      return {
        ...state,
        isSaving: true
      };

    case actions.UPDATE_SERVICE_PROVIDER_SUCCESS:
      return {
        ...state,
        isSaving: false,
        options: state.options ? [...state.options.filter(sp => sp.id !== action.payload.id), action.payload] : [action.payload],
        selectedServiceProvider: action.payload
      };

    case actions.UPDATE_SERVICE_PROVIDER_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case actions.DELETE_SERVICE_PROVIDER_SUCCESS:
      return {
        ...state,
        options: (state.options && [...state.options.filter(sp => sp.id !== action.payload)]) || []
      };

    case actions.DELETE_SERVICE_PROVIDER_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};
