import { VariableValidation } from '../models';

const actions = {
  SET_VARIABLE_VALIDATION: '[VariableValidation] SET VariableValidation',
  SET_VARIABLE_VALIDATION_TO_SEND: '[VariableValidation] SET_TO_SEND VariableValidation',
  CLEAR_VARIABLE_VALIDATION_TO_SEND: '[VariableValidation] CLEAR_TO_SEND VariableValidation',
  CHANGE_VARIABLE_VALIDATION: '[VariableValidation] CHANGE VariableValidation',

  setVariableValidationList: (variableValidationList: VariableValidation[]) => ({
    type: actions.SET_VARIABLE_VALIDATION,
    payload: variableValidationList
  }),

  hasChangeListVariableValidate: (hasChange: boolean) => ({
    type: actions.CHANGE_VARIABLE_VALIDATION,
    payload: hasChange
  }),

  setVariableValidationToSend: (variableValidationList?: VariableValidation[]) => ({
    type: actions.SET_VARIABLE_VALIDATION_TO_SEND,
    payload: variableValidationList || undefined
  }),

  clearVariableValidationToSend: () => ({
    type: actions.CLEAR_VARIABLE_VALIDATION_TO_SEND,
    payload: true
  })
};

export default actions;

export const VariableValidationActions = actions;
