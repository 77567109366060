import { LanguageStage } from '../../redux/language/models';
import { UUID } from '../../redux/models';

export enum ActionButtonType {
  DETAILS,
  EDIT,
  CLONE,
  CLONETEMPLATE,
  PLANS,
  DELETE,
  TRANSLATE,
  REMOVE,
  DOWNLOAD
}

export interface OrderedButtonProps {
  type: ActionButtonType;
  titlePopConfirm?: string;
  textId?: string;
  dotOnFinalText?: boolean;
  color?: string;
  show: boolean;
  objectId?: UUID;
  translateContext?: string;
  translateStage?: LanguageStage[];
  callback?: Function | Object;
}
