import React from 'react';
import { Controller } from 'react-hook-form';
import SuperSelect from 'antd-virtual-select';
import { Option as OptionModel, ModeEnum } from '../../models';

const Option = SuperSelect.Option;

interface VirtualSelectProps {
  name: string;
  initialValue?: string;
  mode?: ModeEnum;
  options?: Array<OptionModel>;
  loading?: boolean;
  disabled?: boolean;
  control: any;
  allowClear?: boolean;
}

const VirtualSelect = ({ name, initialValue, mode, options = [], loading, disabled = false, control, allowClear = true }: VirtualSelectProps) => (
  <Controller
    data-testid={`virtual-input-${name}`}
    name={name}
    as={
      <SuperSelect
        {...(mode && { mode })}
        showSearch
        allowClear={allowClear}
        optionFilterProp='name'
        autoClearSearchValue
        disabled={disabled}
        loading={loading}
        notFoundContent={loading ? 'Loading...' : 'Not found!'}
        getPopupContainer={trigger => trigger.parentNode}>
        {options.map(option => (
          <Option
            key={option.id}
            value={option.id}
            name={`${option.name} ${option.name.toString().toLowerCase()} ${option.name.toString().toUpperCase()}`}>
            {option.name}
          </Option>
        ))}
      </SuperSelect>
    }
    control={control}
    defaultValue={initialValue}
  />
);

export default VirtualSelect;
