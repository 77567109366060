import React from 'react';
const ChevronDownGlyph = props => (
  <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.231231 0.23964C0.53954 -0.07988 1.03941 -0.07988 1.34772 0.23964L5 4.02473L8.65228 0.23964C8.96059 -0.07988 9.46046 -0.07988 9.76877 0.23964C10.0771 0.55916 10.0771 1.0772 9.76877 1.39672L5.55824 5.76036C5.24993 6.07988 4.75007 6.07988 4.44176 5.76036L0.231231 1.39672C-0.0770772 1.0772 -0.0770772 0.55916 0.231231 0.23964Z'
      fill={props.color}
    />
  </svg>
);

export default ChevronDownGlyph;
