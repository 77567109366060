import actions from './actions';
import { MaydayState } from './models';

const initialState: MaydayState = {
  isDevicesLoading: false,
  isDatabasesLoading: false,
  isSendingMayday: false,
  error: null,
  modalActive: false,
  user: null,
  devices: [],
  databases: []
};

export default (state = initialState, action): MaydayState => {
  switch (action.type) {
    case actions.LOAD_DEVICES:
      return {
        ...state,
        isDevicesLoading: true
      };

    case actions.LOAD_DEVICES_SUCCESS:
      return {
        ...state,
        isDevicesLoading: false,
        devices: action.payload
      };

    case actions.LOAD_DATABASES:
      return {
        ...state,
        isDatabasesLoading: true,
        error: action.payload
      };

    case actions.LOAD_DATABASES_SUCCESS:
      return {
        ...state,
        isDatabasesLoading: false,
        databases: action.payload
      };

    case actions.LOAD_DEVICES_FAILURE:
    case actions.LOAD_DATABASES_FAILURE:
      return {
        ...state,
        isDatabasesLoading: false,
        error: action.payload
      };

    case actions.SEND_MAYDAY:
      return {
        ...state,
        isSendingMayday: true
      };

    case actions.SEND_MAYDAY_SUCCESS:
      return {
        ...state,
        error: null,
        isSendingMayday: false
      };

    case actions.SEND_MAYDAY_FAILURE:
      return {
        ...state,
        isSendingMayday: false,
        error: action.payload
      };

    case actions.TOGGLE_MAYDAY_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        user: action.payload
      };

    default:
      return state;
  }
};
