import { Methodology, UUID } from '../models';
import { MethodologyPlans, MethodologyPlansFlat } from './models'

const actions = {
  LOAD_METHODOLOGY_PLANS: '[Methodology Template] Load methodology plans',
  LOAD_METHODOLOGY_PLANS_SUCCESS: '[Methodology Template] Load methodology plans success',
  LOAD_METHODOLOGY_PLANS_FAILURE: '[Methodology Template] Load methodology plans failure',
  SAVE_METHODOLOGY_PLANS: '[Methodology Template] Save methodology plans',
  SAVE_METHODOLOGY_PLANS_SUCCESS: '[Methodology Template] Save methodology plans success',
  SAVE_METHODOLOGY_PLANS_FAILURE: '[Methodology Template] Save methodology plans failure',
  DELETE_METHODOLOGY_PLANS: '[Methodology Template] Delete methodology plans',
  DELETE_METHODOLOGY_PLANS_SUCCESS: '[Methodology Template] Delete methodology plans success',
  DELETE_METHODOLOGY_PLANS_FAILURE: '[Methodology Template] Delete methodology plans failure',
  UPDATE_METHODOLOGY_PLANS: '[Methodology Template] Update methodology plans',
  UPDATE_METHODOLOGY_PLANS_SUCCESS: '[Methodology Template] Update methodology plans success',
  UPDATE_METHODOLOGY_PLANS_FAILURE: '[Methodology Template] Update methodology plans failure',
  TOGGLE_ADD_TEMPLATE_MODAL: '[Methodology Template] Toggle add template modal',
  TOGGLE_TEMPLATE_PLANS_MODAL: '[Methodology Template] Toggle template plans modal',

  saveMethodologyPlans: (methodologyTemplateId: UUID, plan_ids: Array<UUID>) => ({
    type: actions.SAVE_METHODOLOGY_PLANS,
    payload: { methodology_id: methodologyTemplateId, plan_ids }
  }),

  saveMethodologyPlansSuccess: (methodologyPlans: MethodologyPlans) => ({
    type: actions.SAVE_METHODOLOGY_PLANS_SUCCESS,
    payload: methodologyPlans
  }),

  saveMethodologyPlansFailure: (error: string) => ({
    type: actions.SAVE_METHODOLOGY_PLANS_FAILURE,
    payload: error
  }),

  loadMethodologyPlans: () => ({
    type: actions.LOAD_METHODOLOGY_PLANS,
  }),

  loadMethodologyPlansSuccess: (methodologyPlans: MethodologyPlansFlat[]) => ({
    type: actions.LOAD_METHODOLOGY_PLANS_SUCCESS,
    payload: methodologyPlans
  }),

  loadMethodologyPlansFailure: (error: string) => ({
    type: actions.LOAD_METHODOLOGY_PLANS_FAILURE,
    payload: error
  }),

  deleteMethodologyPlans: (methodologyTemplateId: UUID) => ({
    type: actions.DELETE_METHODOLOGY_PLANS,
    payload: methodologyTemplateId
  }),

  deleteMethodologyPlansSuccess: () => ({
    type: actions.DELETE_METHODOLOGY_PLANS_SUCCESS,
  }),

  deleteMethodologyPlansFailure: (error: string) => ({
    type: actions.DELETE_METHODOLOGY_PLANS_FAILURE,
    payload: error
  }),

  updateMethodologyPlans: (methodologyTemplateId: UUID, plans_ids) => ({
    type: actions.UPDATE_METHODOLOGY_PLANS,
    payload: { methodologyTemplateId, plans_ids }
  }),

  updateMethodologyPlansSuccess: (methodologyPlans: MethodologyPlans) => ({
    type: actions.UPDATE_METHODOLOGY_PLANS_SUCCESS,
    payload: methodologyPlans
  }),

  updateMethodologyPlansFailure: (error: string) => ({
    type: actions.UPDATE_METHODOLOGY_PLANS_FAILURE,
    payload: error
  }),

  toggleAddTemplateModal: (cloneModal: boolean, cloneMethodology?: Methodology) => ({
    type: actions.TOGGLE_ADD_TEMPLATE_MODAL,
    payload: { cloneModal: cloneModal, cloneMethodology: cloneMethodology }
  }),

  toggleTemplatePlansModal: (templatePlans?: any) => ({
    type: actions.TOGGLE_TEMPLATE_PLANS_MODAL,
    payload: { templatePlans }
  }),
};

export default actions;

export const MethodologyTemplateActions = actions;
