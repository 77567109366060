import { BasicUser, User } from '../../redux/models';
import { CommandRequest } from './models';

/**
 * Build a Request Body with account and payload to send to Demeter. Is the Demeter pattern for creation and edition of objects.
 *
 * @param object
 * @param currentUser
 */
export function commandRequest<T>(object: T, currentUser: User): CommandRequest<T> {
  const account: BasicUser = {
    id: currentUser.id,
    name: currentUser.name
  };
  return { account, payload: object };
}
