import { I18nMap, UUID } from '../app/basicModels';
import { Company } from '../company/models';
import { ProductSet } from '../productSet/models';
import { EntityState } from 'redux-ngrx-entity';
import { IndicatorDiagnostic, Expression } from '../indicator/models';
import { SeasonTree, Methodology, Characteristic, IndependentVariable, Crop } from '../models';
import { CustomVariableType, InputDefinition } from '../characteristic/models';
import { ExtraDimension } from '../extraDimensions/models';

export interface MethodologyDeepState extends EntityState<MethodologyDeep> {
  methodologyDeep: MethodologyDeep | null;
  customVariables: CustomVariableType[];
  error: string | null;
  inspectionGroupName: string | null;
  redirectToMethodologyDeep: boolean;
  tabActive: string;
  inspectionGroupId: UUID | null;
  phenomenonInfo: PhenomenonInfo | null;
  isLoading: boolean;
  isSaving: boolean;
  isCloning: boolean;
  addPhenomModalActive: boolean;
  addExtraDimensionModalActive: boolean;
  addInspectionGroupModalActive: boolean;
  addCharacteristicIndicatorActive: boolean;
  cloneCharacteristicIndicatorActive: boolean;
  addMethodologyModalActive: boolean;
  cloneModal: boolean;
  cloneMethodology?: Methodology;
  clonedVariables: number;
  clonedIndicators: number;
  clonedPhenomena: number;
}

export interface MethodologyDeep {
  id?: UUID;
  name: I18nMap;
  description?: I18nMap;
  isNew: boolean;
  company?: Company;
  crop?: CropDeep;
  productSet?: ProductSet;
  defaultMethodology?: boolean;
  parentId?: UUID;
  seasonsTree?: SeasonTreeDeep;
  inspectionLayout?: InspectionLayoutDeep;
  analyticContextId?: UUID;
  multiPhenomenaIndicators?: IndicatorDeep[];
  defaultIndicatorsNotFound?: { indicatorId: UUID; characteristic: Characteristic }[];
  modified_at?: string;
  deleted_at?: string | null;
  wrongIndicators?: IndicatorDeep[];
  variablesValidation?: VariableValidation[];
  characteristicIds?: string[];
}

export interface CropDeep {
  id?: UUID;
  name?: I18nMap;
}

export interface SeasonTreeDeep {
  seasons?: SeasonDeep[];
  seasonProperties?: SeasonPropertyDeep[];
  seasonAreas?: SeasonAreaDeep[];
}

export interface SeasonDeep {
  id?: UUID;
  name?: string;
}

export interface SeasonPropertyDeep {
  id?: UUID;
  name?: string;
}

export interface SeasonAreaDeep {
  id?: UUID;
  name?: string;
}

export interface InspectionLayoutDeep {
  id: UUID;
  inspectionGroups: InspectionGroupDeep[];
  independentVariables?: IndependentVariable[];
  variablesValidation?: VariableValidation[];
}

export interface VariableValidation {
  id: string;
  phenomenon_id: string;
  operation: string;
  main_variable: MainVariable;
  compared_with: ComparedWith;
}

export interface MainVariable {
  operand_id: string;
  type: string;
}

export interface ComparedWith {
  operand_id: string;
  type: string;
}

export interface InspectionGroupDeep {
  id: UUID;
  name?: I18nMap;
  inspectionGroupId: UUID;
  priority: number;
  categories?: CategoryDeep[];
  extraDimensions?: ExtraDimensionWrapperDeep;
  collapsed?: boolean;
}

export interface ExtraDimensionWrapperDeep {
  id: UUID;
  collapsed?: boolean;
  components: ExtraDimensionsDeep[];
}
export interface ExtraDimensionsDeep {
  id: UUID;
  name?: I18nMap;
  description?: I18nMap;
  companyId?: UUID;
  priority: number;
  collapsed?: boolean;
}
export interface CategoryDeep {
  id: UUID;
  name?: I18nMap;
  categoryId: UUID;
  priority: number;
  phenomenons?: PhenomenonDeep[];
  collapsed?: boolean;
  comesFromCanonical?: boolean;
}

export interface PhenomenonDeep {
  id: UUID;
  name?: I18nMap;
  phenomenonId: UUID;
  priority?: number;
  components?: ComponentDeep[];
  collapsed?: boolean;
  comesFromCanonical?: boolean;
  scientificName?: I18nMap;
}

export interface ComponentDeep {
  indicator?: IndicatorDeep;
  characteristic?: CharacteristicDeep;
  priority?: number;
}

export interface IndicatorDeep {
  id: UUID;
  indicatorId: UUID;
  name: I18nMap;
  isDefault: boolean;
  priority?: number;
  showOnInspectionLayout: boolean;
  showOnTimeline: boolean;
  showOnHeatmap: boolean;
  diagnostics: IndicatorDiagnostic[];
  characteristicsIds?: UUID[];
  phenomenonIds?: UUID[];
  comesFromCanonical?: boolean;
  wrongConfiguration?: boolean;
  expression?: Expression;
}

export interface CharacteristicDeep {
  id: UUID;
  characteristicId: UUID;
  name: I18nMap;
  priority?: number;
  comesFromCanonical?: boolean;
  aliasesIds?: UUID[];
  input_definition?: InputDefinition;
}

export enum ClassName {
  CHARACTERISTIC = 'OrderedCharacteristicDTO',
  INDICATOR = 'OrderedIndicatorDTO'
}

export interface BasicFormPayload {
  name?: string;
  seasons?: string[];
  description?: string;
  productSet?: string;
  seasonsTree?: SeasonTree[];
}

export interface PhenomenonInfo {
  id: UUID;
  phenomenonId: UUID;
}
export interface MethodologyUpdatePayload {
  id: UUID;
  companyId: UUID | null;
  methodology_dto: Methodology;
}

export interface CustomVariablesGetDTO {
  content: CustomVariableGetDTO[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  number_of_elements: number;
  size: number;
  total_elements: number;
  total_pages: number;
}

export interface CustomVariableGetDTO {
  class_name: string;
  company_id: string;
  created_at: string;
  deleted_at: string;
  modified_at: string;
  crop_id: string;
  description: Description;
  id: string;
  key: string;
  last_modified_by_user_id: string;
  name: Description;
  uom: string;
  value: number;
}

export interface Description {
  localized_strings: LocalizedStrings;
}

export interface LocalizedStrings {
  [id: string]: string;
}

export interface SaveMethodologyPayload {
  methodologyDeep: MethodologyDeep;
  plans?: UUID[];
  showNotification?: boolean;
}

export interface ConvertToMethodologyDeepProps {
  methodology;
  phenomenonsTree;
  company?: Company;
  crops?: Crop[];
  seasonTree?: SeasonTree[];
  independentVariables?: Characteristic[];
  extraDimensions?: ExtraDimension[];
  flagPreventIndicatorDuplicity?: boolean;
}
