import { EntityState } from 'redux-ngrx-entity';

export interface Language {
  drawer: Object;
  current: Options;
  options: Options[];
  newOptions: Options[];
}

export interface LanguageState extends EntityState<Language> {
  drawer: {
    isOpen: boolean;
    context: string;
  };
  current: Options;
  options: Options[];
  newOptions: Options[];
}

export type Drawer = {
  isOpen: boolean;
};

export enum newLanguagesOptions {
  EN = 'en',
  US = 'en-US',
  PT = 'pt-BR',
  ES = 'es',
  AR = 'es-AR',
  DE = 'de-DE',
  FR = 'fr-FR',
  PL = 'pl-PL',
  HU = 'hu-HU',
  IT = 'it-IT'
}

export enum Options {
  EN = 'en',
  US = 'en-US',
  PT = 'pt-BR',
  ES = 'es',
  AR = 'es-AR',
  DE = 'de-DE',
  FR = 'fr-FR',
  PL = 'pl-PL',
  HU = 'hu-HU'
}

export interface LanguageStage {
  language: string;
  stage: TranslationStages;
}

export enum TranslationStages {
  COMPLETE = 'COMPLETE',
  PARTIAL = 'PARTIAL',
  NONE = 'NONE'
}
