import { map, concatMap, catchError } from 'rxjs/operators';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';
import { Action, UUID } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import actions from './actions';
import { getRegionalRiskOrgs, updateRegionalRiskOrgsByOrg } from './service';

export const handleLoadRegionalRiskOrgs = action$ =>
  action$.pipe(
    ofType(actions.LOAD_REGIONAL_RISK_ORGS),
    map((action: Action) => action.payload),
    concatMap(() =>
      getRegionalRiskOrgs().pipe(
        map(response => response.data),
        map(products => {
          return actions.LoadRegionalRiskOrgsSuccess(products);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading Regional Risk Organizations!');
          return of(actions.LoadRegionalRiskOrgsFailure(errorMsg));
        })
      )
    )
  );

export const handleUpdateRegionalRiskOrgsByOrg = action$ =>
  action$.pipe(
    ofType(actions.UPDATE_REGIONAL_RISK_ORGS_BY_ORG),
    map((action: Action<UUID>) => action.payload),
    concatMap(({ companyId, avizioOrgList, qualimetreOrgList }) => {
      return updateRegionalRiskOrgsByOrg(companyId, avizioOrgList, qualimetreOrgList).pipe(
        map(response => response.data),
        map(() => {
          return actions.updateRegionalRiskOrgsByOrgSuccess();
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error updating Regional Risk Access!');
          return of(actions.updateRegionalRiskOrgsByOrgFailure(errorMsg));
        })
      );
    })
  );
