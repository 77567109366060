import React from 'react';

const frFRA = () => (
  <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path d='M0 0H5.33316V12H0V0Z' fill='#002395' />
    <path d='M5.33344 0H10.6666V12H5.33344V0Z' fill='white' />
    <path d='M10.6669 0H16V12H10.6669V0Z' fill='#ED2939' />
  </svg>
);

export default frFRA;
