import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PageableQuery, TalosPayload, TalosResponse, UUID } from '../app/basicModels';
import { Characteristic, CharacteristicPage, IndependentVariable } from './models';
import CONFIG from '../../settings';
import { cloneDeep } from 'lodash';
import { concatMap, map, retryWhen, switchMap, take } from 'rxjs/operators';
import { forkJoin, of, throwError, timer } from 'rxjs';

export const characteristicUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}/api/v1/characteristics`;
const cloneCharacteristicUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}/v1/characteristics`;
const CHARACTERISTICS_PAGE_SIZE = 5000;

export const getCharacteristic = (characteristicId: UUID): AxiosObservable<Characteristic> => {
  return axios.get<Characteristic>(`${characteristicUrl}/${characteristicId}`);
};

export const saveCharacteristic = (characteristic: TalosPayload<Characteristic>, isNew: boolean): AxiosObservable<TalosResponse> => {
  if (isNew) {
    return createCharacteristic(characteristic);
  }
  return updateCharacteristic(characteristic);
};

export const createCharacteristic = (characteristic: TalosPayload<Characteristic>): AxiosObservable<TalosResponse> => {
  return axios.post<TalosResponse>(`${characteristicUrl}`, characteristic);
};

export const updateCharacteristic = (characteristic: TalosPayload<Characteristic>): AxiosObservable<TalosResponse> => {
  return axios.put<TalosResponse>(`${characteristicUrl}`, characteristic);
};

export const deleteCharacteristic = (characteristic: Characteristic): AxiosObservable<TalosResponse> => {
  return axios.put<TalosResponse>(`${characteristicUrl}/remove`, characteristic);
};

export const getIndependentVariable = (id: UUID): AxiosObservable<IndependentVariable> => {
  return axios.get<IndependentVariable>(`${characteristicUrl}/${id}`);
};

export const getIndependentVariables = (_query: PageableQuery, companyId: UUID) => {
  return getAllCharacteristics(companyId, true);
};

export const getAllCharacteristics = (
  companyId?: UUID,
  only_independent_variables?: boolean,
  showCanonicals?: boolean,
  showDeleted?: boolean,
  disableCache?: boolean
) => {
  const size = CHARACTERISTICS_PAGE_SIZE;
  return getCharacteristicsPage({ pageable: { page: 0, size } }, companyId, only_independent_variables, showCanonicals, showDeleted, disableCache).pipe(
    concatMap(({ content, total_pages }) => {
      const pages = Array.from({ length: total_pages - 1 }, (_, i) => i + 1);
      const observables = pages.map(p =>
        getCharacteristicsPage({ pageable: { page: p, size } }, companyId, only_independent_variables, showCanonicals, showDeleted, disableCache).pipe(
          concatMap(characteristicsPage => of(characteristicsPage.content))
        )
      );
      return forkJoin([of(content), ...observables]);
    }),
    map(phenomenons => ({ data: { content: phenomenons.flat(), total_pages: 0, size: 0, pages: 0, total: 0 } }))
  );
};

export const getCharacteristicsPage = (
  _query: PageableQuery,
  company_id?: UUID,
  only_independent_variables?: boolean,
  showCanonicals?: boolean,
  showDeleted?: boolean,
  disableCache?: boolean
) => {
  let url = `${characteristicUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}`;
  if (company_id) {
    url += `&company_id=${company_id}`;
  }
  if (only_independent_variables !== undefined) {
    url += `&only_independent_variables=${only_independent_variables}`;
  }
  if (showCanonicals !== undefined) {
    url += `&showCanonicals=${showCanonicals}`;
  }
  if (showDeleted !== undefined) {
    url += `&showDeleted=${showDeleted}`;
  }

  let headers = {};
  if (disableCache) {
    headers = {"Cache-Control": 'no-cache'};
  }
  
  return axios.get<CharacteristicPage>(url, { headers }).pipe(
    map(c => c.data),
    retryWhen(errors =>
      errors.pipe(
        switchMap((error, index) => {
          if ((error.response?.status === 500 || error.response?.status === 503) && index < 2) {
            const delay = 500 * 2 ** index;
            return timer(delay);
          }
          return throwError(error);
        }),
        take(3)
      )
    )
  );
};

export const getCharacteristics = (_query: PageableQuery, phenomenonId: UUID): AxiosObservable<CharacteristicPage> => {
  return axios.get<CharacteristicPage>(
    `${characteristicUrl}/by-phenomenon/${phenomenonId}?page=${_query.pageable.page}&size=${_query.pageable.size}`
  );
};

export const findAllCharacteristics = (_query: PageableQuery, companyId: UUID, showCanonicals: boolean, disableCache: boolean = false
) => {
  return getAllCharacteristics(companyId, undefined, showCanonicals, false, disableCache);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const findAllCharacteristicsCanonicals = (_query: PageableQuery) => {
  return getAllCharacteristics(undefined, undefined, true, false);
};

export const cloneCharacteristic = (
  characteristicId: UUID,
  companyId: UUID,
  enableCottonGrowth?: boolean
): AxiosObservable<Characteristic> => {
  return enableCottonGrowth
    ? axios.post(`${cloneCharacteristicUrl}/${characteristicId}/clone-to-company/${companyId}`)
    : axios.post(`${characteristicUrl}/${characteristicId}/clone-to-company/${companyId}`);
};

export function updateCharacteristicInPhenomenonsTree(characteristic: Characteristic, phenomenonsTree: any) {
  // eslint-disable-next-line prefer-const
  let newPhenomenonsTree = cloneDeep(phenomenonsTree);
  if (characteristic && characteristic.phenomenon_id) {
    newPhenomenonsTree.forEach(phenomenon => {
      if (characteristic.phenomenon_id === phenomenon.id) {
        if (!phenomenon.characteristics) phenomenon.characteristics = [];
        const characteristicIndex = phenomenon.characteristics.findIndex(c => c.id === characteristic.id);
        if (characteristicIndex !== -1) {
          phenomenon.characteristics[characteristicIndex] = characteristic;
        } else {
          phenomenon.characteristics.push(characteristic);
        }
      }
    });
  }

  return newPhenomenonsTree;
}
