import React from 'react';

const CloseGlyph = props => (
  <svg
    role='img'
    width='10'
    height='10'
    viewBox='0 0 10 10'
    fill='none'
    xmlns='https://www.w3.org/2000/svg'
    data-testid={props.testId || 'closeGlyph'}>
    <path
      d='M0.279338 0.279337C-0.0931125 0.651788 -0.0931122 1.25565 0.279338 1.6281L3.65124 5L0.279338 8.3719C-0.0931124 8.74435 -0.0931126 9.34821 0.279337 9.72066C0.651788 10.0931 1.25565 10.0931 1.6281 9.72066L5 6.34876L8.3719 9.72066C8.74435 10.0931 9.34821 10.0931 9.72066 9.72066C10.0931 9.34821 10.0931 8.74435 9.72066 8.3719L6.34876 5L9.72066 1.6281C10.0931 1.25565 10.0931 0.651787 9.72066 0.279337C9.34821 -0.0931126 8.74435 -0.0931124 8.3719 0.279338L5 3.65124L1.6281 0.279338C1.25565 -0.0931124 0.651788 -0.0931126 0.279338 0.279337Z'
      fill={props.color || '#FFF'}
    />
  </svg>
);

export default CloseGlyph;
