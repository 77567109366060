import { UUID, PageableQuery } from '../app/basicModels';
import { PhenomenonCategory, LoadPhenomenonCategoryType, PhenomenonCategoryPage } from './models';

const actions = {
  TOGGLE_PHENOMENON_CATEGORY_EDIT_MODAL: '[Phenomenon Category] Toggle phenomenon category edit modal',
  LOAD_PHENOMENON_CATEGORIES: '[Phenomenon Category] Load Phenomenon Categories',
  LOAD_PHENOMENON_CATEGORIES_SUCCESS: '[Phenomenon Category] Load Phenomenon Categories Success',
  LOAD_PHENOMENON_CATEGORIES_FAILURE: '[Phenomenon Category] Load Phenomenon Categories Failure',
  SAVE_PHENOMENON_CATEGORY: '[Phenomenon Category] Save Phenomenon Category',
  SAVE_PHENOMENON_CATEGORY_SUCCESS: '[Phenomenon Category] Save Phenomenon Category Success',
  SAVE_PHENOMENON_CATEGORY_FAILURE: '[Phenomenon Category] Save Phenomenon Category Failure',
  LOAD_PHENOMENON_CATEGORY: '[Phenomenon Category] Load Phenomenon Category',
  LOAD_PHENOMENON_CATEGORY_SUCCESS: '[Phenomenon Category] Load Phenomenon Category Success',
  LOAD_PHENOMENON_CATEGORY_FAILURE: '[Phenomenon Category] Load Phenomenon Category Failure',
  DELETE_PHENOMENON_CATEGORY: '[Phenomenon Category] Delete Phenomenon Category',
  DELETE_PHENOMENON_CATEGORY_SUCCESS: '[Phenomenon Category] Delete Phenomenon Category Success',
  DELETE_PHENOMENON_CATEGORY_FAILURE: '[Phenomenon Category] Delete Phenomenon Category Failure',
  CLEAR_PHENOMENON_CATEGORY_SELECT: '[Phenomenon Category] Clear Phenomenon Category Select',

  togglePhenomenonCategoryEditModal: () => ({
    type: actions.TOGGLE_PHENOMENON_CATEGORY_EDIT_MODAL
  }),

  loadPhenomenonCategories: (
    pageableQuery: PageableQuery,
    showCanonicals: boolean = true,
    companyId: string | null | undefined = null
  ) => ({
    type: actions.LOAD_PHENOMENON_CATEGORIES,
    payload: {
      pageableQuery,
      companyId,
      showCanonicals
    }
  }),

  loadPhenomenonCategoriesSuccess: (categoryPage: PhenomenonCategoryPage) => ({
    type: actions.LOAD_PHENOMENON_CATEGORIES_SUCCESS,
    payload: categoryPage
  }),

  loadPhenomenonCategoriesFailure: (error: string) => ({
    type: actions.LOAD_PHENOMENON_CATEGORIES_FAILURE,
    payload: error
  }),

  savePhenomenonCategory: (category: PhenomenonCategory) => ({
    type: actions.SAVE_PHENOMENON_CATEGORY,
    payload: category
  }),

  savePhenomenonCategorySuccess: (category: PhenomenonCategory) => ({
    type: actions.SAVE_PHENOMENON_CATEGORY_SUCCESS,
    payload: category
  }),

  savePhenomenonCategoryFailure: (error: string) => ({
    type: actions.SAVE_PHENOMENON_CATEGORY_FAILURE,
    payload: error
  }),

  loadPhenomenonCategory: (id: UUID) => ({
    type: actions.LOAD_PHENOMENON_CATEGORY,
    payload: id
  }),

  loadPhenomenonCategorySuccess: (category: PhenomenonCategory) => ({
    type: actions.LOAD_PHENOMENON_CATEGORY_SUCCESS,
    payload: category
  }),

  loadPhenomenonCategoryFailure: (error: string) => ({
    type: actions.LOAD_PHENOMENON_CATEGORY_FAILURE,
    payload: error
  }),

  deletePhenomenonCategory: (category: PhenomenonCategory) => ({
    type: actions.DELETE_PHENOMENON_CATEGORY,
    payload: category
  }),

  deletePhenomenonCategorySuccess: (deleted: boolean) => ({
    type: actions.DELETE_PHENOMENON_CATEGORY_SUCCESS,
    payload: deleted
  }),

  deletePhenomenonCategoryFailure: (error: string) => ({
    type: actions.DELETE_PHENOMENON_CATEGORY_FAILURE,
    payload: error
  }),
  clearPhenomenonCategorySelect: () => ({
    type: actions.CLEAR_PHENOMENON_CATEGORY_SELECT
  })
};

export default actions;

export const PhenomenonCategoryActions = actions;
