import actions from './actions';
import { RegionalRiskState } from './models';

const initialState: RegionalRiskState = {
  isLoading: false,
  error: null,
  avizioOrgs: [],
  qualimetreOrgs: [],
  avizioPermissions: [],
  qualimetrePermissions: [],
  isSavedOrgs: false,
  isSavingOrgs: false,
  isLoadingTechnicians: false,
  isLoadingUserPermissions: false,
  isSavingUserPermissions: false,
  techniciansList: [],
  userPermissionsList: {}
};

export default (state = initialState, action): RegionalRiskState => {
  switch (action.type) {
    case actions.LOAD_REGIONAL_RISK_ORGS:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case actions.LOAD_REGIONAL_RISK_ORGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        avizioOrgs: action.payload.avizio,
        qualimetreOrgs: action.payload.qualimetre
      };

    case actions.LOAD_REGIONAL_RISK_ORGS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.LOAD_REGIONAL_RISK_ORGS_BY_ORG:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case actions.LOAD_REGIONAL_RISK_ORGS_BY_ORG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        avizioPermissions: action.payload.avizio,
        qualimetrePermissions: action.payload.qualimetre
      };

    case actions.LOAD_REGIONAL_RISK_ORGS_BY_ORG_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.UPDATE_REGIONAL_RISK_ORGS_BY_ORG:
      return {
        ...state,
        isSavedOrgs: false,
        isSavingOrgs: true
      };

    case actions.UPDATE_REGIONAL_RISK_ORGS_BY_ORG_SUCCESS:
      return {
        ...state,
        isSavedOrgs: true,
        isSavingOrgs: false
      };

    case actions.UPDATE_REGIONAL_RISK_ORGS_BY_ORG_ERROR:
      return {
        ...state,
        isSavedOrgs: false,
        isSavingOrgs: false,
        error: action.payload
      };

    case actions.LOAD_REGIONAL_RISK_TECHNICIANS:
      return {
        ...state,
        isLoadingTechnicians: true,
        techniciansList: []
      };

    case actions.LOAD_REGIONAL_RISK_TECHNICIANS_SUCCESS:
      return {
        ...state,
        isLoadingTechnicians: false,
        techniciansList: action.payload
      };

    case actions.LOAD_REGIONAL_RISK_TECHNICIANS_ERROR:
      return {
        ...state,
        isLoadingTechnicians: false,
        error: action.payload
      };

    case actions.LOAD_REGIONAL_RISK_USER_PERMISSIONS:
      return {
        ...state,
        isLoadingUserPermissions: true,
        userPermissionsList: {}
      };

    case actions.LOAD_REGIONAL_RISK_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoadingUserPermissions: false,
        userPermissionsList: action.payload
      };

    case actions.LOAD_REGIONAL_RISK_USER_PERMISSIONS_ERROR:
      return {
        ...state,
        isLoadingUserPermissions: false,
        error: action.payload
      };

    case actions.UPDATE_REGIONAL_RISK_USER_PERMISSIONS:
      return {
        ...state,
        isSavingUserPermissions: true
      };

    case actions.UPDATE_REGIONAL_RISK_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isSavingUserPermissions: false
      };

    case actions.UPDATE_REGIONAL_RISK_USER_PERMISSIONS_ERROR:
      return {
        ...state,
        isSavingUserPermissions: false,
        error: action.payload
      };

    case actions.RESET_SAVED_ORGS:
      return {
        ...state,
        isSavedOrgs: false,
        isSavingOrgs: false,
        error: null
      };
    case actions.RESET_SAVED_EXTERNAL_USERS:
      return {
        ...state,
        isLoadingUserPermissions: false,
        techniciansList: [],
        userPermissionsList: {},
        error: null
      };

    default:
      return state;
  }
};
