import { QueryClient } from 'react-query';
import { UserTypeEnum, Authority, OrgUser } from '../../redux/models';
import { Option } from '../../components/formBuilder/models';
import { Dictionary } from 'redux-ngrx-entity';

export enum RBACQueryType {
  GET_WORKSPACE_OPERATIONS = 'GET_WORKSPACE_OPERATIONS',
  GET_ALL_ROLES_BY_USER = 'GET_ALL_ROLES_BY_USER',
  GET_USERS_BY_ORGID = 'GET_USERS_BY_ORGID',
  GET_PENDING_USERS_BY_ORGID = 'GET_PENDING_USERS_BY_ORGID'
}

export enum RBACPermissionTypesEnum {
  READ = 'read',
  WRITE = 'write',
  DELETE = 'delete',
  NEW = 'new'
}

export enum RBACActions {
  METHODOLOGY = 'methodology',
  FIXED_POINTS = 'fixed_points',
  CANONICAL_CROP = 'canonical_crop',
  ADMIN_DEVICES = 'admin_devices',
  FINANCIAL = 'financial',
  CANONICAL_PHENOMENON = 'canonical_phenomenon',
  CANONICAL_CHARACTERISTICS = 'canonical_characteristics',
  CANONICAL_INDICATORS = 'canonical_indicators',
  CANONICAL_PHENOMENON_CATEGORY = 'canonical_phenomenon_category',
  CANONICAL_MULTI_PHENOMENON_INDICATOR = 'canonical_multi_phenomenon_indicator',
  CANONICAL_PRODUCT = 'canonical_product',
  CANONICAL_PRODUCT_SET = 'canonical_product_set',
  CANONICAL_VENDOR = 'canonical_vendor',
  ADMIN_ACCESS = 'admin_access',
  CUSTOM_CHARACTERISTICS = 'custom_characteristics',
  CUSTOM_INDICATORS = 'custom_indicators',
  CUSTOM_PHENOMENON = 'custom_phenomenon',
  CUSTOM_CROPS = 'custom_crops',
  ADMIN_DATA_MANAGEMENT = 'admin_data_management'
}

export interface RBACOperationsProps {
  [key: string]: RBACPermissionTypesEnum[];
}

export interface RBACWorkspaceOperations {
  [key: string]: RBACOperationsProps;
}

export interface RBACParsedOperations {
  allowed_operations: RBACWorkspaceOperations;
  denied_operations: RBACWorkspaceOperations;
}

export interface RBACOperations {
  action: string;
  resource: string;
}

export interface RBACGetUsersByOrgResponse {
  content: User[];
  pageable: Pageable;
  total_elements: number;
  last: boolean;
  total_pages: number;
  first: boolean;
  sort: Sort;
  number: number;
  number_of_elements: number;
  size: number;
  empty: boolean;
  accept_status?: string;
}

export interface Pageable {
  sort: Sort;
  page_number: number;
  page_size: number;
  offset: number;
  unpaged: boolean;
  paged: boolean;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface RBACGetWorkspaceOperationsResponse {
  account_id: string;
  allowed_operations: RBACOperations[];
  denied_operations: RBACOperations[];
}

export enum RBACLevels {
  WORKSPACE = 'workspace',
  PROPERTY = 'property',
  SYSTEM = 'system',
  ORG = 'org',
  APP = 'app',
  USER = 'user'
}

export interface RBACDefaultRequestProps {
  workspaceId?: string | null;
  propertyId?: string | null;
  companyId?: string | null;
  appId?: string | null;
}

export interface RBACMakeResourceByLevelProps extends RBACDefaultRequestProps {
  level: RBACLevels;
}

export interface RBACSetUpOperationsForRequestProps extends RBACDefaultRequestProps {
  operations: RBACOperations[];
}

export type RBACGetWorkspaceOperationsProps = RBACSetUpOperationsForRequestProps;

interface RBACCreateOperationProps extends RBACDefaultRequestProps {
  action: RBACActions;
  permission: RBACPermissionTypesEnum;
  level: RBACLevels;
}
export type CreateOperationByRBACLevels = RBACCreateOperationProps;

export type GetAllRolesByUserProps = {
  orgId: string;
};

export type GetAllRolesByUserResponse = {
  empty: boolean;
  first: boolean;
  last: boolean;
  number_of_elements: number;
  pageable: {
    current_key: any;
    size: number;
    next_key: any;
  };
  content: Array<ContentRolesRBAC>;
};

export interface ContentRolesRBAC {
  id: string;
  account_id: string;
  role_id: string;
  name: string;
  context: string;
  version: number;
  level: string;
  property_id?: string;
  resource_attachment: string;
  resources: Array<string>;
  accept_status?: string;
}

export interface AllRolesByUserParsed {
  [key: string]: {
    [key: string]: {
      resource: string;
      role_id?: string;
    };
  };
}

export interface RBACAvailableForUserProps {
  verifyRBACAvailableForUser: () => boolean;
}

export interface GetPermissionUserProps {
  invalidCallback?: () => void;
  rbacOperations: Array<{
    rbacActions: RBACActions;
    rbacLevels: RBACLevels;
    rbacPermission: RBACPermissionTypesEnum[];
  }>;
}

export enum LevelPermissionsEnum {
  ORGANIZATION = 'ORGANIZATION',
  PROPERTY = 'PROPERTY',
  USER = 'USER'
}

export interface handleOnSaveProps {
  data: any;
  orgId: string;
  actionType?: ActionTypesEnum;
  userTypeSelected: UserTypeEnum;
  dispatch: Function;
  workspaceId: string;
  companiesOptions?: Option[];
  providersByUser?: string[];
}

export interface PayloadInviteRBACProps {
  id?: string;
  name: string;
  phone: string;
  type?: string;
  email?: string;
  locale: string;
  authorities?: Authority[];
  job_title?: string;
  company?: string[];
  country_code: string;
  rbac_roles?: RBACFormatToSendProps[];
  /**
   * @deprecated Use name instead.
   */
  first_name?: string;
  /**
   * @deprecated Use name instead.
   */
  last_name?: string;
}

export interface RBACFormatToSendProps {
  org_id?: string;
  role_context?: string;
  role_id: string;
  propertyHashToVerify?: string;
  workspace_id: string;
  property_id?: string;
  app_id?: string;
}

export interface RBACMinimumDataRole {
  resource: string;
  role_id: string;
}

export interface UpdateUserRolesRBACRequestParams {
  updates: Update[];
}

export interface Update {
  operation: string;
  roles: RBACFormatToSendProps[];
}

export interface PayloadDeleteUserRBACProps {
  userRoles: GetAllRolesByUserResponse['content'];
  queryClient: QueryClient;
  workspaceID: string;
  userID: string;
  orgID: string;
}

export interface User {
  created_at: string;
  updated_at: string;
  created_by_id: string;
  last_updated_by_id: string;
  deleted: boolean;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  authorities?: any[];
  type: string;
  ssn: null;
  role: string;
  job_title: string;
  phone: string;
  locale: string;
  clock_format: string | null;
  date_format: null;
  tutorial_complete: boolean;
  opt_ins: any[];
  pending_change_password: boolean;
  identity_provider: string;
  country_code: string;
  email_verified: boolean;
  photo_uri: string;
  login: string;
  id: string;
}

export enum ActionTypesEnum {
  ADD = 'ADD',
  EDIT = 'EDIT'
}

export interface InviteControleCertoParams {
  id?: string;
  type: string;
  password?: string;
  email: string;
  org_creation_strategy: string;
  opt_ins: any[];
  invite_id: string | null;
  terms_accepted_on: string;
  rbac_roles?: RBACFormatToSendProps[];
  companiesByServiceProvider?: string[];
}

export interface ListOrganizationProvider {
  id: string;
  workspaceID: string;
  name?: string;
}

export interface CreateRegularInviteParams {
  dispatch: Function;
  orgId: string;
  workspaceId: string;
  payload: PayloadCreateRegularInviteParams;
}

export interface PayloadCreateRegularInviteParams {
  email: string;
  name: string;
  locale: string;
  country_code: string;
  phone: string;
  job_title: string;
  rbac_roles: RBACFormatToSendProps[];
}

export interface EditRegularInviteParams {
  dispatch: Function;
  userID: string;
  orgId: string;
  payload: PayloadInviteRBACProps;
  workspaceId: string;
}

export interface CreateManagedInviteParams {
  dispatch: Function;
  orgId: string;
  payload: any;
}

export interface EditManagedInviteParams {
  userID: string;
  orgId: string;
  workspaceId: string;
  dispatch: Function;
  dataSent: PayloadInviteRBACProps & {
    language: string;
  };
  rbac_roles: RBACFormatToSendProps[];
}

export interface CreateControleCertoInviteParams {
  dispatch: Function;
  orgId: string;
  hasMultipleOrgs: boolean;
  rolesSelectsMultipleOrg: Dictionary<RBACFormatToSendProps>;
  payload: any;
  password: string;
}

export interface ResponseCreateUserManaged {
  created_at: string;
  updated_at: string;
  created_by_id: string;
  last_updated_by_id: string;
  deleted: boolean;
  name: string;
  first_name: null;
  last_name: null;
  email: null;
  authorities: any[];
  type: string;
  ssn: null;
  role: string;
  job_title: null;
  phone: null;
  locale: string;
  clock_format: string;
  date_format: null;
  tutorial_complete: boolean;
  opt_ins: any[];
  pending_change_password: boolean;
  identity_provider: string;
  country_code: string;
  email_verified: boolean;
  photo_uri: string;
  login: string;
  id: string;
}

export interface EditControleCertoInviteParams {
  dispatch: Function;
  userID: string;
  orgId: string;
  workspaceId: string;
  hasMultipleOrgs: boolean;
  providersByUser?: string[];
  companiesOptions?: Option[];
  data: any;
  payload: any;
}

export interface GenerateInputsForBasicInfoDataProps {
  dataInput: any;
  actionType: ActionTypesEnum;
  userType: UserTypeEnum;
  userByEmail: OrgUser | null;
}
export enum RBACActionsEnum {
  ADD = 'ADD',
  EDIT = 'EDIT',
  REMOVE = 'REMOVE'
}
export interface RemoveOrAddRolesForMultipleOrgsProps {
  action: RBACActionsEnum;
  userID: string;
  data: any;
  companiesOptions: Option[];
  changedOrgsList: string[];
}

export enum RolesLevelAdminEnum {
  ProtectorAdminCC = 'ProtectorAdminCC',
  ProtectorAdminSupportAgent = 'ProtectorAdminSupportAgent',
  ProtectorAdminETDENG = 'ProtectorAdminETDENG'
}
