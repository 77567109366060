import { Organization } from '../../../../redux/models';

export const typeHasRole = (type, role): boolean => type.values.includes(role);

export const countMatches = (providersByUser: string[], orgIds: string[] = []) => {
  return orgIds.filter(orgId => providersByUser.includes(orgId)).length;
};

export const getBestMatch = (acc: Organization, actual: Organization, providersByUser: string[]) => {
  const oldMatches = countMatches(providersByUser, acc.provider?.organizations);
  const newMatches = countMatches(providersByUser, actual.provider?.organizations);
  return newMatches > oldMatches ? actual : acc;
};
