import { UUID } from '../models';
import { Measurement, LoadMeasurementsRequestParams } from './models';

const actions = {
  LOAD_MEASUREMENTS_BY_PROPERTY: '[Measurement] Load Measurements By Property',
  LOAD_MEASUREMENTS_BY_PROPERTY_SUCCESS: '[Measurement] Load Measurements By Property Success',
  LOAD_MEASUREMENTS_BY_PROPERTY_FAILURE: '[Measurement] Load Measurements By Property Failure',
  SET_RANGE_KEY: '[Measurement] Set Range Key',
  TOGGLE_FILTER_MODAL: '[Measurement] Toggle Filter Modal',
  UPSERT_MEASUREMENTS_BY_PROPERTY: '[Measurement] Upsert Measurements By Propert',
  EDIT_MEASUREMENT_VALUE: '[Measurement] Edit Measurements Value',
  EDIT_MEASUREMENT_VALUE_SUCCESS: '[Measurement] Edit Measurements Value Success',
  EDIT_MEASUREMENT_VALUE_FAILURE: '[Measurement] Edit Measurements Value Failure',
  DELETE_MEASUREMENT: '[Measurement] Delete Measurement',
  DELETE_MEASUREMENT_SUCCESS: '[Measurement] Delete Measurement Success',
  DELETE_MEASUREMENT_FAILURE: '[Measurement] Delete Measurement Failure',
  TOGGLE_MEASUREMENT_MODAL: '[Measurement] Toggle Measurement Modal',

  loadMeasurementsByProperty: (params: LoadMeasurementsRequestParams) => ({
    type: actions.LOAD_MEASUREMENTS_BY_PROPERTY,
    payload: { ...params }
  }),

  loadMeasurementsByPropertySuccess: (measurements: Measurement[]) => ({
    type: actions.LOAD_MEASUREMENTS_BY_PROPERTY_SUCCESS,
    payload: measurements
  }),

  loadMeasurementsByPropertyFailure: (error: string) => ({
    type: actions.LOAD_MEASUREMENTS_BY_PROPERTY_FAILURE,
    payload: error
  }),

  setRangeKey: (rangeKey: string) => ({
    type: actions.SET_RANGE_KEY,
    payload: rangeKey
  }),

  toggleFilterModal: () => ({
    type: actions.TOGGLE_FILTER_MODAL
  }),

  upsertMeasurementsByProperty: (measurements: Measurement[]) => ({
    type: actions.UPSERT_MEASUREMENTS_BY_PROPERTY,
    payload: measurements
  }),

  editMeasurementValue: (measurement: Measurement) => ({
    type: actions.EDIT_MEASUREMENT_VALUE,
    payload: measurement
  }),

  editMeasurementValueSuccess: (measurement: Measurement) => ({
    type: actions.EDIT_MEASUREMENT_VALUE_SUCCESS,
    payload: measurement
  }),

  editMeasurementValueFailure: (error: string) => ({
    type: actions.EDIT_MEASUREMENT_VALUE_FAILURE,
    payload: error
  }),

  deleteMeasurement: (payload: any) => ({
    type: actions.DELETE_MEASUREMENT,
    payload: payload
  }),

  deleteMeasurementSuccess: (measurementId: UUID) => ({
    type: actions.DELETE_MEASUREMENT_SUCCESS,
    payload: measurementId
  }),

  deleteMeasurementFailure: (error: string) => ({
    type: actions.DELETE_MEASUREMENT_FAILURE,
    payload: error
  }),

  toggleMeasurementModal: (measurementId?: UUID) => ({
    type: actions.TOGGLE_MEASUREMENT_MODAL,
    payload: measurementId
  })
};

export default actions;

export const MeasurementActions = actions;
