import { Action, UUID } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map, concatMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import actions from './actions';
import { getProductSets, saveProductSet, getProductSet, deleteProductSet } from './service';
import { ProductSetPage, ProductSet, LoadProductSetQueryPayload } from './models';
import { notification } from '../../components';
import uuid from 'uuid/v4';
import { injectMetadata } from '../request-control';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';


export const handleLoadProductSets = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PRODUCT_SETS),
    map((action: Action<LoadProductSetQueryPayload>) => action.payload),
    concatMap((query: any) =>
      getProductSets(query.pageableQuery, query.companyId, query.showCanonicals).pipe(
        map(response => response.data),
        map((products: ProductSetPage) => {
          return actions.loadProductSetsSuccess(products);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading product sets!');
          return of(actions.loadProductSetsFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadProductSet = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PRODUCT_SET),
    map((action: Action<UUID>) => action.payload),
    concatMap((productId: UUID) =>
      getProductSet(productId).pipe(
        map(response => response.data),
        map((product: ProductSet) => {
          return actions.loadProductSetSuccess(product);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading product set!');
          return of(actions.loadProductSetFailure(errorMsg));
        })
      )
    )
  );

export const handleSaveProductSet = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_PRODUCT_SET),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      )
    ),
    concatMap(([action, currentUser]: any) => {
      let product = action.payload;
      const productToSave = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          ...product,
          cohese: true
        }
      };
      if (product.isNew) {
        productToSave.payload.id = uuid();
      }

      product = productToSave;

      return saveProductSet(product, product.payload.isNew).pipe(
        map(response => {
          const saveProductSetSuccess = injectMetadata(actions.saveProductSetSuccess, { ...action.metadata, response });
          return saveProductSetSuccess(product.payload);
        }),
        catchError((error: any) => {
          const msg = error.response && error.response.data ? error.response.data.message : '';
          const saveProductSetFailure = injectMetadata(actions.saveProductSetFailure, { ...action.metadata, response: error.response });

          return of(saveProductSetFailure(msg));
        })
      );
    })
  );

export const handleDeleteProductSet = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_PRODUCT_SET),
    map((action: Action<ProductSet>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      )
    ),
    map(([product, currentUser]: any) => {
      const productToSave = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          ...product
        }
      };
      return productToSave;
    }),
    concatMap((product: any) =>
      deleteProductSet(product).pipe(
        map(response => {
          notification('success', 'Product Set deleted!');
          return actions.deleteProductSetSuccess(product.payload);
        }),
        catchError((error: any) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error deleting product set!');
          return of(actions.deleteProductSetFailure(errorMsg));
        })
      )
    )
  );
