import React, { Component } from 'react';
import { Input as AntdInput } from 'antd';
import { InputWrapper, InputGroupWrapper, InputSearchWrapper, TextAreaWrapper } from './styles/input.style';
import WithDirection from '../../settings/withDirection';

const { Search, Group } = AntdInput;

const WDStyledInput = InputWrapper(AntdInput);
const StyledInput = WithDirection(WDStyledInput);

const WDInputGroup = InputGroupWrapper(Group);
const InputGroup = WithDirection(WDInputGroup);

const WDInputSearch = InputSearchWrapper(Search);
const InputSearch = WithDirection(WDInputSearch);

const WDTextarea = TextAreaWrapper(AntdInput.TextArea);
const StyledTextarea = WithDirection(WDTextarea);

export default class InputValidation extends Component {
  render() {
    const { children, ...props } = this.props;
    return <StyledInput {...props}>{children}</StyledInput>;
  }
}

export class Textarea extends Component {
  render() {
    const { children, ...props } = this.props;
    return <StyledTextarea {...props}>{children}</StyledTextarea>;
  }
}

const TextArea = Textarea;
const Input = InputValidation;

export { Input, InputSearch, InputGroup, TextArea };
