import actions from './actions';
import { User, UserState, UserTypeEnum } from './models';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';

const entity: EntityAdapter<User> = createEntityAdapter<User>();

export const initialState: UserState = entity.getInitialState({
  isLoading: false,
  isSaving: false,
  inviteUserByEmailActive: false,
  isLoadingUsersList: false,
  isLoadingProvidersCompanies: false,
  error: null,
  isErrorLoadRolesRbac: undefined,
  currentUser: null,
  userInvited: null,
  isUserModalActive: false,
  userTypeSelected: UserTypeEnum.REGULAR,
  isControleCertoUserModalActive: false,
  isSugarcaneActive: false,
  lastOrgId: null,
  roles: null,
  userId: null,
  emailUser: null,
  passwordStatus: null,
  lastUserEdited: null
});

export default (state = initialState, action): UserState => {
  switch (action.type) {
    case actions.DELETE_USER:
    case actions.DELETE_USER_RBAC:
    case actions.LOAD_ROLES:
    case actions.LOAD_USER_BY_EMAIL:
    case actions.LOAD_USER_BY_EMAILV2:
    case actions.LOAD_USER_ME:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_ORG_USERS:
      return {
        ...state,
        isLoadingUsersList: true,
        lastOrgId: action.payload
      };

    case actions.CREATE_USER:
    case actions.CREATE_CONTROLE_CERTO_USER:
    case actions.EDIT_USER:
    case actions.EDIT_CONTROLE_CERTO_USER:
    case actions.INVITE_USER_BY_EMAIL:
      return {
        ...state,
        isSaving: true
      };

    case actions.LOAD_USER_ME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        currentUser: {
          ...state.currentUser,
          ...action.payload
        }
      };

    case actions.INVITE_USER_BY_EMAIL_FAILURE:
    case actions.SAVE_USER_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case actions.DELETE_USER_FAILURE:
    case actions.LOAD_ROLES_FAILURE:
    case actions.LOAD_USER_ME_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.LOAD_ORG_USERS_FAILURE:
      return {
        ...state,
        isLoadingUsersList: false,
        error: action.payload
      };

    case actions.INVITE_USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        isSaving: false,
        inviteUserByEmailActive: false,
        userInvited: action.payload
      };

    case actions.LOAD_ORG_USERS_SUCCESS:
      return entity.addAll(action.payload, {
        ...state,
        isLoadingUsersList: false,
        error: null
      });

    case actions.LOAD_ROLES_RBAC_BY_USER_SUCCESS:
      return entity.updateOne(
        {
          id: action.payload.userId,
          changes: {
            authorities: action.payload.roles
          }
        },
        { ...state, isLoading: false }
      );

    case actions.LOAD_ROLES_RBAC_BY_USER_FAILURE:
      return {
        ...state,
        isErrorLoadRolesRbac: action.payload
      };

    case actions.DELETE_USER_SUCCESS:
      return entity.removeOne(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });

    case actions.SAVE_USER_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isSaving: false,
        error: null,
        isUserModalActive: false,
        userId: null,
        lastUserEdited: action.payload
      });

    case actions.SAVE_CONTROLE_CERTO_USER_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isSaving: false,
        error: null,
        isControleCertoUserModalActive: false,
        userId: null,
        lastUserEdited: action.payload
      });

    case actions.TOGGLE_INVITE_USER_BY_EMAIL_MODAL:
      return {
        ...state,
        inviteUserByEmailActive: action.payload,
        userTypeSelected: UserTypeEnum.REGULAR,
        emailUser: null
      };

    case actions.TOGGLE_ADD_EDIT_USER_MODAL:
      return {
        ...state,
        isControleCertoUserModalActive: false,
        isUserModalActive: action.payload.open,
        userTypeSelected: action.payload.userType,
        userId: action.payload.userId,
        emailUser: null
      };

    case actions.TOGGLE_CONTROLE_CERTO_USER_MODAL:
      return {
        ...state,
        isUserModalActive: false,
        isControleCertoUserModalActive: action.payload.open,
        userId: action.payload.userId,
        emailUser: null
      };

    case actions.SET_SUGARCANE_ORG_PERMISSION:
      return {
        ...state,
        isSugarcaneActive: action.payload
      };

    case actions.SET_IS_LOADING_PROVIDERS_COMPANIES:
      return {
        ...state,
        isLoadingProvidersCompanies: action.payload
      };

    case actions.SET_LAST_ORG_ID_LOAD_USERS:
      return {
        ...state,
        lastOrgId: action.payload
      };

    case actions.LOAD_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        roles: action.payload
      };

    case actions.LOAD_USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        emailUser: action.payload
      };

    case actions.LOAD_USER_BY_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        emailUser: null
      };

    case actions.UPDATE_USER_PASSWORD:
    case actions.CLEAR_USER_PASSWORD:
      return {
        ...state,
        passwordStatus: null,
        error: null
      };

    case actions.UPDATE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        passwordStatus: 'failure',
        error: action.payload
      };

    case actions.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordStatus: 'success',
        error: action.payload
      };

    case actions.CLEAR_SAVED_EDITED_USER:
      return {
        ...state,
        lastUserEdited: null
      };

    case actions.LOAD_ROLES_RBAC_USER_LOGGED_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          authorities: action.payload
        } as User
      };

    case actions.SET_VALUE_IS_SAVING_STATE:
      return {
        ...state,
        isSaving: action.payload
      };
    default:
      return state;
  }
};
