import styled from 'styled-components';
import { borderRadius } from '../../../settings/style-util';
import WithDirection from '../../../settings/withDirection';

const WDImgUploadWrapper = styled.div`
  .isoUploadImage {
    width: 120px;
    height: 120px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    overflow: hidden;
    margin: auto;
    cursor: pointer;
    position: relative;
    ${borderRadius('50%')};

    border-style: dashed;
    border-width: 1px;

    .uploadComponent {
      height: 100%;
    }

    .ant-upload {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .uploader-trigger {
      font-size: 25px;
      z-index: 1;
      background-color: #ffffff;
      padding: 5px;
      border-radius: 50%;

      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
`;

const ImgUploadWrapper = WithDirection(WDImgUploadWrapper);

export { ImgUploadWrapper };
