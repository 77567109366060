import { AsyncAction } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import Actions from '../actions';

const {
  requestStarted,
  requestSucceded,
  requestFailed,
  SAVE_FIXEDPOINT,
  SAVE_FIXEDPOINT_SUCCESS,
  SAVE_FIXEDPOINT_FAILURE,
  SAVE_INDICATOR,
  SAVE_INDICATOR_SUCCESS,
  SAVE_INDICATOR_FAILURE,
  SAVE_PRODUCT,
  SAVE_PRODUCT_SUCCESS,
  SAVE_PRODUCT_FAILURE,
  SAVE_PRODUCT_SET,
  SAVE_PRODUCT_SET_SUCCESS,
  SAVE_PRODUCT_SET_FAILURE,
  SAVE_PHENOMENON_CATEGORY,
  SAVE_PHENOMENON_CATEGORY_SUCCESS,
  SAVE_PHENOMENON_CATEGORY_FAILURE,
  SAVE_VENDOR,
  SAVE_VENDOR_SUCCESS,
  SAVE_VENDOR_FAILURE
} = Actions;

export const handleRequestStarted = action$ =>
  action$.pipe(
    ofType(SAVE_FIXEDPOINT, SAVE_INDICATOR, SAVE_PHENOMENON_CATEGORY, SAVE_PRODUCT, SAVE_PRODUCT_SET, SAVE_VENDOR),
    map((action: AsyncAction) => requestStarted(action.metadata || { id: action.type }))
  );

export const handleRequestSucceded = action$ =>
  action$.pipe(
    ofType(
      SAVE_FIXEDPOINT_SUCCESS,
      SAVE_INDICATOR_SUCCESS,
      SAVE_PHENOMENON_CATEGORY_SUCCESS,
      SAVE_PRODUCT_SUCCESS,
      SAVE_PRODUCT_SET_SUCCESS,
      SAVE_VENDOR_SUCCESS
    ),
    map((action: AsyncAction) => requestSucceded(action.payload, action.metadata))
  );

export const handleRequestFailed = action$ =>
  action$.pipe(
    ofType(
      SAVE_FIXEDPOINT_FAILURE,
      SAVE_INDICATOR_FAILURE,
      SAVE_PHENOMENON_CATEGORY_FAILURE,
      SAVE_PRODUCT_FAILURE,
      SAVE_PRODUCT_SET_FAILURE,
      SAVE_VENDOR_FAILURE
    ),
    map((action: AsyncAction) => requestFailed(action.payload, action.metadata))
  );
