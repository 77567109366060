import { UUID } from '../app/basicModels';
import { SeasonTree } from './models';

const actions = {
  LOAD_SEASONS_TREE: '[Season] Load Seasons Tree',
  LOAD_SEASONS_TREE_SUCCESS: '[Season] Load Seasons Tree Success',
  LOAD_SEASONS_TREE_FAILURE: '[Season] Load Seasons Tree Failure',

  loadSeasonsTree: (companyId: UUID) => ({
    type: actions.LOAD_SEASONS_TREE,
    payload: companyId
  }),

  loadSeasonsTreeSuccess: (seasonsTree: SeasonTree[]) => ({
    type: actions.LOAD_SEASONS_TREE_SUCCESS,
    payload: seasonsTree
  }),

  loadSeasonsTreeFailure: (error: string) => ({
    type: actions.LOAD_SEASONS_TREE_FAILURE,
    payload: error
  })
};

export default actions;

export const SeasonActions = actions;
