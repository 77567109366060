import { Region } from './models';

const actions = {
  LOAD_REGIONS_SUCCESS: '[Regions] Load Regions Success',
  LOAD_REGIONS_FAILURE: '[Regions] Load Regions Failure',

  loadRegionsSuccess: (regions: Region[]) => ({
    type: actions.LOAD_REGIONS_SUCCESS,
    payload: regions
  }),
  loadRegionsFailure: (error: string) => ({
    type: actions.LOAD_REGIONS_FAILURE,
    payload: error
  })
};

export default actions;

export const RegionActions = actions;
