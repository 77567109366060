import { Controlled as CodeMirror } from 'react-codemirror2';
import StyleCodeMirror from './styles/codeMirror.style';

import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/php/php';
import 'codemirror/mode/python/python';

import 'codemirror/theme/ambiance-mobile.css';
import 'codemirror/theme/base16-dark.css';

import 'codemirror/theme/zenburn.css';
import 'codemirror/theme/solarized.css';
import 'codemirror/theme/rubyblue.css';
import 'codemirror/theme/paraiso-dark.css';
import 'codemirror/theme/midnight.css';
import 'codemirror/theme/material.css';
import 'codemirror/theme/hopscotch.css';
import 'codemirror/theme/twilight.css';

const CodeMirrors = StyleCodeMirror(CodeMirror);

export default CodeMirrors;
