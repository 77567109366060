import { UUID, PageableQuery } from '../app/basicModels';
import { DragDropType, LoadMethodologyType, Methodology } from './models';

const actions = {
  LOAD_METHODOLOGIES: '[Methodology] Load Methodologies',
  LOAD_METHODOLOGIES_SUCCESS: '[Methodology] Load Methodologies Success',
  LOAD_METHODOLOGIES_FAILURE: '[Methodology] Load Methodologies Failure',
  LOAD_METHODOLOGIES_BY_COMPANY: '[Methodology] Load Methodologies by Company',
  LOAD_METHODOLOGIES_BY_COMPANY_SUCCESS: '[Methodology] Load Methodologies by Company Success',
  LOAD_METHODOLOGIES_BY_COMPANY_FAILURE: '[Methodology] Load Methodologies by Company Failure',
  ADD_OR_UPDATE_METHODOLOGY: '[Methodology] Update Methodology',
  DELETE_METHODOLOGY: '[Methodology] Delete Methodology',
  DELETE_METHODOLOGY_SUCCESS: '[Methodology] Delete Methodology Success',
  NEW_ORDER_PHENOMENONS: '[Methodology] New Order Phenomenons',
  ADD_INDICATOR_IN_ANALYTIC_CONTEXT: '[Methodology] Add indicator in analytic context',
  UPDATE_METHODOLOGY_DRAFT: '[Methodology] Update methodology draft',
  UPDATE_ORDER: '[Methodology] Update Order',
  LOAD_ERROR_METHODOLOGY_FAILURE: '[Methodology] Load Error Methodology Failure',
  TOGGLE_IS_LOADING: '[Methodology] Toggle is loading',

  loadMethodologies: (pageableQuery: LoadMethodologyType) => ({
    type: actions.LOAD_METHODOLOGIES,
    payload: pageableQuery
  }),

  loadMethodologiesByCompany: (pageableQuery: PageableQuery, companyId: UUID) => ({
    type: actions.LOAD_METHODOLOGIES_BY_COMPANY,
    payload: {
      companyId,
      pageableQuery
    }
  }),
  loadMethodologiesSuccess: (methodologyPage: ReadonlyArray<Methodology>) => ({
    type: actions.LOAD_METHODOLOGIES_SUCCESS,
    payload: methodologyPage
  }),

  loadMethodologiesByCompanySuccess: (methodologyPage: ReadonlyArray<Methodology>) => ({
    type: actions.LOAD_METHODOLOGIES_BY_COMPANY_SUCCESS,
    payload: methodologyPage
  }),

  loadMethodologiesFailure: (error: string) => ({
    type: actions.LOAD_METHODOLOGIES_FAILURE,
    payload: error
  }),

  loadMethodologiesByCompanyFailure: (error: string) => ({
    type: actions.LOAD_METHODOLOGIES_BY_COMPANY_FAILURE,
    payload: error
  }),

  updateMethodology: (methodology: Methodology) => ({
    type: actions.ADD_OR_UPDATE_METHODOLOGY,
    payload: methodology
  }),

  deleteMethodology: (methodology: Methodology) => ({
    type: actions.DELETE_METHODOLOGY,
    payload: methodology
  }),

  deleteMethodologySuccess: (methodology: Methodology) => ({
    type: actions.DELETE_METHODOLOGY_SUCCESS,
    payload: methodology
  }),

  loadErrorMethodologyFailure: (error: string) => ({
    type: actions.LOAD_ERROR_METHODOLOGY_FAILURE,
    payload: error
  }),

  newOrderPhenomenons: (phenomenons: any, inspectionGroupId: UUID) => ({
    type: actions.NEW_ORDER_PHENOMENONS,
    payload: {
      phenomenons,
      inspectionGroupId
    }
  }),

  addIndicatorInAnalyticContext: (indicatorId: UUID, destination: DragDropType) => ({
    type: actions.ADD_INDICATOR_IN_ANALYTIC_CONTEXT,
    payload: {
      indicatorId,
      destination
    }
  }),

  updateMethodologyDraft: (methodology: Methodology) => ({
    type: actions.UPDATE_METHODOLOGY_DRAFT,
    payload: methodology
  }),

  updateOrder: (methodology: Methodology) => ({
    type: actions.UPDATE_ORDER,
    payload: methodology
  }),

  toggleIsLoading: () => ({
    type: actions.TOGGLE_IS_LOADING
  })
};

export default actions;

export const MethodologyActions = actions;
