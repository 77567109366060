import { Action, UUID, initialPageableQuery } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map, concatMap, catchError, withLatestFrom, flatMap } from 'rxjs/operators';
import { of, Observable, concat } from 'rxjs';
import actions from './actions';
import { getIndicator, saveIndicator, deleteIndicator, getIndicators, getIndicatorsForCompany } from './service';
import { fixIndicatorPayload, getIndicatorDeep, updateIndicatorInPhenomenonsTree } from './utils';
import { IndicatorPage, Indicator, LoadIndicatorQueryPayload } from './models';
import { notification } from '../../components';
import phenomenonActions from '../phenomenon/actions';
import methodologyActions from '../methodology/actions';
import methodologyDeepActions from '../methodologyDeep/actions';
import { injectMetadata } from '../request-control';
import {
  updateIndicatorOnMethodologyDeep,
  addComponentToPhenomenonDeep,
  getCharacteristicsIdsByMethodologyDeep,
  isWrongIndicator,
  removeByIndicatorId,
  removeIndicatorFromMethodologyDeep
} from '../methodologyDeep/services';
import { MethodologyDeep, IndicatorDeep } from '../methodologyDeep/models';
import { getComponentDeepWithProperties } from '../methodologyDeep/utils';
import { cloneDeep } from 'lodash';
import { commandRequest } from '../../helpers/request/utils';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';

export const handleLoadIndicators = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_INDICATORS),
    map((action: Action<LoadIndicatorQueryPayload>) => action.payload),
    concatMap((query: LoadIndicatorQueryPayload) =>
      getIndicators(query.pageableQuery, query.companyId, query.showCanonicals).pipe(
        map(response => response.data),
        map((indicators: IndicatorPage) => {
          return actions.loadIndicatorsSuccess(indicators);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading indicators!');
          return of(actions.loadIndicatorsFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadIndicatorsForCompany = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_INDICATORS_FOR_COMPANY),
    map((action: Action<any>) => action.payload),
    concatMap((query: any) =>
      getIndicatorsForCompany(query).pipe(
        map(response => response.data),
        map((indicators: IndicatorPage) => {
          return actions.loadIndicatorsForCompanySuccess(indicators.content);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading indicators for company!');
          return of(actions.loadIndicatorsForCompanyFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadIndicator = action$ =>
  action$.pipe(
    ofType(actions.LOAD_INDICATOR),
    map((action: Action<UUID>) => action.payload),
    concatMap((indicatorId: UUID) =>
      getIndicator(indicatorId).pipe(
        map(response => response.data),
        map((indicator: Indicator) => {
          return actions.loadIndicatorSuccess(indicator);
        }),
        catchError(error => {
          let status;
          if (error.message.length > 0) {
            notification('error', error.message, error.status);
          } else {
            const { response } = error;
            const msg = (response && response.data && response.data.detail) || '';
            status = response && response.data && response.data.status;
            notification('error', 'Error loading indicator!', msg);
          }
          return of(actions.loadIndicatorFailure(status || error));
        })
      )
    )
  );

const retrieveShowOnInspectionLayout = (methodologyDeepInspectionGroups, indicatorId) => {
  let resp = false;
  methodologyDeepInspectionGroups.map(group => {
    group.categories.map(category => {
      category.phenomenons.map(phenomenon => {
        phenomenon.components.map(component => {
          if (component.indicator != undefined) {
            if (component.indicator.indicatorId == indicatorId) {
              resp = component.indicator.showOnInspectionLayout;
            }
          }
        });
      });
    });
  });
  return resp;
};

export const handleSaveIndicator = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_INDICATOR),
    withLatestFrom(
      state$.pipe(map((state: any) => state.MethodologyDeep.methodologyDeep)),
      state$.pipe(map((state: any) => state.Characteristic.allCharacteristics)),
      state$.pipe(map((state: any) => state.User.currentUser))
    ),
    concatMap(([action, methodologyDeep, characteristics, currentUser]: any) => {
      const { indicator } = action.payload;
      const { orderedComponentId, orderedPhenomenonId, methodologyId } = action.payload;
      const payload = fixIndicatorPayload(indicator);
      const request = commandRequest<Indicator>(payload, currentUser);
      return saveIndicator(request, request.payload.isNew, request.payload.default!).pipe(
        concatMap(response => {
          const { payload } = request;
          const saveIndicatorSuccess = injectMetadata(actions.saveIndicatorSuccess, { ...action.metadata, response });
          if (methodologyId) {
            const inspectionLayoutShow = retrieveShowOnInspectionLayout(methodologyDeep.inspectionLayout.inspectionGroups, indicator.id);
            const characteristicsOnMethodology = getCharacteristicsIdsByMethodologyDeep(methodologyDeep);
            const isWrong = isWrongIndicator(payload, characteristicsOnMethodology);
            const indicatorDeep: IndicatorDeep = getIndicatorDeep(payload, orderedComponentId, isWrong, inspectionLayoutShow);
            let methodology: MethodologyDeep = cloneDeep(methodologyDeep);
            if (!payload.isNew) {
              methodology = removeIndicatorFromMethodologyDeep(methodology, payload.id!);
            }
            if (isWrong) {
              const wrongComponents = methodology.wrongIndicators || [];
              const wrongIndicatorIds = wrongComponents.map(i => i.indicatorId);
              if (!wrongIndicatorIds.includes(payload.id!)) {
                wrongComponents.push(indicatorDeep);
                methodology.wrongIndicators = wrongComponents;
              }
            } else {
              methodology.wrongIndicators = removeByIndicatorId(methodology.wrongIndicators, payload.id!);
              if (orderedPhenomenonId) {
                methodology = addComponentToPhenomenonDeep(methodology, orderedPhenomenonId, getComponentDeepWithProperties(indicatorDeep));
              } else {
                methodology = updateIndicatorOnMethodologyDeep(methodology, indicatorDeep);
              }
            }
            notification('success', 'Indicator saved!');
            const actionsSaveIndicatorAndUpdateMethodologyDeep = [
              saveIndicatorSuccess(request.payload),
              methodologyDeepActions.updateMethodologyDeep(methodology)
            ];
            if (action.payload.orderedPhenomenonId) {
              actionsSaveIndicatorAndUpdateMethodologyDeep.push(methodologyDeepActions.toggleAddCharacteristicIndicatorModal());
            }
            return concat(actionsSaveIndicatorAndUpdateMethodologyDeep);
          }
          notification('success', 'Indicator saved!');
          return of(saveIndicatorSuccess({ ...request.payload, isNew: false }));
        }),
        catchError((error: any) => {
          const msg = error.response && error.response.data ? error.response.data.message : '';
          const saveIndicatorFailure = injectMetadata(actions.saveIndicatorFailure, {
            ...action.metadata,
            response: error.response
          });

          return of(saveIndicatorFailure(msg || 'Error saving indicator!'));
        })
      );
    })
  );

export const handleDeleteIndicator = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_INDICATOR),
    map((action: Action<Indicator>) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: any) => state.Methodology.methodologySelect)),
      state$.pipe(map((state: any) => state.Phenomenon.phenomenonsTree)),
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      )
    ),
    map(([indicator, methodology, phenomenonsTree, currentUser]: any) => {
      const mutablePhenomenonsTree = phenomenonsTree;
      let phenomenonIndex = -1;
      let indicatorIndex = -1;
      mutablePhenomenonsTree.forEach((phenomenon, index) => {
        phenomenon.indicators.forEach((indicator_obj, indicator_index) => {
          if (indicator_obj.id === indicator.id) {
            phenomenonIndex = index;
            indicatorIndex = indicator_index;
          }
        });
      });
      if (phenomenonIndex !== -1) {
        const mutableIndicators = mutablePhenomenonsTree[phenomenonIndex].indicators;
        mutableIndicators.splice(indicatorIndex, 1);
        mutablePhenomenonsTree[phenomenonIndex].indicators = mutableIndicators;
      }
      const indicatorToSave = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          ...indicator
        },
        methodology: {
          ...methodology
        },
        phenomenonsTree: mutablePhenomenonsTree
      };
      return indicatorToSave;
    }),
    concatMap((indicator: any) =>
      deleteIndicator({
        account: indicator.account,
        payload: indicator.payload
      } as any).pipe(
        flatMap(() => {
          notification('success', 'Indicator deleted!');
          if (indicator.payload.company_id) {
            return concat([
              phenomenonActions.loadPhenomenonsTree({ ...initialPageableQuery }, indicator.payload.company_id),
              methodologyActions.updateMethodologyDraft(indicator.methodology),
              actions.deleteIndicatorSuccess(indicator.payload)
            ]);
          }
          return of(actions.deleteIndicatorSuccess(indicator.payload));
        }),
        catchError((error: any) => {
          const msg = notifyErrorOrFallback(error, 'Error deleting indicator!');
          return of(actions.deleteIndicatorFailure(msg));
        })
      )
    )
  );

export const handleSaveIndicatorSuccess = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_INDICATOR_SUCCESS),
    map((action: Action<Indicator>) => action.payload),
    concatMap((indicator: Indicator): Observable<any> => {
      const { phenomenonsTree } = state$.value.Phenomenon;
      const newPhenomenonsTree = updateIndicatorInPhenomenonsTree(indicator, phenomenonsTree);
      return of(phenomenonActions.loadPhenomenonsTreeSuccess(newPhenomenonsTree));
    })
  );
