import { combineEpics } from 'redux-observable';

import { handleLoginRequest, handleLogoutRequest, checkAuthorization } from './auth/epics';

import { handleLoadCrops, handleLoadCrop, handleSaveCrop, handleDeleteCrop, handleUpdateCrop, handleLoadCompanyCrops } from './crop/epics';

import { handleLoadProducts, handleLoadProduct, handleSaveProduct, handleDeleteProduct } from './product/epics';

import { handleLoadProductSets, handleLoadProductSet, handleSaveProductSet, handleDeleteProductSet } from './productSet/epics';

import {
  handleLoadPhenomenonCategories,
  handleLoadPhenomenonCategory,
  handleSavePhenomenonCategory,
  handleSavePhenomenonCategorySuccess,
  handleDeletePhenomenonCategory
} from './phenomenonCategory/epics';

import {
  handleLoadPhenomenons,
  handleLoadPhenomenonsByMethodology,
  handleLoadPhenomenon,
  handleSavePhenomenon,
  handleDeletePhenomenon,
  handleLoadPhenomenonsTree,
  handleSavePhenomenonSuccess,
  handleLoadPhenomenonsSimple,
  handleCloneCharacteristicIndicatorToPhenomenon
} from './phenomenon/epics';

import {
  handleLoadCharacteristics,
  handleLoadCharacteristic,
  handleSaveCharacteristic,
  handleLoadIndependentVariables,
  handleDeleteCharacteristic,
  handleSaveCharacteristicSuccess,
  handleFindAllCharacteristics,
  handleFindAllCharacteristicsCanonicals
} from './characteristic/epics';

import {
  handleLoadIndicators,
  handleLoadIndicator,
  handleSaveIndicator,
  handleDeleteIndicator,
  handleSaveIndicatorSuccess,
  handleLoadIndicatorsForCompany
} from './indicator/epics';

import { handleLoadCompanies } from './company/epics';

import { handleLoadSeasonsTree } from './season/epics';

import {
  handleLoadMethodologies,
  handleLoadMethodologiesByCompany,
  handleDeleteMethodology,
  handleNewOrderPhenomenons,
  handleAddNewComponent,
  handleAddNewPhenomenon,
  handleAddIndicatorInAnalyticContext,
  handleUpdateOrder
} from './methodology/epics';

import {
  handleLoadUserMe,
  handleLoadUserMeFailure,
  handleLoadOrgUsersList,
  handleDeleteUser,
  handleInviteUserByEmail,
  handleCreateUser,
  handleCreateControleCertoUser,
  handleEditUser,
  handleEditControleCertoUser,
  handleRemoveSubscriptionUserByCompany,
  loadRoles,
  loadOrgUserByEmail,
  loadOrgUserByEmailV2,
  updateUserPassword,
  handleDeleteUserRBAC
} from './user/epics';

import {
  handleLoadDevelopmentPhases,
  handleSaveDevelopmentPhase,
  handleDeleteDevelopmentPhase,
  handleSaveCustomDevelopmentPhase,
  handleDeleteCustomDevelopmentPhase,
  handleEnableCustomDevelopmentPhase,
  handleEnableDevelopmentPhase
} from './developmentPhase/epics';

import { handleSaveVendorSuccess, handleLoadVendor, handleDeleteVendor, handleLoadVendors, handleSaveVendor } from './vendor/epics';

import {
  handleSaveFixedPoint,
  handleLoadFixedPointByCompany,
  handleLoadFixedPoints,
  handleLoadPhenomenonsOfFixedPoint,
  handleUpdateFixedPoint,
  handleVerifyModals,
  handleLoadFixedPointsByCompany,
  handleLoadInstalledFixedPointsByCompany,
  handleDeleteFixedPoint
} from './fixedPoint/epics';

import { handleLoadTraps, handleLoadTrap, handleCloneTrapToCompanies } from './traps/epics';

import { handleLoadPluviometers, handleLoadFixedPointPluviometer } from './pluviometer/epics';

import { handleLoadGenerics, handleLoadFixedPointGeneric } from './generic/epics';

import { handleRequestStarted, handleRequestSucceded, handleRequestFailed } from './request-control/epics';

import {
  handleLoadMethodologyDeep,
  handleSaveMethodologyDeep,
  handleReorderMethodologyDeep,
  handleUpdateCollapsedMethodologyDeep,
  toggleDefaultMethodologyDeep,
  handleDeleteCharacteristicIndicatorFromMethodologyDeep,
  handleDeleteOnlyIndicatorFromMethodologyDeep,
  handleUpdateBasicInformation,
  handleDeletePhenomenonFromMethodologyDeep,
  handleUpdateCharacteristicFromMethodologyDeep,
  handleAddPhenomenonToMethodologyDeep,
  handleUpsertInspectionGroup,
  handleDeleteInspectionGroupMethodologyDeep,
  handleAddCharacteristicsIndicatorsToMethodologyDeep,
  handleToggleShowOnInspectionLayout,
  handleToggleShowOnTimeline,
  handleRemoveMultPhenonIndicatorFromMethodologyDeep,
  handleSaveMethodologyDeepSuccess,
  handleCloneMethodologyDeep,
  handleDeleteIndependentVariableFromMethodologyDeep,
  handleAddExtraDimensionToMethodologyDeep,
  handleDeleteExtraDimensionToMethodologyDeep,
  handleLoadCustomVariables
} from './methodologyDeep/epics';

import { handleLoadDevices, handleLoadDatabases, handleSendMayday } from './mayday/epics';

import { handleLoadMeasurements, handleEditValueMeasurements, handleDeleteMeasurement } from './measurement/epics';

import { handleLoadPropertyInfo, handleLoadPropertiesByCompany } from './property/epics';

import { handleLoadFieldsByProperty } from './field/epics';

import {
  handleGetServiceProviders,
  handleGetCompaniesByServiceProvider,
  handleCreateServiceProvider,
  handleUpdateServiceProvider,
  handleDeleteServiceProvider
} from './serviceProviders/epics';

import {
  handleLoadTrapMeasurementsByProperty,
  handleEditValueTrapMeasurements,
  handleDeleteTrapMeasurement
} from './trapMeasurement/epics';

import { handleLoadCropwisePlans } from './cropwisePlans/epics';

import {
  handleSaveMethodologyPlans,
  handleLoadMethodologyPlans,
  handleDeleteMethodologyPlans,
  handleUpdateMethodologyPlans
} from './methodologyTemplate/epics';

import { handleCreateExtraDimension, handleLoadAllExtraDimensionsByCompany } from './extraDimensions/epics';

import { handleLoadRegionalRiskOrgs, handleUpdateRegionalRiskOrgsByOrg } from './regionalRisk/epics';

export default combineEpics(
  handleLoadCustomVariables,
  handleLoginRequest,
  handleLogoutRequest,
  checkAuthorization,
  handleLoadCrops,
  handleLoadCrop,
  handleSaveCrop,
  handleDeleteCrop,
  handleLoadProducts,
  handleLoadProduct,
  handleSaveProduct,
  handleDeleteProduct,
  handleLoadProductSets,
  handleLoadProductSet,
  handleSaveProductSet,
  handleDeleteProductSet,
  handleLoadPhenomenonCategories,
  handleLoadPhenomenonCategory,
  handleSavePhenomenonCategory,
  handleSavePhenomenonCategorySuccess,
  handleDeletePhenomenonCategory,
  handleLoadPhenomenons,
  handleLoadPhenomenonsByMethodology,
  handleLoadPhenomenon,
  handleSavePhenomenon,
  handleDeletePhenomenon,
  handleSavePhenomenonSuccess,
  handleLoadPhenomenonsSimple,
  handleCloneCharacteristicIndicatorToPhenomenon,
  handleLoadCharacteristics,
  handleLoadCharacteristic,
  handleLoadIndependentVariables,
  handleSaveCharacteristic,
  handleDeleteCharacteristic,
  handleSaveCharacteristicSuccess,
  handleFindAllCharacteristics,
  handleLoadIndicators,
  handleLoadIndicator,
  handleSaveIndicator,
  handleDeleteIndicator,
  handleSaveIndicatorSuccess,
  handleLoadCompanies,
  handleLoadSeasonsTree,
  handleLoadPhenomenonsTree,
  handleLoadMethodologies,
  handleLoadMethodologiesByCompany,
  handleDeleteMethodology,
  handleNewOrderPhenomenons,
  handleAddNewComponent,
  handleAddNewPhenomenon,
  handleAddIndicatorInAnalyticContext,
  handleLoadUserMe,
  handleLoadUserMeFailure,
  handleLoadDevelopmentPhases,
  handleSaveDevelopmentPhase,
  handleDeleteDevelopmentPhase,
  handleLoadVendor,
  handleDeleteVendor,
  handleLoadVendors,
  handleSaveVendor,
  handleSaveVendorSuccess,
  handleSaveFixedPoint,
  handleDeleteFixedPoint,
  handleLoadFixedPointByCompany,
  handleLoadFixedPoints,
  handleLoadTraps,
  handleLoadTrap,
  handleCloneTrapToCompanies,
  handleLoadPluviometers,
  handleLoadFixedPointPluviometer,
  handleLoadGenerics,
  handleLoadFixedPointGeneric,
  handleLoadFixedPointsByCompany,
  handleLoadInstalledFixedPointsByCompany,
  handleFindAllCharacteristicsCanonicals,
  handleLoadPhenomenonsOfFixedPoint,
  handleLoadIndicatorsForCompany,
  handleUpdateOrder,
  handleUpdateFixedPoint,
  handleVerifyModals,
  handleUpdateCrop,
  handleRequestStarted,
  handleRequestSucceded,
  handleRequestFailed,
  handleSaveMethodologyDeep,
  handleLoadMethodologyDeep,
  handleReorderMethodologyDeep,
  handleUpdateCollapsedMethodologyDeep,
  toggleDefaultMethodologyDeep,
  handleDeleteCharacteristicIndicatorFromMethodologyDeep,
  handleDeleteOnlyIndicatorFromMethodologyDeep,
  handleDeleteIndependentVariableFromMethodologyDeep,
  handleUpdateBasicInformation,
  handleDeletePhenomenonFromMethodologyDeep,
  handleUpdateCharacteristicFromMethodologyDeep,
  handleAddPhenomenonToMethodologyDeep,
  handleUpsertInspectionGroup,
  handleDeleteInspectionGroupMethodologyDeep,
  handleAddCharacteristicsIndicatorsToMethodologyDeep,
  handleToggleShowOnInspectionLayout,
  handleToggleShowOnTimeline,
  handleRemoveMultPhenonIndicatorFromMethodologyDeep,
  handleSaveMethodologyDeepSuccess,
  handleLoadOrgUsersList,
  handleDeleteUser,
  handleDeleteUserRBAC,
  handleInviteUserByEmail,
  handleCreateUser,
  handleCreateControleCertoUser,
  handleEditUser,
  handleEditControleCertoUser,
  handleLoadMeasurements,
  handleRemoveSubscriptionUserByCompany,
  handleLoadFieldsByProperty,
  handleEditValueMeasurements,
  handleLoadPropertyInfo,
  handleLoadPropertiesByCompany,
  handleDeleteMeasurement,
  loadRoles,
  handleGetServiceProviders,
  handleGetCompaniesByServiceProvider,
  handleCreateServiceProvider,
  handleLoadTrapMeasurementsByProperty,
  handleEditValueTrapMeasurements,
  handleDeleteTrapMeasurement,
  handleUpdateServiceProvider,
  handleDeleteServiceProvider,
  handleCloneMethodologyDeep,
  handleLoadCompanyCrops,
  loadOrgUserByEmail,
  loadOrgUserByEmailV2,

  handleSaveCustomDevelopmentPhase,
  handleDeleteCustomDevelopmentPhase,
  handleEnableCustomDevelopmentPhase,
  handleEnableDevelopmentPhase,
  updateUserPassword,
  handleSendMayday,
  handleLoadDevices,
  handleLoadDatabases,
  handleLoadCropwisePlans,
  handleSaveMethodologyPlans,
  handleLoadMethodologyPlans,
  handleDeleteMethodologyPlans,
  handleUpdateMethodologyPlans,
  handleCreateExtraDimension,
  handleLoadAllExtraDimensionsByCompany,
  handleAddExtraDimensionToMethodologyDeep,
  handleDeleteExtraDimensionToMethodologyDeep,
  handleLoadRegionalRiskOrgs,
  handleUpdateRegionalRiskOrgsByOrg
);
