import { UUID, PageableQuery } from '../app/basicModels';
import { FixedPoint } from '../fixedPoint/models';

const actions = {
  LOAD_GENERICS: '[Generic] Load All Generics',
  LOAD_GENERICS_SUCCESS: '[Generics] Load Generics Success',
  LOAD_GENERICS_FAILURE: '[Generics] Load Generics Failure',
  SAVE_GENERIC: '[Generic] Save Generic',
  SAVE_GENERIC_SUCCESS: '[Generic] Save Generic Success',
  SAVE_GENERIC_FAILURE: '[Generic] Save Generic Failure',
  UPDATE_GENERIC: '[Generic] Update Generic',
  LOAD_GENERIC: '[Generic] Load Generic',
  LOAD_GENERIC_SUCCESS: '[Generic] Load Generic Success',
  LOAD_GENERIC_FAILURE: '[Generic] Load Generic Failure',
  DELETE_GENERIC: '[Generic] Delete Generic',
  DELETE_GENERIC_SUCCESS: '[Generic] Delete Generic Success',
  DELETE_GENERIC_FAILURE: '[Generic] Delete Generic Failure',
  SELECT_FIXEDPOINT_GENERIC: '[Generic] Select Fixed Point Generic',
  CLEAR_FIXED_POINT_SELECT_GENERIC: '[Generic] Clear Fixed Point Generic Selected',
  TOGGLE_FIXED_POINT_GENERIC_PHENOMENA_MODAL: '[Generic] Toggle Fixed Point Generic Phenomena Modal',
  TOGGLE_CHARACTERISTICS_INDICATORS_PHENOMENA_GENERIC_MODAL: '[Generic] Toggle Characteristics/Indicators Phenomena Modal',

  loadGenerics: (pageableQuery: PageableQuery, onlyGeneric?: boolean) => ({
    type: actions.LOAD_GENERICS,
    payload: { pageableQuery, onlyGeneric }
  }),
  loadGenericsSuccess: (fixedPointPage: FixedPoint[]) => ({
    type: actions.LOAD_GENERICS_SUCCESS,
    payload: fixedPointPage
  }),
  loadGenericsFailure: (error: string) => ({
    type: actions.LOAD_GENERICS_FAILURE,
    payload: error
  }),
  selectFixedPointGeneric: (fixedPoint: FixedPoint) => ({
    type: actions.SELECT_FIXEDPOINT_GENERIC,
    payload: fixedPoint
  }),
  clearFixedPointSelectGeneric: () => ({
    type: actions.CLEAR_FIXED_POINT_SELECT_GENERIC
  }),

  loadGeneric: (id: UUID) => ({
    type: actions.LOAD_GENERIC,
    payload: id
  }),
  loadGenericSuccess: (fixedPoint: FixedPoint) => ({
    type: actions.LOAD_GENERIC_SUCCESS,
    payload: fixedPoint
  }),

  loadGenericFailure: (error: string) => ({
    type: actions.LOAD_GENERIC_FAILURE,
    payload: error
  }),
  saveGeneric: (fixedPoint: FixedPoint) => ({
    type: actions.SAVE_GENERIC,
    payload: fixedPoint
  }),
  saveGenericSuccess: (fixedPoint: FixedPoint) => ({
    type: actions.SAVE_GENERIC_SUCCESS,
    payload: fixedPoint
  }),
  saveGenericFailure: (error: string) => ({
    type: actions.SAVE_GENERIC_FAILURE,
    payload: error
  }),
  updateGeneric: (fixedPoint: FixedPoint, save = false) => ({
    type: actions.UPDATE_GENERIC,
    payload: {
      fixedPoint,
      save
    }
  }),
  deleteFixedPointGeneric: (fixedPoint: FixedPoint) => ({
    type: actions.DELETE_GENERIC,
    payload: fixedPoint
  }),

  deleteFixedPointGenericSuccess: (deleted: boolean) => ({
    type: actions.DELETE_GENERIC_SUCCESS,
    payload: deleted
  }),

  deleteFixedPointGenericFailure: (error: string) => ({
    type: actions.DELETE_GENERIC_FAILURE,
    payload: error
  }),
  toggleFixedPointGenericPhenomenaModal: () => ({
    type: actions.TOGGLE_FIXED_POINT_GENERIC_PHENOMENA_MODAL
  })
};

export default actions;

export const FixedPointGenericActions = actions;
