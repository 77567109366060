import { ofType } from 'redux-observable';
import { map, catchError, exhaustMap, switchMap } from 'rxjs/operators';
import actions from './actions';
import { of } from 'rxjs';
import { UserCredentials, LoginError } from './models';
import { kAuth } from './service';
import { Action } from '../app/basicModels';
import { notification } from '../../components';

export const handleLoginRequest = action$ =>
  action$.pipe(
    ofType(actions.LOGIN_REQUEST),
    map((action: Action<UserCredentials>) => action.payload),
    exhaustMap((userCredentials: UserCredentials) =>
      kAuth(userCredentials).pipe(
        map(response => {
          const token = response.data;
          localStorage.setItem('striderToken', JSON.stringify(token));
          return actions.loginSuccess(token);
        }),
        catchError((error: LoginError) => {
          console.log('error', error);
          notification('error', 'Usuário ou senha inválidos!');
          return of(actions.loginFailure(error));
        })
      )
    )
  );

export const handleLogoutRequest = action$ =>
  action$.pipe(
    ofType(actions.LOGOUT_REQUEST),
    map(() => {
      if (localStorage.getItem('striderToken')) {
        localStorage.removeItem('striderToken');
      }
      return actions.logoutSuccess();
    }),
    catchError((error: LoginError) => of(actions.logoutFailure(error)))
  );

export const checkAuthorization = action$ =>
  action$.pipe(
    ofType(actions.CHECK_AUTHORIZATION),
    switchMap(async () => {
      const token = localStorage.getItem('striderToken');
      if (token) {
        return actions.loginSuccess(JSON.parse(token));
      }
      return actions.logoutRequest();
    }),
    catchError((error: LoginError) => of(actions.loginFailure(error)))
  );
