const rowStyle = {
  width: '100%',
  display: 'flex',
  flexFlow: 'row wrap'
};
const colStyle = {
  marginBottom: '16px'
};
const floatButton = { position: 'fixed', top: '10%', right: '2%', marginTop: '40px', zIndex: 1 };
const noClickWallStyle = { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999999, cursor: 'no-drop' };

const gutter = 16;

const basicStyle = {
  rowStyle,
  colStyle,
  gutter,
  noClickWallStyle,
  floatButton
};

export default basicStyle;
