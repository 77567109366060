const actions = {
  REQUEST_STARTED: '[RequestControl] Request Started',
  REQUEST_SUCCEDED: '[RequestControl] Request Request Succeded',
  REQUEST_FAILED: '[RequestControl] Request Failed',
  CLEAR_REQUEST_DATA: '[RequestControl] Clear Request Data',
  requestStarted: metadata => ({
    type: actions.REQUEST_STARTED,
    metadata
  }),
  requestSucceded: (payload, metadata) => ({
    type: actions.REQUEST_SUCCEDED,
    payload,
    metadata
  }),
  requestFailed: (message, metadata) => ({
    type: actions.REQUEST_FAILED,
    payload: message,
    metadata
  }),
  clearRequestData: metadata => ({
    type: actions.CLEAR_REQUEST_DATA,
    metadata
  })
};

export default actions;

export const RequestControlActions = actions;
