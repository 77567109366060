import { DeleteTrapMeasurementsRequestParams, LoadTrapMeasurementsRequestParams, TrapMeasurement, TrapStation } from './models';
import { UUID } from '../models';

const actions = {
  LOAD_TRAP_MEASUREMENTS_BY_PROPERTY: '[Trap measurement] Load Trap measurements By Property',
  LOAD_TRAP_MEASUREMENTS_BY_PROPERTY_SUCCESS: '[Trap measurement] Load Trap measurements By Property Success',
  LOAD_TRAP_MEASUREMENTS_BY_PROPERTY_FAILURE: '[Trap measurement] Load Trap measurements By Property Failure',
  LOAD_NEXT_PAGE_TRAP_MEASUREMENTS_BY_PROPERTY: '[Trap measurement] Load Next Page Trap measurements By Property',
  LOAD_NEXT_PAGE_TRAP_MEASUREMENTS_BY_PROPERTY_SUCCESS: '[Trap measurement] Load Next Page Trap measurements By Property Success',
  LOAD_TRAP_STATIONS_BY_PROPERTY_SUCCESS: '[Trap station] Load Trap stations By Property Success',
  LOAD_TRAP_STATIONS_BY_PROPERTY_FAILURE: '[Trap station] Load Trap measurements By Property Failure',
  SET_TRAP_MEASUREMENTS_RANGE_KEY: '[Trap measurement] Set Range Key',
  TOGGLE_TRAP_MEASUREMENT_EDIT_MODAL: '[Trap measurement] Toggle Edit Trap Measurement Modal',
  TOGGLE_TRAP_MEASUREMENT_DETAILS_MODAL: '[Trap measurement] Toggle Trap Measurement Details Modal',
  EDIT_TRAP_MEASUREMENT_VALUE: '[Trap measurement] Edit Trap Measurements Value',
  EDIT_TRAP_MEASUREMENT_VALUE_SUCCESS: '[Trap measurement] Edit Trap Measurements Value Success',
  EDIT_TRAP_MEASUREMENT_VALUE_FAILURE: '[Trap measurement] Edit Trap Measurements Value Failure',
  DELETE_TRAP_MEASUREMENT: '[Trap measurement] Delete Trap Measurement',
  DELETE_TRAP_MEASUREMENT_SUCCESS: '[Trap measurement] Delete Trap Measurement Success',
  DELETE_TRAP_MEASUREMENT_FAILURE: '[Trap measurement] Delete Trap Measurement Failure',

  loadTrapMeasurementsByProperty: (params: LoadTrapMeasurementsRequestParams) => ({
    type: actions.LOAD_TRAP_MEASUREMENTS_BY_PROPERTY,
    payload: { ...params }
  }),

  loadTrapMeasurementsByPropertySuccess: (measurements: TrapMeasurement[]) => ({
    type: actions.LOAD_TRAP_MEASUREMENTS_BY_PROPERTY_SUCCESS,
    payload: measurements
  }),

  loadTrapMeasurementsByPropertyFailure: (error: string) => ({
    type: actions.LOAD_TRAP_MEASUREMENTS_BY_PROPERTY_FAILURE,
    payload: error
  }),

  loadNextPageTrapMeasurementsByProperty: (params: LoadTrapMeasurementsRequestParams) => ({
    type: actions.LOAD_NEXT_PAGE_TRAP_MEASUREMENTS_BY_PROPERTY,
    payload: { ...params }
  }),

  loadNextPageTrapMeasurementsByPropertySuccess: (measurements: TrapMeasurement[]) => ({
    type: actions.LOAD_NEXT_PAGE_TRAP_MEASUREMENTS_BY_PROPERTY_SUCCESS,
    payload: measurements
  }),

  setTrapMeasurementsRangeKey: (rangeKey: string) => ({
    type: actions.SET_TRAP_MEASUREMENTS_RANGE_KEY,
    payload: rangeKey
  }),

  loadTrapStationsByPropertySuccess: (Stations: TrapStation[]) => ({
    type: actions.LOAD_TRAP_STATIONS_BY_PROPERTY_SUCCESS,
    payload: Stations
  }),

  loadTrapStationsByPropertyFailure: (error: string) => ({
    type: actions.LOAD_TRAP_STATIONS_BY_PROPERTY_FAILURE,
    payload: error
  }),

  editTrapMeasurementValue: (measurement: TrapMeasurement) => ({
    type: actions.EDIT_TRAP_MEASUREMENT_VALUE,
    payload: measurement
  }),

  editTrapMeasurementValueSuccess: (measurement: TrapMeasurement) => ({
    type: actions.EDIT_TRAP_MEASUREMENT_VALUE_SUCCESS,
    payload: measurement
  }),

  editTrapMeasurementValueFailure: (error: string) => ({
    type: actions.EDIT_TRAP_MEASUREMENT_VALUE_FAILURE,
    payload: error
  }),

  deleteTrapMeasurement: (measurement: DeleteTrapMeasurementsRequestParams) => ({
    type: actions.DELETE_TRAP_MEASUREMENT,
    payload: measurement
  }),

  deleteTrapMeasurementSuccess: (trapMeasurementId: UUID) => ({
    type: actions.DELETE_TRAP_MEASUREMENT_SUCCESS,
    payload: trapMeasurementId
  }),

  deleteTrapMeasurementFailure: (error: string) => ({
    type: actions.DELETE_TRAP_MEASUREMENT_FAILURE,
    payload: error
  }),

  toggleTrapMeasurementModal: (trapMeasurementId?: UUID) => ({
    type: actions.TOGGLE_TRAP_MEASUREMENT_EDIT_MODAL,
    payload: trapMeasurementId
  }),

  toggleMeasurementDetailsModal: (trapMeasurementId?: UUID) => ({
    type: actions.TOGGLE_TRAP_MEASUREMENT_DETAILS_MODAL,
    payload: trapMeasurementId
  })
};

export default actions;

export const TrapMeasurementActions = actions;
