import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { UUID } from '../app/basicModels';
import CONFIG from '../../settings';
import { RegionalRiskUpdateUserPermissionsPayload } from './models';

const baseUrl = CONFIG.protectorApiUrl;
const regionalBaseUrl = `${baseUrl}/v1/regional-risks`;
const regionalOrgsUrl = `${regionalBaseUrl}/external/entities`;
const regionalOrgsByOrgUrl = orgId => `${regionalBaseUrl}/company/${orgId}`;
const regionalTechniciansUrl = orgId => `${regionalOrgsByOrgUrl(orgId)}/user`;
const usersPermissionsUrl = (orgId, userId) => `${regionalTechniciansUrl(orgId)}/${userId}`;

export const getRegionalRiskOrgs = (): AxiosObservable<any> => {
  return axios.get<any>(`${regionalOrgsUrl}`);
};

export const updateRegionalRiskOrgsByOrg = (orgId: UUID, avizio: Array<string>, qualimetre: Array<string>): AxiosObservable<any> => {
  return axios.post<any>(`${regionalOrgsByOrgUrl(orgId)}`, { avizio, qualimetre });
};

export const updateRegionalUsersPermissions = (
  orgId: UUID,
  userId: UUID | null,
  payload: RegionalRiskUpdateUserPermissionsPayload
): AxiosObservable<any> => {
  return axios.post<any>(`${usersPermissionsUrl(orgId, userId)}`, payload);
};
