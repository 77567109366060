import actions from './actions';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';
import { Indicator, IndicatorState, IndicatorPage } from './models';
import { initialPage } from '../app/basicModels';

const entity: EntityAdapter<Indicator> = createEntityAdapter<Indicator>();

const initialState: IndicatorState = entity.getInitialState({
  page: {
    ...initialPage
  },
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  error: null,
  editModalActive: false,
  indicatorSelect: null,
  indicatorSelectLoading: false,
  isLoadingIndicatorsForCompany: false,
  indicatorsForCompany: []
});

export default (state = initialState, action): IndicatorState => {
  switch (action.type) {
    case actions.TOGGLE_INDICATOR_EDIT_MODAL:
      return {
        ...state,
        editModalActive: !state.editModalActive
      };

    case actions.LOAD_INDICATORS:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_INDICATORS_SUCCESS:
      return entity.addAll((action.payload as IndicatorPage).content, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null
      });

    case actions.LOAD_INDICATORS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.LOAD_INDICATORS_FOR_COMPANY:
      return {
        ...state,
        isLoadingIndicatorsForCompany: true
      };

    case actions.LOAD_INDICATORS_FOR_COMPANY_SUCCESS:
      return entity.addAll(action.payload, {
        ...state,
        indicatorsForCompany: action.payload,
        isLoadingIndicatorsForCompany: false,
        error: null
      });

    case actions.LOAD_INDICATORS_FOR_COMPANY_FAILURE:
      return {
        ...state,
        isLoadingIndicatorsForCompany: false,
        error: action.payload
      };

    case actions.SAVE_INDICATOR:
      return {
        ...state,
        isSaving: true
      };

    case actions.SAVE_INDICATOR_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        indicatorsForCompany: [...state.indicatorsForCompany, action.payload],
        isSaving: false,
        error: null
      });

    case actions.SAVE_INDICATOR_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case actions.LOAD_INDICATOR:
      return {
        ...state,
        indicatorSelectLoading: true,
        indicatorSelect: null
      };

    case actions.LOAD_INDICATOR_SUCCESS:
      return {
        ...state,
        indicatorSelectLoading: false,
        indicatorSelect: action.payload
      };

    case actions.LOAD_INDICATOR_FAILURE:
      return {
        ...state,
        indicatorSelectLoading: false,
        error: action.payload
      };

    case actions.DELETE_INDICATOR:
      return {
        ...state,
        isDeleting: true
      };

    case actions.DELETE_INDICATOR_SUCCESS:
      return entity.removeOne(action.payload.id, {
        ...state,
        error: null,
        isDeleting: false
      });

    case actions.DELETE_INDICATOR_FAILURE:
      return {
        ...state,
        error: action.payload,
        isDeleting: false
      };

    case actions.UNLOAD_INDICATORS:
      return initialState;

    case actions.CLEAR_INDICATOR_SELECT:
      return {
        ...state,
        indicatorSelect: null
      };
    case actions.CLEAR_INDICATOR_ERROR:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectIndicatorIds = selectIds;
export const selectIndicatorEntities = selectEntities;
export const selectAllIndicators = selectAll;
export const selectIndicatorTotal = selectTotal;
