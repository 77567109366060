import { UUID, PageableQuery } from '../app/basicModels';
import { FixedPoint, FixedPointDemeterPage, FixedPointPage } from './models';
import { OrderedInspectionGroup } from '../methodology/models';
import { PhenomenonInfo } from '../methodologyDeep/models';

const actions = {
  LOAD_FIXEDPOINTS: '[Fixed Point] Load Fixed Points',
  LOAD_PLUVIOMETERS: '[Fixed Point] Load All Pluviometers',
  LOAD_FIXEDPOINTS_BY_COMPANY: '[Fixed Point] Load Fixed Points By Company',
  LOAD_FIXEDPOINTS_SUCCESS: '[Fixed Point] Load Fixed Points Success',
  LOAD_FIXEDPOINTS_FAILURE: '[Fixed Point] Load Fixed Points Failure',
  LOAD_INSTALLED_FIXEDPOINTS_BY_COMPANY: '[Fixed Point] Load Installed Fixed Points By Company',
  LOAD_INSTALLED_FIXEDPOINTS_BY_COMPANY_SUCCESS: '[Fixed Point] Load Installed Fixed Points By Company Success',
  SAVE_FIXEDPOINT: '[Fixed Point] Save Fixed Point',
  SAVE_FIXEDPOINT_SUCCESS: '[Fixed Point] Save Fixed Point Success',
  SAVE_FIXEDPOINT_FAILURE: '[Fixed Point] Save Fixed Point Failure',
  UPDATE_FIXEDPOINT: '[Fixed Point] Update Fixed Point',
  LOAD_FIXEDPOINT_BY_COMPANY: '[Fixed Point by Company] Load Fixed Point by Company',
  LOAD_FIXEDPOINT_SUCCESS: '[Fixed Point] Load Fixed Point Success',
  LOAD_FIXEDPOINT_FAILURE: '[Fixed Point] Load Fixed Point Failure',
  DELETE_FIXEDPOINT: '[Fixed Point] Delete Fixed Point',
  DELETE_FIXEDPOINT_SUCCESS: '[Fixed Point] Delete Fixed Point Success',
  DELETE_FIXEDPOINT_FAILURE: '[Fixed Point] Delete Fixed Point Failure',
  EDIT_INSPECTION_GROUP: '[Fixed Point] Edit Inspection Group',
  DELETE_INSPECTION_GROUP: '[Fixed Point] Delete Inspection Group',
  CHANGE_INSPECTION_GROUP_TAB: '[Fixed Point] Change inspection group tab',
  NEW_ORDER_INSPECTION_GROUP: '[Fixed Point] New Order Inspection Group',
  NEW_ORDER_PHENOMENONS: '[Fixed Point] New Order Phenomenons',
  CLEAR_FIXED_POINT_SELECT_BY_COMPANY: '[Fixed Point] Clear Fixed Point Selected',
  TOGGLE_FIXED_POINT_PHENOMENA_MODAL: '[Fixed Point] Toggle Fixed Point Phenomena Modal',
  TOGGLE_INDICATORS_PHENOMENA_MODAL: '[Fixed Point] Toggle Indicators Phenomena Modal',
  TOGGLE_CHARACTERISTICS_INDICATORS_PHENOMENA_MODAL: '[Fixed Point] Toggle Characteristics/Indicators Phenomena Modal',
  SAVE_PHENOMENONS_FIXED_POINT: '[Fixed Point] Save Phenomenons Fixed Point',
  LOAD_PHENOMENONS_OF_FIXED_POINT: '[Fixed Point] Load Phenomenons of Fixed Point',
  CLEAR_PHENOMENONS_OF_FIXED_POINT: '[Fixed Point] Clear Phenomenons of Fixed Point',
  LOAD_PHENOMENONS_OF_FIXED_POINT_SUCCESS: '[Fixed Point] Load Phenomenons of Fixed Point Success',
  LOAD_PHENOMENONS_OF_FIXED_POINT_FAILURE: '[Fixed Point] Load Phenomenons of Fixed Point Error',
  LOAD_CHARACTERISTICS_OF_FIXED_POINT: '[Fixed Point] Load Characteristics of Fixed Point',
  LOAD_CHARACTERISTICS_OF_FIXED_POINT_SUCCESS: '[Fixed Point] Load Characteristics of Fixed Point Success',
  LOAD_CHARACTERISTICS_OF_FIXED_POINT_FAILURE: '[Fixed Point] Load Characteristics of Fixed Point Error',
  SELECT_FIXEDPOINT: '[Fixed Point] Select Fixed Point',

  loadFixedPoints: (pageableQuery: PageableQuery, onlyGeneric?: boolean) => ({
    type: actions.LOAD_FIXEDPOINTS,
    payload: { pageableQuery, onlyGeneric }
  }),

  loadPluviometers: (pageableQuery: PageableQuery) => ({
    type: actions.LOAD_PLUVIOMETERS,
    payload: pageableQuery
  }),

  loadFixedPointsByCompany: (pageableQuery: PageableQuery, companyId?: UUID) => ({
    type: actions.LOAD_FIXEDPOINTS_BY_COMPANY,
    payload: { pageableQuery, companyId }
  }),

  loadFixedPointsSuccess: (fixedPointPage: FixedPoint[]) => ({
    type: actions.LOAD_FIXEDPOINTS_SUCCESS,
    payload: fixedPointPage
  }),

  loadFixedPointsFailure: (error: string) => ({
    type: actions.LOAD_FIXEDPOINTS_FAILURE,
    payload: error
  }),

  loadInstalledFixedPointsByCompany: (pageableQuery: PageableQuery, companyId?: UUID) => ({
    type: actions.LOAD_INSTALLED_FIXEDPOINTS_BY_COMPANY,
    payload: { pageableQuery, companyId }
  }),

  loadInstalledFixedPointsByCompanySuccess: (fixedPointPage: FixedPointPage) => ({
    type: actions.LOAD_INSTALLED_FIXEDPOINTS_BY_COMPANY_SUCCESS,
    payload: fixedPointPage
  }),

  saveFixedPoint: (fixedPoint: FixedPoint) => ({
    type: actions.SAVE_FIXEDPOINT,
    payload: fixedPoint
  }),

  saveFixedPointSuccess: (fixedPoint: FixedPoint) => ({
    type: actions.SAVE_FIXEDPOINT_SUCCESS,
    payload: fixedPoint
  }),

  saveFixedPointFailure: (error: string) => ({
    type: actions.SAVE_FIXEDPOINT_FAILURE,
    payload: error
  }),

  updateFixedPoint: (fixedPoint: FixedPoint, save = false) => ({
    type: actions.UPDATE_FIXEDPOINT,
    payload: {
      fixedPoint,
      save
    }
  }),

  deleteFixedPoint: (fixedPoint: FixedPoint) => ({
    type: actions.DELETE_FIXEDPOINT,
    payload: fixedPoint
  }),

  deleteFixedPointSuccess: (deleted: boolean) => ({
    type: actions.DELETE_FIXEDPOINT_SUCCESS,
    payload: deleted
  }),

  deleteFixedPointFailure: (error: string) => ({
    type: actions.DELETE_FIXEDPOINT_FAILURE,
    payload: error
  }),

  selectFixedPoint: (fixedPoint: FixedPoint) => ({
    type: actions.SELECT_FIXEDPOINT,
    payload: fixedPoint
  }),

  loadFixedPointByCompany: (id: UUID) => ({
    type: actions.LOAD_FIXEDPOINT_BY_COMPANY,
    payload: id
  }),

  loadFixedPointSuccess: (fixedPoint: FixedPoint) => ({
    type: actions.LOAD_FIXEDPOINT_SUCCESS,
    payload: fixedPoint
  }),

  loadFixedPointFailure: (error: string) => ({
    type: actions.LOAD_FIXEDPOINT_FAILURE,
    payload: error
  }),

  changeInspectionGroupTab: (id: UUID) => ({
    type: actions.CHANGE_INSPECTION_GROUP_TAB,
    payload: id
  }),

  editInspectionGroup: (inspectionGroup: OrderedInspectionGroup) => ({
    type: actions.EDIT_INSPECTION_GROUP,
    payload: inspectionGroup
  }),

  deleteInspectionGroup: (id: UUID) => ({
    type: actions.DELETE_INSPECTION_GROUP,
    payload: id
  }),

  newOrderInspectionGroup: (ids: UUID[]) => ({
    type: actions.NEW_ORDER_INSPECTION_GROUP,
    payload: ids
  }),

  newOrderPhenomenons: (phenomenons: any, inspectionGroupId: UUID) => ({
    type: actions.NEW_ORDER_PHENOMENONS,
    payload: {
      phenomenons,
      inspectionGroupId
    }
  }),

  clearFixedPointSelectByCompany: () => ({
    type: actions.CLEAR_FIXED_POINT_SELECT_BY_COMPANY
  }),

  toggleFixedPointPhenomenaModal: () => ({
    type: actions.TOGGLE_FIXED_POINT_PHENOMENA_MODAL
  }),

  toggleIndicatorsPhenomenaModal: () => ({
    type: actions.TOGGLE_INDICATORS_PHENOMENA_MODAL
  }),

  toggleCharacteristicsIndicatorsPhenomenaModal: (phenomenonInfo?: PhenomenonInfo) => ({
    type: actions.TOGGLE_CHARACTERISTICS_INDICATORS_PHENOMENA_MODAL,
    payload: phenomenonInfo
  }),

  savePhenomenonsFixedPoint: (fixedPoint: any, phenomenonsIds: any[]) => ({
    type: actions.NEW_ORDER_PHENOMENONS,
    payload: {
      fixedPoint,
      phenomenonsIds
    }
  }),

  loadPhenomenonsOfFixedPoint: (fixedPoint: any) => ({
    type: actions.LOAD_PHENOMENONS_OF_FIXED_POINT,
    payload: fixedPoint
  }),

  loadPhenomenonsOfFixedPointSuccess: (phenomenonsOfFixedPoint: any[]) => ({
    type: actions.LOAD_PHENOMENONS_OF_FIXED_POINT_SUCCESS,
    payload: phenomenonsOfFixedPoint
  }),

  loadPhenomenonsOfFixedPointError: (error: string) => ({
    type: actions.LOAD_PHENOMENONS_OF_FIXED_POINT_FAILURE,
    payload: error
  }),

  saveCharacteristicsOfFixedPoint: (fixedPoint: any) => ({
    type: actions.LOAD_CHARACTERISTICS_OF_FIXED_POINT,
    payload: fixedPoint
  }),

  saveCharacteristicsOfFixedPointSuccess: (characteristicsOfFixedPoint: any[]) => ({
    type: actions.LOAD_CHARACTERISTICS_OF_FIXED_POINT_SUCCESS,
    payload: characteristicsOfFixedPoint
  }),

  saveCharacteristicsOfFixedPointFailure: (error: string) => ({
    type: actions.LOAD_CHARACTERISTICS_OF_FIXED_POINT_FAILURE,
    payload: error
  }),

  clearPhenomenonsOfFixedPoint: () => ({
    type: actions.CLEAR_PHENOMENONS_OF_FIXED_POINT
  })
};

export default actions;

export const FixedPointActions = actions;
