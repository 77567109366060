import {
  OrgUser,
  ControleCertoUser,
  NewAuthority,
  ContextAuthorization,
  Organization,
  Role,
  BasicInfoControleCertoData,
  AccountType,
  rolesDictionary,
  Authority,
  Roles
} from './models';
import { UUID } from '../models';
import _ from 'lodash';
import { getOrganizationAuthority } from './service';
import { hasPermission, PermissionScope, UserPermissions } from '../../helpers/userAuth/userAuth';
import { upsertById } from '../../helpers';
import { RBACActions } from '../../querys/rbac/rbac.query.model';
import { typeHasRole } from '../../containers/ControleCerto/UserDrawer/forms/infoByRoleRBAC.utils';

const { READ, WRITE } = PermissionScope;

export const roleRulesUtils = {
  admin: {
    values: ['ADMIN_SYT_BRBH'],
    fields: []
  },
  reseller: {
    values: ['ADMIN_PROVIDER', 'RTV'],
    fields: ['company']
  },
  serviceProvider: {
    values: ['ADMIN_CONSULTANCY', 'FIELD_TECHNICIAN'],
    fields: ['companiesByServiceProvider', 'serviceProvider']
  },
  farmer: {
    values: ['FARMER', 'PROVIDER_ADVISOR', 'AGRONOMIST'],
    fields: []
  }
};

const {
  FARMER,
  AGRONOMIST,
  FIELD_TECHNICIAN,
  ADMIN_CONSULTANCY,
  PROVIDER_ADVISOR,
  ADMIN_PROVIDER,
  RTV,
  ADMIN_SYT_BRBH,
  PROTECTOR_USER,
  PROTECTOR_FULL_USER,
  ETD,
  ENGINEERING,
  OTHER
} = Role;

export const CONTROLE_CERTO_ROLES = [FARMER, AGRONOMIST, FIELD_TECHNICIAN, ADMIN_CONSULTANCY, PROVIDER_ADVISOR, ADMIN_PROVIDER, RTV];

export const SUPPORT_ROLES = [ADMIN_SYT_BRBH, PROTECTOR_USER, PROTECTOR_FULL_USER, ETD, ENGINEERING, OTHER];

const { ORGANIZATION } = ContextAuthorization;
const { USER } = AccountType;

const {
  CUSTOM_CHARACTERISTICS,
  CUSTOM_INDICATORS,
  CUSTOM_PHENOMENON,
  CUSTOM_CROPS,
  CANONICAL_PHENOMENON,
  CANONICAL_CHARACTERISTICS,
  CANONICAL_INDICATORS,
  METHODOLOGY,
  CANONICAL_CROP,
  CANONICAL_MULTI_PHENOMENON_INDICATOR,
  CANONICAL_PHENOMENON_CATEGORY,
  CANONICAL_PRODUCT,
  CANONICAL_PRODUCT_SET,
  CANONICAL_VENDOR,
  FIXED_POINTS
} = UserPermissions;

export const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const getPropertyContextAuthorities = (user: OrgUser | ControleCertoUser): NewAuthority[] => {
  const userAuthorities: NewAuthority[] = [];
  user.authorities.forEach(authority => {
    if (authority.context === 'PROPERTY') userAuthorities.push(authority);
  });
  return userAuthorities;
};

export const getUserAuthorities = (user: OrgUser | ControleCertoUser): NewAuthority[] => {
  let userAuthorities: NewAuthority[] = [];
  if (user.authorities) userAuthorities = getPropertyContextAuthorities(user);
  return userAuthorities;
};

const filterAuthsControleCerto = (authorities: NewAuthority[], orgControleCertoIds: UUID[]) => {
  return authorities.filter(a => a.context === ORGANIZATION && orgControleCertoIds.includes(a.id));
};

const filterAuthsNotControleCerto = (authorities: NewAuthority[], orgControleCertoIds: UUID[]) => {
  return authorities.filter(a => a.context !== ORGANIZATION || (a.context === ORGANIZATION && !orgControleCertoIds.includes(a.id)));
};

export const getControleCertoAuthorities = (authorities: NewAuthority[], orgsControleCerto: Organization[]) => {
  const orgsCCIds: UUID[] = orgsControleCerto.map(org => org.id);
  const orgAuthoritiesControleCerto = filterAuthsControleCerto(authorities, orgsCCIds);
  return orgAuthoritiesControleCerto;
};

export const getNotControleCertoAuthorities = (authorities: NewAuthority[], orgsControleCerto: Organization[]) => {
  const orgsCCIds: UUID[] = orgsControleCerto.map(org => org.id);
  const orgAuthoritiesControleCerto = filterAuthsNotControleCerto(authorities, orgsCCIds);
  return orgAuthoritiesControleCerto;
};

const getOwnershipScopesControleCerto = (user: OrgUser | ControleCertoUser, orgsControleCerto: Organization[]) => {
  const organizationAuthorities = getControleCertoAuthorities(user.authorities, orgsControleCerto);
  const ownershipScopes: string[] = [];
  organizationAuthorities.forEach(orgAuthority => {
    const ownerShipScopesOrg = orgAuthority.ownership_authority_scopes;
    if (ownerShipScopesOrg) {
      if (!ownershipScopes.includes(READ) && ownerShipScopesOrg.includes(READ)) {
        ownershipScopes.push(READ);
      }
      if (!ownershipScopes.includes(WRITE) && ownerShipScopesOrg.includes(WRITE)) {
        ownershipScopes.push(WRITE);
      }
    }
  });
  return ownershipScopes;
};

export const getOwnershipScopesByUser = (user: OrgUser | ControleCertoUser, orgId: UUID, orgsControleCerto: Organization[]) => {
  const { role } = user;
  if (isControleCertoRole(role as any)) {
    return getOwnershipScopesControleCerto(user, orgsControleCerto);
  }
  const orgAuthority = user.authorities.find(a => a.context === ORGANIZATION && a.id === orgId);
  if (!orgAuthority) return [];
  return orgAuthority.ownership_authority_scopes || [];
};

export const hasOrganizationAuthority = (auths: NewAuthority[], orgId: UUID): boolean => {
  return Boolean(auths.find(auth => auth.context === 'ORGANIZATION' && auth.id === orgId));
};

export const isControleCertoRole = (role: Role) => {
  return CONTROLE_CERTO_ROLES.includes(role);
};

export const isSuportRole = (role: string) => {
  return SUPPORT_ROLES.includes(role as Role);
};

export const buildAuthority = (id: UUID, context: ContextAuthorization, ownershipScopes: string[]): NewAuthority => {
  return {
    id,
    context,
    scopes: [READ, WRITE],
    ownership_authority_scopes: ownershipScopes
  };
};

export const getOrganizationAuthorities = (authoritiesAvailable: Authority[]) => {
  return getAuthoritiesByContext(authoritiesAvailable, ContextAuthorization.ORGANIZATION);
};

const getAuthoritiesByContext = (authoritiesAvailable: Authority[], context: ContextAuthorization) => {
  return authoritiesAvailable.filter(a => a.context === context);
};

export const buildControleCertoUser = (
  basicInformations: BasicInfoControleCertoData,
  authorities: NewAuthority[],
  user: ControleCertoUser
) => {
  const { role, email, phone, password, name, language, country_code } = basicInformations;
  return {
    id: user && user.id,
    name,
    email,
    phone,
    password,
    country_code,
    type: USER as any,
    role: role as any,
    authorities,
    locale: language,
    default_licensing_account_id: user && user.default_licensing_account_id
  };
};

export const handleAuthoritiesUserControleCerto = (
  user: ControleCertoUser,
  currentOrgId: UUID,
  basicInformations: BasicInfoControleCertoData,
  orgsControleCerto: Organization[],
  readMethodology: boolean
) => {
  const {
    role,
    company,
    serviceProvider,
    companiesByServiceProvider,
    userOwnershipScopes,
    userInventoryScopes,
    userFinancialScopes,
    userSugarcaneScopes,
    userMethodologyScopes,
    propertiesAuthorities
  } = basicInformations;

  let authorities: NewAuthority[] = [];
  if (typeHasRole(roleRulesUtils.farmer, role)) {
    authorities = [...propertiesAuthorities];
  }
  if (typeof company === 'string') {
    authorities.push(
      getOrganizationAuthority(
        company,
        userOwnershipScopes,
        userInventoryScopes,
        userFinancialScopes,
        userSugarcaneScopes,
        userMethodologyScopes,
        readMethodology
      )
    );
  } else if (typeof company === 'object') {
    company.forEach(c =>
      authorities.push(
        getOrganizationAuthority(
          c,
          userOwnershipScopes,
          userInventoryScopes,
          userFinancialScopes,
          userSugarcaneScopes,
          userMethodologyScopes,
          readMethodology
        )
      )
    );
  }
  if (serviceProvider) {
    const serviceProviderOrgsAuths = companiesByServiceProvider.map(orgServiceProviderId =>
      getOrganizationAuthority(
        orgServiceProviderId,
        userOwnershipScopes,
        userInventoryScopes,
        userFinancialScopes,
        userSugarcaneScopes,
        userMethodologyScopes,
        readMethodology
      )
    );
    authorities = [...authorities, ...serviceProviderOrgsAuths];
  }

  if (user && user.authorities) {
    const orgsNotControleCertoAuths = getNotControleCertoAuthorities(user.authorities, orgsControleCerto);
    authorities = [...authorities, ...orgsNotControleCertoAuths];
  }

  authorities = _.uniqWith(authorities, _.isEqual);

  const currentOrgAuthority = getOrganizationAuthority(
    currentOrgId,
    userOwnershipScopes,
    userInventoryScopes,
    userFinancialScopes,
    userSugarcaneScopes,
    userMethodologyScopes,
    readMethodology
  );

  const newAuthorities = upsertById(authorities, currentOrgAuthority);
  return newAuthorities;
};

export const handleAuthoritiesUserNotControleCerto = (
  user: ControleCertoUser,
  orgId: UUID,
  basicInformations: BasicInfoControleCertoData,
  readMethodology: boolean
) => {
  if (!user) throw new Error('User is required!');

  const { userOwnershipScopes, userInventoryScopes, userFinancialScopes, userSugarcaneScopes, userMethodologyScopes } = basicInformations;

  const authorityCurrentCompany = getOrganizationAuthority(
    orgId,
    userOwnershipScopes,
    userInventoryScopes,
    userFinancialScopes,
    userSugarcaneScopes,
    userMethodologyScopes,
    readMethodology
  );

  if (user.authorities) {
    const authorities = user.authorities.filter(
      authoritie => authoritie.context !== ORGANIZATION || (authoritie.context === ORGANIZATION && authoritie.id !== orgId)
    );
    authorities.push(authorityCurrentCompany);
    return authorities;
  }
  return [authorityCurrentCompany];
};

export const mapRoles = (roles: Roles[]) => {
  return (
    roles &&
    roles
      .filter(r => isControleCertoRole(r as any))
      .map((role: Roles) => ({
        id: role,
        // @ts-ignore
        name: rolesDictionary[role]
      }))
  );
};

export const getAccessFlags = (currentUser, isRBAC, rbacPermission) => {
  if (isRBAC) {
    return {
      canEditCanonicalCharacteristic: !!rbacPermission[RBACActions.CANONICAL_CHARACTERISTICS]?.length,
      canEditCanonicalIndicator: !!rbacPermission[RBACActions.CANONICAL_INDICATORS]?.length,
      canEditCanonicalPhenomenon: !!rbacPermission[RBACActions.CANONICAL_PHENOMENON]?.length,
      canEditCustomCharacteristic: !!rbacPermission[RBACActions.CUSTOM_CHARACTERISTICS]?.length,
      canEditCustomIndicator: !!rbacPermission[RBACActions.CUSTOM_INDICATORS]?.length,
      canEditCustomPhenomenon: !!rbacPermission[RBACActions.CUSTOM_PHENOMENON]?.length,
      canEditMethodology: !!rbacPermission[RBACActions.METHODOLOGY]?.length,
      canEditCanonicalCrop: !!rbacPermission[RBACActions.CANONICAL_CROP]?.length,
      canEditCustomCrop: !!rbacPermission[RBACActions.CUSTOM_CROPS]?.length,
      canEditCanonicalMultPhenon: !!rbacPermission[RBACActions.CANONICAL_MULTI_PHENOMENON_INDICATOR]?.length,
      canEditCanonicalProduct: !!rbacPermission[RBACActions.CANONICAL_PRODUCT]?.length,
      canEditCanonicalProductSet: !!rbacPermission[RBACActions.CANONICAL_PRODUCT_SET]?.length,
      canEditCanonicalPhenomenonCategory: !!rbacPermission[RBACActions.CANONICAL_PHENOMENON_CATEGORY]?.length,
      canEditCanonicalVendor: !!rbacPermission[RBACActions.CANONICAL_VENDOR]?.length,
      canEditFixedPoints: !!rbacPermission[RBACActions.FIXED_POINTS]?.length
    };
  }
  return {
    canEditCanonicalCharacteristic: hasPermission(currentUser, CANONICAL_CHARACTERISTICS, WRITE),
    canEditCanonicalIndicator: hasPermission(currentUser, CANONICAL_INDICATORS, WRITE),
    canEditCanonicalPhenomenon: hasPermission(currentUser, CANONICAL_PHENOMENON, WRITE),
    canEditCustomCharacteristic: hasPermission(currentUser, CUSTOM_CHARACTERISTICS, WRITE),
    canEditCustomIndicator: hasPermission(currentUser, CUSTOM_INDICATORS, WRITE),
    canEditCustomPhenomenon: hasPermission(currentUser, CUSTOM_PHENOMENON, WRITE),
    canEditMethodology: hasPermission(currentUser, METHODOLOGY, WRITE),
    canEditCanonicalCrop: hasPermission(currentUser, CANONICAL_CROP, WRITE),
    canEditCustomCrop: hasPermission(currentUser, CUSTOM_CROPS, WRITE),
    canEditCanonicalMultPhenon: hasPermission(currentUser, CANONICAL_MULTI_PHENOMENON_INDICATOR, WRITE),
    canEditCanonicalProduct: hasPermission(currentUser, CANONICAL_PRODUCT, WRITE),
    canEditCanonicalProductSet: hasPermission(currentUser, CANONICAL_PRODUCT_SET, WRITE),
    canEditCanonicalPhenomenonCategory: hasPermission(currentUser, CANONICAL_PHENOMENON_CATEGORY, WRITE),
    canEditCanonicalVendor: hasPermission(currentUser, CANONICAL_VENDOR, WRITE),
    canEditFixedPoints: hasPermission(currentUser, FIXED_POINTS, WRITE)
  };
};
