import actions from './actions';
import { Measurement, MeasurementState } from './models';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';

const entity: EntityAdapter<Measurement> = createEntityAdapter<Measurement>();

const initialState: MeasurementState = entity.getInitialState({
  isLoading: false,
  error: null,
  measurementModalIsActive: false,
  measurementId: null,
  filterVisible: false,
  rangeKey: null
});

export default (state = initialState, action): MeasurementState => {
  switch (action.type) {
    case actions.LOAD_MEASUREMENTS_BY_PROPERTY:
      return entity.removeAll({
        ...state,
        isLoading: true
      });

    case actions.EDIT_MEASUREMENT_VALUE:
    case actions.DELETE_MEASUREMENT:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_MEASUREMENTS_BY_PROPERTY_SUCCESS:
      return entity.addAll(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });

    case actions.UPSERT_MEASUREMENTS_BY_PROPERTY:
      return entity.upsertMany(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });

    case actions.LOAD_MEASUREMENTS_BY_PROPERTY_FAILURE:
    case actions.EDIT_MEASUREMENT_VALUE_FAILURE:
    case actions.DELETE_MEASUREMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.SET_RANGE_KEY:
      return {
        ...state,
        rangeKey: action.payload
      };

    case actions.TOGGLE_FILTER_MODAL:
      return {
        ...state,
        filterVisible: !state.filterVisible
      };

    case actions.EDIT_MEASUREMENT_VALUE_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });

    case actions.DELETE_MEASUREMENT_SUCCESS:
      return entity.removeOne(action.payload, {
        ...state,
        error: null,
        isLoading: false
      });

    case actions.TOGGLE_MEASUREMENT_MODAL:
      return {
        ...state,
        measurementModalIsActive: !state.measurementModalIsActive,
        measurementId: !state.measurementModalIsActive && action.payload ? action.payload : null
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectMeasurementIds = selectIds;
export const selectMeasurementEntities = selectEntities;
export const selectAllMeasurements = selectAll;
export const selectMeasurementsTotal = selectTotal;
