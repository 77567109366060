import { cloneDeep } from 'lodash';
import { uuid } from '../../helpers/index';
import { FeatureFlagEnum, Indicator, IndicatorDiagnostic, OutputType } from '../indicator/models';
import { getValueLanguage } from '../language/utils';
import { SeasonTree } from '../season/models';
import { Characteristic, FeatureFlag, InputDefinitionType, Phenomenon, ProductSet, UUID } from '../models';
import {
  CharacteristicDeep,
  ClassName,
  ComponentDeep,
  IndicatorDeep,
  InspectionLayoutDeep,
  MethodologyDeep,
  PhenomenonDeep
} from './models';

export const localized_strings = {
  localized_strings: {
    en: 'Presença'
  }
};

const defaultCharacteristic = {
  characteristic: {
    id: 'fe709827-addb-4fde-9a33-5a1e5340720b',
    characteristicId: '5ac292aa-4d6e-47c5-b48a-26bb2127c9a1',
    name: localized_strings,
    priority: 1,
    comesFromCanonical: false
  },
  priority: 1
};

const componentsWithCharacteristicAndIndicators = [
  {
    class_name: ClassName.CHARACTERISTIC,
    id: 'c06bb037-9f75-4386-8b8f-46d1f82f8730',
    component_id: 'c06bb037-9f75-4386-8b8f-46d1f82f8730',
    priority: 0
  },
  {
    class_name: ClassName.INDICATOR,
    id: 'ad6ac818-253c-44cb-b14b-556d4ad8df09',
    component_id: 'ad6ac818-253c-44cb-b14b-556d4ad8df09',
    priority: 1
  }
];

export const defaultMethodology = {
  id: uuid(),
  company_id: '9c807fb5-206d-421c-8bfa-4144dc86860f',
  crop_id: '941e76ad-8156-4c1c-aa31-db069e0695ba',
  isNew: false,
  name: {
    localized_strings: {
      en: 'Metodologia teste 1'
    }
  },
  description: {
    localized_strings: {
      en: 'Metodologia teste 1'
    }
  },
  inspection_layout: {},
  analytic_context_dto: {},
  season_ids: [],
  season_area_ids: [],
  season_property_ids: []
};

const literalValue = [
  {
    value: 10,
    localized_literal: localized_strings
  }
];

const inputDefinition = {
  values: literalValue,
  class_name: 'NumpadInputDefinitionDTO' as any
};

export const defaultProductSet: ProductSet = {
  id: uuid(),
  isNew: false,
  name: {
    localized_strings: {
      en: 'Name Product Set'
    }
  },
  company_id: uuid(),
  product_ids: [],
  description: {
    localized_strings: {
      en: 'Description Product Set'
    }
  }
};

export const defaultMethodologyDeep: MethodologyDeep = {
  id: uuid(),
  isNew: true,
  analyticContextId: uuid(),
  name: {
    localized_strings: {
      en: 'Name Methodology deep'
    }
  },
  company: {
    id: uuid(),
    name: 'Company Methodology deep'
  },
  crop: {
    id: uuid(),
    name: {
      localized_strings: {
        en: 'Crop Methodology deep'
      }
    }
  },
  description: {
    localized_strings: {
      en: 'Description Methodology deep'
    }
  },
  seasonsTree: {
    seasonAreas: [],
    seasonProperties: [],
    seasons: [{ id: 'das9817-93128hd-012378', name: 'Season Default' }]
  },
  productSet: defaultProductSet,
  inspectionLayout: {
    id: uuid(),
    inspectionGroups: []
  },
  multiPhenomenaIndicators: []
};

export const defaultInspectionLayout = {
  id: uuid(),
  inspectionGroups: [
    {
      id: uuid(),
      inspectionGroupId: uuid(),
      name: {
        localized_strings: {
          en: 'Name Inspection Group Deep'
        }
      },
      priority: 0,
      categories: [],
      extraDimensions: {
        id: uuid(),
        collapsed: false,
        components: []
      }
    }
  ]
};

export const getMethodologyDeepWithEmptyInspectionGroup = () => {
  const methodologyDeepEmptyInspG = { ...defaultMethodologyDeep };
  methodologyDeepEmptyInspG.inspectionLayout = { ...defaultInspectionLayout };
  return methodologyDeepEmptyInspG;
};

export const methodologyWithInspectionGroups: MethodologyDeep = {
  ...defaultMethodologyDeep,
  inspectionLayout: {
    id: uuid(),
    inspectionGroups: [
      {
        id: uuid(),
        inspectionGroupId: uuid(),
        priority: 1,
        extraDimensions: {
          id: uuid(),
          collapsed: false,
          components: []
        }
      },
      {
        id: uuid(),
        inspectionGroupId: uuid(),
        priority: 2,
        extraDimensions: {
          id: uuid(),
          collapsed: false,
          components: []
        }
      }
    ]
  }
};

export const getBaseCategories = () => [
  {
    id: uuid(),
    categoryId: uuid(),
    name: {
      localized_strings: {
        en: 'Name Category Deep'
      }
    },
    priority: 0,
    phenomenons: [
      {
        id: uuid(),
        phenomenonId: uuid(),
        name: {
          localized_strings: {
            en: 'Name Phenomenon Deep'
          }
        },
        priority: 0,
        components: []
      },
      {
        id: uuid(),
        phenomenonId: uuid(),
        name: {
          localized_strings: {
            en: 'Name Phenomenon Deep'
          }
        },
        priority: 1,
        components: []
      },
      {
        id: uuid(),
        phenomenonId: uuid(),
        name: {
          localized_strings: {
            en: 'Name Phenomenon Deep'
          }
        },
        priority: 2,
        components: []
      }
    ]
  }
];

export const getCategory = (categoryId, phenomenonId) => {
  return {
    id: uuid(),
    categoryId,
    name: {
      localized_strings: {
        en: 'Name Category Deep'
      }
    },
    priority: 0,
    phenomenons: [
      {
        id: uuid(),
        phenomenonId,
        name: {
          localized_strings: {
            en: 'Name Phenomenon Deep'
          }
        },
        priority: 0,
        components: []
      }
    ]
  };
};

export const inspectionLayoutDeepWithThreePhenomena = {
  ...defaultInspectionLayout,
  inspectionGroups: [
    {
      ...defaultInspectionLayout.inspectionGroups[0],
      categories: [...getBaseCategories()]
    }
  ]
};

export const inspectionLayoutDeepWithOneCharacteristicAndOneIndicator: InspectionLayoutDeep = {
  ...cloneDeep(defaultInspectionLayout),
  inspectionGroups: [
    {
      ...cloneDeep(defaultInspectionLayout.inspectionGroups[0]),
      categories: [
        {
          id: uuid(),
          categoryId: uuid(),
          name: {
            localized_strings: {
              en: 'Name Category Deep'
            }
          },
          priority: 0,
          phenomenons: [
            {
              id: uuid(),
              phenomenonId: uuid(),
              name: {
                localized_strings: {
                  en: 'Name Phenomenon Deep'
                }
              },
              priority: 0,
              components: [
                {
                  priority: 0,
                  characteristic: {
                    id: uuid(),
                    characteristicId: uuid(),
                    name: {
                      localized_strings: {
                        en: 'Name Characteristic Deep'
                      }
                    },
                    priority: 0
                  }
                },
                {
                  priority: 1,
                  indicator: {
                    id: uuid(),
                    indicatorId: uuid(),
                    name: {
                      localized_strings: {
                        en: 'Name Indicator Deep Custom'
                      }
                    },
                    isDefault: false,
                    priority: 1,
                    showOnInspectionLayout: true,
                    showOnTimeline: true,
                    showOnHeatmap: false,
                    characteristicsIds: [uuid()],
                    phenomenonIds: [uuid()],
                    diagnostics: []
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export const getInspectionLayoutDeepWithIndicatorCustom = () => {
  const inspectionLayout = {
    ...inspectionLayoutDeepWithOneCharacteristicAndOneIndicator
  };
  inspectionLayout.inspectionGroups[0].categories![0].phenomenons![0].components!.push({
    priority: 2,
    indicator: {
      id: uuid(),
      indicatorId: uuid(),
      name: {
        localized_strings: {
          en: 'Name Indicator Deep Default'
        }
      },
      isDefault: false,
      priority: 2,
      showOnInspectionLayout: false,
      showOnTimeline: true,
      showOnHeatmap: false,
      characteristicsIds: [uuid()],
      phenomenonIds: [uuid()],
      diagnostics: []
    },
    characteristic: {
      id: uuid(),
      characteristicId: uuid(),
      name: {
        localized_strings: {
          en: 'Name Characteristic Deep'
        }
      },
      priority: 2
    }
  });
  return inspectionLayout;
};

export const getMethodologyDeepWithIndicatorCustom = () => {
  return {
    ...defaultMethodologyDeep,
    inspectionLayout: getInspectionLayoutDeepWithIndicatorCustom()
  };
};

export const getMethodologyDeepWithInspectionLayoutWithOnlyOneCharacteristic = () => {
  return {
    ...defaultMethodologyDeep,
    inspectionLayout: cloneDeep(inspectionLayoutDeepWithOneCharacteristicAndOneIndicator)
  };
};

export const getMethodologyWithCharacteristicAndIndicatorInInspectionLayout = () => {
  const methodology = { ...defaultMethodology };
  methodology.inspection_layout = {
    id: '',
    ordered_inspection_groups: [
      {
        id: '',
        component_id: '',
        name: {
          localized_strings: {
            en: 'Legacy Inspection Group'
          }
        },
        priority: 0,
        ordered_categories: [
          {
            id: '23a5282b-3f39-4203-a7cd-ec8454bc22da',
            component_id: '23a5282b-3f39-4203-a7cd-ec8454bc22da',
            priority: 0,
            ordered_phenomenons: [
              {
                id: '00519baf-ab2f-5c06-93a6-8a9006d42566',
                component_id: '00519baf-ab2f-5c06-93a6-8a9006d42566',
                priority: 0,
                ordered_components: componentsWithCharacteristicAndIndicators
              }
            ]
          }
        ]
      }
    ],
    characteristic_ids: ['c06bb037-9f75-4386-8b8f-46d1f82f8730'],
    indicator_ids: ['ad6ac818-253c-44cb-b14b-556d4ad8df09']
  };
  methodology.analytic_context_dto = analyticContext;

  return methodology;
};

export const getMethodologyWithCharacteristicInInspectionLayout = () => {
  const methodology = { ...defaultMethodology };
  methodology.inspection_layout = {
    id: '',
    ordered_inspection_groups: [
      {
        id: '',
        component_id: '',
        name: {
          localized_strings: {
            en: 'Legacy Inspection Group'
          }
        },
        priority: 0,
        ordered_categories: [
          {
            id: '23a5282b-3f39-4203-a7cd-ec8454bc22da',
            component_id: '23a5282b-3f39-4203-a7cd-ec8454bc22da',
            priority: 0,
            ordered_phenomenons: [
              {
                id: '00519baf-ab2f-5c06-93a6-8a9006d42566',
                component_id: '00519baf-ab2f-5c06-93a6-8a9006d42566',
                priority: 0,
                ordered_components: [
                  {
                    class_name: ClassName.CHARACTERISTIC,
                    id: 'c06bb037-9f75-4386-8b8f-46d1f82f8730',
                    component_id: 'c06bb037-9f75-4386-8b8f-46d1f82f8730',
                    priority: 0
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    characteristic_ids: ['c06bb037-9f75-4386-8b8f-46d1f82f8730'],
    indicator_ids: []
  };
  return methodology;
};

export const diagnostics: IndicatorDiagnostic[] = [
  {
    label: {
      localized_strings: {
        en: 'Diagnostic',
        'pt-BR': 'Diagnostic'
      }
    },
    thresholds: [
      {
        start_value: 2,
        end_value: 3,
        label: {
          localized_strings: {
            en: 'DAMAGE',
            'pt-BR': 'DAMAGE'
          }
        },
        color: '#f85555'
      },
      {
        start_value: 1,
        end_value: 2,
        label: {
          localized_strings: {
            en: 'CONTROL',
            'pt-BR': 'CONTROL'
          }
        },
        color: '#ffca28'
      },
      {
        start_value: 0,
        end_value: 1,
        label: {
          localized_strings: {
            en: 'ACCEPTANCE',
            'pt-BR': 'ACCEPTANCE'
          }
        },
        color: '#6fca73'
      }
    ],
    type: 'AGRONOMIC'
  }
];

export const featureFlags: string[] = [FeatureFlagEnum.NO_HEATMAP, FeatureFlagEnum.NO_TIMELINE];

const featureFlagsAnaltyicContext: FeatureFlag[] = [{ label: FeatureFlagEnum.NO_HEATMAP }, { label: FeatureFlagEnum.NO_TIMELINE }];

export const analyticContext = {
  id: '8ac9a7c8-e9b6-404f-8e32-7218ee7534b7',
  custom_indicator_dtos: [
    {
      indicator_id: 'ad6ac818-253c-44cb-b14b-556d4ad8df09',
      feature_flags: featureFlagsAnaltyicContext,
      diagnostics
    }
  ]
};

export const getMethodologyWithCharacteristicAndAnalyticContext = () => {
  const methodology = getMethodologyWithCharacteristicInInspectionLayout();
  methodology.analytic_context_dto = analyticContext;
  return methodology;
};

export const getInspectLayoutWithThreePhenomena = () => {
  return inspectionLayoutDeepWithThreePhenomena;
};

export const getMethodologyWithCharacteristicAndIndicatorInInspectionLayoutAndAnalyticContext = () => {
  const methodology = getMethodologyWithCharacteristicAndIndicatorInInspectionLayout();
  methodology.analytic_context_dto = analyticContext;
  return methodology;
};

export const getMethodologyWithAnalyticContext = () => {
  const methodology = { ...defaultMethodology };
  methodology.analytic_context_dto = analyticContext;
  return methodology;
};

const baseIndicator = {
  id: 'ad6ac818-253c-44cb-b14b-556d4ad8df09',
  name: {
    localized_strings: {
      en: 'Presença Indicador'
    }
  },
  company_id: null,
  feature_flags: featureFlags,
  diagnostics,
  expression: {
    type: 'MEAN',
    characteristics: ['c06bb037-9f75-4386-8b8f-46d1f82f8730']
  }
};

export const indicators = [
  {
    ...baseIndicator,
    isNew: false,
    return_variable: 'r',
    phenomenon_ids: ['00519baf-ab2f-5c06-93a6-8a9006d42566']
  }
];

export const indicatorsWithParentId = [
  {
    ...baseIndicator,
    parent_id: uuid(),
    phenomenon_ids: ['00519baf-ab2f-5c06-93a6-8a9006d42566']
  }
];

export const multiPhenomenaIndicators = [
  {
    ...baseIndicator,
    phenomenon_ids: ['00519baf-ab2f-5c06-93a6-8a9006d42566', '0f0d4818-caa7-4a47-b71d-114dc8b64abf']
  }
];

export const phenomenonsTreeWithDefaultIndicator = [
  {
    id: '00519baf-ab2f-5c06-93a6-8a9006d42566',
    category_id: '23a5282b-3f39-4203-a7cd-ec8454bc22da',
    name: {
      localized_strings: {
        'pt-BR': 'Antracnose',
        en: 'Antracnose'
      }
    },
    company_id: null,
    characteristics: [
      {
        id: '99d16dfa-8037-4fa1-8d49-78253242693d',
        isNew: false,
        name: {
          localized_strings: {
            en: 'teste img'
          }
        },
        default_indicator_id: undefined,
        company_id: null,
        input_definition: inputDefinition
      },
      {
        id: 'c06bb037-9f75-4386-8b8f-46d1f82f8730',
        isNew: false,
        name: {
          localized_strings: {
            en: 'Presença Característica'
          }
        },
        default_indicator_id: 'ad6ac818-253c-44cb-b14b-556d4ad8df09',
        company_id: null,
        input_definition: inputDefinition
      }
    ],
    indicators: [...indicators],
    short_description: {
      localized_strings: {
        'pt-BR': 'Colletotrichum Circinans',
        en: 'Colletotrichum Circinans'
      }
    }
  }
];

const basePhenomenonsTree = {
  id: '00519baf-ab2f-5c06-93a6-8a9006d42566',
  category_id: '23a5282b-3f39-4203-a7cd-ec8454bc22da',
  name: {
    localized_strings: {
      'pt-BR': 'Antracnose',
      en: 'Antracnose'
    }
  },
  company_id: null,
  characteristics: [
    {
      id: '99d16dfa-8037-4fa1-8d49-78253242693d',
      name: {
        localized_strings: {
          en: 'teste img'
        }
      },
      default_indicator_id: null,
      company_id: null
    },
    {
      id: 'c06bb037-9f75-4386-8b8f-46d1f82f8730',
      name: {
        localized_strings: {
          en: 'Presença Característica'
        }
      },
      default_indicator_id: null,
      company_id: null
    }
  ],
  short_description: {
    localized_strings: {
      'pt-BR': 'Colletotrichum Circinans',
      en: 'Colletotrichum Circinans'
    }
  }
};

export const phenomenonsTreeWithParentId = [
  {
    ...basePhenomenonsTree,
    parent_id: uuid(),
    company_id: uuid(),
    characteristics: [
      {
        id: '99d16dfa-8037-4fa1-8d49-78253242693',
        name: {
          localized_strings: {
            en: 'teste imgsssss'
          }
        },
        default_indicator_id: null,
        parent_id: null,
        company_id: null
      },
      {
        id: 'c06bb037-9f75-4386-8b8f-46d1f82f8730',
        name: {
          localized_strings: {
            en: 'Presença Característica'
          }
        },
        default_indicator_id: null,
        parent_id: uuid(),
        company_id: uuid()
      }
    ],
    indicators: [...indicatorsWithParentId]
  }
];

export const phenomenonsTreeWithoutDefaultIndicator = [
  {
    ...basePhenomenonsTree,
    indicators: [...indicators]
  }
];

export const phenomenonsTreeWithMultiPhenomenaIndicator = [
  {
    ...basePhenomenonsTree,
    indicators: [...multiPhenomenaIndicators]
  }
];

export const companies = [
  {
    name: 'Farm 1',
    address: null,
    country: 'Brasil',
    city: 'Belo Horizonte',
    state: 'Belo Horizonte',
    zipCode: null,
    email: null,
    phone1: '31991622588',
    phone2: null,
    currency: 'DOLLAR',
    subscriptions: [
      {
        companyId: '9c807fb5-206d-421c-8bfa-4144dc86860f',
        product: 'BASE',
        users: ['de0d4d98-2b45-4f87-93c7-35d5cf71f591'],
        areaInHectares: null,
        accessKey: {
          cod: '2368be45',
          notes: null,
          id: '2368be45-f671-4716-9829-b6ea9c998a4b'
        },
        id: '09ca43a3-a59d-488c-8a08-5816b71e5052'
      }
    ],
    purchasePotential: null,
    id: '9c807fb5-206d-421c-8bfa-4144dc86860f'
  },
  {
    name: 'Atto Sementes',
    address: null,
    country: 'Brasil',
    city: 'Belo Horizonte',
    state: 'Belo Horizonte',
    zipCode: null,
    email: null,
    phone1: '31991622588',
    phone2: null,
    currency: 'DOLLAR',
    subscriptions: [
      {
        companyId: 'cc87d5f5-51f2-4eee-a01d-82ad90443012',
        product: 'BASE',
        users: ['de0d4d98-2b45-4f87-93c7-35d5cf71f591'],
        areaInHectares: null,
        accessKey: {
          cod: '2368be45',
          notes: null,
          id: '2368be45-f671-4716-9829-b6ea9c998a4b'
        },
        id: '09ca43a3-a59d-488c-8a08-5816b71e5052'
      }
    ],
    purchasePotential: null,
    id: 'cc87d5f5-51f2-4eee-a01d-82ad90443012'
  }
];

export const crops = [
  {
    isNew: false,
    cohese: true,
    company_id: '9c807fb5-206d-421c-8bfa-4144dc86860f',
    created_at: '2020-03-21T16:03:26Z',
    default_variety_id: '6dd8054f-ba58-4997-a9e5-a2a4aee44bf2',
    deleted_at: null,
    development_phases: [
      {
        crop_id: '941e76ad-8156-4c1c-aa31-db069e0695ba',
        days_after_emergency: 1,
        description: {
          localized_strings: {
            en: 'e1'
          }
        },
        duration_in_days: 5,
        id: 'f9fd119f-9b54-4da7-b1e5-71459fa6b1f0',
        isNew: true,
        localized_instruction: {
          localized_strings: {
            en: 'e1'
          }
        },
        name: {
          localized_strings: {
            en: 'E1'
          }
        }
      },
      {
        crop_id: '941e76ad-8156-4c1c-aa31-db069e0695ba',
        days_after_emergency: 10,
        description: {
          localized_strings: {
            en: 'V1'
          }
        },
        duration_in_days: 5,
        id: '5c47d989-1d93-4b19-a232-37a0a74c82b6',
        isNew: true,
        localized_instruction: {
          localized_strings: {
            en: 'V1'
          }
        },
        name: {
          localized_strings: {
            en: 'V1'
          }
        }
      },
      {
        crop_id: '941e76ad-8156-4c1c-aa31-db069e0695ba',
        days_after_emergency: 15,
        description: {
          localized_strings: {
            en: 'R1'
          }
        },
        duration_in_days: 5,
        id: 'bbd944b3-bade-4e5b-833d-6293da7de13c',
        isNew: true,
        localized_instruction: {
          localized_strings: {
            en: 'R1'
          }
        },
        name: {
          localized_strings: {
            en: 'R1'
          }
        }
      }
    ],
    id: '941e76ad-8156-4c1c-aa31-db069e0695ba',
    modified_at: null,
    name: {
      localized_strings: {
        en: 'Soja'
      }
    },
    variety_products_ids: ['99f49118-b42e-4d41-8ccf-0fcac6febf1d', '6dd8054f-ba58-4997-a9e5-a2a4aee44bf2'],
    wk_slug: 'soybean'
  }
];

export const seasonTree: SeasonTree[] = [
  {
    season: {
      name: 'Soja 19/20',
      cropId: '1537519e-e4c4-4d39-8a13-27fbb0eff041',
      id: '8f2a7170-9183-4b90-802f-23015fa962db'
    },
    seasonProperties: [
      {
        id: '023b028d-75e9-46cd-b6fd-4c44d8a48a08',
        property: {
          companyId: '9c807fb5-206d-421c-8bfa-4144dc86860f',
          name: 'Soinco Property',
          id: '023b028d-75e9-46cd-b6fd-4c44d8a48a08'
        },
        seasonAreas: [
          {
            area: {
              name: 'Soinco Field',
              id: 'f7bd82ca-430d-45f5-bb69-8904dd282862'
            },
            id: 'a066e4c4-ac32-42ff-b29c-464fadf1d52e'
          }
        ]
      }
    ]
  }
];

export const defaultComponents: ComponentDeep[] = [
  {
    characteristic: {
      id: 'c06bb037-9f75-4386-8b8f-46d1f82f8735',
      name: {
        localized_strings: {
          en: 'Tamanho da lagarta em centímetros'
        }
      },
      priority: 0,
      comesFromCanonical: true,
      characteristicId: 'c06bb037-9f75-4386-8b8f-46d1f82f8738'
    },
    priority: 0
  },
  {
    characteristic: {
      id: 'c06bb037-9f75-4386-8b8f-46d1f82f8735',
      characteristicId: 'c06bb037-9f75-4386-8b8f-46d1f82f8715',
      name: {
        localized_strings: {
          en: 'Lagartas médias'
        }
      },
      comesFromCanonical: false,
      priority: 0
    },
    priority: 1,
    indicator: {
      id: 'c06bb037-9f75-4386-8b8f-46d1f82f8735',
      indicatorId: 'c06bb037-9f75-4386-8b8f-76d1f82f8735',
      name: {
        localized_strings: {
          en: 'Média de lagartas médias'
        }
      },
      isDefault: true,
      showOnInspectionLayout: false,
      showOnTimeline: false,
      showOnHeatmap: true,
      diagnostics: [],
      characteristicsIds: ['c06bb037-9f75-4386-8b8f-4651f82f8735'],
      phenomenonIds: [],
      comesFromCanonical: false
    }
  },
  {
    priority: 2,
    indicator: {
      id: 'c06bb037-9f75-4386-8b8f-46d1f82f8735',
      indicatorId: 'c06bb037-9f75-4h86-8b8f-46d1f82f8735',
      name: {
        localized_strings: {
          en: 'Somatório de lagartas médias'
        }
      },
      isDefault: true,
      showOnInspectionLayout: false,
      showOnTimeline: false,
      showOnHeatmap: true,
      diagnostics: [],
      characteristicsIds: ['c06bb037-9f75-4386-8b8f-46d1f82f8715'],
      phenomenonIds: [],
      comesFromCanonical: false
    }
  }
];
function generateComponentByCharacteristicIdAndCharacteristicsFromIndicator(characteristicId: UUID, characteristicsIds: UUID[]) {
  const component: ComponentDeep = {
    indicator: {
      id: uuid(),
      indicatorId: uuid(),
      name: {
        localized_strings: {
          en: 'Indicator'
        }
      },
      isDefault: false,
      showOnInspectionLayout: false,
      showOnTimeline: false,
      showOnHeatmap: false,
      diagnostics: [],
      characteristicsIds
    },
    characteristic: {
      id: uuid(),
      characteristicId,
      name: {
        localized_strings: {
          en: 'Characteristic'
        }
      }
    }
  };
  return component;
}

export const getComponentsFromMethodologyDeepWithCharacteristicsAndIndicators = () => {
  const componentDeepMock: ComponentDeep[] = [];
  // characteristic not linked with other indicator
  componentDeepMock.push(
    generateComponentByCharacteristicIdAndCharacteristicsFromIndicator('9b9ba2b6-44b6-4547-a60c-a1dc7ef3bb33', [
      '9b9ba2b6-44b6-4547-a60c-a1dc7ef3bb33'
    ])
  );

  // characteristic linked with indicator 02 and 03
  componentDeepMock.push(
    generateComponentByCharacteristicIdAndCharacteristicsFromIndicator('6f418363-d2c5-49e4-8b77-ae70e596dc41', [
      '6f418363-d2c5-49e4-8b77-ae70e596dc41'
    ])
  );
  componentDeepMock.push(
    generateComponentByCharacteristicIdAndCharacteristicsFromIndicator('91df8ec6-981a-4567-9777-8df166a1851d', [
      '6f418363-d2c5-49e4-8b77-ae70e596dc41',
      '91df8ec6-981a-4567-9777-8df166a1851d'
    ])
  );

  const componentWithoutCharacteristic: ComponentDeep = {
    indicator: {
      id: uuid(),
      indicatorId: uuid(),
      name: localized_strings,
      isDefault: false,
      showOnInspectionLayout: false,
      showOnTimeline: false,
      showOnHeatmap: false,
      diagnostics: [],
      characteristicsIds: []
    }
  };

  // component without characteristic
  componentDeepMock.push(componentWithoutCharacteristic);
  return componentDeepMock;
};

export const indicatorCharacteristics = {
  name: localized_strings,
  showOnInspectionLayout: false,
  showOnTimeline: true,
  showOnHeatmap: true,
  diagnostics: [],
  isDefault: false
};

export const methodologyDeepWithIndicatorsAndCharacteristics = {
  id: '683d91f6-ade5-40be-85b1-3680b224fcc3',
  name: localized_strings,
  description: localized_strings,
  isNew: false,
  company: {
    id: 'f315754b-309b-4e5a-bc1f-518605a3f489',
    name: 'Agrícola Correa'
  },
  crop: {
    id: '1537519e-e4c4-4d39-8a13-27fbb0eff041',
    name: localized_strings
  },
  defaultMethodology: false,
  seasonsTree: {
    seasons: [
      {
        id: '9fb72688-d36f-4cf0-b1ad-a429a8550d56',
        name: 'Soja Soinco - 19/20'
      }
    ],
    seasonProperties: [],
    seasonAreas: []
  },
  inspectionGroups: [],
  inspectionLayout: {
    id: '67e90b39-2b2b-435e-8187-9948002db1ed',
    inspectionGroups: [
      {
        id: '041ed36d-5003-482e-ac93-2bc8ab6c680b',
        inspectionGroupId: '8b0b924b-bd2b-40b4-9d96-cd16cb79c66b',
        name: localized_strings,
        priority: 1,
        categories: [
          {
            id: '1b360051-5c2d-4768-9731-5f1afb9d083d',
            categoryId: 'e3bdc345-5c2f-497f-8d50-be75705bf5a9',
            priority: 0,
            phenomenons: [
              {
                id: '95e9f9f1-6492-4530-aba1-814390ac1bfa',
                phenomenonId: '5c7e0b63-e5e2-49bc-8f82-1f6b9b3515a7',
                priority: 1,
                components: [
                  defaultCharacteristic,
                  {
                    indicator: {
                      id: '712e9996-ec71-49f4-a99d-8d8fd97b01ee',
                      indicatorId: '888ef4e4-f55f-4552-b86b-dccd813c38d9',
                      name: localized_strings,
                      showOnInspectionLayout: true,
                      showOnTimeline: true,
                      showOnHeatmap: true,
                      diagnostics: [],
                      isDefault: false,
                      priority: 2,
                      characteristicsIds: ['5ac292aa-4d6e-47c5-b48a-26bb2127c9a1'],
                      phenomenonIds: ['5c7e0b63-e5e2-49bc-8f82-1f6b9b3515a7'],
                      comesFromCanonical: false
                    },
                    priority: 2
                  }
                ],
                name: localized_strings,
                comesFromCanonical: false
              },
              {
                id: '8bc38090-5359-411b-8387-92bb17b4efc8',
                phenomenonId: '943de944-fa32-4337-a78e-e519b69e9727',
                priority: 2,
                components: [
                  {
                    characteristic: {
                      ...defaultCharacteristic.characteristic,
                      id: 'e9359b80-2fb4-4231-a1ec-b26a0da0cf50',
                      characteristicId: '8cca0966-1e05-4382-8fe2-24d36fc55a00',
                      name: localized_strings
                    },
                    priority: 1
                  },
                  {
                    indicator: {
                      id: '6b77834c-3291-4a6a-9c01-0a35c90c4f8e',
                      indicatorId: '0707eb9f-a54e-4781-bfce-62a5d1ddc18e',
                      name: localized_strings,
                      showOnInspectionLayout: false,
                      showOnTimeline: true,
                      showOnHeatmap: true,
                      diagnostics: [],
                      isDefault: false,
                      characteristicsIds: ['8cca0966-1e05-4382-8fe2-24d36fc55a00'],
                      phenomenonIds: ['943de944-fa32-4337-a78e-e519b69e9727'],
                      comesFromCanonical: false
                    }
                  }
                ],
                name: localized_strings,
                comesFromCanonical: false,
                collapsed: true
              }
            ],
            name: localized_strings,
            collapsed: true
          },
          {
            id: '7dd6b7bd-e883-4c91-a62e-52acfc5f583b',
            categoryId: '2be4be48-3616-4311-9d84-127a0793a233',
            priority: 1,
            phenomenons: [
              {
                id: '9809616e-c8ed-4476-a2ae-50d5f8f6e388',
                phenomenonId: '0a7d24e1-a143-4492-abe4-8efade61c913',
                priority: 3,
                components: [
                  {
                    ...defaultCharacteristic,
                    id: 'e44be47d-a36f-4174-92d9-b16dca3b8de0',
                    characteristicId: 'b8a0a6f7-a9b3-4c3f-b8db-d6088177f799',
                    name: localized_strings
                  },
                  {
                    indicator: {
                      id: '437f7d93-99b0-45cc-a964-652efd2e2151',
                      indicatorId: '67546c81-5f11-40ca-ae97-0da9c0bb5854',
                      name: localized_strings,
                      showOnInspectionLayout: false,
                      showOnTimeline: true,
                      showOnHeatmap: true,
                      diagnostics: [],
                      isDefault: false,
                      characteristicsIds: ['b8a0a6f7-a9b3-4c3f-b8db-d6088177f799'],
                      phenomenonIds: ['0a7d24e1-a143-4492-abe4-8efade61c913'],
                      comesFromCanonical: false
                    }
                  }
                ],
                name: localized_strings,
                comesFromCanonical: false
              }
            ],
            name: localized_strings
          }
        ],
        collapsed: true
      }
    ]
  },
  multiPhenomenaIndicators: [],
  analyticContextId: '89e8990c-8676-4e28-8624-f0b0d03bb6b5'
};

export const indicatorEdited = {
  cohese: true,
  company_id: 'f315754b-309b-4e5a-bc1f-518605a3f489',
  created_at: '2020-01-15T14:25:02.000+0000',
  decimal_places: 2,
  deleted_at: null,
  description: localized_strings,
  diagnostics: [],
  expression: {
    characteristics: ['8cca0966-1e05-4382-8fe2-24d36fc55a00'],
    constants: undefined,
    name: 'var_1d97b9c5_7e0e_441f_9e77_104525cea3a7',
    output_type: OutputType.DOUBLE,
    script_body:
      'var var_1d97b9c5_7e0e_441f_9e77_104525cea3a7 = function(context) {\n if(context["8cca0966-1e05-4382-8fe2-24d36fc55a00"]){\nreturn Math.mean(context["8cca0966-1e05-4382-8fe2-24d36fc55a00"]);\n}else { \nreturn null;\n} \n}\n',
    smdl_rules: undefined,
    type: 'MEAN',
    variables: undefined
  },
  feature_flags: [FeatureFlagEnum.NO_TIMELINE],
  id: '0707eb9f-a54e-4781-bfce-62a5d1ddc18e',
  modified_at: '2020-04-02T23:04:24.870+0000',
  name: localized_strings,
  parent_id: undefined,
  phenomenon_ids: ['943de944-fa32-4337-a78e-e519b69e9727'],
  short_description: localized_strings,
  uom: localized_strings,
  isNew: false,
  return_variable: ''
};

const phenCharacteristic = {
  aliases_ids: [],
  isNew: false,
  input_definition: inputDefinition,
  id: '99d16dfa-8037-4fa1-8d49-78253242693d',
  name: localized_strings,
  default_indicator_id: undefined,
  company_id: null
};

const phenCharacteristicWithDefaultIndicator = {
  aliases_ids: [],
  isNew: false,
  input_definition: inputDefinition,
  id: '99d16dfa-8037-4fa1-8d49-78253242693d',
  name: localized_strings,
  default_indicator_id: 'ad6ac818-253c-44cb-b14b-556d4ad8df09',
  company_id: null
};

export const mockedCharacteristicDeep = (id: string): CharacteristicDeep => {
  return {
    id,
    characteristicId: '99d16dfa-8037-4fa1-8d49-78253242693d',
    name: localized_strings,
    comesFromCanonical: true,
    aliasesIds: [],
    input_definition: {
      class_name: InputDefinitionType.NUMPAD,
      values: [
        {
          localized_literal: {
            localized_strings: {
              en: 'Presença'
            }
          },
          value: 10
        }
      ]
    }
  };
};

const phenIndicator2 = {
  isNew: false,
  return_variable: 'funciona pfv',
  id: 'ad6ac818-253c-44cb-b14b-556d4ad8df09',
  name: localized_strings,
  company_id: null,
  feature_flags: featureFlags,
  diagnostics: [],
  expression: {
    type: 'MEAN',
    characteristics: ['c06bb037-9f75-4386-8b8f-46d1f82f8730']
  },
  phenomenon_ids: [uuid()]
};

const phenIndicator = {
  isNew: false,
  return_variable: 'teste',
  id: 'ad6ac818-253c-44cb-b14b-556d4ad8df09',
  name: localized_strings,
  company_id: null,
  feature_flags: featureFlags,
  diagnostics: [],
  expression: {
    type: 'MEAN',
    characteristics: ['c06bb037-9f75-4386-8b8f-46d1f82f8730']
  },
  phenomenon_ids: [uuid()]
};

export const mockedIndicatorDeep = (id: string, showOnInspectionLayout = false): IndicatorDeep => {
  return {
    id,
    indicatorId: 'ad6ac818-253c-44cb-b14b-556d4ad8df09',
    name: localized_strings,
    isDefault: false,
    showOnInspectionLayout,
    showOnTimeline: false,
    showOnHeatmap: false,
    diagnostics: [],
    characteristicsIds: ['c06bb037-9f75-4386-8b8f-46d1f82f8730'],
    comesFromCanonical: false,
    expression: {
      characteristics: ['c06bb037-9f75-4386-8b8f-46d1f82f8730'],
      type: 'MEAN'
    }
  };
};

export const phenomenonTreeWithDefaultIndicator: Phenomenon = {
  isNew: false,
  id: '00519baf-ab2f-5c06-93a6-8a9006d42566',
  name: localized_strings,
  company_id: undefined,
  characteristics: [phenCharacteristicWithDefaultIndicator, phenCharacteristic],
  indicators: [phenIndicator, phenIndicator2],
  short_description: localized_strings
};

export const getPhenomenonTree = () => {
  const phenomenonId = uuid();
  return {
    isNew: false,
    id: phenomenonId,
    name: localized_strings,
    company_id: undefined,
    characteristics: [getPhenomenonTreeCharacteristic(), getPhenomenonTreeCharacteristic()],
    indicators: [getPhenomenonTreeIndicator(phenomenonId), getPhenomenonTreeIndicator(phenomenonId)],
    short_description: localized_strings,
    parent_id: undefined
  };
};

export const getPhenomenonTreeCharacteristic = (): Characteristic => {
  return {
    aliases_ids: [],
    isNew: false,
    input_definition: cloneDeep(inputDefinition),
    id: uuid(),
    name: cloneDeep(localized_strings),
    default_indicator_id: undefined,
    company_id: null
  };
};

export const getPhenomenonTreeIndicator = (phenomenonId): Indicator => {
  return {
    isNew: false,
    return_variable: 'teste',
    id: uuid(),
    name: localized_strings,
    company_id: null,
    feature_flags: featureFlags,
    diagnostics: [],
    expression: {
      type: 'MEAN',
      characteristics: ['c06bb037-9f75-4386-8b8f-46d1f82f8730']
    },
    phenomenon_ids: [phenomenonId]
  };
};

export const phenomenonTreeWithoutIndicator: Phenomenon = {
  isNew: false,
  id: '00519baf-ab2f-5c06-93a6-8a9006d42566',
  name: localized_strings,
  company_id: undefined,
  characteristics: [
    {
      aliases_ids: [],
      isNew: false,
      input_definition: inputDefinition,
      id: '99d16dfa-8037-4fa1-8d49-78253242693d',
      name: localized_strings,
      default_indicator_id: undefined,
      company_id: null
    }
  ],
  indicators: [],
  short_description: {
    localized_strings: {
      'pt-BR': 'Colletotrichum Circinans',
      en: 'Colletotrichum Circinans'
    }
  }
};

export const phenomenonTreeWithoutCharacteristic = {
  isNew: false,
  id: 'phenomenon tree without characteristic',
  name: localized_strings,
  company_id: undefined,
  characteristics_dto: [],
  indicators_dto: [phenIndicator],
  short_description: localized_strings
};

export const phenomenonWithoutIndicatorAndCharacteristic: Phenomenon = {
  isNew: false,
  id: '00519baf-ab2f-5c06-93a6-8a9006d42566',
  name: localized_strings,
  company_id: undefined,
  characteristics: [],
  indicators: [],
  short_description: localized_strings
};

export const phenDeepComponents: ComponentDeep = {
  ...defaultCharacteristic,
  indicator: {
    id: '712e9996-ec71-49f4-a99d-8d8fd97b01ee',
    indicatorId: '888ef4e4-f55f-4552-b86b-dccd813c38d9',
    ...indicatorCharacteristics,
    priority: 2,
    characteristicsIds: ['5ac292aa-4d6e-47c5-b48a-26bb2127c9a1'],
    phenomenonIds: ['5c7e0b63-e5e2-49bc-8f82-1f6b9b3515a7'],
    comesFromCanonical: false
  },
  priority: 2
};

const characteristicInfo = {
  name: localized_strings,
  priority: 0,
  comesFromCanonical: false
};

export const mockedComponentDeep: ComponentDeep[] = [
  {
    characteristic: {
      id: '77ad9eba-d1b4-419f-b143-6aa9dff594bc',
      characteristicId: '99956f84-f6e9-4fbd-9eb1-f90f45ca5f5a',
      ...characteristicInfo
    },
    priority: 0
  },
  {
    indicator: {
      id: 'a89c0655-2c21-4651-add1-754ed2998874',
      indicatorId: 'd65f024a-d358-4dc5-8164-91c79d24fa53',
      ...indicatorCharacteristics,
      characteristicsIds: ['99956f84-f6e9-4fbd-9eb1-f90f45ca5f5a'],
      phenomenonIds: ['2274ea85-434f-4510-bf91-be745b3ef710'],
      comesFromCanonical: false
    }
  },
  {
    characteristic: {
      id: 'cb3eca45-3099-4dae-91ed-dc1dcc40a18c',
      characteristicId: 'd6647ca4-31a0-4bfb-be39-a9a214155f0e',
      name: localized_strings
    },
    indicator: {
      id: '95bf2e75-b453-435d-be0a-d4fd5f03557a',
      indicatorId: 'd4fb9d60-e50c-4dc8-a972-6b046d9dcbba',
      ...indicatorCharacteristics,
      characteristicsIds: ['d6647ca4-31a0-4bfb-be39-a9a214155f0e'],
      comesFromCanonical: false
    }
  }
];

export const selectedComponentDeep: ComponentDeep[] = [
  {
    characteristic: {
      id: '77ad9eba-d1b4-419f-b143-6aa9dff594bc',
      characteristicId: '99956f84-f6e9-4fbd-9eb1-f90f45ca5f5a',
      ...characteristicInfo
    },
    priority: 0
  },
  {
    indicator: {
      id: 'a89c0655-2c21-4651-add1-754ed2998874',
      indicatorId: 'd65f024a-d358-4dc5-8164-91c79d24fa53',
      ...indicatorCharacteristics,
      characteristicsIds: ['99956f84-f6e9-4fbd-9eb1-f90f45ca5f5a'],
      phenomenonIds: ['2274ea85-434f-4510-bf91-be745b3ef710'],
      comesFromCanonical: false
    }
  }
];

export const mockedMethodologyDeep: MethodologyDeep = {
  id: '6eb58f99-326b-4ba8-b33c-10f5a58c1d11',
  name: localized_strings,
  description: localized_strings,
  isNew: false,
  company: {
    id: '52aacd3d-442c-475a-a23a-ff2366db224a',
    name: 'Siloti Milho'
  },
  crop: {
    id: 'b8dd2c03-d749-40d8-8706-9a299f74b279',
    name: localized_strings
  },
  defaultMethodology: true,
  parentId: undefined,
  seasonsTree: {
    seasons: [
      {
        id: '37e352e4-249a-42fc-b268-7ac394b486d2',
        name: 'Legacy Season - LUCAS DEVEQUI SILOTI - FAZENDA BERMIRO - MILHO - LUCAS DEVEQUI SILOTI - FAZENDA BERMIRO - MILHO'
      }
    ],
    seasonProperties: [],
    seasonAreas: []
  },
  inspectionLayout: {
    id: '24dabd39-fcb1-4638-864e-2a724669ffd3',
    inspectionGroups: [
      {
        id: 'fb9681a4-70e4-4037-a52c-df58db1767ed',
        inspectionGroupId: 'cbdfa0f9-15e3-4f89-b646-94e7a8c30bcc',
        name: localized_strings,
        priority: 0,
        extraDimensions: {
          id: uuid(),
          collapsed: false,
          components: []
        },
        categories: [
          {
            id: '2cf3005f-4556-4ed3-8b2d-dbae707aebf3',
            categoryId: '00fe1e16-7a82-483f-bb56-c1fe4779b373',
            priority: 0,
            phenomenons: [
              {
                id: '90507a7b-855c-4197-800c-3977ea92739d',
                phenomenonId: '2274ea85-434f-4510-bf91-be745b3ef710',
                priority: 0,
                components: [
                  {
                    characteristic: {
                      id: '4a909858-23d5-449b-a7d6-9eefd36c7718',
                      characteristicId: '99956f84-f6e9-4fbd-9eb1-f90f45ca5f5a',
                      name: localized_strings,
                      priority: 0,
                      comesFromCanonical: false
                    },
                    priority: 0
                  },
                  {
                    indicator: {
                      id: 'c0c8b10e-3f37-4158-869d-000a7af1a246',
                      indicatorId: 'd65f024a-d358-4dc5-8164-91c79d24fa53',
                      ...indicatorCharacteristics,
                      characteristicsIds: ['99956f84-f6e9-4fbd-9eb1-f90f45ca5f5a'],
                      phenomenonIds: ['2274ea85-434f-4510-bf91-be745b3ef710'],
                      comesFromCanonical: false
                    }
                  }
                ],
                name: localized_strings,
                comesFromCanonical: false,
                collapsed: true
              },
              {
                id: '0578f822-d399-442c-a6c2-f0660475483b',
                phenomenonId: 'a59412dd-3bc3-4582-af51-4df2cd6ae1d9',
                priority: 1,
                components: [
                  {
                    characteristic: {
                      id: 'c0356cc2-9488-4ca4-9bea-4581c967f9ba',
                      characteristicId: 'dbb338ae-1328-41f0-88cc-73a50566e233',
                      name: localized_strings,
                      priority: 0,
                      comesFromCanonical: false
                    },
                    priority: 0
                  },
                  {
                    indicator: {
                      ...indicatorCharacteristics,
                      id: '7746dc57-0206-412b-b5a8-402c57a74011',
                      indicatorId: '0dce940f-5bf0-4208-9501-35b8e8147138',
                      characteristicsIds: ['dbb338ae-1328-41f0-88cc-73a50566e233'],
                      phenomenonIds: ['a59412dd-3bc3-4582-af51-4df2cd6ae1d9'],
                      comesFromCanonical: false
                    }
                  }
                ],
                name: localized_strings,
                comesFromCanonical: false
              }
            ],
            name: localized_strings,
            collapsed: true
          }
        ],
        collapsed: true
      }
    ],
    independentVariables: [
      {
        class_name: 'OrderedCharacteristicDTO',
        component_id: '2da1b45d-2a3c-418c-8494-f928a12250fb',
        id: '4ad1065a-11c2-4664-a14c-e9a6a2c94e4e',
        priority: 0
      },
      {
        class_name: 'OrderedCharacteristicDTO',
        component_id: 'ca881cee-8c93-4a8e-bee3-48540a832a24',
        id: '8e3f08b4-46cc-4317-a73a-7dca86268641',
        priority: 1
      }
    ] as any
  },
  multiPhenomenaIndicators: [],
  analyticContextId: '62df7ff2-4013-456d-aaf0-a9d4318d6c64',
  variablesValidation: [
    {
      id: '2c1203ce-a711-4239-bbdb-570c5a906c11',
      phenomenon_id: '0ccdd2f0-4bbd-4923-8b21-f21175dc7c63',
      operation: 'GREATER_THAN',
      main_variable: {
        operand_id: '507e997a-d020-4df8-8d77-b4bff50bce55',
        type: 'VARIABLE'
      },
      compared_with: {
        operand_id: '507e997a-d020-4df8-8d77-b4bff50bce55',
        type: 'VARIABLE'
      }
    }
  ]
};

export const mockedPhenomenonDeep: PhenomenonDeep = {
  id: '90507a7b-855c-4197-800c-3977ea92739d',
  phenomenonId: '2274ea85-434f-4510-bf91-be745b3ef710',
  priority: 0,
  components: [
    {
      characteristic: {
        id: '4a909858-23d5-449b-a7d6-9eefd36c7718',
        characteristicId: '99956f84-f6e9-4fbd-9eb1-f90f45ca5f5a',
        name: localized_strings,
        priority: 0,
        comesFromCanonical: false
      },
      priority: 0
    },
    {
      indicator: {
        id: 'c0c8b10e-3f37-4158-869d-000a7af1a246',
        indicatorId: 'd65f024a-d358-4dc5-8164-91c79d24fa53',
        name: localized_strings,
        showOnInspectionLayout: false,
        showOnTimeline: true,
        showOnHeatmap: true,
        diagnostics: [],
        isDefault: false,
        characteristicsIds: ['99956f84-f6e9-4fbd-9eb1-f90f45ca5f5a'],
        phenomenonIds: ['2274ea85-434f-4510-bf91-be745b3ef710'],
        comesFromCanonical: false
      }
    }
  ],
  name: localized_strings,
  comesFromCanonical: false,
  collapsed: true
};

export const componentDeep: ComponentDeep[] = [
  {
    characteristic: {
      id: 'c9d317d8-7876-448f-bcd2-eb9c5aaaf0ad',
      characteristicId: '16cf8f29-f2f3-4289-92f1-9f3e3a3b2f99',
      name: localized_strings
    }
  }
];

export const allComponentDeep: ComponentDeep[] = [
  ...componentDeep,
  {
    characteristic: {
      id: '4a909858-23d5-449b-a7d6-9eefd36c7718',
      characteristicId: '99956f84-f6e9-4fbd-9eb1-f90f45ca5f5a',
      ...characteristicInfo
    },
    priority: 0
  },
  {
    indicator: {
      id: 'c0c8b10e-3f37-4158-869d-000a7af1a246',
      indicatorId: 'd65f024a-d358-4dc5-8164-91c79d24fa53',
      name: localized_strings,
      showOnInspectionLayout: false,
      showOnTimeline: true,
      showOnHeatmap: true,
      diagnostics: [],
      isDefault: false,
      characteristicsIds: ['99956f84-f6e9-4fbd-9eb1-f90f45ca5f5a'],
      phenomenonIds: ['2274ea85-434f-4510-bf91-be745b3ef710'],
      comesFromCanonical: false
    }
  }
];

export const phenomenonToUpdate: Phenomenon = {
  id: '11c64c52-affb-4273-9e68-d1y6332f00dd',
  name: {
    localized_strings: {
      en: 'Update Name Phenomenon Deep'
    }
  },
  parent_id: undefined,
  short_description: {
    localized_strings: {
      en: 'New Description'
    }
  },
  isNew: false
};

export const characteristicEdited: Characteristic = {
  aliases_ids: [],
  isNew: false,
  id: '5ac292aa-4d6e-47c5-b48a-26bb2127c9a1',
  name: localized_strings,
  input_definition: {
    class_name: InputDefinitionType.NUMPAD,
    values: [
      {
        value: 0,
        localized_literal: localized_strings
      }
    ]
  }
};

export const mockSeasonsTree = [
  {
    season: {
      name: 'Soja Soinco - 19/20',
      startsAt: '2019-07-10T03:00:00+0000',
      endsAt: '2020-07-10T03:00:00+0000',
      cropId: '1537519e-e4c4-4d39-8a13-27fbb0eff041',
      id: '9fb72688-d36f-4cf0-b1ad-a429a8550d56'
    },
    seasonProperties: [
      {
        property: {
          name: 'Guaicuruzes',
          id: '4dd29324-db52-444a-945e-a8cd42702966'
        },
        seasonAreas: [
          {
            varieties: null,
            area: {
              name: 'Downtown',
              id: '0f72c8ff-d476-4b4f-8f18-766037eba8f1'
            },
            seasonPropertyId: null,
            areaInHectares: null,
            id: '1df78d0d-3531-4b1a-a028-d63329f13068'
          }
        ],
        id: '44a4aec5-6376-4802-8674-01724e6d9989'
      }
    ]
  }
];

export const mockedMethodology: MethodologyDeep = {
  id: '659cd8b3-9db9-4c8f-a540-12327ecdb7f1',
  name: localized_strings,
  description: localized_strings,
  isNew: false,
  company: {
    id: 'c47d498d-e9bd-4cca-892b-d134ca906bf5',
    name: 'My Tests'
  },
  crop: {
    id: 'b1464693-b274-45d4-9075-784b76c6dd50',
    name: localized_strings
  },
  defaultMethodology: true,
  seasonsTree: {
    seasons: [],
    seasonAreas: []
  }
};

export const mockedBasicInfo = {
  name: 'Presença',
  company: 'c47d498d-e9bd-4cca-892b-d134ca906bf5',
  crop: 'b1464693-b274-45d4-9075-784b76c6dd50',
  description: 'Presença',
  seasons: []
};
export const phenomenaTreeMock = [
  {
    ...phenomenonsTreeWithDefaultIndicator[0],
    id: '2274ea85-434f-4510-bf91-be745b3ef710',
    isNew: false,
    name: { localized_strings: { en: 'Teste Tree' } },
    company_id: undefined
  }
];

export const phenomenaTreeMockedWithMultPhenon = [
  {
    ...phenomenaTreeMock[0],
    characteristics: phenomenaTreeMock[0].characteristics.map((value, index) =>
      index === 1
        ? {
            ...value,
            default_indicator_id: undefined
          }
        : value
    ),
    indicators: [
      {
        ...phenomenaTreeMock[0].indicators[0],
        phenomenon_ids: [uuid(), uuid()]
      }
    ]
  }
];

export const phenomenaTreeMockedWithIndicatorWithoutPhenomena = [
  {
    ...phenomenaTreeMock[0],
    indicators: [
      {
        ...phenomenaTreeMock[0].indicators[0],
        phenomenon_ids: undefined
      }
    ]
  }
];

export const phenomenaTreeMockExpected = {
  id: phenomenaTreeMock[0].id,
  isCanonic: true,
  name: 'Teste Tree',
  scientificName: getValueLanguage(phenomenaTreeMock[0].short_description),
  category: 'Teste'
};

export const phenomenaTsreeMockedWithCategory = [
  {
    ...phenomenaTreeMock[0],
    category: {
      name: {
        localized_strings: {
          en: 'Teste'
        }
      }
    }
  }
];

export const methodologyDeepWithMultiPhenomIndicators: MethodologyDeep = {
  id: '6eb58f99-326b-4ba8-b33c-10f5a58c1d11',
  name: localized_strings,
  description: localized_strings,
  isNew: false,
  company: {
    id: '52aacd3d-442c-475a-a23a-ff2366db224a',
    name: 'Siloti Milho'
  },
  crop: {
    id: 'b8dd2c03-d749-40d8-8706-9a299f74b279',
    name: localized_strings
  },
  defaultMethodology: true,
  parentId: undefined,
  seasonsTree: {
    seasons: [
      {
        id: '37e352e4-249a-42fc-b268-7ac394b486d2',
        name: 'Legacy Season - LUCAS DEVEQUI SILOTI - FAZENDA BERMIRO - MILHO - LUCAS DEVEQUI SILOTI - FAZENDA BERMIRO - MILHO'
      }
    ],
    seasonProperties: [],
    seasonAreas: []
  },
  inspectionLayout: {
    id: '24dabd39-fcb1-4638-864e-2a724669ffd3',
    inspectionGroups: [
      {
        id: 'fb9681a4-70e4-4037-a52c-df58db1767ed',
        inspectionGroupId: 'cbdfa0f9-15e3-4f89-b646-94e7a8c30bcc',
        name: localized_strings,
        priority: 0,
        extraDimensions: {
          id: uuid(),
          collapsed: false,
          components: []
        },
        categories: [
          {
            id: '2cf3005f-4556-4ed3-8b2d-dbae707aebf3',
            categoryId: '00fe1e16-7a82-483f-bb56-c1fe4779b373',
            priority: 0,
            phenomenons: [
              {
                id: '90507a7b-855c-4197-800c-3977ea92739d',
                phenomenonId: '2274ea85-434f-4510-bf91-be745b3ef710',
                priority: 0,
                components: [
                  {
                    characteristic: {
                      id: '4a909858-23d5-449b-a7d6-9eefd36c7718',
                      characteristicId: '99956f84-f6e9-4fbd-9eb1-f90f45ca5f5a',
                      name: localized_strings,
                      priority: 0,
                      comesFromCanonical: false
                    },
                    priority: 0
                  }
                ],
                name: localized_strings,
                comesFromCanonical: false,
                collapsed: true
              }
            ],
            name: localized_strings,
            collapsed: true
          }
        ],
        collapsed: true
      }
    ]
  },
  multiPhenomenaIndicators: [
    {
      id: 'c0c8b10e-3f37-4158-869d-000a7af1a246',
      indicatorId: 'd65f024a-d358-4dc5-8164-91c79d24fa53',
      ...indicatorCharacteristics,
      characteristicsIds: ['99956f84-f6e9-4fbd-9eb1-f90f45ca5f5a'],
      phenomenonIds: ['2274ea85-434f-4510-bf91-be745b3ef710'],
      comesFromCanonical: false
    }
  ],
  analyticContextId: '62df7ff2-4013-456d-aaf0-a9d4318d6c64'
};

export const olimpoMethodology = {
  id: '45c91f6b-6973-4b61-bbb3-5f153a413c86',
  name: {
    localized_strings: {
      en: 'Methodology Olimpo'
    }
  },
  description: {
    localized_strings: {
      en: 'Olimpo'
    }
  },
  isNew: false,
  company: {
    id: 'd5e96f8b-dee8-4d5d-802c-58f4cc9914d8',
    name: 'Olimpo'
  },
  crop: {
    id: '1537519e-e4c4-4d39-8a13-27fbb0eff041',
    name: {
      localized_strings: {
        en: 'Soja'
      }
    }
  },
  defaultMethodology: false,
  seasonsTree: {
    seasons: [],
    seasonProperties: [],
    seasonAreas: []
  },
  modified_at: '2020-12-29T19:05:54.767+0000',
  deleted_at: null,
  defaultIndicatorsNotFound: [],
  inspectionLayout: {
    id: '16d6441c-f286-4fb0-b0ed-f2de7d0ae55b',
    inspectionGroups: [
      {
        id: '144d1c7a-8f1e-4fad-bffa-b54905142e88',
        inspectionGroupId: '090c3c90-9f8c-44e2-9b8b-ca40de5fd00b',
        name: {
          localized_strings: {
            en: 'Insetos'
          }
        },
        priority: 0,
        categories: [
          {
            id: 'd9e8fdf0-57bf-4696-9bda-70bbd43f78e5',
            categoryId: 'c1072a0d-624a-4d04-bcd2-ce0289f65cbf',
            priority: 0,
            phenomenons: [
              {
                id: 'ee3ed815-d825-437d-a12b-b618f2e871a1',
                phenomenonId: 'f47e3dc1-4dc8-4fa2-aa73-b1672151b6f7',
                priority: 0,
                components: [
                  {
                    characteristic: {
                      id: '5318a65e-56d5-4d17-b471-da1034ac7c1b',
                      characteristicId: 'ef55187e-30eb-4511-aaea-a8facaf33eb7',
                      name: {
                        localized_strings: {
                          en: 'Número de Posturas'
                        }
                      },
                      priority: 0,
                      comesFromCanonical: true
                    },
                    indicator: {
                      id: '42c975cf-0162-44b9-a454-d18bef1855b8',
                      indicatorId: '7915a966-b8fd-435e-bbb3-65b50032eb44',
                      name: {
                        localized_strings: {
                          en: 'Número de Posturas'
                        }
                      },
                      showOnInspectionLayout: false,
                      showOnTimeline: true,
                      showOnHeatmap: true,
                      diagnostics: [
                        {
                          development_phases: null,
                          label: null,
                          thresholds: [
                            {
                              color: '#f85555',
                              end_value: 50,
                              label: {
                                localized_strings: {
                                  en: 'DAMAGE',
                                  'pt-BR': 'DAMAGE'
                                }
                              },
                              start_value: 10
                            },
                            {
                              color: '#6fca73',
                              end_value: 6,
                              label: {
                                localized_strings: {
                                  en: 'ACCEPTANCE',
                                  'pt-BR': 'ACCEPTANCE'
                                }
                              },
                              start_value: 0
                            },
                            {
                              color: '#ffca28',
                              end_value: 10,
                              label: {
                                localized_strings: {
                                  en: 'CONTROL',
                                  'pt-BR': 'CONTROL'
                                }
                              },
                              start_value: 6
                            }
                          ],
                          type: 'AGRONOMIC'
                        }
                      ],
                      isDefault: true,
                      characteristicsIds: ['ef55187e-30eb-4511-aaea-a8facaf33eb7'],
                      phenomenonIds: ['f47e3dc1-4dc8-4fa2-aa73-b1672151b6f7'],
                      comesFromCanonical: true
                    },
                    priority: 0
                  },
                  {
                    indicator: {
                      id: '4d7fc583-0e42-4af9-aa84-989f035afb3b',
                      indicatorId: '4a84388b-e769-4e6f-9b58-5261f442d93a',
                      name: {
                        localized_strings: {
                          en: 'teste 3'
                        }
                      },
                      showOnInspectionLayout: false,
                      showOnTimeline: true,
                      showOnHeatmap: true,
                      diagnostics: [],
                      isDefault: false,
                      characteristicsIds: ['ef55187e-30eb-4511-aaea-a8facaf33eb7'],
                      phenomenonIds: ['f47e3dc1-4dc8-4fa2-aa73-b1672151b6f7'],
                      comesFromCanonical: true
                    }
                  }
                ],
                name: {
                  localized_strings: {
                    en: 'Percevejo castanho'
                  }
                },
                comesFromCanonical: true,
                scientificName: {
                  localized_strings: {
                    en: 'Atarsocoris brachiariae'
                  }
                }
              }
            ],
            name: {
              localized_strings: {
                en: 'Insetos'
              }
            }
          },
          {
            id: '48b618d6-f9a8-49f8-9941-65ee15f2446f',
            categoryId: '4810a9d2-27d5-4cfa-890c-94a75c40ca49',
            priority: 1,
            phenomenons: [
              {
                id: '140ca93b-5cad-46fa-a22d-288a36886b0e',
                phenomenonId: '023a8711-7c17-422b-8b47-96407e7ff63f',
                priority: 0,
                components: [
                  {
                    characteristic: {
                      id: '119ddbd8-87e0-44d4-82b2-0f68e5f42d61',
                      characteristicId: '495a0ced-efbf-44e7-83e0-cfede2e7e292',
                      name: {
                        localized_strings: {
                          en: 'Presença de reboleiras'
                        }
                      },
                      priority: 0,
                      comesFromCanonical: false
                    },
                    indicator: {
                      id: '8fae0a45-1c9d-4043-be76-73d0fa2044a8',
                      indicatorId: 'db98a221-734e-4962-9ec3-b4816b28e7cf',
                      name: {
                        localized_strings: {
                          en: ''
                        }
                      },
                      showOnInspectionLayout: false,
                      showOnTimeline: false,
                      showOnHeatmap: false,
                      diagnostics: [],
                      isDefault: true,
                      characteristicsIds: ['495a0ced-efbf-44e7-83e0-cfede2e7e292'],
                      phenomenonIds: ['023a8711-7c17-422b-8b47-96407e7ff63f'],
                      comesFromCanonical: false
                    },
                    priority: 0
                  }
                ],
                name: {
                  localized_strings: {
                    en: 'Ácaro-rajado',
                    'pt-BR': 'Ácaro-rajado'
                  }
                },
                comesFromCanonical: true,
                scientificName: {
                  localized_strings: {
                    en: 'Tetranychus Urticae',
                    'pt-BR': 'Tetranychus Urticae'
                  }
                }
              },
              {
                id: '5a8c98b9-66d2-4478-8686-239c3c888199',
                phenomenonId: 'bfabee99-5508-4b18-a0e3-6ff1a91618e5',
                priority: 1,
                components: [
                  {
                    characteristic: {
                      id: '40d513c2-03ce-40f8-8b65-370e56c6652d',
                      characteristicId: 'a06d15bf-da24-4144-9991-d0ef3206cb52',
                      name: {
                        localized_strings: {
                          en: 'Working'
                        }
                      },
                      priority: 0,
                      comesFromCanonical: true
                    },
                    indicator: {
                      id: '503154f4-ce65-4e5e-a5cc-6be483163bd3',
                      indicatorId: 'd09f95d7-14d5-4eb0-b09b-752ee44bfabc',
                      name: {
                        localized_strings: {
                          en: 'Is Working',
                          es: '',
                          'pt-BR': 'Tradução deu certo?'
                        }
                      },
                      showOnInspectionLayout: false,
                      showOnTimeline: true,
                      showOnHeatmap: true,
                      diagnostics: [
                        {
                          thresholds: [
                            {
                              color: '#2FB966',
                              end_value: 20,
                              label: {
                                localized_strings: {
                                  en: 'ACCEPTANCE',
                                  'pt-BR': 'ACCEPTANCE'
                                }
                              },
                              start_value: 0
                            },
                            {
                              color: '#FFCA28',
                              end_value: 71.6,
                              label: {
                                localized_strings: {
                                  en: 'CONTROL',
                                  'pt-BR': 'CONTROL'
                                }
                              },
                              start_value: 20
                            },
                            {
                              color: '#F74141',
                              end_value: 100,
                              label: {
                                localized_strings: {
                                  en: 'DAMAGE',
                                  'pt-BR': 'DAMAGE'
                                }
                              },
                              start_value: 71.6
                            }
                          ],
                          type: 'AGRONOMIC'
                        }
                      ],
                      isDefault: true,
                      characteristicsIds: ['a06d15bf-da24-4144-9991-d0ef3206cb52'],
                      phenomenonIds: ['bfabee99-5508-4b18-a0e3-6ff1a91618e5'],
                      comesFromCanonical: true
                    },
                    priority: 0
                  }
                ],
                name: {
                  localized_strings: {
                    en: 'Vaquinha Diabrótica'
                  }
                },
                comesFromCanonical: true,
                scientificName: {
                  localized_strings: {
                    en: 'Diabrotica Speciosa'
                  }
                }
              }
            ],
            name: {
              localized_strings: {
                en: 'Ácaros'
              }
            }
          }
        ]
      },
      {
        id: 'df854c07-30ae-409c-8033-c41f212adfca',
        inspectionGroupId: 'fa688df6-10d5-4822-9e81-5d4614f338b7',
        name: {
          localized_strings: {
            en: 'Outro'
          }
        },
        priority: 1,
        categories: [
          {
            id: '9bcc6585-ec9b-4aef-9bb3-1435ccb2cee8',
            categoryId: 'c1072a0d-624a-4d04-bcd2-ce0289f65cbf',
            priority: 0,
            phenomenons: [
              {
                id: 'e6eeef23-97c8-4e47-8cee-c240faaf961b',
                phenomenonId: 'f47e3dc1-4dc8-4fa2-aa73-b1672151b6f7',
                priority: 0,
                components: [
                  {
                    characteristic: {
                      id: '2b510f66-ff8f-4061-80a7-0faff8980e40',
                      characteristicId: 'b59ad40a-8ca3-4520-9bb0-2a76485174a6',
                      name: {
                        localized_strings: {
                          en: 'Número de Ninfas'
                        }
                      },
                      priority: 0,
                      comesFromCanonical: true
                    },
                    indicator: {
                      id: '2a46eae6-32a1-47d1-a150-74dddf3f1561',
                      indicatorId: '4dbe323b-33e1-479a-a38d-36579f563c8e',
                      name: {
                        localized_strings: {
                          en: 'Número de Ninfas'
                        }
                      },
                      showOnInspectionLayout: false,
                      showOnTimeline: true,
                      showOnHeatmap: true,
                      diagnostics: [
                        {
                          development_phases: null,
                          label: null,
                          thresholds: [
                            {
                              color: '#ffca28',
                              end_value: 8,
                              label: {
                                localized_strings: {
                                  en: 'CONTROL',
                                  'pt-BR': 'CONTROL'
                                }
                              },
                              start_value: 4
                            },
                            {
                              color: '#6fca73',
                              end_value: 4,
                              label: {
                                localized_strings: {
                                  en: 'ACCEPTANCE',
                                  'pt-BR': 'ACCEPTANCE'
                                }
                              },
                              start_value: 0
                            },
                            {
                              color: '#f85555',
                              end_value: 50,
                              label: {
                                localized_strings: {
                                  en: 'DAMAGE',
                                  'pt-BR': 'DAMAGE'
                                }
                              },
                              start_value: 8
                            }
                          ],
                          type: 'AGRONOMIC'
                        }
                      ],
                      isDefault: true,
                      characteristicsIds: ['b59ad40a-8ca3-4520-9bb0-2a76485174a6'],
                      phenomenonIds: ['f47e3dc1-4dc8-4fa2-aa73-b1672151b6f7'],
                      comesFromCanonical: true
                    },
                    priority: 0
                  },
                  {
                    indicator: {
                      id: 'a8f5a9f8-4eea-402b-81df-4cecb8592441',
                      indicatorId: '75c7841b-e240-4f43-9aa2-34b3f30325fd',
                      name: {
                        localized_strings: {
                          en: 'Média de ninfas'
                        }
                      },
                      showOnInspectionLayout: false,
                      showOnTimeline: true,
                      showOnHeatmap: true,
                      diagnostics: [
                        {
                          development_phases: null,
                          label: null,
                          thresholds: [
                            {
                              color: '#6fca73',
                              end_value: 1,
                              label: {
                                localized_strings: {
                                  en: 'ACCEPTANCE',
                                  'pt-BR': 'ACCEPTANCE'
                                }
                              },
                              start_value: 0
                            },
                            {
                              color: '#ffca28',
                              end_value: 2,
                              label: {
                                localized_strings: {
                                  en: 'CONTROL',
                                  'pt-BR': 'CONTROL'
                                }
                              },
                              start_value: 1
                            },
                            {
                              color: '#f85555',
                              end_value: 100,
                              label: {
                                localized_strings: {
                                  en: 'DAMAGE',
                                  'pt-BR': 'DAMAGE'
                                }
                              },
                              start_value: 2
                            }
                          ],
                          type: 'AGRONOMIC'
                        }
                      ],
                      isDefault: false,
                      characteristicsIds: ['b59ad40a-8ca3-4520-9bb0-2a76485174a6'],
                      phenomenonIds: ['f47e3dc1-4dc8-4fa2-aa73-b1672151b6f7'],
                      comesFromCanonical: false
                    }
                  }
                ],
                name: {
                  localized_strings: {
                    en: 'Percevejo castanho'
                  }
                },
                comesFromCanonical: true,
                scientificName: {
                  localized_strings: {
                    en: 'Atarsocoris brachiariae'
                  }
                }
              },
              {
                id: '97b3572e-fd99-47a0-ae7a-631a82e28069',
                phenomenonId: 'b70fdd2c-2ad3-4bad-8cf3-e7f7ed122721',
                priority: 1,
                components: [
                  {
                    characteristic: {
                      id: 'bd837392-2fbd-4182-a1b6-9dcc2fa2c2e0',
                      characteristicId: 'fc2a8789-08d1-48bb-91d6-eebe0d8ecfc5',
                      name: {
                        localized_strings: {
                          en: 'Número de Lagartas Grandes'
                        }
                      },
                      priority: 0,
                      comesFromCanonical: true
                    },
                    indicator: {
                      id: 'b51b12f5-9cc5-48da-9c4c-e5b0740d1394',
                      indicatorId: '526702b7-e5d8-4db2-aa2d-ebdaa43129de',
                      name: {
                        localized_strings: {
                          en: 'Média de lagartas grandes'
                        }
                      },
                      showOnInspectionLayout: false,
                      showOnTimeline: true,
                      showOnHeatmap: true,
                      diagnostics: [
                        {
                          development_phases: null,
                          label: null,
                          thresholds: [
                            {
                              color: '#6fca73',
                              end_value: 3,
                              label: {
                                localized_strings: {
                                  en: 'ACCEPTANCE',
                                  'pt-BR': 'ACCEPTANCE'
                                }
                              },
                              start_value: 0
                            },
                            {
                              color: '#f85555',
                              end_value: 100,
                              label: {
                                localized_strings: {
                                  en: 'DAMAGE',
                                  'pt-BR': 'DAMAGE'
                                }
                              },
                              start_value: 4
                            },
                            {
                              color: '#ffca28',
                              end_value: 4,
                              label: {
                                localized_strings: {
                                  en: 'CONTROL',
                                  'pt-BR': 'CONTROL'
                                }
                              },
                              start_value: 3
                            }
                          ],
                          type: 'AGRONOMIC'
                        }
                      ],
                      isDefault: true,
                      characteristicsIds: ['fc2a8789-08d1-48bb-91d6-eebe0d8ecfc5'],
                      phenomenonIds: ['b70fdd2c-2ad3-4bad-8cf3-e7f7ed122721'],
                      comesFromCanonical: true
                    },
                    priority: 0
                  }
                ],
                name: {
                  localized_strings: {
                    en: 'Lagarta Das Folhas',
                    'pt-BR': 'Lagarta Das Folhas'
                  }
                },
                comesFromCanonical: true,
                scientificName: {
                  localized_strings: {
                    en: 'Omiodes Indicata',
                    'pt-BR': 'Omiodes Indicata'
                  }
                }
              }
            ],
            name: {
              localized_strings: {
                en: 'Insetos'
              }
            }
          },
          {
            id: 'b43b08ec-5bff-4188-b71e-73b3b1858adf',
            categoryId: '40cfec45-cf88-5751-acaf-17f3a8506192',
            priority: 1,
            phenomenons: [
              {
                id: '990e5f74-9993-4881-b5d2-c2f6bfa8c866',
                phenomenonId: '0c7b66f2-45bd-418b-810d-be5a35394e01',
                priority: 0,
                components: [
                  {
                    characteristic: {
                      id: '3a0c2514-c18e-4835-a01c-d3c94ce4a5a2',
                      characteristicId: 'ab2d4129-d557-43e0-865c-0770a2a07256',
                      name: {
                        localized_strings: {
                          en: 'Presença'
                        }
                      },
                      priority: 0,
                      comesFromCanonical: false
                    },
                    indicator: {
                      id: '76c76077-0b0e-43ce-b564-1cd4f13d2e9c',
                      indicatorId: '197e9bbd-09c3-4f05-8362-cc43048274e4',
                      name: {
                        localized_strings: {
                          en: 'Presença'
                        }
                      },
                      showOnInspectionLayout: false,
                      showOnTimeline: true,
                      showOnHeatmap: true,
                      diagnostics: [
                        {
                          development_phases: null,
                          label: null,
                          thresholds: [
                            {
                              color: '#f85555',
                              end_value: 100,
                              label: {
                                localized_strings: {
                                  en: 'DAMAGE',
                                  'pt-BR': 'DAMAGE'
                                }
                              },
                              start_value: 10
                            },
                            {
                              color: '#6fca73',
                              end_value: 5,
                              label: {
                                localized_strings: {
                                  en: 'ACCEPTANCE',
                                  'pt-BR': 'ACCEPTANCE'
                                }
                              },
                              start_value: 0
                            },
                            {
                              color: '#ffca28',
                              end_value: 10,
                              label: {
                                localized_strings: {
                                  en: 'CONTROL',
                                  'pt-BR': 'CONTROL'
                                }
                              },
                              start_value: 5
                            }
                          ],
                          type: 'AGRONOMIC'
                        }
                      ],
                      isDefault: true,
                      characteristicsIds: ['ab2d4129-d557-43e0-865c-0770a2a07256'],
                      phenomenonIds: ['0c7b66f2-45bd-418b-810d-be5a35394e01'],
                      comesFromCanonical: false
                    },
                    priority: 0
                  }
                ],
                name: {
                  localized_strings: {
                    en: 'Milheto',
                    'pt-BR': 'Milheto'
                  }
                },
                comesFromCanonical: true,
                scientificName: {
                  localized_strings: {
                    en: 'Pennisetum glaucum'
                  }
                }
              }
            ],
            name: {
              localized_strings: {
                en: 'Planta Daninha'
              }
            }
          }
        ]
      }
    ]
  },
  multiPhenomenaIndicators: [],
  analyticContextId: '9d7e43e8-b74e-4b2e-b896-d3d8560eecb0',
  wrongIndicators: [
    {
      id: 'f98d031c-3bd1-40f7-a68d-f4e21bafd559',
      indicatorId: 'c1832597-3b01-482e-9382-fe3c7bcd4a66',
      name: {
        localized_strings: {
          en: 'Média de adultos'
        }
      },
      showOnInspectionLayout: false,
      showOnTimeline: true,
      showOnHeatmap: true,
      diagnostics: [
        {
          development_phases: null,
          label: null,
          thresholds: [
            {
              color: '#6fca73',
              end_value: 1,
              label: {
                localized_strings: {
                  en: 'ACCEPTANCE',
                  'pt-BR': 'ACCEPTANCE'
                }
              },
              start_value: 0
            },
            {
              color: '#ffca28',
              end_value: 2,
              label: {
                localized_strings: {
                  en: 'CONTROL',
                  'pt-BR': 'CONTROL'
                }
              },
              start_value: 1
            },
            {
              color: '#f85555',
              end_value: 100,
              label: {
                localized_strings: {
                  en: 'DAMAGE',
                  'pt-BR': 'DAMAGE'
                }
              },
              start_value: 2
            }
          ],
          type: 'AGRONOMIC'
        }
      ],
      characteristicsIds: ['3750ec07-7ac5-4669-8dfc-b58099df242c'],
      comesFromCanonical: false,
      isDefault: false,
      phenomenonIds: ['f47e3dc1-4dc8-4fa2-aa73-b1672151b6f7'],
      wrongConfiguration: true
    }
  ]
};
