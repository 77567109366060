import { of, concat } from 'rxjs';
import { map, concatMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Action, UUID } from '../app/basicModels';
import actions from './actions';
import { getDevices, getDatabases, sendMayday, GetDevicesPayload } from './services';
import { Databases, Mayday } from './models';
import { notification } from '../../components';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';

export const handleLoadDevices = action$ =>
  action$.pipe(
    ofType(actions.LOAD_DEVICES),
    map((action: Action<GetDevicesPayload>) => action.payload),
    concatMap(({ userId, page, size }) =>
      getDevices({ userId, page, size }).pipe(
        map(response => {
          return actions.loadDevicesSuccess(response.data);
        }),
        catchError(error => {
          const errorMsg = notifyErrorOrFallback(error, 'Error on load devices!');
          return of(actions.loadDevicesFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadDatabases = action$ =>
  action$.pipe(
    ofType(actions.LOAD_DATABASES),
    map((action: Action<object>) => action.payload),
    concatMap((userId: UUID) =>
      getDatabases(userId).pipe(
        map(response => {
          const databases = response.data.content as unknown as Databases;
          return actions.loadDatabasesSuccess(databases);
        }),
        catchError(error => {
          const errorMsg = notifyErrorOrFallback(error, 'Error on load databases!');
          return of(actions.loadDatabasesFailure(errorMsg));
        })
      )
    )
  );

export const handleSendMayday = action$ =>
  action$.pipe(
    ofType(actions.SEND_MAYDAY),
    map((action: Action<object>) => action.payload),
    concatMap((mayday: Mayday) =>
      sendMayday(mayday).pipe(
        concatMap(() => {
          notification('success', 'Mayday sent!');
          return concat([actions.sendMaydaySuccess('Success'), actions.loadDatabases(mayday.userId as any)]);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error on send mayday!');
          return of(actions.sendMaydayFailure(errorMsg));
        })
      )
    )
  );
