import { ReactElement } from 'react';
import { TreeNodeSimpleMode } from 'antd/lib/tree-select/interface';
import moment from 'moment';
import { I18nMap } from '../../redux/models';

export interface FormBuilderProps {
  inputs: InputForm[];
  errors: any;
  control: any;
  fieldsClass?: string;
}

export enum ModeEnum {
  DEFAULT = 'default',
  MULTIPLE = 'multiple'
}

export enum SizeEnum {
  SMALL = 'small',
  DEFAULT = '  default',
  LARGE = '  large'
}

export interface InputForm {
  label?: ReactElement;
  name: string;
  initialValue?: any;
  icon?: ReactElement | false;
  options?: Option[];
  treeData?: TreeNodeSimpleMode[];
  disabled?: boolean;
  type: InputType;
  mask?: string;
  placeholder?: string;
  mode?: ModeEnum;
  size?: SizeEnum;
  className?: string;
  required?: boolean;
  translatableObject?: I18nMap;
  min?: number;
  loading?: boolean;
  callback?: Function;
  addItem?: any;
  onChange?: Function;
  onBlur?: Function;
  onSelect?: Function;
  maxLength?: number;
  integerOnChange?: (e: Array<string>) => void;
  defaultValue?: string | Array<string>;
  allowClear?: boolean;
}

export interface Option {
  id: string | number | undefined;
  name: string | JSX.Element;
  workspaceId?: string | null;
  disabled?: boolean;
}

export interface CropOption {
  id: string | number;
  name: string | JSX.Element;
  disabled?: boolean;
  location: string[];
}

export enum InputType {
  INPUT = 'INPUT',
  INPUT_ACTION = 'INPUT_ACTION',
  TREE_SELECT = 'TREE_SELECT',
  SELECT = 'SELECT',
  INPUT_MASK = 'INPUT_MASK',
  EMPTY = 'EMPTY',
  INPUT_PASSWORD = 'INPUT_PASSWORD',
  INPUT_NUMBER = 'INPUT_NUMBER',
  DATE = 'DATE',
  TEXTAREA = 'TEXTAREA',
  TRANSLATABLE = 'TRANSLATABLE',
  TRANSLATABLE_AREA = 'TRANSLATABLE_AREA',
  RADIO = 'RADIO',
  INPUT_PASSWORD_ICON = 'INPUT_PASSWORD_ICON',
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  VIRTUAL_SELECT = 'VIRTUAL_SELECT'
}

export const RANGE_DAYS_ONE_WEEK_CURRENT_DAY = [moment().subtract(7, 'days'), moment()];
export const RANGE_CURRENT_DAY = [moment().subtract(0, 'days'), moment()];

export enum DateFormat {
  DD_MMM_YY = 'DD MMM, YY',
  YYYY_MM_DD = 'YYYY-MM-DD',
  hh_mm_ss_DD_MM_YYYY = 'h:mm:ss DD-MM-YYYY',
  HH_mm_ss_DD_MM_YYYY = 'HH:mm:ss DD-MM-YYYY'
}
