import { ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { map, concatMap, catchError, withLatestFrom } from 'rxjs/operators';
import { notification } from '../../components';
import { commandRequest } from '../../helpers/request/utils';
import Actions from '../actions';
import { addExtraDimensionToMethodology, editExtraDimensionToMethodology } from '../methodologyDeep/services';
import actions from './actions';
import { createExtraDimension, updateExtraDimension, loadAllExtraDimensionsByCompany } from './service';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';

const {saveMethodologyDeepSuccess} = Actions;

export const handleCreateExtraDimension = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_EXTRA_DIMENSION),
    withLatestFrom(
      state$.pipe(map((state: any) => state.User.currentUser)),
      state$.pipe(map((state: any) => state.MethodologyDeep.methodologyDeep))
    ),
    concatMap(([action, currentUser, methodologyDeep]: any) => {
      const exPayload = action.payload;
      const objToSave = commandRequest(exPayload.extraDimension, currentUser)
      if (action.payload.isNew) {
        return createExtraDimension(objToSave).pipe(
          concatMap(() => {
            notification('success', 'Resource created!');
            const newMethodologyDeep = addExtraDimensionToMethodology(methodologyDeep, exPayload.inspectionGroupId, [exPayload.extraDimension])
            return concat([actions.saveExtraDimensionSuccess(objToSave.payload), saveMethodologyDeepSuccess(newMethodologyDeep)])
          }),
          catchError((error) => {
            const msg = notifyErrorOrFallback(error, 'Error create resource. Try it later.');
            return of(actions.saveExtraDimensionFailure(msg))
          })
        )
      }
      return updateExtraDimension(objToSave).pipe(
          concatMap(() => {
            notification('success', 'Resource Edited!');
            const newMethodologyDeep = editExtraDimensionToMethodology(methodologyDeep, objToSave.payload.id, objToSave.payload);
            return concat([actions.saveExtraDimensionSuccess(objToSave.payload), saveMethodologyDeepSuccess(newMethodologyDeep)])
          }),
          catchError((error) => {
            const msg = notifyErrorOrFallback(error, 'Error edited resource. Try it later.');
            return of(actions.saveExtraDimensionFailure(msg))
          })
        )
    })
  )

  export const handleLoadAllExtraDimensionsByCompany = (action$, state$) => 
  action$.pipe(
    ofType(actions.LOAD_EXTRA_DIMENSIONS_BY_COMPANY),
    map((action: any) => action.payload),
    concatMap((payload: any) =>
    loadAllExtraDimensionsByCompany(payload.companyId).pipe(
        map(response => {
          return actions.loadExtraDimensionsByCompanySuccess(response.data.content);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading company crops!');
          return of(actions.loadExtraDimensionsByCompanyFailure(errorMsg));
        })
      )
    )
  );