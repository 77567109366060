import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createBrowserHistory as createHistory } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';
import epics from './epics';

const history = createHistory();
const routeMiddleware = routerMiddleware(history);
const epicMiddleware = createEpicMiddleware();
const middlewares = [routeMiddleware, epicMiddleware];

export const createRootReducer = () =>
  combineReducers({
    router: connectRouter(history),
    ...reducers
  });

const store = createStore(createRootReducer(), composeWithDevTools(applyMiddleware(...middlewares)));

epicMiddleware.run(epics);

export { store, history };
