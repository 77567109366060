import { Field } from '../field/models';
import { Characteristic, Phenomenon, Region, User, UUID } from '../models';
import { Measurement, Monitoring } from './models';
import { getValueLanguage } from '../language/utils';
import { getAllParentsAsString } from './utils';

type TranslatedName = {
  translatedName?: ReturnType<typeof getValueLanguage>;
};

export const buildMeasurements = (
  monitorings: Monitoring[],
  phenomena: Phenomenon[],
  users: User[],
  fields: Field[],
  propertyId: UUID,
  regions: Region[]
): Measurement[] => {
  const phenomenonByCharacteristicId: Record<string, Phenomenon & TranslatedName> = {};
  const phenomenonCharacteristicsById: Record<string, Characteristic & TranslatedName> = {};

  const regionNames = regions.map(region => region.name);

  phenomena.forEach(phenomenon => {
    phenomenon.characteristics?.forEach(characteristic => {
      const { id } = characteristic;

      if (id) {
        if (!phenomenonByCharacteristicId[id]) {
          phenomenonByCharacteristicId[id] = phenomenon;
          phenomenonByCharacteristicId[id].translatedName = getValueLanguage(phenomenon.name);
        }

        if (!phenomenonCharacteristicsById[id]) {
          phenomenonCharacteristicsById[id] = characteristic;
          phenomenonCharacteristicsById[id].translatedName = getValueLanguage(characteristic.name);
        }
      }
    });
  });

  const usersById: Record<string, User> = {};

  users.forEach(user => {
    const { id } = user;
    if (id && !usersById[id]) {
      usersById[id] = user;
    }
  });

  const fieldsById: Record<string, Field> = {};

  fields.forEach(field => {
    const { id } = field;
    if (id && !fieldsById[id]) {
      fieldsById[id] = field;
    }
  });

  const regionById: Record<string, Region> = {};

  regions.forEach(region => {
    const { id } = region;

    if (id && !regionById[id]) {
      regionById[id] = region;
    }
  });

  const measurements: Measurement[] = [];

  monitorings.forEach(monitoring => {
    const user = usersById[monitoring.scouter_id];
    const field = fieldsById[monitoring.area_id];

    const nodeIdToFindParents = field ? field.parent_region_id : '';

    const node = regionById[nodeIdToFindParents];

    const regionList = node ? getAllParentsAsString(regions, node) : regionNames;

    monitoring.measurements.forEach(measure => {
      const phenomenon = phenomenonByCharacteristicId[measure.characteristic_id];
      const characteristic = phenomenon && phenomenonCharacteristicsById[measure.characteristic_id];

      measurements.push({
        id: measure.id,
        monitoringId: monitoring.id,
        representedByMonitoringId: monitoring.represented_by,
        propertyId,
        user: user && { id: user.id, name: user.name },
        phenomenon: phenomenon && {
          id: phenomenon.id,
          name: phenomenon.translatedName
        },
        characteristic: characteristic
          ? {
              id: measure.characteristic_id,
              name: characteristic.translatedName,
              inputDefinition: characteristic.input_definition,
              companyId: characteristic.company_id || null,
              parentId: characteristic.parent_id || null,
              phenomenonId: characteristic.phenomenon_id,
              defaultIndicatorId: characteristic.default_indicator_id,
              cropIds: characteristic.crop_ids
            }
          : {
              id: measure.characteristic_id,
              name: measure.characteristic_id
            },
        date: monitoring.start_date,
        area: field && { id: field.id, name: field.name },
        value: measure.value,
        coordinates: monitoring.coordinates,
        region: regionList
      });
    });
  });

  return measurements;
};

export const getEditMeasurementRequest = (measurement: Measurement) => {
  const formattedDate = measurement.date.substring(0, 10);
  return {
    monitoringId: measurement.monitoringId,
    measurementId: measurement.id,
    propertyId: measurement.propertyId,
    startDate: formattedDate,
    value: measurement.value
  };
};
