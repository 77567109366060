import { ServiceProvider } from './models';
import { UUID, Company } from '../models';

const actions = {
  LOAD_SERVICE_PROVIDERS: '[SERVICE PROVIDERS] Load Service Providers',
  LOAD_SERVICE_PROVIDERS_SUCCESS: '[SERVICE PROVIDERS] Load Service Providers Success',
  LOAD_SERVICE_PROVIDERS_FAILURE: '[SERVICE PROVIDERS] Load Service Providers Failure',
  LOAD_COMPANIES_BY_SERVICE_PROVIDER: '[SERVICE PROVIDERS] Load Companies By Service Provider',
  LOAD_COMPANIES_BY_SERVICE_PROVIDER_SUCCESS: '[SERVICE PROVIDERS] Load Companies By Service Provider Success',
  LOAD_COMPANIES_BY_SERVICE_PROVIDER_FAILURE: '[SERVICE PROVIDERS] Load Companies By Service Provider Failure',
  TOGGLE_SERVICE_PROVIDERS_MODAL: '[SERVICE PROVIDERS] Toggle Service Providers Modal',
  DELETE_COMPANY_FROM_SERVICE_PROVIDER_COMPANIES: '[SERVICE PROVIDERS] Delete Company From Service Provider Companies',
  CLEAR_SERVICE_PROVIDER_COMPANIES: '[SERVICE PROVIDERS] Clear Service Provider Companies',
  ADD_COMPANY_IN_SERVICE_PROVIDER_COMPANIES: '[SERVICE PROVIDERS] Add Company In Service Provider Companies',
  CREATE_SERVICE_PROVIDER: '[SERVICE PROVIDERS] Create Service Provider',
  CREATE_SERVICE_PROVIDER_SUCCESS: '[SERVICE PROVIDERS] Create Service Provider Success',
  CREATE_SERVICE_PROVIDER_FAILURE: '[SERVICE PROVIDERS] Create Service Provider Failure',
  UPDATE_SERVICE_PROVIDER: '[SERVICE PROVIDERS] Update Service Provider',
  UPDATE_SERVICE_PROVIDER_SUCCESS: '[SERVICE PROVIDERS] Update Service Provider Success',
  UPDATE_SERVICE_PROVIDER_FAILURE: '[SERVICE PROVIDERS] Update Service Provider Failure',
  DELETE_SERVICE_PROVIDER: '[SERVICE PROVIDERS] Delete Service Provider',
  DELETE_SERVICE_PROVIDER_SUCCESS: '[SERVICE PROVIDERS] Delete Service Provider Success',
  DELETE_SERVICE_PROVIDER_FAILURE: '[SERVICE PROVIDERS] Delete Service Provider Failure',


  loadServiceProviders: () => ({
    type: actions.LOAD_SERVICE_PROVIDERS
  }),

  loadServiceProvidersSuccess: (serviceProviders: ServiceProvider[]) => ({
    type: actions.LOAD_SERVICE_PROVIDERS_SUCCESS,
    payload: serviceProviders
  }),

  loadServiceProvidersFailure: (error: string) => ({
    type: actions.LOAD_SERVICE_PROVIDERS_FAILURE,
    payload: error
  }),

  loadCompaniesByServiceProvider: (serviceProviderId: UUID) => ({
    type: actions.LOAD_COMPANIES_BY_SERVICE_PROVIDER,
    payload: serviceProviderId
  }),

  loadCompaniesByServiceProviderSuccess: (companies: Company[]) => ({
    type: actions.LOAD_COMPANIES_BY_SERVICE_PROVIDER_SUCCESS,
    payload: companies
  }),

  loadCompaniesByServiceProviderFailure: (error: string) => ({
    type: actions.LOAD_COMPANIES_BY_SERVICE_PROVIDER_FAILURE,
    payload: error
  }),

  toggleServiceProvidersModal: (serviceProvider?: ServiceProvider) => ({
    type: actions.TOGGLE_SERVICE_PROVIDERS_MODAL,
    payload: serviceProvider
  }),

  deleteCompanyFromServiceProviderCompanies: (company?: Company) => ({
    type: actions.DELETE_COMPANY_FROM_SERVICE_PROVIDER_COMPANIES,
    payload: company
  }),

  addCompanyInServiceProviderCompanies: (company?: Company) => ({
    type: actions.ADD_COMPANY_IN_SERVICE_PROVIDER_COMPANIES,
    payload: company
  }),

  clearServiceProviderCompanies: () => ({
    type: actions.CLEAR_SERVICE_PROVIDER_COMPANIES
  }),

  createServiceProvider: (serviceProvider:ServiceProvider) => ({
    type: actions.CREATE_SERVICE_PROVIDER,
    payload: serviceProvider
  }),

  createServiceProviderSuccess: (serviceProvider:ServiceProvider) => ({
    type: actions.CREATE_SERVICE_PROVIDER_SUCCESS,
    payload: serviceProvider
  }),

  createServiceProviderFailure: (error: string) => ({
    type: actions.CREATE_SERVICE_PROVIDER_FAILURE,
    payload: error
  }),

  updateServiceProvider: (serviceProvider:ServiceProvider) => ({
    type: actions.UPDATE_SERVICE_PROVIDER,
    payload: serviceProvider
  }),

  updateServiceProviderSuccess: (serviceProvider:ServiceProvider) => ({
    type: actions.UPDATE_SERVICE_PROVIDER_SUCCESS,
    payload: serviceProvider
  }),

  updateServiceProviderFailure: (error: string) => ({
    type: actions.UPDATE_SERVICE_PROVIDER_FAILURE,
    payload: error
  }),

  deleteServiceProvider: (serviceProviderId:UUID) => ({
    type: actions.DELETE_SERVICE_PROVIDER,
    payload: serviceProviderId
  }),

  deleteServiceProviderSuccess: (serviceProviderId:UUID) => ({
    type: actions.DELETE_SERVICE_PROVIDER_SUCCESS,
    payload: serviceProviderId
  }),

  deleteServiceProviderFailure: (error: string) => ({
    type: actions.DELETE_SERVICE_PROVIDER_FAILURE,
    payload: error
  })

};

export default actions;
export const ServiceProvidersActions = actions;
