import React from 'react';

const esAR = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M22 5H2V19H22V5Z' fill='#74ACDF' />
    <path d='M22 9H2V15H22V9Z' fill='white' />
    <path
      d='M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z'
      fill='#F6B40E'
    />
  </svg>
);

export default esAR;
