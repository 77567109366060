import { Company } from './models';
import { UUID, Organization } from '../models';

const actions = {
  LOAD_COMPANIES: '[Company] Load Companies',
  LOAD_COMPANIES_SUCCESS: '[Company] Load Companies Success',
  LOAD_COMPANIES_FAILURE: '[Company] Load Companies Failure',
  SET_CONTROLE_CERTO_COMPANIES: '[Company] Set Controle Certo companies',
  SELECT_COMPANY: '[Company] Select Company',
  CLEAR_SELECTED_COMPANY: '[Company] Clear Selected Company',
  TOGGLE_IS_LOADING: '[Company] Reverse is loading',

  loadCompanies: () => ({
    type: actions.LOAD_COMPANIES
  }),

  loadCompaniesSuccess: (companies: Company[], loadedAll = true) => ({
    type: actions.LOAD_COMPANIES_SUCCESS,
    payload: { companies, loadedAll }
  }),

  loadCompaniesFailure: (error: string) => ({
    type: actions.LOAD_COMPANIES_FAILURE,
    payload: error
  }),

  setControleCertoCompanies: (companies: Organization[]) => ({
    type: actions.SET_CONTROLE_CERTO_COMPANIES,
    payload: companies
  }),

  selectCompany: (id: UUID) => ({
    type: actions.SELECT_COMPANY,
    payload: id
  }),

  clearSelectedCompany: () => ({
    type: actions.CLEAR_SELECTED_COMPANY
  }),

  toggleIsLoading: () => ({
    type: actions.TOGGLE_IS_LOADING
  })
};

export default actions;

export const CompanyActions = actions;
