import actions from './actions';
import { Field, FieldState } from './models';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';

const entity: EntityAdapter<Field> = createEntityAdapter<Field>();

const initialState: FieldState = entity.getInitialState({
  isLoading: false,
  error: null,
  lastPropertyId: null
});

export default (state = initialState, action): FieldState => {
  switch (action.type) {
    case actions.LOAD_FIELDS_BY_PROPERTY:
      return {
        ...state,
        isLoading: true,
        lastPropertyId: action.payload
      };
    case actions.LOAD_FIELDS_BY_PROPERTY_SUCCESS:
      return entity.addAll(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });

    case actions.LOAD_FIELDS_BY_PROPERTY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.SET_LAST_PROPERTY_ID_LOAD_FIELDS:
      return {
        ...state,
        lastPropertyId: action.payload
      };
    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectFieldIds = selectIds;
export const selectFieldEntities = selectEntities;
export const selectAllFields = selectAll;
export const selectFieldsTotal = selectTotal;
