import actions from './actions';
import { Company, CompanyState } from './models';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';

const entity: EntityAdapter<Company> = createEntityAdapter<Company>();

const initialState: CompanyState = entity.getInitialState({
  isLoading: false,
  error: null,
  orgsControleCerto: [],
  selected: null,
  loadedAll: false
});

export default (state = initialState, action): CompanyState => {
  switch (action.type) {
    case actions.LOAD_COMPANIES:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_COMPANIES_SUCCESS:
      return entity.upsertMany(action.payload.companies, {
        ...state,
        isLoading: false,
        error: null,
        loadedAll: action.payload.loadedAll
      });

    case actions.LOAD_COMPANIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.SET_CONTROLE_CERTO_COMPANIES:
      return {
        ...state,
        orgsControleCerto: action.payload,
        isLoading: false
      };

    case actions.SELECT_COMPANY:
      return {
        ...state,
        selected: action.payload
      };

    case actions.CLEAR_SELECTED_COMPANY:
      return {
        ...state,
        selected: null
      };

    case actions.TOGGLE_IS_LOADING:
      return {
        ...state,
        isLoading: !state.isLoading
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectCompanyIds = selectIds;
export const selectCompanyEntities = selectEntities;
export const selectAllCompanies = selectAll;
export const selectCompanyTotal = selectTotal;
