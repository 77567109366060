import React from 'react';
import { Link } from 'react-router-dom';

export default ({ collapsed }) => {
  return (
    <div className='isoLogoWrapper'>
      {collapsed ? (
        <div>
          <h3>
            <Link to='/dashboard'>
              <img alt='Protector Admin' src={process.env.PUBLIC_URL + '/images/logo-small.png'} style={{ width: '50px' }} />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to='/dashboard'>
            <img alt='Protector Admin' src={process.env.PUBLIC_URL + '/images/logo-reversed.png'} style={{ width: '150px' }} />
          </Link>
        </h3>
      )}
    </div>
  );
};
