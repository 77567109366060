import { useQuery, QueryObserverResult, useInfiniteQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import axiosObservable from 'axios-observable';
import {
  RBACGetWorkspaceOperationsResponse,
  RBACGetWorkspaceOperationsProps,
  RBACParsedOperations,
  RBACQueryType,
  RBACSetUpOperationsForRequestProps,
  GetAllRolesByUserResponse,
  RBACActions,
  RBACDefaultRequestProps,
  RBACPermissionTypesEnum,
  RBACWorkspaceOperations,
  GetPermissionUserProps,
  RBACOperations,
  GetAllRolesByUserProps
} from './rbac.query.model';
import { parseWorkspaceOperations, createOperation } from './rbac.functions';
import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';
import { Role } from '../../redux/models';
import { protectorAppId } from '../../redux/cropwisePlans/models';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { getPendingUsersByOrg, getUsersByOrg } from '../../redux/user/service';
import { adapterToRemoveAuthorities } from './rbac.adapters';
import { useDispatch } from 'react-redux';
import { UserActions } from '../../redux/user/actions';
import notification from '../../components/notification';
import Actions from '../../redux/actions';

const baseURL = process.env.REACT_APP_BASE_API_URL || 'http://localhost:8080';
const TEN_MINUTES = 1000 * 60 * 10;

const { loadRolesRBACByUsersSuccess, loadRolesRBACByUsersFailure } = UserActions;

const { toggleAddEditUserModal } = Actions;

export const getAllRolesByUser = (userId: string): Promise<AxiosResponse<GetAllRolesByUserResponse>> => {
  const url = `${baseURL}/v2/accounts/${userId}/roles`;
  return axios.get<GetAllRolesByUserResponse>(url);
};

export const getAllRolesByUserPaginated = async ({ userId, next_key }) => {
  const cursor = next_key ? `?last_key=${next_key}` : '';
  const url = `${baseURL}/v2/accounts/${userId}/roles${cursor}`;
  const response = await axios.get<GetAllRolesByUserResponse>(url.trim());
  return response.data;
};

export const getWorkspaceOperationsObservable = (operations: any): AxiosObservable<RBACGetWorkspaceOperationsResponse> => {
  const url = `${baseURL}/v2/auth/verify`;

  return axiosObservable.post<RBACGetWorkspaceOperationsResponse>(url, operations);
};

export const getWorkspaceOperations = ({
  operations
}: RBACGetWorkspaceOperationsProps): Promise<AxiosResponse<RBACGetWorkspaceOperationsResponse>> => {
  const url = `${baseURL}/v2/auth/verify`;

  return axios.post<RBACGetWorkspaceOperationsResponse>(url, {
    operations
  });
};

export const useGetRBACOperations = ({
  operations,
  workspaceId
}: RBACSetUpOperationsForRequestProps & { workspaceId?: string }): QueryObserverResult<RBACParsedOperations, unknown> => {
  return useQuery(
    [RBACQueryType.GET_WORKSPACE_OPERATIONS, operations, workspaceId],
    async () => {
      const response = await getWorkspaceOperations({ operations });

      return parseWorkspaceOperations(response?.data);
    },
    {
      staleTime: TEN_MINUTES,
      enabled: !!operations?.length
    }
  );
};

export const parseRbacResponse = operations =>
  Object.values(operations).reduce((acumm, value) => {
    // eslint-disable-next-line no-param-reassign
    acumm = Object.assign(acumm, value);
    return acumm;
  }, {});

export const useVerifyRBACPermissions = ({ isLoading }: RBACDefaultRequestProps & { isLoading: boolean }) => {
  const verifyPermission = useCallback(
    (operations: RBACWorkspaceOperations) => {
      if (isLoading) return undefined;

      return parseRbacResponse(operations);
    },
    [isLoading]
  );

  return {
    verifyPermission
  };
};

export const userIsLegacyAdminStrider = async (user, flags) => {
  const availableRBAC = flags?.P40_29585_integrate_web_panel_with_RBAC;

  if (user?.is_using_rbac && availableRBAC) {
    const { data } = await getWorkspaceOperations({
      operations: [
        {
          action: `${RBACActions.ADMIN_DATA_MANAGEMENT}:${RBACPermissionTypesEnum.WRITE}`,
          resource: `crn:app:${protectorAppId}`
        }
      ]
    });

    const isAdmin = !!data?.allowed_operations?.length;
    return isAdmin;
  }
  return user?.role === Role.ADMIN_SYT_BRBH;
};

export const useGetRBACAuthorityUser = ({
  rbacOperations,
  invalidCallback,
  workspaceId,
  companyId,
  propertyId
}: GetPermissionUserProps & { workspaceId?: string; companyId?: string; propertyId?: string }):
  | { [key in RBACActions]?: RBACPermissionTypesEnum[] }
  | {}
  | undefined => {
  const fullOperatios = rbacOperations.reduce((acumm, { rbacLevels, rbacActions, rbacPermission }) => {
    rbacPermission.forEach(permission => {
      const operation = createOperation({
        companyId,
        action: rbacActions,
        level: rbacLevels,
        workspaceId,
        propertyId,
        permission,
        appId: protectorAppId
      });
      return acumm.push(operation);
    });

    return acumm;
  }, [] as RBACOperations[]);

  const { data, isLoading, isError } = useGetRBACOperations({
    operations: fullOperatios,
    workspaceId
  });

  const { verifyPermission } = useVerifyRBACPermissions({
    isLoading
  });

  if (isEmpty(data?.allowed_operations) && !isLoading && invalidCallback) {
    invalidCallback();
  }

  if (data?.allowed_operations && rbacOperations.length) {
    if (!isLoading && !isError) {
      return verifyPermission(data.allowed_operations) as { [key in RBACActions]?: RBACPermissionTypesEnum[] };
    }
  }
};

export const useGetUsersByOrgRBAC = ({ orgId }: GetAllRolesByUserProps) => {
  return useQuery(
    [RBACQueryType.GET_USERS_BY_ORGID, orgId],
    async () => {
      const response = await getUsersByOrg({ orgId });
      return adapterToRemoveAuthorities(response?.data?.content);
    },
    {
      staleTime: TEN_MINUTES
    }
  );
};

export const useGetPendingUsersByOrgRBAC = ({ orgId }: { orgId: string }) => {
  return useQuery([RBACQueryType.GET_PENDING_USERS_BY_ORGID, orgId], async () => {
    const response = await getPendingUsersByOrg({ orgId });

    return response.data.content;
  });
};

export const useGetRolesByUser = ({ userId, isEnabled }: { userId: string; isEnabled: boolean }) => {
  return useQuery(
    [RBACQueryType.GET_ALL_ROLES_BY_USER, userId],
    async () => {
      const { data } = await getAllRolesByUser(userId);

      return data.content;
    },
    {
      enabled: isEnabled
    }
  );
};

export const useGetRolesByUserPaginated = ({ userId, isEnabled }: { userId: string | null; isEnabled: boolean }) => {
  const dispatch = useDispatch();
  const { hasNextPage, fetchNextPage, isFetching, data, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [RBACQueryType.GET_ALL_ROLES_BY_USER, userId],
    queryFn: ({ pageParam = null }) =>
      getAllRolesByUserPaginated({
        userId,
        next_key: pageParam
      }),
    getNextPageParam: (lastPage: GetAllRolesByUserResponse) => lastPage.pageable.next_key,
    onError: (error: string) => {
      notification('error', 'Error loading user roles!');
      dispatch(loadRolesRBACByUsersFailure(error));
      dispatch(toggleAddEditUserModal({ open: false }));
    },
    enabled: isEnabled,
    retry: 3,
    retryDelay: 2000
  });

  useEffect(() => {
    if (hasNextPage && !isFetching) fetchNextPage();
  }, [hasNextPage, isFetching, fetchNextPage]);

  useEffect(() => {
    if (!isFetching && !hasNextPage) {
      const result = {
        userId,
        roles: data?.pages?.flatMap(e => e.content)
      };
      dispatch(loadRolesRBACByUsersSuccess(result));
    }
  }, [data?.pages, dispatch, hasNextPage, isFetching, userId]);

  return {
    isLoading: isFetchingNextPage || isFetching,
    data
  };
};
