import { UUID } from '../../redux/app/basicModels';
import { ActionButtonType } from '../actionButtons/models';

export interface DraggableItemProps {
  item: any;
  index: number;
  children?: any;
  callback?: any;
}

export interface DroppableHeaderProps {
  id: UUID;
  color: 'BLUE' | 'GREEN' | 'GREY';
  title?: string;
  addButtonText?: string;
  cloneButtonText?: string;
  altAddButtonText?: string;
  dataTestId?: string;
  collapseAll?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  onAdd?: () => void;
  onClone?: () => void;
  onAltAdd?: () => void;
  showCollapseAll?: boolean;
  showAddButton?: boolean;
  showCloneButton?: boolean;
  isCanonical?: boolean;
  isDragDisabled?: boolean;
  buttonDroppableType?: ActionButtonType;
}

export interface Color {
  textColor: string;
  glyphColor: string;
}

export interface NestedList {
  id: string | number;
  title: string;
  collapsed?: boolean;
  content?: any;
  header?: any;
  children?: NestedList | NestedList[];
}

export enum HeaderColor {
  GREEN = 'GREEN',
  GREY = 'GREY',
  BLUE = 'BLUE'
}
