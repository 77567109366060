import { EntityState } from 'redux-ngrx-entity';
import { ExtraDimensions } from '../../components/addExtraDimensionsModal/addExtraDimensionsModal';
import { UUID, I18nMap } from '../app/basicModels';
import { ExtraDimension } from '../extraDimensions/models';

export enum DevelopmentPhaseType {
  EMERGENCY = 'EMERGENCY',
  VEGETATIVE = 'VEGETATIVE',
  REPRODUCTIVE = 'REPRODUCTIVE'
}

export interface DevelopmentPhase {
  isNew: boolean;
  id?: UUID;
  name: I18nMap;
  localized_instruction?: I18nMap;
  description?: I18nMap;
  crop_id?: UUID;
  duration_in_days?: Number;
  type?: DevelopmentPhaseType;
  days_after_emergency?: Number;
  sort?: Number;
}

export interface DevelopmentPhaseState extends EntityState<DevelopmentPhase> {
  isLoading: boolean;
  isSaving: boolean;
  developmentPhases: DevelopmentPhase[];
  error: string | null;
  isModalActive: boolean;
  developmentPhaseSelect?: DevelopmentPhase;
}

export interface ExtraDimensionState extends EntityState<ExtraDimensions> {
  isLoading: boolean;
  isSaving: boolean;
  extraDimensions?: ExtraDimension[];
  error: string | null;
  extraDimensionSelect?: ExtraDimension;
}