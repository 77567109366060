import axios from 'axios-observable';
import { UserToken, UserCredentials } from './models';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import CONFIG from '../../settings';

const authUrl = CONFIG.apiUrl + ':' + CONFIG.basePort + '/oauth/token';

export const kAuth = (userCredentials: UserCredentials): AxiosObservable<UserToken> => {
  const params = new URLSearchParams();

  params.append('username', userCredentials.username);
  params.append('password', userCredentials.password);
  params.append('grant_type', 'password');

  return axios.post<UserToken>(`${authUrl}`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic cHJvdGVjdG9yOnBhc3NvbGFyZ28='
    }
  });
};

export const kRefreshToken = (refreshToken: string): AxiosObservable<UserToken> => {
  const params = new URLSearchParams();

  params.append('refresh_token', refreshToken);
  params.append('grant_type', 'refresh_token');

  return axios.post<UserToken>(`${authUrl}`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic cHJvdGVjdG9yOnBhc3NvbGFyZ28='
    }
  });
};
