import { pull } from 'lodash';
import { LanguageStage, TranslationStages } from './models';
import { I18nMap } from '../models';
import { languagesContent } from './languageContent';

export const languages = {
  'en-US': languagesContent['en-US'],
  'pt-BR': languagesContent['pt-BR'],
  es: languagesContent['es-ES'],
  'es-AR': languagesContent['es-AR'],
  en: languagesContent['en-GB'],
  'de-DE': languagesContent['de-DE'],
  'pl-PL': languagesContent['pl-PL'],
  'fr-FR': languagesContent['fr-FR'],
  'hu-HU': languagesContent['hu-HU'],
  fr: languagesContent['fr-FR'],
  de: languagesContent['de-DE']
};

export const newLanguages = {
  ...languages,
  'it-IT': languagesContent['it-IT']
};

export const calculateTranslationField = (object: I18nMap, flag = false): LanguageStage[] => {
  const objectEntries = Object.entries(object.localized_strings);
  const languageKeys = Object.keys(flag ? newLanguages : languagesContent);
  const result = objectEntries.map(translation => {
    return {
      language: translation[0],
      stage: translation[1] ? TranslationStages.COMPLETE : TranslationStages.NONE
    };
  });

  languageKeys.forEach(languageKey => {
    const languageStage = result.find(calc => calc.language === languageKey);
    if (!languageStage) {
      result.push({ language: languageKey, stage: TranslationStages.NONE });
    }
  });

  return result;
};

export const calculateTranslationScreen = (objectArray: I18nMap[], flag = false): LanguageStage[] => {
  const result: LanguageStage[] = [];
  objectArray.forEach(object => {
    const currentTranslations = calculateTranslationField(object, flag);
    currentTranslations.forEach((translation: LanguageStage) => {
      const oldResult = result.find(el => el.language === translation.language);
      if (oldResult) {
        if (
          (oldResult.stage === TranslationStages.COMPLETE && translation.stage === TranslationStages.NONE) ||
          (oldResult.stage === TranslationStages.NONE && translation.stage === TranslationStages.COMPLETE)
        ) {
          pull(result, oldResult);
          result.push({ ...translation, stage: TranslationStages.PARTIAL });
        }
      } else result.push(translation);
    });
  });
  return result;
};

export const setValueLanguage = (value, language) => ({
  localized_strings: {
    [language]: value
  }
});

export const hasValueLanguage = (value, language) => value.localized_strings && value.localized_strings[language];

export const getValueLanguage = (value, language?) => {
  if (typeof value === 'string') {
    return value;
  }
  const translations = !!value && value.hasOwnProperty('localized_strings') && value.localized_strings;

  const languagesList = [
    language,
    'en',
    'en-gb',
    'pt',
    'pt-BR',
    'pt-br',
    'es',
    'es-AR',
    'en-US',
    'en-us',
    'de',
    'de-DE',
    'pl',
    'pl-PL',
    'fr',
    'fr-fr',
    'fr-FR',
    'hu',
    'hu-HU',
    'hu-hu',
    'it-IT',
    'it-it',
    'it'
  ];

  let translation = languagesList.find(item => typeof translations[item] === 'string');
  if (!translation && Object.keys(translations).length > 0) {
    [translation] = Object.keys(translations);
  }
  return translation ? translations[translation] : NotAvailable.TRANSLATION;
};

export enum NotAvailable {
  TRANSLATION = 'Translation not available'
}
