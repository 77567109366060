import React, { FC } from 'react';
import { Actions } from '../../redux/actions';
import { UUID } from '../../redux/models';
import { ExtraDimensions } from './extraDimensions.component';
import {useDispatch} from 'react-redux';
import './extraDimensions.style.scss';
import { useHistory } from 'react-router';

const { deleteExtraDimensionMethodologyDeep } = Actions;

export interface ResourceProps {
  id: UUID;
  title: string;
  inspectionGroupId: UUID;
}

export const ExtraDimensionsWrapper: FC<ResourceProps> = ({
  id,
  title,
  inspectionGroupId
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const remove = (extraDimensionId) => {
    dispatch(deleteExtraDimensionMethodologyDeep({extraDimensionId, inspectionGroupId}));
  };
  const edit = (extraDimensionId, companyId, methodologyId) => {
    history.push(`/extra-dimensions/${extraDimensionId}/by-company/${companyId}/by-methodology/${methodologyId}/inspection-group/${inspectionGroupId}`);
  };
  return (
    <ExtraDimensions
      id={id}
      title={title}
      deleteFn={remove}
      editFn={edit}
    />
  );
};

export default ExtraDimensionsWrapper;