import { UUID, PageableQuery } from '../app/basicModels';
import { FixedPoint } from '../fixedPoint/models';

const actions = {
  LOAD_PLUVIOMETERS: '[Pluviometers] Load All Pluviometers',
  LOAD_PLUVIOMETERS_SUCCESS: '[Pluviometers] Load Pluviometers Success',
  LOAD_PLUVIOMETERS_FAILURE: '[Pluviometers] Load Pluviometers Failure',
  SAVE_PLUVIOMETER: '[Pluviometer] Save Pluviometer',
  SAVE_PLUVIOMETER_SUCCESS: '[Pluviometer] Save Pluviometer Success',
  SAVE_PLUVIOMETER_FAILURE: '[Pluviometer] Save Pluviometer Failure',
  UPDATE_PLUVIOMETER: '[Pluviometer] Update Pluviometer',
  LOAD_PLUVIOMETER: '[Pluviometer] Load Pluviometer',
  LOAD_PLUVIOMETER_SUCCESS: '[Pluviometer] Load Pluviometer Success',
  LOAD_PLUVIOMETER_FAILURE: '[Pluviometer] Load Pluviometer Failure',
  DELETE_PLUVIOMETER: '[Pluviometer] Delete Pluviometer',
  DELETE_PLUVIOMETER_SUCCESS: '[Pluviometer] Delete Pluviometer Success',
  DELETE_PLUVIOMETER_FAILURE: '[Pluviometer] Delete Pluviometer Failure',
  SELECT_FIXEDPOINT_PLUVIOMETER: '[Pluviometer] Select Fixed Point Pluviometer',
  CLEAR_FIXED_POINT_SELECT_PLUVIOMETER: '[Pluviometer] Clear Fixed Point Pluviometer Selected',
  TOGGLE_FIXED_POINT_PLUVIOMETER_PHENOMENA_MODAL: '[Pluviometer] Toggle Fixed Point Pluviometer Phenomena Modal',
  TOGGLE_CHARACTERISTICS_INDICATORS_PHENOMENA_PLUVIOMETER_MODAL: '[Pluviometer] Toggle Characteristics/Indicators Phenomena Modal',

  loadPluviometers: (pageableQuery: PageableQuery) => ({
    type: actions.LOAD_PLUVIOMETERS,
    payload: pageableQuery
  }),
  loadPluviometersSuccess: (fixedPointPage: FixedPoint[]) => ({
    type: actions.LOAD_PLUVIOMETERS_SUCCESS,
    payload: fixedPointPage
  }),
  loadPluviometersFailure: (error: string) => ({
    type: actions.LOAD_PLUVIOMETERS_FAILURE,
    payload: error
  }),
  selectFixedPointPluviomenter: (fixedPoint: FixedPoint) => ({
    type: actions.SELECT_FIXEDPOINT_PLUVIOMETER,
    payload: fixedPoint
  }),
  clearFixedPointSelectPluviomenter: () => ({
    type: actions.CLEAR_FIXED_POINT_SELECT_PLUVIOMETER
  }),

  loadPluviometer: (id: UUID) => ({
    type: actions.LOAD_PLUVIOMETER,
    payload: id
  }),
  loadPluviometerSuccess: (fixedPoint: FixedPoint) => ({
    type: actions.LOAD_PLUVIOMETER_SUCCESS,
    payload: fixedPoint
  }),

  loadPluviomenterFailure: (error: string) => ({
    type: actions.LOAD_PLUVIOMETER_FAILURE,
    payload: error
  }),
  savePluviomenter: (fixedPoint: FixedPoint) => ({
    type: actions.SAVE_PLUVIOMETER,
    payload: fixedPoint
  }),
  savePluviometerSuccess: (fixedPoint: FixedPoint) => ({
    type: actions.SAVE_PLUVIOMETER_SUCCESS,
    payload: fixedPoint
  }),
  savePluviomenterFailure: (error: string) => ({
    type: actions.SAVE_PLUVIOMETER_FAILURE,
    payload: error
  }),
  updatePluviomenter: (fixedPoint: FixedPoint, save = false) => ({
    type: actions.UPDATE_PLUVIOMETER,
    payload: {
      fixedPoint,
      save
    }
  }),
  deleteFixedPointPluviomenter: (fixedPoint: FixedPoint) => ({
    type: actions.DELETE_PLUVIOMETER,
    payload: fixedPoint
  }),

  deleteFixedPointPluviometerSuccess: (deleted: boolean) => ({
    type: actions.DELETE_PLUVIOMETER_SUCCESS,
    payload: deleted
  }),

  deleteFixedPointPluviomenterFailure: (error: string) => ({
    type: actions.DELETE_PLUVIOMETER_FAILURE,
    payload: error
  }),
  toggleFixedPointPluviometerPhenomenaModal: () => ({
    type: actions.TOGGLE_FIXED_POINT_PLUVIOMETER_PHENOMENA_MODAL
  })
};

export default actions;

export const FixedPointPluviometerActions = actions;
