/* eslint-disable import/prefer-default-export */
import axios from 'axios-observable';
import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import CONFIG from '../../settings';
import {
  EditTrapMeasurementsRequestBody,
  EditTrapMeasurementsRequestParams,
  LoadTrapMeasurementsRequestParams,
  TrapMeasurementDTO,
  TrapStation
} from './models';
import { CursorPage, UUID } from '../models';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';

const baseUrl = CONFIG.protectorApiUrl;

export const getTrapMeasurements = (params?: LoadTrapMeasurementsRequestParams): Observable<TrapMeasurementDTO> => {
  if (!params) {
    return throwError('Params is required');
  }

  const rangeKeyParam = params.rangeKey ? `&next_key=${params.rangeKey}` : '';
  const scouterParam = params.scouterId ? `&scouter_id=${params.scouterId}` : '';

  return axios
    .get<TrapMeasurementDTO>(
      `${baseUrl}/v1/static-point-action/search-trap-monitoring?property_id=${params.propertyId}&start=${params.startDate}&end=${params.endDate}${scouterParam}${rangeKeyParam}`
    )
    .pipe(map(response => response.data));
};

export const getTrapStations = (propertyId?: UUID, cursor?: string): Observable<CursorPage<TrapStation>> => {
  if (!propertyId) {
    return throwError('Params is required');
  }

  const cursorParam = cursor ? `&cursor=${cursor}` : '';

  return axios.get<CursorPage<TrapStation>>(`${baseUrl}/api/v1/properties/${propertyId}/stations?station_type=TRAP${cursorParam}`).pipe(
    map(response => response.data),
    mergeMap(response => {
      if (response.is_last && !response.cursor) {
        return of(response);
      }

      return getTrapStations(propertyId, response.cursor).pipe(
        map(nextResponse => ({
          ...response,
          content: [...response.content, ...nextResponse.content]
        }))
      );
    })
  );
};

export const editTrapMeasurementValue = (
  params: EditTrapMeasurementsRequestParams,
  body: EditTrapMeasurementsRequestBody
): AxiosObservable<TrapMeasurementDTO> => {
  return axios.patch(`${baseUrl}/v1/static-point-action/${params.actionId}/static-point/${params.staticPointId}/edit`, body, {
    headers: { 'Content-Type': 'application/json' }
  });
};

export const deleteTrapMeasurement = ({ actionId, staticPointId, characteristic_id }): AxiosObservable<TrapMeasurementDTO> => {
  return axios.delete(`${baseUrl}/v1/static-point-action/${actionId}/static-point/${staticPointId}/delete`, {
    data: { characteristic_id }
  });
};
