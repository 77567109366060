import React from 'react';

export interface MethodologyIconProps {
  readonly color?: string;
}

const MethodologyIcon = ({ color = '#707374', width = '24', height = '24' }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      d='M5 4L5 20C5 21.1 5.9 22 7 22L19 22C20.1 22 21 21.1 21 20L21 4C21 2.9 20.1 2 19 2L7 2C5.9 2 5 2.9 5 4ZM19 5L19 19C19 19.55 18.55 20 18 20L8 20C7.45 20 7 19.55 7 19L7 5C7 4.45 7.45 4 8 4L18 4C18.55 4 19 4.45 19 5Z'
      fill={color}
    />
    <path
      d='M9 7.6L9 20.4C9 21.28 9.675 22 10.5 22L19.5 22C20.325 22 21 21.28 21 20.4L21 7.6C21 6.72 20.325 6 19.5 6L10.5 6C9.675 6 9 6.72 9 7.6ZM19 9L19 19C19 19.44 18.4125 20 18 20L12 20C11.5 20 11 19.5 11 19L11 9C11 8.5 11.5 8 12 8L18 8C18.5 8 19 8.5 19 9Z'
      fill={color}
    />
    <path d='M13 10H18V11.5H13V10Z' fill={color} />
    <path d='M13 13H18V14.5H13V13Z' fill={color} />
    <path d='M13 16H18V17.5H13V16Z' fill={color} />
  </svg>
);

export default MethodologyIcon;
