import actions from './actions';
import { CropState, Crop, CropPage, CropSuccessResponse } from './models';
import { initialPage } from '../app/basicModels';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';

const entity: EntityAdapter<Crop> = createEntityAdapter<Crop>();

const initialState: CropState = entity.getInitialState({
  page: {
    ...initialPage
  },
  isLoading: false,
  error: null,
  lastLoadedLocation: null,
  isSaving: false,
  cropSelect: null,
  cropSelectLoading: false,
  companyCrops: [],
  isDeleting: false
});

export default (state = initialState, action): CropState => {
  switch (action.type) {
    case actions.LOAD_CROPS:
      return {
        ...state,
        isLoading: true,
        lastLoadedLocation: null
      };

    case actions.LOAD_CROPS_SUCCESS:
      return entity.addAll((action.payload as CropSuccessResponse).page.content, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        lastLoadedLocation: (action.payload as CropSuccessResponse).location,
        error: null
      });

    case actions.LOAD_CROPS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.SAVE_CROP:
      return {
        ...state,
        isSaving: true
      };

    case actions.SAVE_CROP_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isSaving: false,
        cropSelect: null,
        error: null
      });

    case actions.SAVE_CROP_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case actions.LOAD_CROP:
      return {
        ...state,
        cropSelectLoading: true,
        cropSelect: null
      };

    case actions.LOAD_CROP_SUCCESS:
      return {
        ...state,
        cropSelectLoading: false,
        cropSelect: action.payload
      };

    case actions.LOAD_CROP_FAILURE:
      return {
        ...state,
        cropSelectLoading: false,
        error: action.payload
      };

    case actions.DELETE_CROP:
      return {
        ...state,
        isDeleting: true
      };

    case actions.DELETE_CROP_SUCCESS:
      return entity.removeOne(action.payload.id, {
        ...state,
        error: null,
        isDeleting: false
      });

    case actions.DELETE_CROP_FAILURE:
      return {
        ...state,
        error: null,
        isDeleting: false
      };

    case actions.CLEAR_CROP_SELECT:
      return {
        ...state,
        cropSelect: null
      };

    case actions.SELECT_CROP:
      return entity.upsertOne(action.payload, {
        ...state,
        cropSelect: action.payload
      });

    case actions.LOAD_COMPANY_CROPS:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_COMPANY_CROPS_SUCCESS:
      return {
        ...state,
        companyCrops: action.payload,
        isLoading: false,
        error: null
      };

    case actions.LOAD_COMPANY_CROPS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.UNLOAD_COMPANY_CROPS:
      return {
        ...state,
        companyCrops: []
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectCropIds = selectIds;
export const selectCropEntities = selectEntities;
export const selectAllCrops = selectAll;
export const selectCropTotal = selectTotal;
