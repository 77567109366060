import { Action, PageableQuery, TalosPayload, UUID } from '../app/basicModels';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { catchError, concatMap, flatMap, map, withLatestFrom } from 'rxjs/operators';
import { concat, merge, Observable, of } from 'rxjs';
import actions from '../actions';
import {
  deleteCharacteristic,
  findAllCharacteristics,
  findAllCharacteristicsCanonicals,
  getCharacteristic,
  getCharacteristics,
  getIndependentVariables,
  saveCharacteristic,
  updateCharacteristicInPhenomenonsTree
} from './service';
import { Characteristic, CharacteristicPage, LoadCharacteristicByPhenomenonType, LoadCharacteristicQueryPayload } from './models';
import { notification } from '../../components';
import { notifyErrorOrFallback, ErrorMsg } from '../../settings/models/Error';
import uuid from 'uuid/v4';
import phenomenonActions from '../phenomenon/actions';
import methodologyActions from '../methodology/actions';
import methodologyDeepActions from '../methodologyDeep/actions';
import { saveIndicator } from '../indicator/service';
import { CharacteristicWithDefaultIndicator, Indicator, SaveCharacteristicParams, User } from '../models';
import { AppState } from '../redux.model';

export const handleLoadIndependentVariables = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_INDEPENDENT_VARIABLES),
    map((action: Action<any>) => action.payload),
    concatMap((payload: any) =>
      getIndependentVariables(payload.pageableQuery, payload.companyId).pipe(
        map(response => response.data),
        map((variables: CharacteristicPage) => {
          return actions.loadIndependentVariablesSuccess(variables);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading independent variables!');
          return of(actions.loadIndependentVariablesFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadCharacteristics = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_CHARACTERISTICS),
    map((action: Action<LoadCharacteristicByPhenomenonType>) => action.payload),
    concatMap((payload: any) =>
      getCharacteristics(payload.pageableQuery, payload.phenomenon_id).pipe(
        map(response => response.data),
        map((characteristics: CharacteristicPage) => {
          return actions.loadCharacteristicsSuccess(characteristics);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading characteristics!');
          return of(actions.loadCharacteristicsFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadCharacteristic = action$ =>
  action$.pipe(
    ofType(actions.LOAD_CHARACTERISTIC),
    map((action: Action<UUID>) => action.payload),
    concatMap((characteristicId: UUID) =>
      getCharacteristic(characteristicId).pipe(
        map(response => response.data),
        map((characteristic: Characteristic) => {
          return actions.loadCharacteristicSuccess(characteristic);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading characteristic!');
          return of(actions.loadCharacteristicFailure(errorMsg));
        })
      )
    )
  );

const saveCharacteristicSuccess = (
  characPayload,
  methodologyId?: UUID,
  defaultIndicator?,
  orderedComponentId?: UUID,
  orderedPhenomenonId?: UUID
) => {
  notification('success', 'Characteristic saved!');
  if (methodologyId) {
    return of(
      methodologyDeepActions.updateCharacteristicFromMethodologyDeep(
        characPayload,
        defaultIndicator,
        methodologyId,
        orderedComponentId,
        orderedPhenomenonId
      )
    );
  }
  return of(actions.saveCharacteristicSuccess(characPayload));
};

export const handleSaveCharacteristic = (action$: ActionsObservable<Action<SaveCharacteristicParams>>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(actions.SAVE_CHARACTERISTIC),
    map((action: Action<SaveCharacteristicParams>) => (action.payload ? action.payload : ({} as SaveCharacteristicParams))),
    withLatestFrom(
      state$.pipe(
        map(state => {
          return {
            currentUser: state.User.currentUser as User,
            systemFlags: state.App.systemFlags
          };
        })
      )
    ),
    map(([action, state]) => {
      const { currentUser, systemFlags } = state;
      const { characteristic, methodologyId, orderedComponentId, orderedPhenomenonId, defaultIndicator } = action;

      const characteristicToSave: TalosPayload<Characteristic> = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          ...characteristic,
          cohese: true
        }
      };

      if (characteristic.isNew) {
        if (!characteristicToSave.payload.id) {
          characteristicToSave.payload.id = uuid();
        }
      }

      return {
        characteristic: characteristicToSave,
        defaultIndicator,
        methodologyId,
        orderedComponentId,
        orderedPhenomenonId,
        systemFlags
      };
    }),
    concatMap(({ characteristic, defaultIndicator, methodologyId, orderedComponentId, orderedPhenomenonId, systemFlags }) => {
      const saveCharacteristic$ = (characteristicToSave: TalosPayload<Characteristic | CharacteristicWithDefaultIndicator>) =>
        saveCharacteristic(characteristicToSave, characteristicToSave.payload.isNew).pipe(
          concatMap(() =>
            saveCharacteristicSuccess(
              characteristicToSave.payload,
              methodologyId,
              characteristicToSave.payload.default_indicator_id ? defaultIndicator : undefined,
              orderedComponentId,
              orderedPhenomenonId
            )
          )
        );

      if (!characteristic.payload.default_indicator_id) {
        return saveCharacteristic$(characteristic);
      }

      if (!defaultIndicator) {
        throw new Error('Default indicator is not defined!');
      }

      const indicator = defaultIndicator;
      const indicatorToSave: TalosPayload<Indicator> = {
        account: characteristic.account,
        payload: {
          ...indicator,
          expression: {
            ...indicator.expression,
            name: indicator.expression && indicator.expression.name ? indicator.expression.name : '',
            type: indicator.expression_type ? indicator.expression_type : indicator.expression!.type
          },
          cohese: true
        }
      };

      if (systemFlags.P40_37155_SAVE_CHARACTERISTIC_WITH_INDICATOR && characteristic.payload.isNew) {
        const characteristicDefaultIndicator = {
          ...characteristic,
          payload: {
            ...characteristic.payload,
            default_indicator: indicatorToSave.payload
          }
        };

        // If the characteristic is new, the indicator is saved within the characteristic endpoint
        return saveCharacteristic$(characteristicDefaultIndicator);
      }

      return saveIndicator(indicatorToSave, indicator.isNew, Boolean(indicator.default)).pipe(
        concatMap(() => saveCharacteristic$(characteristic))
      );
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catchError((error: any, caught) => {
      const msg = notifyErrorOrFallback(error, 'Error saving characteristic!');
      return merge(of(actions.saveCharacteristicFailure(msg)), caught);
    })
  );

export const handleDeleteCharacteristic = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_CHARACTERISTIC),
    map((action: Action<Characteristic>) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: any) => state.Methodology.methodologySelect)),
      state$.pipe(map((state: any) => state.Phenomenon.phenomenonsTree)),
      state$.pipe(map((state: any) => state.User.currentUser))
    ),
    map(([characteristic, methodologySelect, phenomenonsTree, currentUser]: any) => {
      let mutablePhenomenonsTree = phenomenonsTree;
      let phenomenonIndex = -1;
      let characteristicIndex = -1;
      mutablePhenomenonsTree.forEach((phenomenon, index) => {
        phenomenon.characteristics.forEach((characteristic_obj, characteristic_index) => {
          if (characteristic_obj.id === characteristic.id) {
            phenomenonIndex = index;
            characteristicIndex = characteristic_index;
          }
        });
      });
      if (phenomenonIndex !== -1) {
        let mutableCharacteristics = mutablePhenomenonsTree[phenomenonIndex].characteristics;
        mutableCharacteristics.splice(characteristicIndex, 1);
        mutablePhenomenonsTree[phenomenonIndex].characteristics = mutableCharacteristics;
      }

      const characteristicToDelete = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          ...characteristic
        }
      };

      return [methodologySelect, mutablePhenomenonsTree, characteristicToDelete];
    }),
    concatMap(([methodology, phenomenaTree, characteristic]) =>
      deleteCharacteristic(characteristic).pipe(
        flatMap(() => {
          notification('success', 'Characteristic deleted!');
          if (characteristic.payload.company_id) {
            return concat([
              phenomenonActions.updatePhenomenonTree(phenomenaTree),
              methodologyActions.updateMethodologyDraft(methodology),
              actions.deleteCharacteristicSuccess(characteristic.payload)
            ]);
          } else {
            return of(actions.deleteCharacteristicSuccess(characteristic.payload));
          }
        }),
        catchError((error: any) => {
          const msg = notifyErrorOrFallback(error, 'Error deleting characteristic!');
          return of(actions.deleteCharacteristicFailure(msg));
        })
      )
    )
  );

export const handleSaveCharacteristicSuccess = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_CHARACTERISTIC_SUCCESS),
    map((action: Action<Characteristic>) => action.payload),
    concatMap((characteristic: Characteristic): Observable<Action> => {
      const phenomenonsTree = state$.value.Phenomenon.phenomenonsTree;
      const newPhenomenonsTree = updateCharacteristicInPhenomenonsTree(characteristic, phenomenonsTree);
      return of(phenomenonActions.loadPhenomenonsTreeSuccess(newPhenomenonsTree));
    })
  );

export const handleFindAllCharacteristics = (action$, state$) =>
  action$.pipe(
    ofType(actions.FIND_ALL_CHARACTERISTICS),
    map((action: Action<LoadCharacteristicQueryPayload>) => action.payload),
    concatMap((query: LoadCharacteristicQueryPayload) =>
      findAllCharacteristics(query.pageableQuery, query.companyId, query.showCanonicals, query.disableCache).pipe(
        map(response => response.data),
        map((characteristicsPage: CharacteristicPage) => {
          return actions.findAllCharacteristicsSuccess(characteristicsPage.content);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading characteristics!');
          return of(actions.findAllCharacteristicsFailure(errorMsg));
        })
      )
    )
  );

export const handleFindAllCharacteristicsCanonicals = (action$, state$) =>
  action$.pipe(
    ofType(actions.FIND_ALL_CHARACTERISTICS_CANONICALS),
    map((action: Action<PageableQuery>) => action.payload),
    withLatestFrom(state$.pipe(map((state: any) => state.Characteristic.findAllCharacteristicsCanonicals))),
    concatMap(([query, allCharacteristics]) => {
      if (!allCharacteristics || allCharacteristics.length === 0) {
        return findAllCharacteristicsCanonicals(query).pipe(
          map(response => response.data),
          map((characteristicsPage: CharacteristicPage) => {
            return actions.findAllCharacteristicsCanonicalsSuccess(characteristicsPage.content);
          }),
          catchError((error: ErrorMsg) => {
            const errorMsg = notifyErrorOrFallback(error, 'Error loading characteristics!');
            return of(actions.findAllCharacteristicsCanonicalsFailure(errorMsg));
          })
        );
      }
      return of(actions.findAllCharacteristicsCanonicalsSuccess(allCharacteristics));
    })
  );
