/* eslint-disable import/prefer-default-export */
import { Page } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map, concatMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import actions from './actions';
import { notifyErrorOrFallback, ErrorMsg } from '../../settings/models/Error';
import { getCompanies } from './service';
import { Company } from './models';

export const handleLoadCompanies = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_COMPANIES),
    withLatestFrom(state$.pipe(map((state: any) => state.Company.loadedAll))),
    concatMap(([, loadedAll]: any) => {
      if (!loadedAll) {
        return getCompanies().pipe(
          map(response => response.data),
          concatMap((companies: Page<Company>) => {
            const companiesControleCerto = companies.content.filter(c => c.provider);
            return concat([
              actions.loadCompaniesSuccess(companies.content),
              actions.setControleCertoCompanies(companiesControleCerto as any)
            ]);
          }),
          catchError((error: ErrorMsg) => {
            const errorMsg = notifyErrorOrFallback(error, 'Error loading companies!');
            return of(actions.loadCompaniesFailure(errorMsg));
          })
        );
      } else {
        return of(actions.toggleIsLoading());
      }
    })
  );
