import React from 'react';
const ChevronUpGlyph = props => (
  <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.231231 5.76036C0.53954 6.07988 1.03941 6.07988 1.34772 5.76036L5 1.97527L8.65228 5.76036C8.96059 6.07988 9.46046 6.07988 9.76877 5.76036C10.0771 5.44084 10.0771 4.9228 9.76877 4.60328L5.55824 0.23964C5.24993 -0.0798799 4.75007 -0.0798799 4.44176 0.23964L0.231231 4.60328C-0.0770772 4.9228 -0.0770772 5.44084 0.231231 5.76036Z'
      fill={props.color}
    />
  </svg>
);
export default ChevronUpGlyph;
