import actions from './actions';
import { initialPage } from '../app/basicModels';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';
import { FixedPoint, FixedPointState } from '../models';
import { iconTypes, fixedPointTypes } from '../fixedPoint/reducer';

const entity: EntityAdapter<FixedPoint> = createEntityAdapter<FixedPoint>();

const initialState: FixedPointState = entity.getInitialState({
  page: {
    ...initialPage
  },
  isLoading: false,
  company_id: null,
  parent_id: null,
  error: null,
  isSaving: false,
  fixedPointSelect: null,
  fixedPointSelectLoading: false,
  fixedPointTypes,
  iconTypes,
  editPhenomenaModalActive: false,
  indicatorsModalVisible: false,
  phenomenonsOfFixedPoint: [],
  characteristicsOfFixePoint: [],
  isLoadingPhenomenonsForFixedPoint: false,
  isLoadingCharacteristicsOfFixedPoint: false,
  isDrafting: false,
  phenomenonInfo: null,
  addCharacteristicIndicatorActive: false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_TRAPS:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_TRAPS_SUCCESS:
      return entity.addAll(action.payload as FixedPoint[], {
        ...state,
        page: {
          number: 0,
          size: 0,
          pages: 0,
          total: 0,
          content: [...action.payload]
        },
        isLoading: false,
        error: null
      });

    case actions.LOAD_TRAPS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.CLONE_TRAP_TO_COMPANIES:
    case actions.SAVE_TRAP:
      return {
        ...state,
        isSaving: true
      };

    case actions.SAVE_TRAP_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isSaving: false,
        error: null,
        fixedPointSelect: action.payload
      });

    case actions.CLONE_TRAP_TO_COMPANIES_FAILURE:
    case actions.SAVE_TRAP_FAILURE:
      return {
        ...state,
        isSaving: false,
        isDrafting: false,
        error: action.payload
      };

    case actions.CLONE_TRAP_TO_COMPANIES_SUCCESS:
      return entity.upsertMany(action.payload, {
        ...state,
        isSaving: false,
        error: null
      });

    case actions.SELECT_FIXEDPOINT_TRAP:
      return {
        ...state,
        fixedPointSelect: action.payload,
        isDrafting: false
      };

    case actions.CLEAR_FIXED_POINT_SELECT_TRAP:
      return {
        ...state,
        fixedPointSelect: null,
        phenomenonsOfFixedPoint: []
      };

    case actions.UPDATE_TRAP:
      return {
        ...state,
        isDrafting: true
      };

    case actions.LOAD_TRAP:
      return {
        ...state,
        fixedPointSelectLoading: true,
        fixedPointSelect: null
      };

    case actions.LOAD_TRAP_SUCCESS:
      return {
        ...state,
        fixedPointSelectLoading: false,
        fixedPointSelect: action.payload
      };

    case actions.LOAD_TRAP_FAILURE:
      return {
        ...state,
        fixedPointSelectLoading: false,
        error: action.payload
      };

    case actions.DELETE_TRAP_SUCCESS:
      return entity.removeOne(action.payload.id, {
        ...state,
        error: null
      });

    case actions.DELETE_TRAP_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case actions.TOGGLE_FIXED_POINT_TRAP_PHENOMENA_MODAL:
      return {
        ...state,
        editPhenomenaModalActive: !state.editPhenomenaModalActive
      };

    case actions.TOGGLE_CHARACTERISTICS_INDICATORS_PHENOMENA_MODAL_TRAP:
      return {
        ...state,
        addCharacteristicIndicatorActive: !state.addCharacteristicIndicatorActive,
        phenomenonInfo: action.payload
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectTrapsIds = selectIds;
export const selectTrapsEntities = selectEntities;
export const selectAllTraps = selectAll;
export const selectTrapsTotal = selectTotal;
export const selectSelectedTrap = state => state.fixedPointSelect;
