import React from 'react';
const MoveGlyph = props => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      d='M19.71 9.31002L16.71 6.31002C16.3179 5.91789 15.6821 5.91789 15.29 6.31002C14.8979 6.70214 14.8979 7.33789 15.29 7.73002L16.58 9.00002H11V3.41002L12.29 4.71002C12.4787 4.8972 12.7342 5.00155 13 5.00002C13.2658 5.00155 13.5213 4.8972 13.71 4.71002C13.8993 4.52225 14.0058 4.26665 14.0058 4.00002C14.0058 3.73338 13.8993 3.47778 13.71 3.29002L10.71 0.290017C10.5213 0.10283 10.2658 -0.00152002 10 1.67363e-05C9.73768 0.00112136 9.48628 0.105273 9.30002 0.290017L6.30002 3.29002C6.04636 3.54189 5.94634 3.90995 6.03764 4.25556C6.12894 4.60118 6.39768 4.87183 6.74264 4.96556C7.0876 5.0593 7.45636 4.96189 7.71002 4.71002L9.00002 3.42002V9.00002H3.41002L4.71002 7.71002C5.10214 7.31789 5.10214 6.68214 4.71002 6.29002C4.31789 5.89789 3.68214 5.89789 3.29002 6.29002L0.290017 9.29002C0.10283 9.47874 -0.00152002 9.73421 1.67363e-05 10C-0.00152002 10.2658 0.10283 10.5213 0.290017 10.71L3.29002 13.71C3.47874 13.8972 3.73421 14.0016 4.00002 14C4.26582 14.0016 4.5213 13.8972 4.71002 13.71C4.89933 13.5223 5.00582 13.2667 5.00582 13C5.00582 12.7334 4.89933 12.4778 4.71002 12.29L3.42002 11H9.00002V16.59L7.71002 15.29C7.45636 15.0364 7.08664 14.9373 6.74014 15.0301C6.39363 15.123 6.12298 15.3936 6.03014 15.7401C5.93729 16.0866 6.03636 16.4564 6.29002 16.71L9.29002 19.71C9.47874 19.8972 9.73421 20.0016 10 20C10.2624 19.9989 10.5138 19.8948 10.7 19.71L13.7 16.71C14.0921 16.3179 14.0921 15.6821 13.7 15.29C13.3079 14.8979 12.6721 14.8979 12.28 15.29L11 16.58V11H16.59L15.29 12.29C15.1007 12.4778 14.9942 12.7334 14.9942 13C14.9942 13.2667 15.1007 13.5223 15.29 13.71C15.4787 13.8972 15.7342 14.0016 16 14C16.2658 14.0016 16.5213 13.8972 16.71 13.71L19.71 10.71C19.8972 10.5213 20.0016 10.2658 20 10C19.9963 9.74115 19.8924 9.4938 19.71 9.31002Z'
      fill={props.color}
    />
  </svg>
);
export default MoveGlyph;
