import { VariableValidation } from '../models';
import actions from './actions';

import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';
import { VariableValidationState } from './models';

const entity: EntityAdapter<VariableValidation[]> = createEntityAdapter<VariableValidation[]>();

const initialState: VariableValidationState = entity.getInitialState({
  variableValidation: [],
  variableValidationToSend: undefined,
  hasChange: false
});

export default (state = initialState, action): VariableValidationState => {
  switch (action.type) {
    case actions.CHANGE_VARIABLE_VALIDATION:
      return {
        ...state,
        hasChange: action.payload
      };

    case actions.SET_VARIABLE_VALIDATION:
      return {
        ...state,
        variableValidation: [...action.payload]
      };

    case actions.SET_VARIABLE_VALIDATION_TO_SEND:
      return {
        ...state,
        variableValidationToSend: [...action?.payload]
      };

    case actions.CLEAR_VARIABLE_VALIDATION_TO_SEND: {
      return {
        ...state,
        variableValidationToSend: undefined
      };
    }
    default:
      return state;
  }
};
