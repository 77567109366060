import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';
import { TrapMeasurementState, TrapMeasurement } from './models';
import actions from './actions';

const entity: EntityAdapter<TrapMeasurement> = createEntityAdapter<TrapMeasurement>();

const initialState: TrapMeasurementState = entity.getInitialState({
  isLoading: false,
  error: undefined,
  stations: [],
  measurementId: undefined,
  measurementModalIsActive: false,
  measurementDetailsModalIsActive: false
});

export default (state = initialState, action): TrapMeasurementState => {
  switch (action.type) {
    case actions.LOAD_TRAP_MEASUREMENTS_BY_PROPERTY:
      return entity.removeAll({
        ...state,
        isLoading: true,
        error: undefined
      });

    case actions.LOAD_TRAP_MEASUREMENTS_BY_PROPERTY_SUCCESS:
      return entity.addAll(action.payload, {
        ...state,
        isLoading: false,
        error: undefined
      });

    case actions.LOAD_NEXT_PAGE_TRAP_MEASUREMENTS_BY_PROPERTY_SUCCESS:
      return entity.upsertMany(action.payload, {
        ...state,
        error: undefined
      });

    case actions.LOAD_TRAP_MEASUREMENTS_BY_PROPERTY_FAILURE:
    case actions.EDIT_TRAP_MEASUREMENT_VALUE_FAILURE:
    case actions.LOAD_TRAP_STATIONS_BY_PROPERTY_FAILURE:
    case actions.DELETE_TRAP_MEASUREMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.LOAD_TRAP_STATIONS_BY_PROPERTY_SUCCESS:
      return {
        ...state,
        stations: action.payload
      };

    case actions.TOGGLE_TRAP_MEASUREMENT_EDIT_MODAL:
      return {
        ...state,
        measurementModalIsActive: !state.measurementModalIsActive,
        measurementId: !state.measurementModalIsActive && action.payload ? action.payload : null
      };
    case actions.TOGGLE_TRAP_MEASUREMENT_DETAILS_MODAL:
      return {
        ...state,
        measurementDetailsModalIsActive: !state.measurementDetailsModalIsActive,
        measurementId: !state.measurementModalIsActive && action.payload ? action.payload : null
      };
    case actions.EDIT_TRAP_MEASUREMENT_VALUE:
    case actions.DELETE_TRAP_MEASUREMENT:
      return {
        ...state,
        isLoading: true
      };

    case actions.EDIT_TRAP_MEASUREMENT_VALUE_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isLoading: false,
        error: undefined
      });
    case actions.DELETE_TRAP_MEASUREMENT_SUCCESS:
      return entity.removeOne(action.payload, {
        ...state,
        error: undefined,
        isLoading: false
      });

    default:
      return state;
  }
};

const { selectIds, selectEntities } = entity.getSelectors();
export const selectMeasurementIds = selectIds;
export const selectMeasurementEntities = selectEntities;
