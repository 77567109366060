import { Phenomenon, PhenomenonSingle } from './models';
import { ComponentDeep, IndicatorDeep, InspectionGroupDeep, MethodologyDeep, PhenomenonDeep } from '../methodologyDeep/models';
import { UUID, PhenomenonCategory, I18nMap } from '../models';
import {
  addCategoryPhenomenaToMethodology,
  createCategoryWithPhenomena,
  createComponentDeepFromPhenomenonTree,
  fixPhenomenaInCorrectCategories,
  getUsedIdsInMethodology
} from '../methodologyDeep/services';
import { cloneDeep, remove } from 'lodash';
import { clearEmptyCategoriesFromInspectionGroup, getIds, getObjectById, isObjectCanonicalCustom } from '../methodologyDeep/utils';
import { Options } from '../language/models';

export const upsertPhenomenonInMethodologyDeep = (
  methodologyDeep: MethodologyDeep,
  phenomenon: Phenomenon,
  orderedPhenomenonId?: UUID,
  orderedInspectionGroupId?: UUID,
  phenomenaTree?: Phenomenon[]
) => {
  let mutableMethodologyDeep = cloneDeep(methodologyDeep);
  if (orderedPhenomenonId) {
    mutableMethodologyDeep = updateInformationAboutPhenomenon(phenomenon, methodologyDeep, orderedPhenomenonId, phenomenaTree);
  } else if (orderedInspectionGroupId) {
    const categoriesWithPhenomena = createCategoryWithPhenomena([phenomenon.id], [phenomenon]);

    mutableMethodologyDeep = addCategoryPhenomenaToMethodology(methodologyDeep, orderedInspectionGroupId, categoriesWithPhenomena);
  }
  return mutableMethodologyDeep;
};

export function updateInformationAboutPhenomenon(
  phenomenon: Phenomenon,
  methodologyDeep: MethodologyDeep,
  orderedPhenomenonId: UUID,
  phenomenaTree?: Phenomenon[]
): MethodologyDeep {
  const phenomenonInTree = getObjectById(phenomenaTree ?? [], phenomenon.id!);
  if (phenomenonInTree && phenomenonInTree.category!.id! !== phenomenon.category!.id!) {
    const methodologyInspectionGroups = methodologyDeep.inspectionLayout!.inspectionGroups;
    const { phenDeep, inspGroupId } = getPhenDeepDataFromInspectionGroup(methodologyInspectionGroups, orderedPhenomenonId);
    let inspectionGroup = methodologyInspectionGroups.find(insp => insp.id === inspGroupId);
    if (inspectionGroup) {
      const phenomenonSet = { phenomenon, phenomenonDeep: phenDeep! };
      inspectionGroup = fixPhenomenaInCorrectCategories(inspectionGroup, [phenomenonSet]);
      return replaceInspectionGroupInMethodology(methodologyDeep, clearEmptyCategoriesFromInspectionGroup(inspectionGroup));
    }
  }
  return updatePhenomenonDeepInMethodology(methodologyDeep, orderedPhenomenonId, phenomenon);
}

export const updatePhenomenonBasicInfo = (
  phenomenon: PhenomenonSingle,
  phenCat: PhenomenonCategory,
  data: any,
  currentLanguage: string
) => {
  const keys = Object.keys((phenomenon.short_description && phenomenon.short_description.localized_strings) || {});
  const shortDescription = canEditShortDescription(phenomenon)
    ? getShortDescription(keys, data.short_description, currentLanguage)
    : phenomenon.short_description;
  const newPhen: PhenomenonSingle = {
    ...phenomenon,
    category: {
      ...phenomenon.category,
      ...phenCat
    },
    name: data.name,
    description: data.description,
    short_description: shortDescription
  };
  return newPhen;
};

export const parsePhenomenonToPhenomenonSingle = (phenomenon: Phenomenon): PhenomenonSingle => {
  const parsedPhenomenon = {
    ...phenomenon,
    characteristics_dto: phenomenon && phenomenon.characteristics ? phenomenon.characteristics : [],
    indicators_dto: phenomenon && phenomenon.indicators ? phenomenon.indicators : [],
    characteristics: getIds(phenomenon.characteristics),
    indicators: getIds(phenomenon.indicators)
  };
  return parsedPhenomenon;
};

export const parsePhenomenonSingleToPhenomenon = (single: PhenomenonSingle): Phenomenon => {
  const parsedPhenomenon = {
    ...single,
    characteristics: single && single.characteristics_dto ? single.characteristics_dto : [],
    indicators: single && single.indicators_dto ? single.indicators_dto : []
  };
  return parsedPhenomenon;
};

export const updatePhenomenaTree = (phenomenon: PhenomenonSingle, phenomenaTree: Phenomenon[]): Phenomenon[] => {
  const mutableTree: Phenomenon[] = cloneDeep(phenomenaTree);
  if (!phenomenon.isNew) {
    remove(mutableTree, p => p.id === phenomenon.id);
  }

  return [...mutableTree, parsePhenomenonSingleToPhenomenon(phenomenon)];
};

const getPhenDeepDataFromInspectionGroup = (inspectionGroups: InspectionGroupDeep[], phenId: UUID) => {
  let inspGroupId: UUID | undefined;
  let categoryId: UUID | undefined;
  let phenDeep: PhenomenonDeep | undefined;

  inspectionGroups.forEach(
    inspG =>
      inspG.categories &&
      inspG.categories.forEach(
        cat =>
          cat.phenomenons &&
          cat.phenomenons.forEach(p => {
            if (p.id === phenId) {
              inspGroupId = inspG.id;
              categoryId = cat.id;
              phenDeep = p;
            }
          })
      )
  );
  return { phenDeep, inspGroupId, categoryId };
};

const updatePhenomenonDeep = (phenDeep: PhenomenonDeep, phen: Phenomenon): PhenomenonDeep => {
  return {
    ...phenDeep,
    name: phen.name,
    scientificName: phen.short_description
  };
};

export const updatePhenomenonDeepInMethodology = (
  methodologyDeep: MethodologyDeep,
  orderedPhenomenonId: UUID,
  phenomenon: Phenomenon
): MethodologyDeep => {
  const updatedMethodologyDeep: MethodologyDeep = { ...methodologyDeep };

  if (updatedMethodologyDeep.inspectionLayout) {
    updatedMethodologyDeep.inspectionLayout = {
      ...(updatedMethodologyDeep.inspectionLayout || {}),
      inspectionGroups: (updatedMethodologyDeep.inspectionLayout.inspectionGroups || []).map(ig => {
        return {
          ...ig,
          categories: (ig.categories || []).map(c => {
            return {
              ...c,
              phenomenons: (c.phenomenons || []).map((p: PhenomenonDeep) => {
                if (p && p.id === orderedPhenomenonId) {
                  return updatePhenomenonDeep(p, phenomenon);
                }
                return p;
              })
            };
          })
        };
      })
    };
  } else {
    updatedMethodologyDeep.inspectionLayout = {
      id: '',
      inspectionGroups: []
    };
  }

  return updatedMethodologyDeep;
};

const replaceInspectionGroupInMethodology = (methodology: MethodologyDeep, inspectionGroup: InspectionGroupDeep): MethodologyDeep => {
  return {
    ...methodology,
    inspectionLayout: {
      ...methodology.inspectionLayout!,
      inspectionGroups: [...methodology.inspectionLayout!.inspectionGroups.filter(insp => insp.id !== inspectionGroup.id), inspectionGroup]
    }
  };
};

export const fixPhenomenonPayload = (phenomenon: Phenomenon) => {
  const phenomenonPayload: any = { ...cloneDeep(phenomenon), cohese: true };
  if (phenomenonPayload) {
    if (phenomenonPayload.indicators_dto) {
      delete phenomenonPayload.indicators_dto;
    }
    if (phenomenonPayload.characteristics_dto) {
      delete phenomenonPayload.characteristics_dto;
    }
  }
  return phenomenonPayload;
};

export const getShortDescription = (availableKeys: string[], value: string, currentLanguage?: string): I18nMap => {
  const shortDescription = { localized_strings: {} };

  [...availableKeys].forEach(key => (shortDescription.localized_strings[key] = value));
  shortDescription.localized_strings[currentLanguage || Options.EN] = value;

  return shortDescription;
};

export const canEditShortDescription = object => {
  if (object.isNew) return true;
  return !isObjectCanonicalCustom(object);
};

export const getComponentsToAddFromClonedPhenomenon = (
  clonedPhenomenon: Partial<Phenomenon>,
  destinationPhenomenon?: PhenomenonDeep
): ComponentDeep[] => {
  const componentsToAdd = createComponentDeepFromPhenomenonTree(clonedPhenomenon);

  const { usedCharacteristicIds, usedCharacteristicIdsAsAliases, usedIndicatorIds } = getUsedIdsInMethodology(
    destinationPhenomenon?.components ?? []
  );
  const usedCharacteristics = [...usedCharacteristicIds, ...usedCharacteristicIdsAsAliases];
  const componentsToAddFiltered = componentsToAdd.filter(component => {
    if (component.characteristic) {
      return !usedCharacteristics.includes(component.characteristic.id);
    }

    const indicator = component.indicator as IndicatorDeep;
    return !usedIndicatorIds.includes(indicator.id);
  });

  return componentsToAddFiltered;
};
