import React, { Component } from 'react';
import { Select as WDSelect } from 'antd';
//import { AntSelect } from './styles/select.style';
import WithDirection from '../../settings/withDirection';

const IsoSelect = WithDirection(WDSelect);
const SelectOption = WDSelect.Option;

export default class SelectValidation extends Component {
  render() {
    const { children, ...props } = this.props;
    return (
      <IsoSelect
        {...props}
        filterOption={(input, option) => {
          return (
            option.props.children &&
            typeof option.props.children == 'string' &&
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}>
        {children}
      </IsoSelect>
    );
  }
}

const Select = SelectValidation;

export { Select, SelectOption };
