import { isEmpty } from 'lodash';
import React from 'react';
import CharacteristicsIndicatorsClass from '../../components/characteristicsIndicators/characteristicsIndicators';
import ExtraDimensionsWrapper from '../../components/extraDimensions/extraDimensionsWrapper';
import { DragabbleList } from '../../components/nestedDraggable/draggableList/draggableList.models';
import { DroppableHeader } from '../../components/nestedDraggable/droppableHeader/droppableHeader';
import { uuid } from '../../helpers';
import { getValueLanguage } from '../../redux/language/utils';
import {
  CategoryDeep,
  ComponentDeep,
  ExtraDimensionsDeep,
  InspectionGroupDeep,
  MethodologyDeep,
  PhenomenonDeep
} from '../../redux/methodologyDeep/models';
import { mapIndicatorsDeepByPhenomena } from '../../redux/methodologyDeep/services';
import { getComponentDeepWithProperties } from '../../redux/methodologyDeep/utils';
import { templateCompanyId, UUID } from '../../redux/models';
import InspectionGroupHeader from './CurrentMethodology/InspectionGroupHeader/inspectionGroupHeader';
import PhenomenonHeader from './CurrentMethodology/PhenomenonHeader/phenomenonHeader';

export function getComponentName(component: ComponentDeep): string {
  const characteristicName = getRecordNameWithFallback(component.characteristic);
  const indicatorsName = getRecordNameWithFallback(component.indicator);

  if (characteristicName && indicatorsName) {
    return `${characteristicName} / ${indicatorsName}`;
  }
  if (characteristicName) {
    return `${characteristicName}`;
  }
  if (indicatorsName) {
    return `${indicatorsName}`;
  }
  return '';
}

function getRecordNameWithFallback(record: any): string {
  return record && record.name ? getValueLanguage(record.name) : '';
}

const isComponentCanonical = (component: ComponentDeep): boolean => {
  if (component.characteristic) {
    return !!component.characteristic.comesFromCanonical;
  }
  if (component.indicator) {
    return !!component.indicator.comesFromCanonical;
  }
  return false;
};

function getComponentProperties(component: ComponentDeep, phenomenonId: UUID, methodologyId: UUID) {
  const { indicator } = component;

  if (component.characteristic && !component.characteristic.id && component.indicator && !component.indicator.id)
    throw new Error('Methodology inconsistencies found. Both characteristics and indicator ids are undefined.');

  return {
    id: component?.characteristic?.id ?? component?.indicator?.id ?? '',
    title: getComponentName(component),
    canonical: isComponentCanonical(component),
    timeline: indicator ? indicator.showOnTimeline : false,
    thresholds: indicator && indicator.diagnostics && indicator.diagnostics[0] && indicator.diagnostics[0].thresholds,
    inspectionLayout: indicator ? indicator.showOnInspectionLayout : false,
    characteristicId: component.characteristic ? component.characteristic.characteristicId : undefined,
    indicatorId: indicator ? indicator.indicatorId : undefined,
    phenomenonId,
    methodologyId,
    wrongConfiguration: component.indicator && component.indicator.wrongConfiguration
  };
}

export function createNestedMethodology(methodologyDeep: MethodologyDeep, flags?): DragabbleList {
  const methodology = { ...methodologyDeep };
  return {
    id: methodology.id ? methodology.id : 'methodology',
    title: getRecordNameWithFallback(methodology),
    children: methodology?.inspectionLayout?.inspectionGroups
      ? methodology.inspectionLayout.inspectionGroups.map(inspectionGroup => {
          return getInspectionGroupComponent(inspectionGroup, methodology, flags);
        })
      : undefined
  };
}

function getInspectionGroupComponent(inspectionGroup: InspectionGroupDeep, methodology: MethodologyDeep, flags: any) {
  const inspectionGroupComponent = getRecord(inspectionGroup, 'GREY', methodology);
  inspectionGroupComponent.children = inspectionGroup.categories
    ? inspectionGroup.categories.map(category => getCategoryComponent(category, methodology))
    : [];

  if (methodology?.inspectionLayout?.inspectionGroups?.length && flags.enableExtraDimensions) {
    const selectedInspectionGroup = methodology.inspectionLayout.inspectionGroups.find(ig => inspectionGroupComponent.id === ig.id);
    if (selectedInspectionGroup?.extraDimensions && !isEmpty(selectedInspectionGroup?.extraDimensions?.components)) {
      inspectionGroupComponent.children.push({
        id: selectedInspectionGroup.extraDimensions.id ?? uuid(),
        priority: inspectionGroupComponent.children.length,
        title: 'Resources',
        collapsed: selectedInspectionGroup.extraDimensions.collapsed,
        isDragDisabled: true,
        record: [],
        content: [],
        header: getHeader(
          {
            id: uuid(),
            name: { localized_strings: { en: 'Resources' } },
            priority: 10
          },
          'BLUE',
          null,
          null,
          null,
          null,
          true
        ),
        children: getExtraDimensionComponent(selectedInspectionGroup.extraDimensions.components ?? [], selectedInspectionGroup.id),
        footer: undefined
      });
    }
  }

  return inspectionGroupComponent;
}

function getCategoryComponent(category: CategoryDeep, methodology: MethodologyDeep) {
  const categoryComponent = getRecord(category, 'BLUE');
  categoryComponent.children = category.phenomenons
    ? category.phenomenons.map(phenomenon => getPhenomenonComponent(phenomenon, methodology))
    : [];
  return categoryComponent;
}

function getPhenomenonComponent(phenomenon: PhenomenonDeep, methodology: MethodologyDeep) {
  const phenomenonComponent = getRecord(phenomenon, 'GREEN');
  phenomenonComponent.footer = [];
  phenomenon?.components?.forEach(component => {
    if (component.characteristic) {
      phenomenonComponent?.children?.push(createComponent(component, phenomenon.phenomenonId, methodology.id!));
    } else {
      phenomenonComponent.footer.push(createComponent(component, phenomenon.phenomenonId, methodology.id!));
    }
  });

  if (methodology.wrongIndicators) {
    const indicatorsByPhenomenon = mapIndicatorsDeepByPhenomena(methodology.wrongIndicators)[phenomenon.phenomenonId] ?? [];
    const wrongComponents = indicatorsByPhenomenon.map(i =>
      createComponent(getComponentDeepWithProperties(i), phenomenon.phenomenonId, methodology.id!)
    );
    phenomenonComponent.footer.push(...wrongComponents);
  }
  return phenomenonComponent;
}

function createComponent(component: ComponentDeep, phenomenonId: UUID, methodologyId: UUID) {
  const componentProperties = getComponentProperties(component, phenomenonId, methodologyId) as any;
  return {
    id: componentProperties.id,
    priority: component.priority,
    title: componentProperties.title,
    content: <CharacteristicsIndicatorsClass {...componentProperties} />
  };
}

function getExtraDimensionComponentProperties(component, inspectionGroupId) {
  return {
    id: component.id,
    title: getValueLanguage(component.name),
    priority: component.priority,
    inspectionGroupId
  };
}

function createExtraDimensionComponent(component: ExtraDimensionsDeep, inspectionGroupId: UUID) {
  const componentProperties = getExtraDimensionComponentProperties(component, inspectionGroupId);
  return {
    id: uuid() ?? componentProperties.id,
    priority: componentProperties.priority,
    title: componentProperties.title,
    inspectionGroupId,
    content: <ExtraDimensionsWrapper {...componentProperties} />
  };
}

function getExtraDimensionComponent(extraDimensions: ExtraDimensionsDeep[], inspectionGroupId: UUID) {
  return extraDimensions.map(res => {
    return createExtraDimensionComponent(res, inspectionGroupId);
  });
}

const getRecord = (
  record: PhenomenonDeep | CategoryDeep | InspectionGroupDeep,
  color = 'BLUE',
  methodolodyDeep?: MethodologyDeep
): DragabbleList => {
  return {
    id: record.id ?? uuid(),
    priority: record.priority,
    collapsed: !!record.collapsed,
    title: getRecordNameWithFallback(record),
    record,
    header: getHeader(record, color, null, null, null, null, false, methodolodyDeep),
    children: []
  };
};

function getHeader(
  record: PhenomenonDeep | CategoryDeep | InspectionGroupDeep | ExtraDimensionsDeep,
  color,
  onAdd?,
  addButtonText?,
  onDelete?,
  onEdit?,
  isDragDisabled?,
  methodologyDeep?
) {
  const collapse = true;
  if ('inspectionGroupId' in record) {
    return (
      <InspectionGroupHeader
        extraDimensionActive={!checkIfMethodologyIsTemplate(methodologyDeep)}
        id={record.id}
        name={getValueLanguage(record.name)}
      />
    );
  }
  if ('phenomenonId' in record) {
    return (
      <PhenomenonHeader
        id={record.id}
        phenomenonId={record.phenomenonId}
        name={getValueLanguage(record.name)}
        scientificName={getValueLanguage(record.scientificName)}
        isCanonical={!!record.comesFromCanonical}
      />
    );
  }
  return (
    <DroppableHeader
      id={record.id}
      title={getValueLanguage(record.name)}
      color={color}
      collapseAll={collapse}
      onDelete={onDelete}
      onEdit={onEdit}
      onAdd={onAdd}
      isDragDisabled={Boolean(isDragDisabled)}
      addButtonText={addButtonText}
    />
  );
}

const checkIfMethodologyIsTemplate = (methodologyDeep: MethodologyDeep) => {
  return Boolean(methodologyDeep.company!.id === templateCompanyId);
};
