import actions from './actions';
import { Region, RegionState } from './models';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';

const entity: EntityAdapter<Region> = createEntityAdapter<Region>();

const initialState: RegionState = entity.getInitialState({
  regions: [],
  error: null
});

export default (state = initialState, action): any => {
  switch (action.type) {
    case actions.LOAD_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.payload
      };
    case actions.LOAD_REGIONS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
