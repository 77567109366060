import {
  BasicInfoUser,
  User,
  InviteUserRequestParams,
  OrgUser,
  Roles,
  NewAuthority,
  ControleCertoUser,
  ToggleAddEditUserModalProps
} from './models';
import { UUID } from '../models';
import { User as UserRBAC, GetAllRolesByUserResponse } from '../../querys/rbac/rbac.query.model';
import { QueryClient } from 'react-query';

const actions = {
  CLEAR_SAVED_EDITED_USER: '[User] Clear Saved Edited User',
  LOAD_USER_ME: '[User] Load User Me',
  LOAD_USER_ME_SUCCESS: '[User] Load User Me Success',
  LOAD_USER_ME_FAILURE: '[User] Load User Me Failure',
  DELETE_USER: '[User] Delete User',
  DELETE_USER_SUCCESS: '[User] Delete User Success',
  DELETE_USER_FAILURE: '[User] Delete User Failure',
  INVITE_USER_BY_EMAIL: '[User] Invite User By Email',
  DELETE_USER_RBAC: '[User] Delete User RBAC',
  INVITE_USER_BY_EMAIL_SUCCESS: '[User] Invite User By Email Success',
  INVITE_USER_BY_EMAIL_FAILURE: '[User] Invite User By Email Failure',
  LOAD_ORG_USERS: '[User] Load Org Users ',
  LOAD_ROLES_RBAC_BY_USER: '[User] Load Roles RBAC By User ',
  LOAD_ROLES_RBAC_BY_USER_SUCCESS: '[User] Load Roles RBAC By Use Success',
  LOAD_ROLES_RBAC_BY_USER_FAILURE: '[User] Load Roles RBAC By Use Failure',
  LOAD_ORG_USERS_SUCCESS: '[User] Load Org Users Success',
  LOAD_ORG_USERS_FAILURE: '[User] Load Org Users Failure',
  CREATE_USER: '[User] Create User',
  CREATE_CONTROLE_CERTO_USER: '[User Controle Certo] Create Controle Certo User',
  EDIT_USER: '[User] Edit User',
  EDIT_CONTROLE_CERTO_USER: '[User Controle Certo] Edit Controle Certo User',
  SAVE_USER_SUCCESS: '[User] Save User Success',
  SAVE_CONTROLE_CERTO_USER_SUCCESS: '[User Controle Certo] Save Controle Certo User Success',
  SAVE_USER_FAILURE: '[User] Save User Failure',
  SAVE_CONTROLE_CERTO_USER_FAILURE: '[User Controle Certo] Save Controle Certo User Failure',
  TOGGLE_INVITE_USER_BY_EMAIL_MODAL: '[User] Toggle invite user by email',
  TOGGLE_ADD_EDIT_USER_MODAL: '[User] Toggle Add/Edit User Modal',
  REMOVE_SUBSCRIPTION_USER_BY_COMPANY: '[User] Remove subscription user by company',
  SET_LAST_ORG_ID_LOAD_USERS: '[User] Set last org id load Users',
  LOAD_ROLES: '[User] Load roles',
  LOAD_ROLES_SUCCESS: 'Load roles success',
  LOAD_ROLES_FAILURE: 'Load roles failure',
  TOGGLE_CONTROLE_CERTO_USER_MODAL: '[User - CC] Toggle Controle Certo User Modal',
  SET_SUGARCANE_ORG_PERMISSION: 'Set Sugarcane Org Permission',
  SET_IS_LOADING_PROVIDERS_COMPANIES: 'Set Is Loading Providers Companies',
  LOAD_USER_BY_EMAIL: '[User] Load Org User by Email ',
  LOAD_USER_BY_EMAILV2: '[User] Load Org User by Email V2 ',
  LOAD_USER_BY_EMAIL_SUCCESS: '[User] Load Org User by Email Success',
  LOAD_USER_BY_EMAIL_FAILURE: '[User] Load Org User by Email Failure',
  UPDATE_USER_PASSWORD: '[User] Update User Password',
  UPDATE_USER_PASSWORD_SUCCESS: '[User] Update User Password Success',
  UPDATE_USER_PASSWORD_FAILURE: '[User] Update User Password Failure',
  CLEAR_USER_PASSWORD: '[User] Clear User Password',
  SET_VALUE_IS_SAVING_STATE: '[USER] Set Value Is Saving State',
  LOAD_ROLES_RBAC_USER_LOGGED_SUCCESS: '[User] Load Roles RBAC By User Logged Success',

  loadUserMe: () => ({
    type: actions.LOAD_USER_ME
  }),

  loadUserMeSuccess: (currentUser: User) => ({
    type: actions.LOAD_USER_ME_SUCCESS,
    payload: currentUser
  }),

  loadRolesUserLoggedSuccess: (roles: GetAllRolesByUserResponse['content']) => ({
    type: actions.LOAD_ROLES_RBAC_USER_LOGGED_SUCCESS,
    payload: roles
  }),

  loadUserMeFailure: (error: string) => ({
    type: actions.LOAD_USER_ME_FAILURE,
    payload: error
  }),

  deleteUser: (orgUser: string, companyId?: UUID) => ({
    type: actions.DELETE_USER,
    payload: { orgUser, companyId }
  }),

  deleteUserRBAC: (
    userRoles: GetAllRolesByUserResponse['content'],
    userID: string,
    orgID: string,
    workspaceID: string,
    queryClient: QueryClient
  ) => {
    return {
      type: actions.DELETE_USER_RBAC,
      payload: { userRoles, userID, orgID, workspaceID, queryClient }
    };
  },
  deleteUserSuccess: (id: UUID) => ({
    type: actions.DELETE_USER_SUCCESS,
    payload: id
  }),

  deleteUserFailure: (error: string) => ({
    type: actions.DELETE_USER_FAILURE,
    payload: error
  }),

  inviteUserByEmail: (user: InviteUserRequestParams, orgId: UUID) => ({
    type: actions.INVITE_USER_BY_EMAIL,
    payload: { user, orgId }
  }),

  inviteUserByEmailSuccess: (user: InviteUserRequestParams) => ({
    type: actions.INVITE_USER_BY_EMAIL_SUCCESS,
    payload: user
  }),

  inviteUserByEmailFailure: (error: string) => ({
    type: actions.INVITE_USER_BY_EMAIL_FAILURE,
    payload: error
  }),

  loadOrgUsers: (orgId: UUID) => ({
    type: actions.LOAD_ORG_USERS,
    payload: orgId
  }),

  loadRolesRBACByUsersSuccess: (users: any) => ({
    type: actions.LOAD_ROLES_RBAC_BY_USER_SUCCESS,
    payload: users
  }),

  loadRolesRBACByUsersFailure: (error: string) => ({
    type: actions.LOAD_ROLES_RBAC_BY_USER_FAILURE,
    payload: error
  }),

  loadOrgUsersSuccess: (users: User[] | UserRBAC[]) => ({
    type: actions.LOAD_ORG_USERS_SUCCESS,
    payload: users
  }),

  loadOrgUsersFailure: (error: string) => ({
    type: actions.LOAD_ORG_USERS_FAILURE,
    payload: error
  }),

  createUser: (basicInfoUser: BasicInfoUser, authorities: NewAuthority[], orgId: UUID) => ({
    type: actions.CREATE_USER,
    payload: { basicInfoUser, authorities, orgId }
  }),

  saveUserSuccess: (user: User) => ({
    type: actions.SAVE_USER_SUCCESS,
    payload: user
  }),

  saveUserFailure: (error: string) => ({
    type: actions.SAVE_USER_FAILURE,
    payload: error
  }),

  createControleCertoUser: (userInfo: ControleCertoUser) => ({
    type: actions.CREATE_CONTROLE_CERTO_USER,
    payload: userInfo
  }),

  saveControleCertoUserSuccess: (user: ControleCertoUser) => ({
    type: actions.SAVE_CONTROLE_CERTO_USER_SUCCESS,
    payload: user
  }),

  editUser: (basicInfoData: BasicInfoUser, authorities: NewAuthority[], userId: UUID) => ({
    type: actions.EDIT_USER,
    payload: { basicInfoData, authorities, userId }
  }),

  editControleCertoUser: (userInfo: ControleCertoUser, companyId) => ({
    type: actions.EDIT_CONTROLE_CERTO_USER,
    payload: {
      userInfo,
      companyId
    }
  }),

  toggleInviteUserByEmailModal: open => ({
    type: actions.TOGGLE_INVITE_USER_BY_EMAIL_MODAL,
    payload: open
  }),

  toggleAddEditUserModal: ({ open, userId, userType }: ToggleAddEditUserModalProps) => ({
    type: actions.TOGGLE_ADD_EDIT_USER_MODAL,
    payload: { open, userId, userType }
  }),

  toggleControleCertoUserModal: (open, userId?) => ({
    type: actions.TOGGLE_CONTROLE_CERTO_USER_MODAL,
    payload: { open, userId }
  }),

  setSugarcaneOrgPermission: (isActive: boolean) => ({
    type: actions.SET_SUGARCANE_ORG_PERMISSION,
    payload: isActive
  }),

  setIsLoadingProvidersCompanies: (isLoading: boolean) => ({
    type: actions.SET_IS_LOADING_PROVIDERS_COMPANIES,
    payload: isLoading
  }),

  removeSubscriptionUserByCompany: (userId: UUID, companyId: UUID) => ({
    type: actions.REMOVE_SUBSCRIPTION_USER_BY_COMPANY,
    payload: { userId, companyId }
  }),

  setLastOrgIdLoadUsers: (orgId: UUID) => ({
    type: actions.SET_LAST_ORG_ID_LOAD_USERS,
    payload: orgId
  }),

  loadRoles: () => ({
    type: actions.LOAD_ROLES
  }),

  loadRolesSuccess: (roles: Roles[]) => ({
    type: actions.LOAD_ROLES_SUCCESS,
    payload: roles
  }),

  loadRolesFailure: (error: string) => ({
    type: actions.LOAD_ROLES_FAILURE,
    payload: error
  }),

  loadOrgUserByEmail: (email: string) => ({
    type: actions.LOAD_USER_BY_EMAIL,
    payload: email
  }),

  loadOrgUserByEmailV2: (email: string) => ({
    type: actions.LOAD_USER_BY_EMAILV2,
    payload: email
  }),

  loadOrgUserByEmailSuccess: (user: OrgUser | null) => ({
    type: actions.LOAD_USER_BY_EMAIL_SUCCESS,
    payload: user
  }),

  loadOrgUserByEmailFailure: (error: string) => ({
    type: actions.LOAD_USER_BY_EMAIL_FAILURE,
    payload: error
  }),

  updateUserPassword: (userId: UUID, password: string) => ({
    type: actions.UPDATE_USER_PASSWORD,
    payload: { userId, password }
  }),

  clearUserPassword: () => ({
    type: actions.CLEAR_USER_PASSWORD
  }),

  updateUserPasswordSuccess: () => ({
    type: actions.UPDATE_USER_PASSWORD_SUCCESS
  }),

  updateUserPasswordFailure: (error: string) => ({
    type: actions.UPDATE_USER_PASSWORD_FAILURE,
    payload: error
  }),
  clearSavedEditedUser: () => ({
    type: actions.CLEAR_SAVED_EDITED_USER
  }),
  updateValueIsSaving: isSavingValue => ({
    type: actions.SET_VALUE_IS_SAVING_STATE,
    payload: isSavingValue
  })
};

export default actions;
export const UserActions = actions;
