import React from 'react';
const CanonicalKeyGlyph = props => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      d='M0.604814 12.9152C0.915464 13.2137 1.406 13.2151 1.71831 12.9183L1.75324 12.8851L2.35632 13.4647C2.66746 13.7637 3.15919 13.7637 3.47032 13.4647C3.79945 13.1484 3.79945 12.6218 3.47032 12.3055L2.91332 11.7702L3.49341 11.2127L4.0965 11.7923C4.40764 12.0913 4.89936 12.0914 5.21051 11.7924C5.53967 11.476 5.53967 10.9494 5.21051 10.6331L4.65349 10.0979L8.29779 6.59505C8.40471 6.98512 8.61054 7.35487 8.92883 7.66087C9.88992 8.58441 11.4481 8.58441 12.4092 7.66087C13.3093 6.79573 13.355 5.43325 12.5692 4.50437C12.8251 4.39064 13.0682 4.24025 13.2793 4.03742C14.2402 3.11377 14.2402 1.61631 13.2793 0.692659C12.3182 -0.230886 10.76 -0.230886 9.79892 0.692659C9.58786 0.895598 9.43149 1.12913 9.31303 1.37518C8.3465 0.619879 6.92879 0.663814 6.02858 1.52885C5.0676 2.4525 5.0676 3.94996 6.02858 4.87361C6.34699 5.1795 6.73172 5.37731 7.13761 5.48006L0.605053 11.7529C0.274893 12.07 0.274784 12.598 0.604814 12.9152ZM10.959 1.80765C11.2794 1.4997 11.7988 1.4997 12.1192 1.80765C12.4395 2.11549 12.4395 2.61468 12.1192 2.92253C11.7988 3.23038 11.2794 3.23038 10.959 2.92253C10.6387 2.61468 10.6387 2.11549 10.959 1.80765ZM10.089 5.4311C10.4093 5.12315 10.9287 5.12315 11.2491 5.4311C11.5694 5.73895 11.5694 6.23803 11.2491 6.54598C10.9288 6.85383 10.4093 6.85383 10.089 6.54598C9.76859 6.23803 9.76859 5.73895 10.089 5.4311ZM7.18877 2.64384C7.5091 2.33588 8.02841 2.33588 8.34885 2.64384C8.66918 2.95168 8.66918 3.45077 8.34885 3.75872C8.02852 4.06657 7.5091 4.06657 7.18877 3.75872C6.86834 3.45077 6.86834 2.95168 7.18877 2.64384Z'
      fill={props.color ? props.color : '#515253'}
    />
  </svg>
);
export default CanonicalKeyGlyph;
