import React from 'react';

const plPOL = () => (
  <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path d='M0 0H16V6H0V0Z' fill='white' />
    <path d='M0 6H16V12H0V6Z' fill='#F71408' />
  </svg>
);

export default plPOL;
