import actions from './actions';
import { initialPage, Page } from '../app/basicModels';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';
import { PhenomenonCategory, PhenomenonCategoryState, PhenomenonCategoryPage } from './models';

const entity: EntityAdapter<PhenomenonCategory> = createEntityAdapter<PhenomenonCategory>();

const initialState: PhenomenonCategoryState = entity.getInitialState({
  page: {
    ...initialPage
  },
  isLoading: false,
  error: null,
  editModalActive: false,
  phenomenonCategorySelect: null,
  isSaving: false,
  phenomenonCategorySelectLoading: false
});

export default (state = initialState, action): PhenomenonCategoryState => {
  switch (action.type) {
    case actions.TOGGLE_PHENOMENON_CATEGORY_EDIT_MODAL:
      return {
        ...state,
        editModalActive: !state.editModalActive
      };

    case actions.LOAD_PHENOMENON_CATEGORIES:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_PHENOMENON_CATEGORIES_SUCCESS:
      return entity.addAll((action.payload as PhenomenonCategoryPage).content, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null
      });

    case actions.LOAD_PHENOMENON_CATEGORIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.SAVE_PHENOMENON_CATEGORY:
      return {
        ...state,
        isSaving: true
      };

    case actions.SAVE_PHENOMENON_CATEGORY_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isSaving: false,
        error: null,
        phenomenonCategorySelect: action.payload
      });

    case actions.SAVE_PHENOMENON_CATEGORY_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case actions.LOAD_PHENOMENON_CATEGORY:
      return {
        ...state,
        phenomenonCategorySelectLoading: true,
        phenomenonCategorySelect: null
      };

    case actions.LOAD_PHENOMENON_CATEGORY_SUCCESS:
      return {
        ...state,
        phenomenonCategorySelectLoading: false,
        phenomenonCategorySelect: action.payload
      };

    case actions.LOAD_PHENOMENON_CATEGORY_FAILURE:
      return {
        ...state,
        phenomenonCategorySelectLoading: false,
        error: action.payload
      };

    case actions.DELETE_PHENOMENON_CATEGORY_SUCCESS:
      return entity.removeOne(action.payload.id, {
        ...state,
        error: null
      });

    case actions.DELETE_PHENOMENON_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case actions.CLEAR_PHENOMENON_CATEGORY_SELECT:
      return {
        ...state,
        phenomenonCategorySelect: null
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectPhenomenonCategoryIds = selectIds;
export const selectPhenomenonCategoryEntities = selectEntities;
export const selectAllPhenomenonCategories = selectAll;
export const selectPhenomenonCategoryTotal = selectTotal;
