import React from 'react';

const huHU = () => (
  <svg width='18' height='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M2 14.332H22V18.9987H2V14.332Z' fill='#008751' />
    <path d='M2 5H22V9.66667H2V5Z' fill='#CE1126' />
    <path d='M2 9.66797H22V14.3346H2V9.66797Z' fill='white' />
  </svg>
);

export default huHU;
