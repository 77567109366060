import styled from 'styled-components';
import { Collapse, Row } from 'antd';

const { Panel } = Collapse;

const BoxListWrapper = styled.div`
  .container {
    height: 262px;
    overflow: auto;
    position: relative;
    padding: 6px;
    border-radius: 4px;
  }
  .isoSearchBar {
    margin-bottom: 5px;
  }
  .ant-list-item {
    cursor: pointer;
  }
`;

export const PhenomenonContainer = styled(Row)`
  height: 92px;
`;

export default BoxListWrapper;
