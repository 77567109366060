import React from 'react';

export interface FixedPointCategoriesProps {
  readonly color?: string;
}

const FixedPointCategoriesIcon = ({ color = '#707374', width = '24', height = '24' }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.7075 2.27958C14.0794 1.90681 14.6824 1.90681 15.0544 2.27958L21.721 8.9614C22.0184 9.25947 22.0855 9.71776 21.8861 10.0889L15.2195 22.498C14.8599 23.1673 13.902 23.1673 13.5424 22.498L6.87573 10.0889C6.67634 9.71776 6.74345 9.25947 7.04084 8.9614L13.7075 2.27958ZM14.3809 4.30469L8.88873 9.80935L14.3809 20.0323L19.8731 9.80935L14.3809 4.30469ZM8.38773 3.23417C8.75966 3.60694 8.75966 4.21133 8.38773 4.5841L4.11939 8.86215L9.98783 20.1733C10.2305 20.641 10.0489 21.2174 9.58225 21.4606C9.11559 21.7038 8.54056 21.5218 8.2979 21.0541L2.10744 9.12224C1.91574 8.75276 1.98517 8.30137 2.27897 8.0069L7.04086 3.23417C7.41279 2.8614 8.0158 2.8614 8.38773 3.23417Z'
      fill={color}
    />
  </svg>
);

export default FixedPointCategoriesIcon;
