import React from 'react';

const esESP = () => (
  <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path d='M0 0H16V12H0V0Z' fill='#C60B1E' />
    <path d='M0 3.19995H16V8.79995H0V3.19995Z' fill='#FFC400' />
  </svg>
);

export default esESP;
