import React from 'react';
import EnUsaFlag from '../../assets/flags/en-USA';
import PtBraFlag from '../../assets/flags/pt-BRA';
import EsEspFlag from '../../assets/flags/es-ESP';
import EsARFlag from '../../assets/flags/es-AR';
import EnGBRFlag from '../../assets/flags/en-GBR';
import DeDEUFlag from '../../assets/flags/de-DEU';
import PlPOLFlag from '../../assets/flags/pl-POL';
import FrFRAFlag from '../../assets/flags/fr-FRA';
import HuHUFlag from '../../assets/flags/hu-HU';
import ItITAFlag from '../../assets/flags/it-ITA';

const flagNameStyle = {
  display: 'flex',
  alignItems: 'center'
};

const handleFlagName = (flag, name) => (
  <span style={flagNameStyle}>
    {flag}&nbsp;&nbsp;{name}
  </span>
);

// eslint-disable-next-line import/prefer-default-export
export const languagesContent = {
  'en-US': {
    name: '(en-USA)',
    flag: <EnUsaFlag />,
    flagName: handleFlagName(<EnUsaFlag />, '(en-USA)'),
    fullName: '(en-USA) English',
    fullFlagName: handleFlagName(<EnUsaFlag />, '(en-USA) English')
  },
  'pt-BR': {
    name: '(pt-BRA)',
    flag: <PtBraFlag />,
    flagName: handleFlagName(<PtBraFlag />, '(pt-BRA)'),
    fullName: '(pt-BRA) Portuguese',
    fullFlagName: handleFlagName(<PtBraFlag />, '(pt-BRA) Portuguese')
  },
  'es-ES': {
    name: '(es-ESP)',
    flag: <EsEspFlag />,
    flagName: handleFlagName(<EsEspFlag />, '(es-ESP)'),
    fullName: '(es-ESP) Spanish',
    fullFlagName: handleFlagName(<EsEspFlag />, '(es-ESP) Spanish')
  },
  'es-AR': {
    name: '(es-AR)',
    flag: <EsARFlag />,
    flagName: handleFlagName(<EsARFlag />, '(es-AR)'),
    fullName: '(es-AR) Spanish',
    fullFlagName: handleFlagName(<EsARFlag />, '(es-AR) Spanish')
  },
  'en-GB': {
    name: '(en-GBR)',
    flag: <EnGBRFlag />,
    flagName: handleFlagName(<EnGBRFlag />, '(en-GBR)'),
    fullName: '(en-GBR) English',
    fullFlagName: handleFlagName(<EnGBRFlag />, '(en-GBR) English')
  },
  'de-DE': {
    name: '(de-DEU)',
    flag: <DeDEUFlag />,
    flagName: handleFlagName(<DeDEUFlag />, '(de-DEU)'),
    fullName: '(de-DEU) Deutsch',
    fullFlagName: handleFlagName(<DeDEUFlag />, '(de-DEU) Deutsch')
  },
  'pl-PL': {
    name: '(pl-POL)',
    flag: <PlPOLFlag />,
    flagName: handleFlagName(<PlPOLFlag />, '(pl-POL)'),
    fullName: '(pl-POL) Polskie',
    fullFlagName: handleFlagName(<PlPOLFlag />, '(pl-POL) Polskie')
  },
  'fr-FR': {
    name: '(fr-FRA)',
    flag: <FrFRAFlag />,
    flagName: handleFlagName(<FrFRAFlag />, '(fr-FRA)'),
    fullName: '(fr-FRA) François',
    fullFlagName: handleFlagName(<FrFRAFlag />, '(fr-FRA) François')
  },
  'hu-HU': {
    name: '(hu-HU)',
    flag: <HuHUFlag />,
    flagName: handleFlagName(<HuHUFlag />, '(hu-HU)'),
    fullName: '(hu-HU) Hungarian',
    fullFlagName: handleFlagName(<HuHUFlag />, '(hu-HU) Hungarian')
  },
  'it-IT': {
    name: '(it-ITA)',
    flag: <ItITAFlag />,
    flagName: handleFlagName(<ItITAFlag />, '(it-ITA)'),
    fullName: '(it-ITA) Italian',
    fullFlagName: handleFlagName(<ItITAFlag />, '(it-ITA) Italian')
  }
};
