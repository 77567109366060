import { UUID } from '../../redux/models';

export function getPathEdit(
  companyId: UUID,
  phenomenonId: UUID,
  instanceId: UUID | undefined,
  methodologyId: UUID,
  orderedId: UUID,
  isIndicator: boolean
) {
  if (!instanceId) throw new Error('CharacteristicId or IndicatorId is undefined');

  const pathEdit = '/' + companyId + getPathCanonicalEdit(phenomenonId, instanceId, methodologyId, orderedId, isIndicator);

  return pathEdit;
}

export function getPathCanonicalEdit(
  phenomenonId: UUID,
  instanceId: UUID | undefined,
  methodologyId: UUID,
  orderedId: UUID,
  isIndicator: boolean
) {
  if (!instanceId) throw new Error('CharacteristicId or IndicatorId is undefined');
  const generalPath = !isIndicator ? '/characteristic-edit/' : '/indicator-edit/';

  const pathEdit =
    generalPath + instanceId + '/by-phenomenon/' + phenomenonId + '?methodologyId=' + methodologyId + '&orderedComponentId=' + orderedId;

  return pathEdit;
}
