import React from 'react';
import { Controller } from 'react-hook-form';
import { Radio } from 'antd';
import { Option } from '../../models';

interface SelectItemProps {
  name: string;
  initialValue?: string;
  disabled?: boolean;
  options?: ReadonlyArray<Option>;
  control: any;
}

const RadioItem = ({ name, initialValue, disabled, options, control }: SelectItemProps) => (
  <>
    <Controller
      data-testid={`input-radio-${name}`}
      name={name}
      disabled={disabled}
      control={control}
      defaultValue={initialValue}
      as={({ onChange, value }) => (
        <Radio.Group value={value} onChange={e => onChange(e.target.value)}>
          {options &&
            [...options].map(object => {
              return (
                <Radio value={object.id} key={object.id} data-testid={`input-radio-${object.id}`}>
                  {object.name}
                </Radio>
              );
            })}
        </Radio.Group>
      )}
    />
  </>
);

export default RadioItem;
