import React, { FC } from 'react';
import { Company, UUID } from '../../../../redux/models';
import { useDispatch, useSelector } from 'react-redux';
import { DroppableHeader } from '../../../../components/nestedDraggable/droppableHeader/droppableHeader';
import { useHistory } from 'react-router-dom';
import { HeaderColor } from '../../../../components/nestedDraggable/nestedDraggable.models';
import { Actions } from '../../../../redux/actions';
import { ActionButtonType } from '../../../../components/actionButtons/models';
import { AppState } from '../../../../redux/redux.model';
import { MethodologyDeep } from '../../../../redux/methodologyDeep/models';

const { toggleAddCharacteristicIndicatorModal, toggleCloneCharacteristicIndicatorDrawer, deletePhenomenonFromMethodologyDeep } = Actions;

interface PhenomenonHeaderProps {
  id: UUID;
  phenomenonId: UUID;
  name: string;
  scientificName: string;
  isCanonical?: boolean;
}
const { DETAILS, EDIT } = ActionButtonType;
const ADD_BUTTON_TEXT = 'Add Characteristics/Indicators';
const CLONE_BUTTON_TEXT = 'Clone';

export const PhenomenonHeader: FC<PhenomenonHeaderProps> = ({ id, phenomenonId, name, scientificName, isCanonical }) => {
  const history = useHistory();

  const methodologyId = useSelector((state: AppState) => (state.MethodologyDeep.methodologyDeep as MethodologyDeep).id);
  const companyId = useSelector((state: AppState) => ((state.MethodologyDeep.methodologyDeep as MethodologyDeep).company as Company).id);
  const canEditCustomPhenomenon = useSelector((state: AppState) => state.App.accessFlags.canEditCustomPhenomenon);
  const flags = useSelector((state: AppState) => state.App.systemFlags);

  const onEditPhenomenon = () => {
    history.push(`/phenomenon-edit/${phenomenonId}/by-company/${companyId}?methodologyId=${methodologyId}&orderedPhenomenonId=${id}`);
  };

  const dispatch = useDispatch();

  const onDeletePhenomenon = () => {
    dispatch(deletePhenomenonFromMethodologyDeep(id));
  };

  const onAddCharacteristicIndicator = () => {
    dispatch(toggleAddCharacteristicIndicatorModal({ id, phenomenonId }));
  };

  const onCloneCharacteristicIndicator = () => {
    dispatch(toggleCloneCharacteristicIndicatorDrawer({ id, phenomenonId }));
  };

  return (
    <DroppableHeader
      id={id}
      title={`${name}${` (${scientificName || ''})`}`}
      color={HeaderColor.GREEN}
      collapseAll
      onEdit={onEditPhenomenon}
      onDelete={onDeletePhenomenon}
      onAdd={onAddCharacteristicIndicator}
      onClone={onCloneCharacteristicIndicator}
      addButtonText={ADD_BUTTON_TEXT}
      cloneButtonText={CLONE_BUTTON_TEXT}
      showAddButton={canEditCustomPhenomenon}
      showCloneButton={canEditCustomPhenomenon && flags.P40_39706_CLONE_CHARACTERISTICS_INDICATORS}
      dataTestId='button-add-characteristic-and-indicator'
      showCollapseAll={false}
      isCanonical={isCanonical}
      buttonDroppableType={canEditCustomPhenomenon ? EDIT : DETAILS}
    />
  );
};

export default PhenomenonHeader;
