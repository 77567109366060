import React from 'react';

const ptBRA = () => (
  <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M0 0H16V11.871H0V0Z' fill='#229E45' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.03487 10.7828L15.5724 5.94287L7.98987 1.08813L0.427368 5.95276L8.03487 10.7828Z'
      fill='#F8E509'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.3201 5.93545C11.3201 7.67405 9.89258 9.08373 8.13008 9.08373C7.50025 9.08275 6.88485 8.89707 6.36169 8.55015C5.83852 8.20324 5.43107 7.71067 5.19085 7.13472C4.95062 6.55877 4.88841 5.92529 5.01207 5.31436C5.13573 4.70343 5.43971 4.14249 5.88559 3.70244C6.33147 3.26239 6.89923 2.963 7.51708 2.84211C8.13494 2.72121 8.77516 2.78425 9.35681 3.02325C9.93845 3.26224 10.4354 3.66647 10.7849 4.18483C11.1343 4.70318 11.3206 5.31239 11.3201 5.93545Z'
      fill='#2B49A3'
    />
  </svg>
);

export default ptBRA;
