import actions from './actions';
import { MethodologyTemplateState } from './models';

const initialState: MethodologyTemplateState = {
  isLoading: false,
  isSaving: false,
  error: null,
  methodologyPlans: [],
  addTemplateModalActive: false,
  templatePlansModalActive: false,
  selected: {},
  cloneModal: false,
  cloneMethodology: undefined
}

export default (state = initialState, action): MethodologyTemplateState => {
  switch (action.type) {
    case actions.LOAD_METHODOLOGY_PLANS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.LOAD_METHODOLOGY_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        methodologyPlans: action.payload
      };

    case actions.LOAD_METHODOLOGY_PLANS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.SAVE_METHODOLOGY_PLANS_FAILURE:
    case actions.UPDATE_METHODOLOGY_PLANS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        error: action.payload
      };

    case actions.DELETE_METHODOLOGY_PLANS_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case actions.UPDATE_METHODOLOGY_PLANS:
    return {
      ...state,
      isSaving: true,
    };

    case actions.UPDATE_METHODOLOGY_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        templatePlansModalActive: false,
        methodologyPlans: [...state.methodologyPlans
          .filter(methodologyPlan => methodologyPlan.methodology_id !== action.payload.methodology_id), action.payload],
        selected: action.payload,
        isSaving: false,
      };

    case actions.SAVE_METHODOLOGY_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addTemplateModalActive: false,
        templatePlansModalActive: false,
        methodologyPlans: [...state.methodologyPlans, action.payload],
      };

    case actions.TOGGLE_ADD_TEMPLATE_MODAL:
      return {
        ...state,
        cloneModal: action.payload.cloneModal,
        cloneMethodology: action.payload.cloneMethodology,
        addTemplateModalActive: !state.addTemplateModalActive,
      };

    case actions.TOGGLE_TEMPLATE_PLANS_MODAL:
      return {
        ...state,
        ...(action.payload.templatePlans && ({ selected: action.payload.templatePlans })),
        templatePlansModalActive: !state.templatePlansModalActive,
      };

    case actions.SAVE_METHODOLOGY_PLANS:
    case actions.DELETE_METHODOLOGY_PLANS:
    case actions.DELETE_METHODOLOGY_PLANS_SUCCESS:
    default:
      return state;
  }
};
