import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { notification } from '../../components';
import { store } from '../../redux/store';
import { Action, UUID } from '../app/basicModels';
import actions from './actions';
import actionsModalUser from '../user/actions';
import { getPropertiesByCompany, getPropertyData } from './service';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';
import { AppState } from '../redux.model';
import { Property } from './models';

export const handleLoadPropertyInfo = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_PROPERTY_INFO),
    map((action: Action<UUID>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: AppState) => {
          return state.Property.entities;
        })
      )
    ),
    concatMap(([propertyId, entities]) => {
      const properties: Map<string, Property> = new Map(Object.entries(entities));
      const property = properties.get(propertyId);
      if (property) {
        return of(actions.loadPropertyInfoSuccess(property));
      }

      return getPropertyData(propertyId).pipe(
        map(response => {
          return actions.loadPropertyInfoSuccess(response);
        }),
        catchError(error => {
          notification('error', 'Error Loading Property Info!');
          return of(actions.loadPropertyInfoFailure(error));
        })
      );
    })
  );

export const handleLoadPropertiesByCompany = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PROPERTIES_BY_COMPANY),
    map((action: Action<object>) => action.payload),
    concatMap((id: UUID) =>
      getPropertiesByCompany(id).pipe(
        map((properties: any) => {
          return actions.loadPropertiesByCompanySuccess(properties);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading properties by company');
          return of(actions.loadPropertiesByCompanyFailure(errorMsg));
        })
      )
    )
  );
