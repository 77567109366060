/* eslint-disable */
/* @ts-ignore */
import _, { startCase, toLower } from 'lodash';
import { ControlledAction, Role } from '../redux/models';
import { getValueLanguage } from '../redux/language/utils';
import { ExpressionTypes, IndicatorDiagnostic } from '../redux/indicator/models';
import { InputDefinition, InputDefinitionType } from '../redux/characteristic/models';
import { notification } from '../components';
import { ComponentDeep, IndicatorDeep } from '../redux/methodologyDeep/models';
import { ParsedComponentDeep } from './utility.models';

export const setCookie = (key, value) => {
  const newCookie = `${key}=${encodeURIComponent(JSON.stringify(value))};domain=${
    window.location.hostname === 'localhost' ? 'localhost' : '.strider.ag'
  };path=/`;
  document.cookie = newCookie;
};

export const deleteCookie = key => {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => {
    return number > 1 ? 's' : '';
  };
  const number = num => (num > 9 ? `${num}` : `0${num}`);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return `${days} day${numberEnding(days)}`;
      }
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const month = months[givenTime.getUTCMonth()];
      const day = number(givenTime.getUTCDate());
      return `${day} ${month}`;
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export const isoDateToDateTime = isoDate => {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`;
  const day = `0${date.getDate()}`;
  const hours = date.getHours();
  const minutes = `0${date.getMinutes()}`;
  return `${day.substr(-2)}/${month.substr(-2)}/${year} - ${hours}:${minutes.substr(-2)}`;
};

export function deepEqual(a, b) {
  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (Object.keys(a).length !== Object.keys(b).length) return false;
    for (const key in a) if (!deepEqual(a[key], b[key])) return false;
    return true;
  }
  return a === b;
}

export function difference(object: ControlledAction[], base): any {
  function changes(object, base) {
    return _.transform(object, function (result: any, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

export function orderListByPriority(list) {
  return list.sort((a, b) => (a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0));
}

export function getLocalData(key) {
  const data = localStorage.getItem(key);

  return data ? JSON.parse(data) : null;
}

export function setLocalData(key, data) {
  return localStorage.setItem(key, JSON.stringify(data));
}

export function compare(a, b) {
  // Use toLowerCase() to ignore character casing
  const nameA = a.name.localized_strings ? getValueLanguage(a.name).toLowerCase() : a.name;
  const nameB = b.name.localized_strings ? getValueLanguage(b.name).toLowerCase() : b.name;

  return nameA.localeCompare(nameB);
}

export function compareByStartValue(a, b) {
  const startA = a.start_value;
  const startB = b.start_value;

  if (startA > startB) {
    return 1;
  }
  return -1;
}

export function getNameWithScientificName(instance) {
  return `${getValueLanguage(instance.name)} (${getValueLanguage(instance.short_description)})`;
}

export const findObjectById = (list, id) => {
  if (list && list.id === id) {
    return list;
  }
  let result;
  let p;
  for (p in list) {
    if (list.hasOwnProperty(p) && typeof list[p] === 'object' && p !== 'header') {
      result = findObjectById(list[p], id);
      if (result) {
        return result;
      }
    }
  }
  return result;
};

export const roleOptions = Object.values(Role).map(role => {
  return { id: role, name: startCase(toLower(role)) };
});

export const sortByProperty = property => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const carachteristicTableType = {
  SWITCH: 'Switch',
  NUMPAD: 'Numerical',
  CATEGORICAL: 'Categorical'
};

const getCharacType = (className: InputDefinitionType | undefined) => {
  switch (className) {
    case InputDefinitionType.SWITCH:
      return carachteristicTableType.SWITCH;
    case InputDefinitionType.NUMPAD:
      return carachteristicTableType.NUMPAD;
    case InputDefinitionType.CATEGORICAL:
      return carachteristicTableType.CATEGORICAL;
    default:
      break;
  }
};

const getCharacMaxValue = (input_definition?: InputDefinition | undefined): string | undefined => {
  if (input_definition?.class_name === InputDefinitionType.SWITCH) {
    return input_definition?.switch_on_value?.toString();
  }
  if (input_definition?.class_name === InputDefinitionType.NUMPAD) {
    return input_definition?.maximum_value?.toString();
  }
  return '';
};

const formatIndicatorType = indType => {
  if (indType === ExpressionTypes.SMDL) {
    return 'SMDL';
  }
  return indType;
};

const getDataInfo = <T, U>(condition: T, callback: () => U) => {
  return condition ? callback() : '---';
};

export const sliceId = (id: string) => {
  return id.slice(0, 8);
};

export const getCharacteristicName = (component: ComponentDeep): string => {
  const { characteristic, indicator } = component;

  if (!characteristic) {
    return '';
  }

  const indicatorName = indicator ? ` / ${getValueLanguage(indicator.name)}` : '';
  const indicatorId = indicator ? ` / ${sliceId(indicator.indicatorId)}` : '';
  const characteristicTitle = `${getValueLanguage(characteristic.name)}${indicatorName} - (${sliceId(
    characteristic.characteristicId
  )}${indicatorId})`;

  return characteristicTitle;
}

export const prepareComponents = (originalComponents: ComponentDeep[]): ParsedComponentDeep[] => {
  const parseComponents = originalComponents
    .filter(c => c.indicator || c.characteristic)
    .map(c => {
      const { characteristic } = c;
      let { indicator } = c;

      if (characteristic) {
        const complementCategory = indicator ? '/Indicator' : '';

        const parsedCharacteristic: ParsedComponentDeep = {
          id: characteristic.characteristicId,
          name: getCharacteristicName(c),
          comesFromCanonical: indicator ? indicator.comesFromCanonical : characteristic.comesFromCanonical,
          category: `Characteristic${complementCategory}`,
          characteristicType: getDataInfo(characteristic.input_definition, () =>
            getCharacType(characteristic?.input_definition?.class_name)
          ),
          maxValue: getDataInfo(characteristic.input_definition, () => getCharacMaxValue(characteristic?.input_definition)),
          unit: getDataInfo(characteristic.input_definition, () => getValueLanguage(characteristic?.input_definition?.localized_unit)),
          indicatorType: getDataInfo(indicator?.expression, () => indicator?.expression?.type),
          diagnostics: getDataInfo(indicator?.diagnostics, () => indicator?.diagnostics)
        };

        return parsedCharacteristic;
      }

      indicator = indicator as IndicatorDeep;
      const parsedIndicator: ParsedComponentDeep = {
        id: indicator.indicatorId,
        name: getValueLanguage(indicator.name),
        comesFromCanonical: indicator.comesFromCanonical,
        category: 'Indicator',
        indicatorType: indicator.expression ? formatIndicatorType(indicator?.expression?.type) : '--',
        diagnostics: indicator.diagnostics ?? '--',
        unit: '--'
      };
      return parsedIndicator;
    });

  return parseComponents;
};

export const someMatch = (ids1, ids2) => {
  return ids1.some(id => ids2.includes(id));
};

export const everyMatch = (ids1, ids2) => {
  return ids1.every(id => ids2.includes(id));
};

export const buildMap = list => {
  const map = {};
  list.forEach(item => {
    map[item.id] = item;
  });
  return map;
};

export const upsertById = (list, item) => {
  if (list) {
    if (list.some(i => i.id === item.id)) {
      return list.map(i => (i.id === item.id ? item : i));
    }
    list.push(item);
    return list;
  }
  return [];
};

export const getNameWithId = (name, id) => {
  return `${getValueLanguage(name)} - (${sliceId(id)}...)`;
};

export const capitalize = word => {
  return `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;
};

export function getEnumKeyByEnumValue(myEnum, enumValue) {
  let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : '';
}

export const copyToClipboard = (text, notificationMessage = 'Copied to the clipboard!') => {
  navigator.clipboard.writeText(text);
  notification('success', notificationMessage);
};
