import axiosObservable from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import CONFIG from '../../settings';
import { UUID } from '../app/basicModels';
import {
  AccountType,
  Authority,
  AuthorityUpdateOperation,
  BasicInfoUser,
  ContextAuthorization,
  ControleCertoUser,
  CreateUserRequestParams,
  EditAuthorityRequestParams,
  EditUserRequestParams,
  InviteUserRequestParams,
  NewAuthority,
  OrgUser,
  Permission,
  Role,
  Subscription,
  User
} from './models';
import _ from 'lodash';
import { PermissionScope } from '../../helpers/userAuth/userAuth';
import { UpdateUserRolesRBACRequestParams, RBACGetUsersByOrgResponse, GetAllRolesByUserResponse } from '../../querys/rbac/rbac.query.model';
import axios, { AxiosResponse } from 'axios';

const { READ, WRITE } = PermissionScope;
const baseUrl = `${CONFIG.apiUrl}:${CONFIG.basePort}`;
const userUrl = `${baseUrl}/v1/users`;
const accountsUrl = `${baseUrl}/v1/accounts`;
export const baseUrlApiV2 = process.env.REACT_APP_BASE_API_URL;
const baseURLMe = process.env.REACT_APP_CROPWISE_API_URL;

const accountsGet = `${baseURLMe}/v2/accounts`;

export const getUserMe = (): AxiosObservable<User> => {
  return axiosObservable.get<User>(`${accountsGet}/me?exclude_attributes=authorities`);
};

export const getUserOrgsList = (org_id): AxiosObservable<any> => {
  return axiosObservable.get<User>(`${baseUrl}/v1/orgs/${org_id}/accounts/`);
};

export const getUsersByOrg = async ({ orgId }): Promise<AxiosResponse<RBACGetUsersByOrgResponse>> => {
  const url = `${baseUrlApiV2}/v2/orgs/${orgId}/accounts?exclude_attributes=authorities`;
  return axios.get<RBACGetUsersByOrgResponse>(url);
};

export const getPendingUsersByOrg = async ({ orgId }): Promise<AxiosResponse<RBACGetUsersByOrgResponse>> => {
  const url = `${baseUrlApiV2}/v2/orgs/${orgId}/invite-status?status_list=PENDING&exclude_attributes=rbac_roles,serialized_authorities`;
  return axios.get<RBACGetUsersByOrgResponse>(url);
};

export const getRolesRBACByUser = (userID): AxiosObservable<GetAllRolesByUserResponse> => {
  return axiosObservable.get(`${baseUrlApiV2}/v2/accounts/${userID}/roles`);
};

export const deleteUser = (id: UUID, orgId: UUID): AxiosObservable<OrgUser> => {
  return axiosObservable.delete<OrgUser>(`${baseUrl}/v1/orgs/${orgId}/accounts/${id}`);
};

export const removeRolesRBACByUser = (userRequestParams: UpdateUserRolesRBACRequestParams, userID: UUID): AxiosObservable<User> => {
  return axiosObservable.post<User>(`${baseUrlApiV2}/v2/accounts/${userID}/roles/updates`, userRequestParams);
};

export const postUser = (userRequestParams: CreateUserRequestParams, orgId: UUID): AxiosObservable<User> => {
  return axiosObservable.post<User>(`${`${baseUrl}/v1/orgs`}/${orgId}/accounts/`, userRequestParams);
};

export const sendAccountInvitation = (user: InviteUserRequestParams, orgId: UUID): AxiosObservable<User> => {
  return axiosObservable.post<User>(`${baseUrl}/v1/invite`, user);
};

export const editUser = (userRequestParams: EditUserRequestParams): AxiosObservable<User> => {
  return axiosObservable.put<User>(`${accountsUrl}/${userRequestParams.id}`, userRequestParams);
};

export const getSubscriptionsByUser = (userId: UUID): AxiosObservable<Subscription[]> => {
  return axiosObservable.get<Subscription[]>(`${baseUrl}/v1/users/${userId}/subscriptions`);
};

export const removeSubscriptionOfCompanyByUser = (userId: UUID, subscriptionId: UUID): AxiosObservable<Subscription> => {
  return axiosObservable.delete<Subscription>(`${baseUrl}/v1/subscriptions/${subscriptionId}/users/${userId}`);
};

export const createUserRequestParams = (basicInfoData: BasicInfoUser, authorities: NewAuthority[]): CreateUserRequestParams => {
  return {
    login: basicInfoData.userName,
    password: basicInfoData.password,
    name: basicInfoData.name,
    type: AccountType.USER,
    role: basicInfoData.role,
    phone: basicInfoData.phone,
    locale: basicInfoData.language,
    country_code: basicInfoData.country_code,
    authorities
  };
};

export const getOrganizationAuthority = (
  orgId: UUID,
  ownershipScopes: PermissionScope[],
  inventoryScopes: PermissionScope[],
  financialScopes: PermissionScope[],
  sugarcaneScopes: PermissionScope[],
  userMethodologyScopes: PermissionScope[],
  readMethodology: boolean
): NewAuthority => {
  if (readMethodology) {
    const authority: NewAuthority = {
      id: orgId,
      context: ContextAuthorization.ORGANIZATION,
      scopes: [READ, WRITE],
      ownership_authority_scopes: ownershipScopes,
      inventory_authority_scopes: inventoryScopes,
      financial_authority_scopes: financialScopes,
      sugarcane_authority_scopes: sugarcaneScopes,
      panel_methodology_authority_scopes: userMethodologyScopes
    };
    return authority;
  }
  const authority: NewAuthority = {
    id: orgId,
    context: ContextAuthorization.ORGANIZATION,
    scopes: [READ, WRITE],
    ownership_authority_scopes: ownershipScopes,
    inventory_authority_scopes: inventoryScopes,
    financial_authority_scopes: financialScopes,
    sugarcane_authority_scopes: sugarcaneScopes
  };
  return authority;
};

export function getAuthorityWritePermissionsByOrganization(authoritiesAvailable: Authority[]) {
  return getAuthorityWritePermissionsByContext(authoritiesAvailable, ContextAuthorization.ORGANIZATION);
}

export function getAuthoritiesReadPermissionsByProperty(authoritiesAvailable: Authority[]) {
  return getAuthorityReadPermissionsByContext(authoritiesAvailable, ContextAuthorization.PROPERTY);
}

export function getAuthoritiesWritePermissionsByProperty(authoritiesAvailable: Authority[]) {
  return getAuthorityWritePermissionsByContext(authoritiesAvailable, ContextAuthorization.PROPERTY);
}

export function getAllAuthorityPermissionsByProperty(authoritiesAvailable: Authority[]) {
  return [
    ...getAuthoritiesReadPermissionsByProperty(authoritiesAvailable),
    ...getAuthoritiesWritePermissionsByProperty(authoritiesAvailable)
  ];
}

const getAuthorityReadPermissionsByContext = (authoritiesAvailable: Authority[], context: ContextAuthorization) => {
  const authority = authoritiesAvailable.find(a => a.context === context);
  return getPermissionsByScope(authority, READ);
};

const getAuthorityWritePermissionsByContext = (authoritiesAvailable: Authority[], context: ContextAuthorization) => {
  const authority = authoritiesAvailable.find(a => a.context === context);
  return getPermissionsByScope(authority, WRITE);
};

const getPermissionsByScope = (authority: Authority | undefined, scope: PermissionScope) => {
  return authority && authority.permissions ? authority.permissions.filter(permission => permission.scope === scope) : [];
};

export const getUserEditRequestParams = (
  basicInfoData: BasicInfoUser,
  authorities: NewAuthority[],
  userId: UUID
): EditUserRequestParams => {
  return {
    name: basicInfoData.name,
    role: basicInfoData.role,
    phone: basicInfoData.phone,
    type: basicInfoData.type,
    locale: basicInfoData.language,
    id: userId,
    default_licensing_account_id: basicInfoData.default_licensing_account_id,
    authorities,
    country_code: basicInfoData.country_code
  };
};

export const buildAllPermissionsFromPermissionsWrite = (permissions: Permission[]) => {
  const permissionsWrite: Permission[] = permissions.filter(p => p.scope === WRITE);
  return [
    ...permissionsWrite,
    ...permissionsWrite.map(p => {
      return {
        ...p,
        scope: READ
      };
    })
  ];
};

export const buildRemoveAuthorityOrganization = (authorities: Authority[], organizationId: UUID) => {
  const authority = authorities.find(a => a.id === organizationId);
  if (!authority) throw new Error('This user has not Authority for this organization!');
  const authoritiesUpdated: EditAuthorityRequestParams = {
    updates: [
      {
        operation: AuthorityUpdateOperation.REMOVE,
        authorities: [authority]
      }
    ]
  };

  return authoritiesUpdated;
};

function getAuthority(availableAuthorities: Authority[], context: ContextAuthorization) {
  return availableAuthorities.find(availableAuthority => availableAuthority.context === context);
}

export const createUsersReadPermissions = (users, availableAuthorities: Authority[]) => {
  const updatedUsers = _.cloneDeep(users);
  users.forEach((user, userIndex) => {
    if (user.authorities) {
      user.authorities.forEach((authority, authorityIndex) => {
        if (authority.permissions) {
          const availableAuthority = getAuthority(availableAuthorities, authority.context);
          authority.permissions.forEach((permission, permissionIndex) => {
            if (
              permission.scope === WRITE &&
              availableAuthority &&
              isPermissionReadAvailable(permission.name, availableAuthority.permissions)
            ) {
              updatedUsers[userIndex].authorities[authorityIndex].permissions.push({
                scope: READ,
                name: permission.name
              });
            }
          });
        }
      });
    }
  });
  return updatedUsers;
};

export const isPermissionReadAvailable = (permissionName: string, availablePermissions: Permission[]) => {
  return availablePermissions.findIndex(permission => permission.name === permissionName && permission.scope === READ) !== -1;
};

export const getApiRoles = (): AxiosObservable<any> => {
  return axiosObservable.get<any>(`${baseUrl}/v1/roles`);
};

export const postControleCertoUser = (userInfo): AxiosObservable<ControleCertoUser> => {
  return axiosObservable.post<ControleCertoUser>(accountsUrl, userInfo);
};

export const putControleCertoUser = (userInfo): AxiosObservable<ControleCertoUser> => {
  return axiosObservable.put<ControleCertoUser>(`${accountsUrl}/${userInfo.id}`, userInfo);
};

export const upsertUserAuthority = (authorities: NewAuthority[], userId: UUID, scopes: PermissionScope[]) => {
  const userAuthority = authorities.find(a => a.id === userId && a.context === ContextAuthorization.USER);
  if (userAuthority) {
    authorities = authorities.map((a: NewAuthority) => (a.id === userId ? { ...a, scopes } : a));
  } else {
    authorities.push({
      id: userId,
      context: ContextAuthorization.USER,
      scopes
    });
  }
  return authorities;
};

export const upsertUserAuthorityByRole = (authorities: NewAuthority[], userId: UUID, isAdmin) => {
  const scopes = isAdmin ? [READ, WRITE] : [READ];
  return upsertUserAuthority(authorities, userId, scopes);
};

export const getOrgUserByEmail = (email: string): AxiosObservable<any> => {
  return axiosObservable.get<any>(`${accountsUrl}?login=${email.trim()}`);
};

export const getOrgUserByEmailV2 = (email: string): AxiosObservable<any> => {
  return axiosObservable.get<any>(`${baseUrlApiV2}/v2/accounts?login=${email}&exclude_attributes=authorities`);
};

export const putUserPassword = (userId: string, password: string): AxiosObservable<any> => {
  return axiosObservable.put<any>(`${userUrl}/${userId}/managed-user/update-password`, { newPassword: password });
};
