import React from 'react';
const TimelineGlyph = props => (
  <svg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='https://www.w3.org/2000/svg' data-testid={'timeline-glyph'}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5163 2.99991C13.3372 2.99115 14 2.32297 14 1.5C14 0.671573 13.3284 0 12.5 0C11.6716 0 11 0.671573 11 1.5C11 1.56197 11.0038 1.62306 11.0111 1.68306L8.98366 4.00009C8.88847 4.0011 8.79541 4.01099 8.70526 4.02895L6.49452 2.37089C6.42905 1.60291 5.78493 1 5 1C4.17157 1 3.5 1.67157 3.5 2.5C3.5 2.56197 3.50376 2.62306 3.51106 2.68306L1.48366 5.00009C0.662758 5.00885 0 5.67703 0 6.5C0 7.32843 0.671573 8 1.5 8C2.32843 8 3 7.32843 3 6.5C3 6.43803 2.99624 6.37694 2.98894 6.31694L5.01635 3.99991C5.11153 3.9989 5.20459 3.98901 5.29474 3.97105L7.50548 5.62911C7.57095 6.39709 8.21507 7 9 7C9.82843 7 10.5 6.32843 10.5 5.5C10.5 5.43803 10.4962 5.37694 10.4889 5.31694L12.5163 2.99991Z'
      fill={props.color}
    />
  </svg>
);
export default TimelineGlyph;
