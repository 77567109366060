import actions from './actions';
import { FixedPointState, FixedPoint } from '../fixedPoint/models';
import { initialPage } from '../app/basicModels';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';

const entity: EntityAdapter<FixedPoint> = createEntityAdapter<FixedPoint>();

export const fixedPointTypes = [
  { id: 'generic', name: 'Generic' },
  { id: 'pluviometer', name: 'Pluviometer' },
  { id: 'trap', name: 'Trap' }
];

export const iconTypes = [
  {
    id: 'DANGER',
    type: 'generic',
    name: 'Danger'
  },
  {
    id: 'AIRPORT',
    type: 'generic',
    name: 'Airport'
  },
  {
    id: 'POLE',
    type: 'generic',
    name: 'Pole'
  },
  {
    id: 'PLUVIOMETER',
    type: 'pluviometer',
    name: 'Pluviometer'
  },
  {
    id: 'LIGHT_TRAP',
    type: 'trap',
    name: 'Light Trap'
  },
  {
    id: 'FLAG',
    type: 'generic',
    name: 'Flag'
  },
  {
    id: 'HQ',
    type: 'generic',
    name: 'Hq'
  },
  {
    id: 'GARAGE',
    type: 'generic',
    name: 'Garage'
  },
  {
    id: 'RENEWABLE_TRAP',
    type: 'trap',
    name: 'Renewable Trap'
  },
  {
    id: 'BUS_STOP',
    type: 'generic',
    name: 'Bus Stop'
  },
  {
    id: 'RESTING_AREA',
    type: 'generic',
    name: 'Resting Area'
  },
  {
    id: 'GAS_STATION',
    type: 'generic',
    name: 'Gas Station'
  },
  {
    id: 'WARNING',
    type: 'generic',
    name: 'Warning'
  },
  {
    id: 'BRIDGE',
    type: 'generic',
    name: 'Bridge'
  },
  {
    id: 'GATE',
    type: 'generic',
    name: 'Gate'
  },
  {
    id: 'HORMONAL_TRAP',
    type: 'trap',
    name: 'Hormonal Trap'
  },
  {
    id: 'WEATHER_STATION',
    type: 'generic',
    name: 'Weather Station'
  },
  {
    id: 'CAMERA',
    type: 'generic',
    name: 'Camera'
  }
];

const initialState: FixedPointState = entity.getInitialState({
  page: {
    ...initialPage
  },
  isLoading: false,
  company_id: null,
  parent_id: null,
  error: null,
  isSaving: false,
  fixedPointSelect: null,
  fixedPointSelectLoading: false,
  fixedPointTypes,
  iconTypes,
  editPhenomenaModalActive: false,
  indicatorsModalVisible: false,
  phenomenonsOfFixedPoint: [],
  characteristicsOfFixePoint: [],
  isLoadingPhenomenonsForFixedPoint: false,
  isLoadingCharacteristicsOfFixedPoint: false,
  isDrafting: false,
  phenomenonInfo: null,
  addCharacteristicIndicatorActive: false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_PLUVIOMETERS:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_PLUVIOMETERS_SUCCESS:
      return entity.addAll(action.payload as FixedPoint[], {
        ...state,
        page: {
          number: 0,
          size: 0,
          pages: 0,
          total: 0,
          content: [...action.payload]
        },
        isLoading: false,
        error: null
      });

    case actions.LOAD_PLUVIOMETERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.LOAD_PLUVIOMETER:
      return {
        ...state,
        fixedPointSelectLoading: true,
        fixedPointSelect: null
      };

    case actions.SAVE_PLUVIOMETER:
      return {
        ...state,
        isSaving: true
      };

    case actions.SAVE_PLUVIOMETER_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isSaving: false,
        error: null,
        fixedPointSelect: action.payload
      });

    case actions.SAVE_PLUVIOMETER_FAILURE:
      return {
        ...state,
        isSaving: false,
        isDrafting: false,
        error: action.payload
      };

    case actions.SELECT_FIXEDPOINT_PLUVIOMETER:
      return {
        ...state,
        fixedPointSelect: action.payload,
        isDrafting: false
      };

    case actions.CLEAR_FIXED_POINT_SELECT_PLUVIOMETER:
      return {
        ...state,
        fixedPointSelect: null,
        phenomenonsOfFixedPoint: []
      };

    case actions.UPDATE_PLUVIOMETER:
      return {
        ...state,
        isDrafting: true
      };

    case actions.LOAD_PLUVIOMETER_SUCCESS:
      return {
        ...state,
        fixedPointSelectLoading: false,
        fixedPointSelect: action.payload
      };

    case actions.LOAD_PLUVIOMETER_FAILURE:
      return {
        ...state,
        fixedPointSelectLoading: false,
        error: action.payload
      };

    case actions.DELETE_PLUVIOMETER_SUCCESS:
      return entity.removeOne(action.payload.id, {
        ...state,
        error: null
      });

    case actions.DELETE_PLUVIOMETER_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case actions.TOGGLE_FIXED_POINT_PLUVIOMETER_PHENOMENA_MODAL:
      return {
        ...state,
        editPhenomenaModalActive: !state.editPhenomenaModalActive
      };

    case actions.TOGGLE_CHARACTERISTICS_INDICATORS_PHENOMENA_PLUVIOMETER_MODAL:
      return {
        ...state,
        addCharacteristicIndicatorActive: !state.addCharacteristicIndicatorActive,
        phenomenonInfo: action.payload
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectPluviometersIds = selectIds;
export const selectPluviometersEntities = selectEntities;
export const selectAllPluviometer = selectAll;
export const selectPluviometersTotal = selectTotal;
export const selectSelectedPluviometer = state => state.fixedPointSelect;
