import React from 'react';

export interface CanonicListsProps {
  readonly color?: string;
}

const CanonicListsIcon = ({ color = '#707374' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      d='M22.3257 14.3V13.2C22.9559 13.1969 23.4005 12.6735 23.4257 12.1C23.4524 11.4907 22.9239 10.9998 22.2544 11H9.00005V13.2H16.8257V14.3C16.799 14.9077 17.2579 15.3999 17.9257 15.4C18.5569 15.4001 19.0005 14.8744 19.0257 14.3V13.2H20.1257V14.3C20.099 14.9076 20.5579 15.3999 21.2256 15.4C21.8568 15.4001 22.3005 14.8744 22.3257 14.3Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.9541 13.0015V10.9993L10.6764 11.0003C10.9531 10.5584 11.1285 10.0493 11.1506 9.5005C11.2169 7.8437 9.92083 6.50036 8.25568 6.5C6.69602 6.49984 5.38081 7.68228 5.1638 9.19285C4.84413 9.07421 4.50114 8.99949 4.13535 8.99934C2.4702 8.99917 1.06649 10.3421 0.999994 11.9989C0.933686 13.6557 2.22974 14.999 3.89489 14.9994C4.26058 14.9994 4.60974 14.9248 4.93874 14.8063C5.03477 16.3168 6.25518 17.4997 7.81483 17.5C9.47998 17.5002 10.8837 16.1573 10.9502 14.5005C10.9721 13.9516 10.8375 13.4426 10.5962 13.0005L14.9541 13.0015ZM3.0101 11.9993C3.03224 11.4469 3.50009 10.9993 4.05529 10.9994C4.61031 10.9995 5.04236 11.4473 5.02022 11.9995C4.998 12.5518 4.53015 12.9994 3.97504 12.9994C3.42002 12.9993 2.98797 12.5515 3.0101 11.9993ZM6.93005 14.4999C6.95219 13.9475 7.42003 13.4999 7.97514 13.5001C8.53025 13.5001 8.96221 13.9478 8.94007 14.5002C8.91794 15.0525 8.45001 15.5001 7.89499 15.5001C7.33988 15.4999 6.90792 15.0522 6.93005 14.4999ZM7.13044 9.49995C7.15258 8.94753 7.62041 8.49995 8.17552 8.50013C8.73064 8.50013 9.1626 8.94785 9.14046 9.50027C9.11833 10.0525 8.65039 10.5002 8.09537 10.5001C7.54026 10.4999 7.1083 10.0522 7.13044 9.49995Z'
      fill={color}
    />
  </svg>
);

export default CanonicListsIcon;
