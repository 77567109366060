import { PageableQuery, Action, UUID } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map, concatMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import actions from './actions';
import actionsTraps from '../traps/actions';
import actionsGeneric from '../generic/actions';
import actionsPluviometer from '../pluviometer/actions';
import { getFixedPoint, getFixedPoints, saveFixedPoint, deleteFixedPoint, getAllPluviometers } from './service';
import { FixedPointPage, FixedPoint, UpdateFixedPointPayload, LoadFixedPointModel, FixedPointType } from './models';
import { notification } from '../../components';
import { injectMetadata } from '../request-control';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';
import { getInstalledStaticPointsByCompany, getStaticPointsByCompany } from '../company/service';
import { TypesOfTraps } from '../../containers/FixedPointCategories/BasicInfo/basicInfoForm';
import { AxiosResponse } from 'axios';

const getActionSaveSuccessByFixedPointType = (className: string, actionMetadata: any, response: AxiosResponse<FixedPoint>) => {
  if (className === FixedPointType.TRAP) {
    return injectMetadata(actionsTraps.saveTrapSuccess, { ...actionMetadata, response });
  }

  if (className === FixedPointType.PLUVIOMETER) {
    return injectMetadata(actionsPluviometer.savePluviometerSuccess, { ...actionMetadata, response });
  }

  return injectMetadata(actionsGeneric.saveGenericSuccess, { ...actionMetadata, response });
};

const getActionSaveFailureByFixedPointType = (className: string, actionMetadata: any, error: any) => {
  if (className === FixedPointType.TRAP) {
    return injectMetadata(actionsTraps.saveTrapFailure, {
      ...actionMetadata,
      response: error.message
    });
  }

  if (className === FixedPointType.PLUVIOMETER) {
    return injectMetadata(actionsPluviometer.savePluviomenterFailure, {
      ...actionMetadata,
      response: error.message
    });
  }

  return injectMetadata(actionsGeneric.saveGenericFailure, {
    ...actionMetadata,
    response: error.message
  });
};

const getActionDeleteSuccessByFixedPointType = (className: string, fixedPointPayload: any) => {
  if (className === FixedPointType.TRAP) {
    return actionsTraps.deleteFixedPointTrapSuccess(fixedPointPayload);
  }

  if (className === FixedPointType.PLUVIOMETER) {
    return actionsPluviometer.deleteFixedPointPluviometerSuccess(fixedPointPayload);
  }

  return actionsGeneric.deleteFixedPointGenericSuccess(fixedPointPayload);
};

const getActionDeleteFailureByFixedPointType = (className: string, message: string) => {
  if (className === FixedPointType.TRAP) {
    return actionsTraps.deleteFixedPointTrapFailure(message);
  }

  if (className === FixedPointType.PLUVIOMETER) {
    return actionsPluviometer.deleteFixedPointPluviomenterFailure(message);
  }

  return actionsGeneric.deleteFixedPointGenericFailure(message);
};

export const handleLoadFixedPoints = action$ =>
  action$.pipe(
    ofType(actions.LOAD_FIXEDPOINTS),
    map((action: Action<PageableQuery>) => action.payload),
    concatMap((payload: LoadFixedPointModel) =>
      getFixedPoints(payload.pageableQuery, payload.onlyGeneric).pipe(
        map((fixedPoints: FixedPoint[]) => actions.loadFixedPointsSuccess(fixedPoints)),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading fixed points!');
          return of(actions.loadFixedPointsFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadPluviometers = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PLUVIOMETERS),
    map((action: Action<PageableQuery>) => action.payload),
    concatMap((query: PageableQuery) =>
      getAllPluviometers(query).pipe(
        map((fixedPoints: FixedPoint[]) => actions.loadFixedPointsSuccess(fixedPoints)),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading fixed points!');
          return of(actions.loadFixedPointsFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadFixedPointsByCompany = action$ =>
  action$.pipe(
    ofType(actions.LOAD_FIXEDPOINTS_BY_COMPANY),
    map((action: Action<PageableQuery>) => action.payload),
    concatMap((payload: { pageableQuery: PageableQuery; companyId: UUID }) =>
      getStaticPointsByCompany(payload.companyId, payload.pageableQuery).pipe(
        map(response => response.data),
        map((fixedPoints: FixedPointPage) => actions.loadFixedPointsSuccess(fixedPoints.content)),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading fixed points!');
          return of(actions.loadFixedPointsFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadInstalledFixedPointsByCompany = action$ =>
  action$.pipe(
    ofType(actions.LOAD_INSTALLED_FIXEDPOINTS_BY_COMPANY),
    map((action: Action<PageableQuery>) => action.payload),
    concatMap((payload: { pageableQuery: PageableQuery; companyId: UUID }) =>
      getInstalledStaticPointsByCompany(payload.companyId, payload.pageableQuery).pipe(
        map(response => response.data),
        map((fixedPoints: FixedPointPage) => actions.loadInstalledFixedPointsByCompanySuccess(fixedPoints)),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading fixed points!');
          return of(actions.loadFixedPointsFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadFixedPointByCompany = action$ =>
  action$.pipe(
    ofType(actions.LOAD_FIXEDPOINT_BY_COMPANY),
    map((action: Action<UUID>) => action.payload),
    concatMap((fixedPointId: UUID) =>
      getFixedPoint(fixedPointId).pipe(
        map(response => response.data),
        map((fixedPoint: any) => {
          let fixedPointFilter = fixedPoint;
          if (fixedPoint.single_use) {
            fixedPointFilter = { ...fixedPoint, sampling_behaviour: TypesOfTraps.SINGLE_USE_ID };
          }
          return actions.loadFixedPointSuccess(fixedPointFilter);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading fixed point!');
          return of(actions.loadFixedPointFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadPhenomenonsOfFixedPoint = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PHENOMENONS_OF_FIXED_POINT),
    map((action: Action<any>) => action.payload),
    map((fixedPoint: FixedPoint) => {
      const phenomenonsOfFixedPoint: any[] = [];
      fixedPoint.inspection_layout_dto &&
        fixedPoint.inspection_layout_dto.ordered_inspection_groups.forEach(orderedInspectionGroup => {
          orderedInspectionGroup.ordered_categories.forEach(orderedCategorie => {
            orderedCategorie.ordered_phenomenons.forEach(orderedPhenomenon => {
              if (!phenomenonsOfFixedPoint.includes(orderedPhenomenon.component_id)) {
                phenomenonsOfFixedPoint.push(orderedPhenomenon.component_id);
              }
            });
          });
        });

      if (fixedPoint.remove_phenomena && fixedPoint.remove_phenomena.length > 0) {
        fixedPoint.remove_phenomena.forEach(element => {
          if (phenomenonsOfFixedPoint.includes(element)) {
            phenomenonsOfFixedPoint.splice(phenomenonsOfFixedPoint.indexOf(element), 1);
          }
        });
      }

      if (fixedPoint.phenomena_to_add && fixedPoint.phenomena_to_add.length > 0) {
        fixedPoint.phenomena_to_add.forEach(element => {
          if (!phenomenonsOfFixedPoint.includes(element)) {
            phenomenonsOfFixedPoint.push(element);
          }
        });
      }
      return actions.loadPhenomenonsOfFixedPointSuccess(phenomenonsOfFixedPoint);
    }),
    catchError((error: ErrorMsg) => {
      const errorMsg = notifyErrorOrFallback(error, 'Error loading phenomenons of fixed point!');
      return of(actions.loadPhenomenonsOfFixedPointError(errorMsg));
    })
  );

export const handleSaveCharacteristicsOfFixedPoint = action$ =>
  action$.pipe(
    ofType(actions.LOAD_CHARACTERISTICS_OF_FIXED_POINT),
    map((action: Action<any>) => action.payload),
    map((characteristicsOfFixedPoint: any[]) => {
      return actions.saveCharacteristicsOfFixedPointSuccess(characteristicsOfFixedPoint);
    }),
    catchError((error: ErrorMsg) => {
      const errorMsg = notifyErrorOrFallback(error, 'Error loading characteristics of fixed point!');
      return of(actions.saveCharacteristicsOfFixedPointFailure(errorMsg));
    })
  );

export const handleSaveFixedPoint = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_FIXEDPOINT),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      )
    ),
    concatMap(([action, currentUser]: any) => {
      let fixedPoint = action.payload;
      const fixedPointToSave = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          ...fixedPoint,
          cohese: true,
          inspection_layout: {
            ...fixedPoint.inspection_layout_dto
          }
        }
      };
      fixedPoint = fixedPointToSave;
      return saveFixedPoint(fixedPoint, fixedPoint.payload.isNew).pipe(
        map(response => {
          fixedPoint.payload.isNew = false;

          const saveFixedPointSuccess = getActionSaveSuccessByFixedPointType(fixedPoint.payload.class_name, action.metadata, response);

          notification('success', 'Fixed Point saved!');
          return saveFixedPointSuccess(fixedPoint.payload);
        }),
        catchError((error: any) => {
          let msg;
          let saveFixedPointFailure;
          if (error.message.length > 0) {
            saveFixedPointFailure = getActionSaveFailureByFixedPointType(fixedPoint.payload.class_name, action.metadata, error);

            msg = error.message;
            notification('error', error.message, error.status);
          } else {
            msg = error.response && error.response.data ? error.response.data.message : '';
            notification('error', msg || 'Error saving fixed point!');

            saveFixedPointFailure = getActionSaveFailureByFixedPointType(fixedPoint.payload.class_name, action.metadata, error);
          }
          return of(saveFixedPointFailure(msg));
        })
      );
    })
  );

export const handleUpdateFixedPoint = action$ =>
  action$.pipe(
    ofType(actions.UPDATE_FIXEDPOINT),
    map((action: Action<UpdateFixedPointPayload>) => action.payload),
    map((payload: any) => {
      if (payload.save) {
        return actions.saveFixedPoint(payload.fixedPoint);
      }
      return actions.selectFixedPoint(payload.fixedPoint);
    })
  );

export const handleVerifyModals = (action$, state$) =>
  action$.pipe(
    ofType(actions.SELECT_FIXEDPOINT, actions.SAVE_FIXEDPOINT_SUCCESS),
    map((action: Action<FixedPoint>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.FixedPointByCompany.editPhenomenaModalActive;
        })
      ),
      state$.pipe(
        map((state: any) => {
          return state.FixedPointByCompany.indicatorsModalVisible;
        })
      )
    ),
    concatMap(([, phenomenonModalActive, indicatorsModalVisible]): Observable<any> => {
      if (indicatorsModalVisible) {
        return of(actions.toggleIndicatorsPhenomenaModal());
      }
      if (phenomenonModalActive) {
        return of(actions.toggleFixedPointPhenomenaModal());
      }
      return of();
    })
  );

export const handleDeleteFixedPoint = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_FIXEDPOINT),
    map((action: Action<FixedPoint>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      )
    ),
    map(([fixedPoint, currentUser]: any) => {
      const fixedPointToDelete = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          ...fixedPoint
        }
      };
      return fixedPointToDelete;
    }),
    concatMap((fixedPoint: any) =>
      deleteFixedPoint(fixedPoint).pipe(
        map(response => {
          notification('success', 'Fixed Point deleted!');

          return getActionDeleteSuccessByFixedPointType(fixedPoint.payload.class_name, fixedPoint.payload);
        }),
        catchError((error: any) => {
          const msg = notifyErrorOrFallback(error, 'Error deleting fixed point!');

          return of(getActionDeleteFailureByFixedPointType(fixedPoint.payload.class_name, msg));
        })
      )
    )
  );
