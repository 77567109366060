import { PageableQuery, Action, UUID } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map, concatMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import actions from './actions';
import { getFixedPoint, getFixedPoints } from '../fixedPoint/service';
import { FixedPoint, LoadFixedPointModel } from '../fixedPoint/models';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';
import { TypesOfTraps } from '../../containers/FixedPointCategories/BasicInfo/basicInfoForm';

export const handleLoadGenerics = action$ =>
  action$.pipe(
    ofType(actions.LOAD_GENERICS),
    map((action: Action<PageableQuery>) => action.payload),
    concatMap((query: LoadFixedPointModel) =>
      getFixedPoints(query.pageableQuery, query.onlyGeneric).pipe(
        map((fixedPoints: FixedPoint[]) => actions.loadGenericsSuccess(fixedPoints)),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading fixed points Generic!');
          return of(actions.loadGenericsFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadFixedPointGeneric = action$ =>
  action$.pipe(
    ofType(actions.LOAD_GENERIC),
    map((action: Action<UUID>) => action.payload),
    concatMap((fixedPointId: UUID) =>
      getFixedPoint(fixedPointId).pipe(
        map(response => response.data),
        map((fixedPoint: any) => {
          let fixedPointFilter = fixedPoint;
          if (fixedPoint.single_use) {
            fixedPointFilter = { ...fixedPoint, sampling_behaviour: TypesOfTraps.SINGLE_USE_ID };
          }
          return actions.loadGenericSuccess(fixedPointFilter);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading fixed point Generic!');
          return of(actions.loadGenericFailure(errorMsg));
        })
      )
    )
  );
