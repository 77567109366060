import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import CONFIG from '../../settings';
import { ServiceProvider } from './models';
import { Page, UUID } from '../app/basicModels';
import { Company } from '../models';

const baseUrl = `${CONFIG.apiUrl}:${CONFIG.basePort}`;
export const serviceProvidersUrl = `${baseUrl}/v1/service-providers`;

export const getServiceProviders = (): AxiosObservable<Page<ServiceProvider>> => {
  return axios.get<Page<ServiceProvider>>(`${serviceProvidersUrl}`);
};

export const getCompaniesByServiceProvider = (serviceProviderId: UUID): AxiosObservable<Page<Company>> => {
  return axios.get<Page<Company>>(`${serviceProvidersUrl}/${serviceProviderId}/orgs`);
};

export const createServiceProvider = (serviceProvider: ServiceProvider): AxiosObservable<ServiceProvider> => {
  return axios.post<ServiceProvider>(`${serviceProvidersUrl}`, serviceProvider);
};

export const updateServiceProvider = (serviceProvider: ServiceProvider): AxiosObservable<ServiceProvider> => {
  return axios.put<ServiceProvider>(`${serviceProvidersUrl}`, serviceProvider);
};

export const deleteServiceProvider = (serviceProviderId: UUID) => {
  return axios.delete(`${serviceProvidersUrl}/${serviceProviderId}`);
};
