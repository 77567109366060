import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PageableQuery, UUID } from '../app/basicModels';
import { ProductSetPage, ProductSet } from './models';
import CONFIG from '../../settings';

const productSetUrl = CONFIG.apiUrl + ':' + CONFIG.demeterPort + '/api/v1/product-set';

export const getProductSets = (_query: PageableQuery, companyId: UUID, showCanonicals: boolean): AxiosObservable<ProductSetPage> => {
  if (companyId) {
    return axios.get<ProductSetPage>(
      `${productSetUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&companyId=${companyId}&showCanonicals=${showCanonicals}`
    );
  } else {
    return axios.get<ProductSetPage>(
      `${productSetUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&showCanonicals=${showCanonicals}`
    );
  }
};

export const getProductSet = (productId: UUID): AxiosObservable<ProductSet> => {
  return axios.get<ProductSet>(`${productSetUrl}/${productId}`);
};

export const saveProductSet = (product: ProductSet, isNew: boolean): AxiosObservable<ProductSet> => {
  if (isNew) {
    return createProductSet(product);
  }
  return updateProductSet(product);
};

export const createProductSet = (product: any): AxiosObservable<ProductSet> => {
  return axios.post<ProductSet>(`${productSetUrl}`, product);
};

export const updateProductSet = (product: any): AxiosObservable<ProductSet> => {
  return axios.put<ProductSet>(`${productSetUrl}`, product);
};

export const deleteProductSet = (product: ProductSet): AxiosObservable<ProductSet> => {
  return axios.put<ProductSet>(`${productSetUrl}/remove`, product);
};
