import { Dictionary, EntityState } from 'redux-ngrx-entity';
import { Action, I18nMap, Page, PageableQuery, UUID } from '../app/basicModels';
import { ParseCharacteristic, ParsePhenomenon } from '../models';

export type IndicatorPage = Page<Indicator>;
export type LoadIndicatorType = (pageableQuery: PageableQuery, showCanonicals?: boolean, companyId?: string) => Action<PageableQuery>;

export type LoadIndicatorSuccessType = (indicatorPage: IndicatorPage) => Action<IndicatorPage>;

export enum OutputType {
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  DOUBLE = 'DOUBLE',
  BOOL = 'BOOLEAN',
  MAP = 'MAP',
  LIST = 'LIST',
  SUMMARY = 'SUMMARY',
  HISTOGRAM = 'HISTOGRAM'
}

export enum RuleNaNAndInfiniteType {
  NULL = 'REPLACE_WITH_NULL',
  ZERO = 'REPLACE_WITH_ZERO'
}

export enum DefaultExpressionType {
  MAX = 'MAX',
  MIN = 'MIN',
  COUNT = 'COUNT',
  PRESENCE = 'PRESENCE',
  SUM = 'SUM',
  RATE = 'RATE',
  STD = 'STD',
  VARIANCE = 'VARIANCE',
  MODE = 'MODE',
  MEAN = 'MEAN',
  HISTOGRAM = 'HISTOGRAM',
  SUMMARY = 'SUMMARY'
}

export enum SessionAggregationType {
  MAX = 'MAX',
  MIN = 'MIN',
  MEAN = 'MEAN',
  SUM = 'SUM'
}

export enum DiagnosticTypes {
  AGRONOMIC = 'AGRONOMIC',
  ANALYTIC = 'ANALYTIC',
  NO_THRESHOLD = 'NO_THRESHOLD'
}

export const DAMAGE = {
  localized_strings: {
    'pt-BR': 'DAMAGE',
    en: 'DAMAGE'
  }
};

export const CONTROL = {
  localized_strings: {
    'pt-BR': 'CONTROL',
    en: 'CONTROL'
  }
};

export const ACCEPTANCE = {
  localized_strings: {
    'pt-BR': 'ACCEPTANCE',
    en: 'ACCEPTANCE'
  }
};

export const MIN_THRESHOLD_VALUE = 0;

export enum ExpressionTypes {
  DEFAULT = 'DEFAULT',
  SMDL = 'SMDL_1_1',
  CUSTOM = 'SUGARSCRIPT'
}

export interface Threshold {
  start_value: number;
  end_value: number;
  label?: I18nMap;
  color?: string;
}

export interface IndicatorDiagnostic {
  label?: I18nMap;
  development_phases?: UUID[];
  thresholds: Threshold[];
  type: string;
}

export interface Expression {
  name?: string;
  script_body?: string;
  output_type?: OutputType;
  characteristics?: any;
  constants?: UUID[];
  variables?: Dictionary<SimpleExpressionVariable>;
  smdl_rules?: SmdlRules;
  type?: string;
}

export type ExpressionPrecedence = 'AGGREGATION' | 'EXPRESSION';

export interface SmdlRules {
  sampling_point_aggregation: string;
  session_aggregation: SessionAggregationType;
  sampling_point_precedence: string;
  nan_rule: string;
  infinity_rule: string;
}

export interface Indicator {
  isNew: boolean;
  id?: UUID;
  name?: I18nMap;
  company_id?: UUID | null;
  parent_id?: UUID | null;
  description?: I18nMap;
  short_description?: I18nMap;
  expression?: Expression;
  return_variable?: string;
  cohese?: boolean;
  output_type?: OutputType | null;
  uom?: I18nMap;
  feature_flags: string[];
  diagnostics: IndicatorDiagnostic[] | [];
  phenomenon_ids?: UUID[];
  decimal_places?: number;
  default?: boolean;
  characteristic_id?: UUID;
  expression_type?: DefaultExpressionType;
  created_at?: string;
  modified_at?: string;
  is_canonical?: boolean;
  deleted_at?: string | null;
}

export interface ParseIndicator {
  id: UUID;
  name: string;
  phenomenons: ParsePhenomenon[];
  characteristics: ParseCharacteristic[];
  company_id: UUID | null;
}

export interface IndicatorState extends EntityState<Indicator> {
  page: IndicatorPage;
  isLoading: boolean;
  error: string | null;
  editModalActive: boolean;
  indicatorSelect: Indicator | null;
  isSaving: boolean;
  indicatorSelectLoading: boolean;
  isLoadingIndicatorsForCompany: boolean;
  indicatorsForCompany: Indicator[];
  isDeleting: boolean;
}

export interface LoadIndicatorQueryPayload {
  pageableQuery: PageableQuery;
  companyId: string;
  showCanonicals: boolean;
}

export enum FeatureFlagEnum {
  NO_TIMELINE = 'NO_TIMELINE',
  NO_HEATMAP = 'NO_HEATMAP',
  POSITIVE_INDICATOR = 'POSITIVE_INDICATOR'
}

export enum FeatureFlagInverse {
  GENERATE_HEATMAP = 'Generate Heatmap',
  SHOW_TIMELINE = 'Show Timeline'
}

export interface SimpleExpressionVariable {
  readonly type: string;
  readonly value: string;
}
