import Auth from './auth/reducer';
import App from './app/reducer';
import Characteristic from './characteristic/reducer';
import Crop from './crop/reducer';
import CropwisePlans from './cropwisePlans/reducer';
import Company from './company/reducer';
import DevelopmentPhase from './developmentPhase/reducer';
import ExtraDimensions from './extraDimensions/reducer';
import Field from './field/reducer';
import FixedPointByCompany from './fixedPoint/reducer';
import FixedPointTrap from './traps/reducer';
import FixedPointPluviometer from './pluviometer/reducer';
import FixedPointGeneric from './generic/reducer';
import Indicator from './indicator/reducer';
import Language from './language/reducer';
import Mayday from './mayday/reducer';
import Measurement from './measurement/reducer';
import Methodology from './methodology/reducer';
import MethodologyDeep from './methodologyDeep/reducer';
import MethodologyTemplate from './methodologyTemplate/reducer';
import PhenomenonCategory from './phenomenonCategory/reducer';
import Product from './product/reducer';
import ProductSet from './productSet/reducer';
import Property from './property/reducer';
import Phenomenon from './phenomenon/reducer';
import RequestControl from './request-control/reducer';
import Season from './season/reducer';
import TrapMeasurement from './trapMeasurement/reducer';
import ServiceProviders from './serviceProviders/reducer';
import User from './user/reducer';
import Vendor from './vendor/reducer';
import RegionalRisk from './regionalRisk/reducer';
import Region from './region/reducer';
import ValidationVariable from './variableValidation/reducer';

export default {
  Auth,
  App,
  Crop,
  CropwisePlans,
  Characteristic,
  Company,
  DevelopmentPhase,
  ExtraDimensions,
  Field,
  FixedPointByCompany,
  Indicator,
  Language,
  Mayday,
  Measurement,
  Methodology,
  MethodologyDeep,
  MethodologyTemplate,
  PhenomenonCategory,
  Product,
  ProductSet,
  Property,
  Phenomenon,
  Region,
  RequestControl,
  Season,
  TrapMeasurement,
  ServiceProviders,
  FixedPointTrap,
  FixedPointPluviometer,
  FixedPointGeneric,
  User,
  Vendor,
  RegionalRisk,
  ValidationVariable
};
