import actions from './actions';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';
import { Language, LanguageState, Options, newLanguagesOptions } from './models';

const entity: EntityAdapter<Language> = createEntityAdapter<Language>();

const initialState: LanguageState = entity.getInitialState({
  drawer: {
    isOpen: false,
    context: ''
  },
  current: Options.EN,
  options: Object.keys(Options).map(key => Options[key]),
  newOptions: Object.keys(newLanguagesOptions).map(key => newLanguagesOptions[key])
});

export default (state = initialState, action): LanguageState => {
  switch (action.type) {
    case actions.TOGGLE_DRAWER:
      return {
        ...state,
        drawer: {
          ...state.drawer,
          isOpen: !state.drawer.isOpen,
          context: action.payload
        }
      };

    case actions.SET_CURRENT_LANGUAGE:
      return {
        ...state,
        current: action.payload
      };

    default:
      return state;
  }
};
