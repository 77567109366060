import React from 'react';

const CheckFilledGlyph = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM5.70711 8.29291L6.95129 9.53709L10.2474 5.34151C10.6111 4.92587 11.2429 4.88375 11.6585 5.24744C12.0741 5.61112 12.1163 6.24288 11.7526 6.65852L7.75258 11.6585C7.37166 12.0939 6.70193 12.1162 6.29289 11.7071L4.29289 9.70712C3.90237 9.3166 3.90237 8.68343 4.29289 8.29291C4.68342 7.90238 5.31658 7.90238 5.70711 8.29291Z'
      fill='#009933'
    />
  </svg>
);

export default CheckFilledGlyph;
