import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import CONFIG from '../../settings';
import { Company, LicensingStatusResponse } from './models';
import { SeasonTree } from '../season/models';
import { UUID, PageableQuery, Page } from '../app/basicModels';
import { CropPage, FixedPointPage, PropertyUnifyingValue } from '../models';
import { PhenomenonPage } from '../phenomenon/models';
import { concatMap, map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

const baseUrl = `${CONFIG.apiUrl}:${CONFIG.basePort}`;
const companyBaseUrl = `${baseUrl}/v1/companies`;
const companyBaseUrlv2 = `${baseUrl}/v2/orgs`;
export const companyBaseLicensingUrlv2 = `${CONFIG.cropwiseApiUrl}/v2/licensing`;
const companyTalosUrl = `${baseUrl}/v1/orgs`;
const companyDemeterUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}/api/v1/companies`;
const propertiesDemeterUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}/api/v1/properties`;
const PHENOMENONS_TREE_PAGE_SIZE = 3000;

export const getCompanies = (): AxiosObservable<Page<Company>> => {
  return axios.get<Page<Company>>(`${companyTalosUrl}`);
};

export const getCompany = (companyId: UUID): AxiosObservable<Company> => {
  return axios.get<Company>(`${companyBaseUrlv2}/${companyId}`);
};

export const getSeasonsTreeByCompany = (companyId: UUID): AxiosObservable<SeasonTree[]> => {
  return axios.get<SeasonTree[]>(`${companyBaseUrl}/${companyId}/seasons/tree`);
};

export const getPhenomenonsTreeByCompany = (_query: PageableQuery, companyId: UUID | null) => {
  const size = PHENOMENONS_TREE_PAGE_SIZE;
  return getPhenomenonsTreeByCompanyPage({ pageable: { page: 0, size } }, companyId).pipe(
    concatMap(({ content, total_pages }) => {
      const pages = Array.from({ length: total_pages - 1 }, (_, i) => i + 1);
      const observables = pages.map(p =>
        getPhenomenonsTreeByCompanyPage({ pageable: { page: p, size } }, companyId).pipe(
          concatMap(phenomenonsPage => of(phenomenonsPage.content))
        )
      );
      return forkJoin([of(content), ...observables]);
    }),
    map(phenomenons => ({ data: { content: phenomenons.flat() } }))
  );
};

export const getPhenomenonsTreeByCompanyPage = (_query: PageableQuery, companyId: UUID | null) => {
  return axios
    .get<PhenomenonPage>(`${companyDemeterUrl}/${companyId}/phenomenons/tree?page=${_query.pageable.page}&size=${_query.pageable.size}`)
    .pipe(map(p => p.data));
};

export const getPropertyUnifyingValue = (propertyId: UUID) => {
  return axios.get(`${propertiesDemeterUrl}/${propertyId}/monitoring_days`);
};

export const setPropertiesUnifyingValue = (propertyId: UUID, formData: PropertyUnifyingValue) => {
  return axios.post<PropertyUnifyingValue>(`${propertiesDemeterUrl}/${propertyId}/monitoring_days`, formData);
};

export const getCompanyCrops = (_query: PageableQuery, companyId: UUID): AxiosObservable<CropPage> => {
  return axios.get<CropPage>(`${companyTalosUrl}/${companyId}/crops`);
};

export const getStaticPointsByCompany = (companyId: UUID, _query: PageableQuery) => {
  return axios.get<FixedPointPage>(
    `${companyDemeterUrl}/${companyId}/static-points?page=${_query.pageable.page}&size=${_query.pageable.size}&only_template=true`
  );
};

export const getInstalledStaticPointsByCompany = (companyId: UUID, _query: PageableQuery) => {
  return axios.get<FixedPointPage>(
    `${companyDemeterUrl}/${companyId}/static-points?page=${_query.pageable.page}&size=${_query.pageable.size}`
  );
};

export const getCompanyLicensing = (companyId: UUID): AxiosObservable<LicensingStatusResponse> => {
  return axios.get(`${companyBaseLicensingUrlv2}/status?org=${companyId}&apps=all`);
};
