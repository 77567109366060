import { DevelopmentPhase } from './models';
import { UUID } from '../models';

const actions = {
  LOAD_DEVELOPMENT_PHASES: '[DevelopmentPhase] Load Development Phases',
  LOAD_DEVELOPMENT_PHASES_SUCCESS: '[DevelopmentPhase] Load Development Phases Success',
  SAVE_DEVELOPMENT_PHASE: '[DevelopmentPhase] Save DevelopmentPhase',
  SAVE_DEVELOPMENT_PHASE_SUCCESS: '[DevelopmentPhase] Save Development Phase Success',
  SAVE_DEVELOPMENT_PHASE_FAILURE: '[DevelopmentPhase] Save Development Phase Failure',
  SAVE_CUSTOM_DEVELOPMENT_PHASE: '[DevelopmentPhase] Save Custom DevelopmentPhase',
  SAVE_CUSTOM_DEVELOPMENT_PHASE_SUCCESS: '[DevelopmentPhase] Save Custom DevelopmentPhase Success',
  SAVE_CUSTOM_DEVELOPMENT_PHASE_FAILURE: '[DevelopmentPhase] Save Custom DevelopmentPhase Failure',
  DELETE_DEVELOPMENT_PHASE: '[DevelopmentPhase] Delete Development Phase',
  DELETE_DEVELOPMENT_PHASE_SUCCESS: '[DevelopmentPhase] Delete Development Phase Success',
  DELETE_DEVELOPMENT_PHASE_FAILURE: '[DevelopmentPhase] Delete Development Phase Failure',
  DELETE_CUSTOM_DEVELOPMENT_PHASE: '[DevelopmentPhase] Delete Custom Development Phase',
  DELETE_CUSTOM_DEVELOPMENT_PHASE_SUCCESS: '[DevelopmentPhase] Delete Custom Development Phase Success',
  DELETE_CUSTOM_DEVELOPMENT_PHASE_FAILURE: '[DevelopmentPhase] Delete Custom Development Phase Failure',
  ENABLE_CUSTOM_DEVELOPMENT_PHASE: '[DevelopmentPhase] Enable Custom Development Phase',
  ENABLE_CUSTOM_DEVELOPMENT_PHASE_SUCCESS: '[DevelopmentPhase] Enable Custom Development Phase Success',
  ENABLE_CUSTOM_DEVELOPMENT_PHASE_FAILURE: '[DevelopmentPhase] Enable Custom Development Phase Failure',
  TOGGLE_DEVELOPMENT_PHASE_MODAL: '[DevelopmentPhase] Toggle development phase modal',
  DEVELOPMENT_PHASE_SELECT: '[DevelopmentPhase] Development Phase Select',
  CLEAR_DEVELOPMENT_PHASE_SELECT: '[DevelopmentPhase] Clear Development Phase Select',
  ENABLE_DEVELOPMENT_PHASE: '[DevelopmentPhase] Enable Development Phase Success',
  ENABLE_DEVELOPMENT_PHASE_SUCCESS: '[DevelopmentPhase] Enable Development Phase Success',
  ENABLE_DEVELOPMENT_PHASE_FAILURE: '[DevelopmentPhase] Enable Development Phase Success',

  loadDevelopmentPhases: () => ({
    type: actions.LOAD_DEVELOPMENT_PHASES
  }),

  loadDevelopmentPhasesSuccess: (developmentPhases: DevelopmentPhase[]) => ({
    type: actions.LOAD_DEVELOPMENT_PHASES_SUCCESS,
    payload: developmentPhases
  }),

  saveDevelopmentPhase: (developmentPhase: DevelopmentPhase, isNew: Boolean) => ({
    type: actions.SAVE_DEVELOPMENT_PHASE,
    payload: { developmentPhase, isNew }
  }),

  saveDevelopmentPhaseSuccess: (developmentPhase: DevelopmentPhase) => ({
    type: actions.SAVE_DEVELOPMENT_PHASE_SUCCESS,
    payload: developmentPhase
  }),

  saveDevelopmentPhaseFailure: error => ({
    type: actions.SAVE_DEVELOPMENT_PHASE_FAILURE,
    payload: error
  }),

  saveCustomDevelopmentPhase: (developmentPhase: DevelopmentPhase, isNew: Boolean) => ({
    type: actions.SAVE_CUSTOM_DEVELOPMENT_PHASE,
    payload: { developmentPhase, isNew }
  }),

  saveCustomDevelopmentPhaseSuccess: (developmentPhase: DevelopmentPhase) => ({
    type: actions.SAVE_CUSTOM_DEVELOPMENT_PHASE_SUCCESS,
    payload: developmentPhase
  }),

  saveCustomDevelopmentPhaseFailure: error => ({
    type: actions.SAVE_CUSTOM_DEVELOPMENT_PHASE_FAILURE,
    payload: error
  }),

  deleteDevelopmentPhase: (developmentPhase: DevelopmentPhase, companyId?: UUID) => ({
    type: actions.DELETE_DEVELOPMENT_PHASE,
    payload: {
      developmentPhase: developmentPhase,
      companyId: companyId
    }
  }),

  deleteDevelopmentPhaseFailure: (error: Error) => ({
    type: actions.DELETE_CUSTOM_DEVELOPMENT_PHASE_FAILURE,
    payload: error
  }),

  deleteDevelopmentPhaseSuccess: (deleted: boolean) => ({
    type: actions.DELETE_DEVELOPMENT_PHASE_SUCCESS,
    payload: deleted
  }),

  deleteCustomDevelopmentPhase: (cropId: UUID, developmentPhaseId: UUID) => ({
    type: actions.DELETE_CUSTOM_DEVELOPMENT_PHASE,
    payload: {
      cropId: cropId,
      developmentPhaseId: developmentPhaseId
    }
  }),

  deleteCustomDevelopmentPhaseSuccess: (developmentPhase: DevelopmentPhase) => ({
    type: actions.DELETE_CUSTOM_DEVELOPMENT_PHASE_SUCCESS,
    payload: developmentPhase
  }),

  deleteCustomDevelopmentPhaseFailure: (error: Error) => ({
    type: actions.DELETE_CUSTOM_DEVELOPMENT_PHASE_FAILURE,
    payload: error
  }),

  toggleDevelopmentPhaseModal: () => ({
    type: actions.TOGGLE_DEVELOPMENT_PHASE_MODAL
  }),

  developmentPhaseSelect: (developmentPhase: DevelopmentPhase | null) => ({
    type: actions.DEVELOPMENT_PHASE_SELECT,
    payload: developmentPhase
  }),

  clearDevelopmentPhaseSelect: () => ({
    type: actions.CLEAR_DEVELOPMENT_PHASE_SELECT
  }),

  enableCustomDevelopmentPhase: (developmentPhaseId: UUID, companyId: UUID) => ({
    type: actions.ENABLE_CUSTOM_DEVELOPMENT_PHASE,
    payload: {
      developmentPhaseId: developmentPhaseId,
      companyId: companyId
    }
  }),

  enableCustomDevelopmentPhaseSuccess: (developmentPhase: DevelopmentPhase) => ({
    type: actions.ENABLE_CUSTOM_DEVELOPMENT_PHASE_SUCCESS,
    payload: developmentPhase
  }),

  enableCustomDevelopmentPhaseFailure: (error: Error) => ({
    type: actions.ENABLE_CUSTOM_DEVELOPMENT_PHASE_FAILURE,
    payload: error
  }),

  enableDevelopmentPhase: (developmentPhaseId: UUID, companyId: UUID) => ({
    type: actions.ENABLE_DEVELOPMENT_PHASE,
    payload: {
      developmentPhaseId: developmentPhaseId,
      companyId: companyId
    }
  }),

  enableDevelopmentPhaseSuccess: (developmentPhase: DevelopmentPhase) => ({
    type: actions.ENABLE_DEVELOPMENT_PHASE_SUCCESS,
    payload: developmentPhase
  }),

  enableDevelopmentPhaseFailure: (error: Error) => ({
    type: actions.ENABLE_DEVELOPMENT_PHASE_FAILURE,
    payload: error
  })
};

export default actions;

export const DevelopmentPhaseActions = actions;
