import { Button as AntdButton } from 'antd';
import { Buttons, ButtonsGroup } from './styles/button.style';
import WithDirection from '../../settings/withDirection';

const AntButton = Buttons(AntdButton);
const Button = WithDirection(AntButton);
const AntButtonGroup = ButtonsGroup(AntdButton.Group);
const ButtonGroup = WithDirection(AntButtonGroup);

export default Button;
export { Button, ButtonGroup };
