import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { MethodologyPlans, MethodologyPlansFlat } from './models';
import CONFIG from '../../settings';
import { UUID } from '../models';

const methodologyPlansUrl = `${CONFIG.apiUrl}:${CONFIG.basePort}/v1`

export const saveMethodologyPlans = (methodologyPlans: MethodologyPlansFlat): AxiosObservable<MethodologyPlans> =>
  axios.post<MethodologyPlans>(`${methodologyPlansUrl}/methodology-plans/templates`, methodologyPlans);

export const loadMethodologyPlans = (): AxiosObservable<MethodologyPlans[]> =>
  axios.get<MethodologyPlans[]>(`${methodologyPlansUrl}/methodology-plans/templates`);

export const deleteMethodologyPlans = (templateId: UUID): AxiosObservable<UUID> =>
  axios.delete<UUID>(`${methodologyPlansUrl}/methodology-plans/${templateId}`);

export const updateMethodologyPlans = (templateId: UUID, plans_ids): AxiosObservable<MethodologyPlans> =>
  axios.patch<MethodologyPlans>(`${methodologyPlansUrl}/methodology-plans/${templateId}`, plans_ids);

