import axios, { AxiosResponse } from 'axios';
import CONFIG from '../../settings';
import {
  UpdateUserRolesRBACRequestParams,
  InviteControleCertoParams,
  PayloadInviteRBACProps,
  ResponseCreateUserManaged
} from './rbac.query.model';
import { UUID, User } from '../../redux/models';

const baseUrl = `${CONFIG.apiUrl}:${CONFIG.basePort}`;
export const protectorURL = process.env.REACT_APP_BASE_API_URL ?? 'http://localhost:8080';
export const cropwiseURLStriderAG = process.env.REACT_APP_CROPWISE_API_URL ?? 'http://localhost:8080';
const cropwiseURL = process.env.REACT_APP_CROPWISE_URL ?? 'http://localhost:8080';

export const getOrganizationInfo = (id: UUID) => {
  return axios.get(`${baseUrl}/v2/orgs/${id}?attributes=licensing_accounts`);
};

export const sendAccountInvitationV2 = async (user: PayloadInviteRBACProps) => {
  const headers = { headers: { 'X-Origin': cropwiseURL } };
  return axios.post(`${protectorURL}/v2/accounts/invitation`, user, headers);
};

export const getUserDataByEmailV2 = (email: string): Promise<AxiosResponse<User>> => {
  return axios.get<User>(`${protectorURL}/v2/accounts?login=${email}`);
};

export const createUserManagerV2 = (user: PayloadInviteRBACProps, orgID: string): Promise<AxiosResponse<ResponseCreateUserManaged>> => {
  return axios.post(`${protectorURL}/v2/orgs/${orgID}/accounts`, user);
};

export const editAccountInvitationV2 = (dataUser: PayloadInviteRBACProps): Promise<AxiosResponse<User>> => {
  const updatedUser = { ...dataUser };
  if (updatedUser?.authorities) {
    delete updatedUser?.authorities;
  }

  if (updatedUser?.first_name || updatedUser?.last_name) {
    delete updatedUser?.first_name;
    delete updatedUser?.last_name;
  }

  return axios.put<User>(`${protectorURL}/v2/accounts/${dataUser.id}`, updatedUser);
};

export const editRolesRBACByUser = (userRequestParams: UpdateUserRolesRBACRequestParams, userID: UUID): Promise<AxiosResponse<User>> => {
  return axios.post<User>(`${protectorURL}/v2/accounts/${userID}/roles/updates`, userRequestParams);
};

export const createUserControleCertoV2 = (user: InviteControleCertoParams): Promise<AxiosResponse<ResponseCreateUserManaged>> => {
  return axios.post(`${cropwiseURLStriderAG}/v2/accounts/signup`, user);
};
