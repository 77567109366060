import React from 'react';

const deDEU = () => (
  <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path d='M0 8H16V12H0V8Z' fill='#FFCE00' />
    <path d='M0 0H16V4H0V0Z' fill='black' />
    <path d='M0 4H16V8H0V4Z' fill='#DD0000' />
  </svg>
);

export default deDEU;
