import { PageableQuery, UUID, FixedPoint } from '../models';
import { PhenomenonInfo } from '../methodologyDeep/models';

const actions = {
  LOAD_TRAPS: '[Trap] Load All Traps',
  LOAD_TRAPS_SUCCESS: '[Traps] Load Traps Success',
  LOAD_TRAPS_FAILURE: '[Traps] Load Traps Failure',
  SAVE_TRAP: '[Trap] Save Trap',
  SAVE_TRAP_SUCCESS: '[Trap] Save Trap Success',
  SAVE_TRAP_FAILURE: '[Trap] Save Trap Failure',
  UPDATE_TRAP: '[Trap] Update Trap',
  LOAD_TRAP: '[Trap] Load Trap',
  LOAD_TRAP_SUCCESS: '[Trap] Load Trap Success',
  LOAD_TRAP_FAILURE: '[Trap] Load Trap Failure',
  DELETE_TRAP: '[Trap] Delete Trap',
  DELETE_TRAP_SUCCESS: '[Trap] Delete Trap Success',
  DELETE_TRAP_FAILURE: '[Trap] Delete Trap Failure',
  CLONE_TRAP_TO_COMPANIES: '[Trap] Clone trap to companies',
  CLONE_TRAP_TO_COMPANIES_SUCCESS: '[Trap] Clone trap to companies Success',
  CLONE_TRAP_TO_COMPANIES_FAILURE: '[Trap] Clone trap to companies Failure',
  SELECT_FIXEDPOINT_TRAP: '[Trap] Select Fixed Point Trap',
  CLEAR_FIXED_POINT_SELECT_TRAP: '[Trap] Clear Fixed Point Trap Selected',
  TOGGLE_FIXED_POINT_TRAP_PHENOMENA_MODAL: '[Trap] Toggle Fixed Point Trap Phenomena Modal',
  TOGGLE_CHARACTERISTICS_INDICATORS_PHENOMENA_MODAL_TRAP: '[Trap] Toggle Characteristics/Indicators Phenomena Modal',

  loadTraps: (pageableQuery: PageableQuery, only_template: boolean) => ({
    type: actions.LOAD_TRAPS,
    payload: { pageableQuery, only_template }
  }),
  loadTrapsSuccess: (fixedPointPage: FixedPoint[]) => ({
    type: actions.LOAD_TRAPS_SUCCESS,
    payload: fixedPointPage
  }),
  loadTrapsFailure: (error: string) => ({
    type: actions.LOAD_TRAPS_FAILURE,
    payload: error
  }),
  selectFixedPointTrap: (fixedPoint: FixedPoint) => ({
    type: actions.SELECT_FIXEDPOINT_TRAP,
    payload: fixedPoint
  }),
  clearFixedPointSelectTrap: () => ({
    type: actions.CLEAR_FIXED_POINT_SELECT_TRAP
  }),

  loadTrap: (id: UUID) => ({
    type: actions.LOAD_TRAP,
    payload: id
  }),
  loadTrapSuccess: (fixedPoint: FixedPoint) => ({
    type: actions.LOAD_TRAP_SUCCESS,
    payload: fixedPoint
  }),

  loadTrapFailure: (error: string) => ({
    type: actions.LOAD_TRAP_FAILURE,
    payload: error
  }),
  saveTrap: (fixedPoint: FixedPoint) => ({
    type: actions.SAVE_TRAP,
    payload: fixedPoint
  }),
  saveTrapSuccess: (fixedPoint: FixedPoint) => ({
    type: actions.SAVE_TRAP_SUCCESS,
    payload: fixedPoint
  }),
  saveTrapFailure: (error: string) => ({
    type: actions.SAVE_TRAP_FAILURE,
    payload: error
  }),
  updateTrap: (fixedPoint: FixedPoint, save = false) => ({
    type: actions.UPDATE_TRAP,
    payload: {
      fixedPoint,
      save
    }
  }),
  deleteFixedPointTrap: (fixedPoint: FixedPoint) => ({
    type: actions.DELETE_TRAP,
    payload: fixedPoint
  }),

  deleteFixedPointTrapSuccess: (deleted: boolean) => ({
    type: actions.DELETE_TRAP_SUCCESS,
    payload: deleted
  }),

  deleteFixedPointTrapFailure: (error: string) => ({
    type: actions.DELETE_TRAP_FAILURE,
    payload: error
  }),

  toggleFixedPointTrapPhenomenaModal: () => ({
    type: actions.TOGGLE_FIXED_POINT_TRAP_PHENOMENA_MODAL
  }),
  toggleCharacteristicsIndicatorsPhenomenaTrapModal: (phenomenonInfo?: PhenomenonInfo) => ({
    type: actions.TOGGLE_CHARACTERISTICS_INDICATORS_PHENOMENA_MODAL_TRAP,
    payload: phenomenonInfo
  }),
  cloneTrapToCompanies: (payload: { companies_id: UUID[]; fixedPoint: FixedPoint }) => ({
    type: actions.CLONE_TRAP_TO_COMPANIES,
    payload
  }),

  cloneTrapToCompaniesSuccess: (fixedPoint: FixedPoint) => ({
    type: actions.CLONE_TRAP_TO_COMPANIES_SUCCESS,
    payload: fixedPoint
  }),

  cloneTrapToCompaniesFailure: (error: string) => ({
    type: actions.CLONE_TRAP_TO_COMPANIES_FAILURE,
    payload: error
  })
};

export default actions;

export const TrapsActions = actions;
