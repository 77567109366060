import actions from './actions';
import { MethodologyState, Methodology } from './models';
import { initialPage } from '../app/basicModels';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';

const entity: EntityAdapter<Methodology> = createEntityAdapter<Methodology>();

const initialState: MethodologyState = entity.getInitialState({
  page: {
    ...initialPage
  },
  isLoading: false,
  error: null,
  companyId: null,
  methodologySelect: null
});

export default (state = initialState, action): MethodologyState => {
  switch (action.type) {
    case actions.LOAD_METHODOLOGIES_BY_COMPANY:
      return {
        ...state,
        companyId: action.payload,
        isLoading: true
      };

    case actions.LOAD_METHODOLOGIES:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_METHODOLOGIES_SUCCESS:
      return entity.addAll(action.payload, {
        ...state,
        companyId: null,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null
      });

    case actions.LOAD_METHODOLOGIES_BY_COMPANY_SUCCESS:
      return entity.addAll(action.payload, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null
      });

    case actions.LOAD_METHODOLOGIES_FAILURE:
    case actions.LOAD_METHODOLOGIES_BY_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.ADD_OR_UPDATE_METHODOLOGY: {
      if (action.payload.isNew) {
        return entity.addOne(action.payload, state);
      }
      return entity.upsertOne(action.payload, state);
    }

    case actions.DELETE_METHODOLOGY_SUCCESS:
      return entity.removeOne(action.payload.id, {
        ...state,
        error: null
      });

    case actions.LOAD_ERROR_METHODOLOGY_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case actions.UPDATE_METHODOLOGY_DRAFT:
      return {
        ...state,
        methodologySelect: action.payload
      };

    case actions.TOGGLE_IS_LOADING:
      return {
        ...state,
        isLoading: !state.isLoading
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectMethodologyIds = selectIds;
export const selectMethodologyEntities = selectEntities;
export const selectAllMethodologies = selectAll;
export const selectMethodologyTotal = selectTotal;
export const selectSelectedMethodology = state => state.methodologySelect;
