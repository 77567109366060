/* eslint-disable */
import * as React from 'react';
import { connect as mainConnect } from 'react-redux';
import { ControlledAction } from '../../redux/models';
import { RequestControlActions } from '../../redux/actions';
import { isEqual, values, keyBy } from 'lodash';
import { difference } from '../../helpers';
import { RouteComponentProps } from 'react-router-dom';
import { notification, Loading } from '..';
import { injectMetadata } from '../../redux/request-control';

const { clearRequestData } = RequestControlActions;

const ControlledContainer = props => (
  <>
    {props.children}
    <Loading loading={props.lockScreen} />
  </>
);

interface ConnectProps extends RouteComponentProps {
  requests: ControlledAction[];
  clearRequestData: (id: string) => void;
}

export const connect = (mapStateToProps, mapDispatchToProps, options?) => {
  const controlledActions: ControlledAction[] = [];

  for (const key in mapDispatchToProps) {
    if (typeof mapDispatchToProps[key] === 'object') {
      const a = mapDispatchToProps[key];
      controlledActions.push(a);
      mapDispatchToProps[key] = injectMetadata(a.action, { id: a.id });
    }
  }

  const requestControlStateToProps = state => ({
    requests: keyBy(
      controlledActions.map(a => ({ ...a, ...(state.RequestControl.requests[a.id] || {}) })),
      'id'
    )
  });

  const higherOrderComponent = Component => {
    const ConnectedComponent = mainConnect(mapStateToProps, mapDispatchToProps)(Component);

    return mainConnect(requestControlStateToProps, { clearRequestData })(
      // @ts-ignore
      controlledActions.length > 0
        ? class extends React.Component<ConnectProps> {
            constructor(props) {
              super(props);
            }

            componentDidUpdate(prevProps) {
              if (!isEqual(prevProps.requests, this.props.requests)) {
                const differences = difference(this.props.requests, prevProps.requests);

                for (const id in differences) {
                  const requestInfo = this.props.requests[id];

                  if (options && options.debug) {
                    if (options.debug === 'deep') {
                      console.log(`[Request Control] ${id} `, requestInfo);
                    } else {
                      console.log(`[Request Control] ${id} `, differences[id]);
                    }
                  }

                  const { status } = requestInfo;

                  const isModal = this.props.location && this.props.location.state && (this.props.location.state as any).modal;

                  if (status === 'SUCCESS') {
                    requestInfo.notifications &&
                      requestInfo.notifications.success &&
                      notification('success', requestInfo.notifications.success);
                    requestInfo.onSuccess && requestInfo.onSuccess();
                    if (requestInfo.redirectOnSuccess) {
                      if (typeof requestInfo.redirectOnSuccess === 'string' && requestInfo.redirectOnSuccess !== 'goBack') {
                        this.props.history.push(requestInfo.redirectOnSuccess);
                      } else {
                        isModal && this.props.history.goBack();
                      }
                    }
                  } else if (status == 'ERROR') {
                    requestInfo.notifications && requestInfo.notifications.error && notification('error', requestInfo.notifications.error);
                    requestInfo.onError && requestInfo.onError();
                    if (requestInfo.redirectOnError) {
                      if (typeof requestInfo.redirectOnError === 'string' && requestInfo.redirectOnError !== 'goBack') {
                        this.props.history.push(requestInfo.redirectOnError);
                      } else {
                        isModal && this.props.history.goBack();
                      }
                    }
                  }
                }
              }
            }

            componentWillUnmount() {
              for (const id in this.props.requests) {
                this.props.clearRequestData(id);
              }
            }

            render() {
              return (
                <ControlledContainer
                  lockScreen={values(this.props.requests)
                    .filter(r => r.lockScreen)
                    .some(r => r.status == 'LOADING')}>
                  <ConnectedComponent {...this.props} />
                </ControlledContainer>
              );
            }
          }
        : props => <ConnectedComponent {...props} />
    );
  };

  return higherOrderComponent;
};
