/* eslint-disable import/prefer-default-export */
import { getValueLanguage } from '../language/utils';
import { getAllParentsAsString } from '../measurement';
import { Phenomenon, User, Region, Field } from '../models';
import { TrapStation, TrapMeasurement, TrapMonitoring, RegionField } from './models';

const getRegionFromField = (regions: Region[], field?: Field): RegionField | undefined => {
  if (!field) {
    return undefined;
  }

  const nodeIdToFindParents = field ? field.parent_region_id : '';
  const node = Object.values(regions).find(x => x.id === nodeIdToFindParents);
  let region = '';
  if (node) {
    region = getAllParentsAsString(regions, node);
  } else if (regions.length > 0) {
    region = regions[0].name;
  }

  return {
    region,
    field
  };
};

export const buildTrapMeasurements = (
  trapMonitoringList: TrapMonitoring[],
  stations: TrapStation[],
  phenomena: Phenomenon[],
  users: User[],
  fields: Field[],
  regions: Region[]
): TrapMeasurement[] => {
  const measurements = trapMonitoringList
    .map(trapMonitoring => {
      const scouter = users.find(u => u.id === trapMonitoring.scouter_id);

      const propertyRegions = Object.values(regions).filter(f => f.propertyId === trapMonitoring.property_id);

      const station = stations.find(stationItem => stationItem.id === trapMonitoring.static_point_id);
      const installedAreaId = station ? station.installed_area : '';
      const installedField = fields.find(field => field.id === installedAreaId);

      const regionFields =
        station && station.areas_ids
          ? (station.areas_ids
              .map(areaId => {
                const field = fields.find(f => f.id === areaId);
                if (!field) {
                  return undefined;
                }
                return getRegionFromField(propertyRegions, field);
              })
              .filter(x => x !== undefined) as RegionField[])
          : [];

      let installedRegionField = getRegionFromField(propertyRegions, installedField);
      if (!installedRegionField && regionFields.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        installedRegionField = regionFields[0];
      }

      return trapMonitoring.measures.map(measure => {
        const phenomenon = phenomena.find(p => p.characteristics && p.characteristics.map(c => c.id).includes(measure.characteristic_id));
        const characteristic =
          phenomenon && phenomenon.characteristics && phenomenon.characteristics.find(c => c.id === measure.characteristic_id);
        const trapMeasurement: TrapMeasurement = {
          actionId: trapMonitoring.action_id,
          type: trapMonitoring.type,
          companyId: trapMonitoring.company_id,
          propertyId: trapMonitoring.property_id,
          staticPointId: trapMonitoring.static_point_id,
          coordinates: trapMonitoring.forced_location || trapMonitoring.start_location,
          date: trapMonitoring.start_time,
          name: station ? station.name : '',
          measureId: measure.id,
          id: measure.id,
          inspectionId: measure.inspection_id,
          value: measure.value,
          phenomenon: phenomenon && {
            ...phenomenon,
            name: getValueLanguage(phenomenon.name)
          },
          characteristicId: measure.characteristic_id,
          characteristic: characteristic && {
            ...characteristic,
            name: getValueLanguage(characteristic.name)
          },
          installedRegionField,
          regionFields,
          scouter
        };
        return trapMeasurement;
      });
    })
    .flat();
  return measurements;
};
