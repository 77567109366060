import { Action } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map, concatMap, withLatestFrom, catchError } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import actions from './actions';
import { DevelopmentPhase } from './models';
import { cloneDeep } from 'lodash';
import cropActions from '../crop/actions';
import {
  createCustomDevelopmentPhase,
  editCustomDevelopmentPhase,
  deleteCustomDevelopmentPhase,
  deleteDevelopmentPhase,
  enableCustomDevelopmentPhase,
  enableDevelopmentPhase
} from './service';
import { notification } from '../../components';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';


export const handleLoadDevelopmentPhases = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_DEVELOPMENT_PHASES, cropActions.LOAD_CROP_SUCCESS),
    withLatestFrom(state$.pipe(map((state: any) => state.Crop.cropSelect))),
    concatMap(([crop]) => {
      const developmentPhases = crop.development_phases ? crop.development_phases : [];
      return of(actions.loadDevelopmentPhasesSuccess(developmentPhases));
    })
  );

export const handleSaveCustomDevelopmentPhase = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_CUSTOM_DEVELOPMENT_PHASE),
    map((action: Action<DevelopmentPhase>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      ),
      state$.pipe(
        map((state: any) => {
          return state.Crop.cropSelect;
        })
      )
    ),
    concatMap(([payload, currentUser, crop]: any) => {
      const { developmentPhase, isNew } = payload;
      if (developmentPhase.duration_in_days === null) {
        developmentPhase.duration_in_days = 0;
      }
      if (developmentPhase.days_after_emergency === null) {
        developmentPhase.days_after_emergency = 0;
      }

      const newCrop = cloneDeep(crop);

      const developmentPhaseToSave = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          ...developmentPhase,
          duration_in_days: parseInt(developmentPhase.duration_in_days),
          days_after_emergency: parseInt(developmentPhase.days_after_emergency)
        }
      };
      if (!isNew) {
        return editCustomDevelopmentPhase(developmentPhaseToSave, developmentPhase.crop_id, developmentPhase.id).pipe(
          map(response => response.data),
          concatMap(dp => {
            const valueIndex = newCrop.development_phases.map(dp => dp.id).indexOf(developmentPhase.id);
            if (valueIndex !== -1) {
              newCrop.development_phases[valueIndex] = developmentPhase;
            }
            return concat([
              actions.saveDevelopmentPhaseSuccess(dp),
              actions.loadDevelopmentPhasesSuccess(newCrop.development_phases),
              cropActions.updateCrop(newCrop)
            ]);
          }),
          catchError((error: ErrorMsg) => {
            const errorMsg = notifyErrorOrFallback(error, 'Error edit development phase!');
            return of(actions.saveDevelopmentPhaseFailure(errorMsg));
          })
        );
      } else {
        return createCustomDevelopmentPhase(developmentPhaseToSave, developmentPhase.crop_id).pipe(
          map(response => response.data),
          concatMap(dp => {
            newCrop.development_phases.unshift(dp);
            return concat([
              actions.saveDevelopmentPhaseSuccess(dp),
              actions.loadDevelopmentPhasesSuccess(newCrop.development_phases),
              cropActions.updateCrop(newCrop)
            ]);
          }),
          catchError((error: ErrorMsg) => {
            const errorMsg = notifyErrorOrFallback(error, 'Error creating development phase!');
            return of(actions.saveDevelopmentPhaseFailure(errorMsg));
          })
        );
      }
    })
  );

export const handleSaveDevelopmentPhase = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_DEVELOPMENT_PHASE),
    map((action: Action<DevelopmentPhase>) => action.payload),
    withLatestFrom(state$.pipe(map((state: any) => state.Crop.cropSelect))),
    concatMap(([payload, crop]: any) => {
      const { developmentPhase } = payload;
      let mutableCrop = cloneDeep(crop);
      developmentPhase.crop_id = crop.id;
      if (developmentPhase.duration_in_days === null) {
        developmentPhase.duration_in_days = 0;
      } else developmentPhase.duration_in_days = parseInt(developmentPhase.duration_in_days);
      if (developmentPhase.days_after_emergency === null) {
        developmentPhase.days_after_emergency = 0;
      } else developmentPhase.days_after_emergency = parseInt(developmentPhase.days_after_emergency);

      const valueIndex = mutableCrop.development_phases.map(dp => dp.id).indexOf(developmentPhase.id);
      if (valueIndex !== -1) {
        mutableCrop.development_phases[valueIndex] = developmentPhase;
      } else {
        mutableCrop.development_phases.push(developmentPhase);
      }
      return concat([
        cropActions.updateCrop(mutableCrop),
        actions.loadDevelopmentPhasesSuccess(mutableCrop.development_phases),
        actions.saveDevelopmentPhaseSuccess(developmentPhase)
      ]);
    })
  );

export const handleDeleteDevelopmentPhase = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_DEVELOPMENT_PHASE),
    map((action: Action<DevelopmentPhase>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      ),
      state$.pipe(
        map((state: any) => {
          return state.Crop.cropSelect;
        })
      )
    ),
    concatMap(([payload, user, crop]: any) => {
      let mutableCrop = cloneDeep(crop);
      const developmentPhaseToSave = {
        account: {
          id: user.id,
          name: user.name
        },
        payload: {
          ...payload.developmentPhase,
          duration_in_days: parseInt(payload.developmentPhase.duration_in_days),
          days_after_emergency: parseInt(payload.developmentPhase.days_after_emergency)
        }
      };
      return deleteDevelopmentPhase(crop.id, payload.developmentPhase.id, developmentPhaseToSave, payload.companyId).pipe(
        map(response => response.data),
        concatMap(() => {
          notification('success', 'Development phase disabled with success!');
          const valueIndex = mutableCrop.development_phases.map(dp => dp.id).indexOf(payload.developmentPhase.id);
          if (valueIndex !== -1) {
            if (payload.companyId) {
              mutableCrop.development_phases[valueIndex].deleted_at = new Date();
            } else {
              mutableCrop.development_phases = mutableCrop.development_phases.filter(dp => dp.id !== payload.developmentPhase.id);
            }
          }

          return concat([
            cropActions.updateCrop(mutableCrop),
            actions.loadDevelopmentPhasesSuccess(mutableCrop.development_phases),
            actions.deleteDevelopmentPhaseSuccess(true)
          ]);
        })
      );
    })
  );

export const handleDeleteCustomDevelopmentPhase = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_CUSTOM_DEVELOPMENT_PHASE),
    map((action: Action<DevelopmentPhase>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.Crop.cropSelect;
        })
      )
    ),
    concatMap(([payload, crop]: any) => {
      return deleteCustomDevelopmentPhase(crop.id, payload.developmentPhaseId).pipe(
        map(response => response.data),
        concatMap(dp => {
          const newCrop = cloneDeep(crop);
          const valueIndex = newCrop.development_phases.map(dp => dp.id).indexOf(payload.developmentPhaseId);
          if (valueIndex !== -1) {
            newCrop.development_phases[valueIndex].deleted_at = new Date();
          }
          notification('success', 'Development phase disabled with success!');
          return concat([
            actions.saveDevelopmentPhaseSuccess(dp),
            actions.deleteCustomDevelopmentPhaseSuccess(dp),
            actions.loadDevelopmentPhasesSuccess(newCrop.development_phases),
            cropActions.updateCrop(newCrop)
          ]);
        }),
        catchError(error => {
          return of(actions.deleteCustomDevelopmentPhaseFailure(error));
        })
      );
    })
  );

export const handleEnableDevelopmentPhase = (action$, state$) =>
  action$.pipe(
    ofType(actions.ENABLE_DEVELOPMENT_PHASE),
    map((action: Action<DevelopmentPhase>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.Crop.cropSelect;
        })
      )
    ),
    concatMap(([payload, crop]: any) => {
      return enableDevelopmentPhase(crop.id, payload.companyId, payload.developmentPhaseId).pipe(
        map(response => response.data),
        concatMap(dp => {
          const newCrop = cloneDeep(crop);
          const valueIndex = newCrop.development_phases.map(dp => dp.id).indexOf(payload.developmentPhaseId);
          if (valueIndex !== -1) {
            delete newCrop.development_phases[valueIndex].deleted_at;
          }
          notification('success', 'Development phase enabled with success!');
          return concat([
            actions.saveDevelopmentPhaseSuccess(dp),
            actions.deleteCustomDevelopmentPhaseSuccess(dp),
            actions.loadDevelopmentPhasesSuccess(newCrop.development_phases),
            cropActions.updateCrop(newCrop)
          ]);
        }),
        catchError(error => {
          return of(actions.deleteCustomDevelopmentPhaseFailure(error));
        })
      );
    })
  );

export const handleEnableCustomDevelopmentPhase = (action$, state$) =>
  action$.pipe(
    ofType(actions.ENABLE_CUSTOM_DEVELOPMENT_PHASE),
    map((action: Action<DevelopmentPhase>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.Crop.cropSelect;
        })
      )
    ),
    concatMap(([payload, crop]: any) => {
      let cropId = payload.cropId ? payload.cropId : crop.id;
      return enableCustomDevelopmentPhase(cropId, payload.companyId, payload.developmentPhaseId).pipe(
        map(response => response.data),
        concatMap(dp => {
          const newCrop = cloneDeep(crop);
          const valueIndex = newCrop.development_phases.map(dp => dp.id).indexOf(payload.developmentPhaseId);
          if (valueIndex !== -1) {
            delete newCrop.development_phases[valueIndex].deleted_at;
          }
          notification('success', 'Development phase enabled with success!');
          return concat([
            actions.saveDevelopmentPhaseSuccess(dp),
            actions.deleteCustomDevelopmentPhaseSuccess(dp),
            actions.loadDevelopmentPhasesSuccess(newCrop.development_phases),
            cropActions.updateCrop(newCrop)
          ]);
        }),
        catchError(error => {
          return of(actions.deleteCustomDevelopmentPhaseFailure(error));
        })
      );
    })
  );
