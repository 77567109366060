import React from 'react';

export interface AlertGlyphProps {
  readonly color?: string;
}

const AlertGlyph = props => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='https://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 13C8.55229 13 9 12.5523 9 12C9 11.4477 8.55229 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13ZM8 3C7.44772 3 7 3.44772 7 4V9C7 9.55228 7.44772 10 8 10C8.55229 10 9 9.55229 9 9V4C9 3.44772 8.55229 3 8 3Z'
      fill={props.color ? props.color : '#F74141'}
    />
  </svg>
);

export default AlertGlyph;
