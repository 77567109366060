import React from 'react';
import CanonicalKeyGlyph from '../../../assets/icons/Glyph/CanonicalKeyGlyph';
import EditGlyph from '../../../assets/icons/Glyph/EditGlyph';
import PlusGlyph from '../../../assets/icons/Glyph/PlusGlyph';
import ChevronUpGlyph from '../../../assets/icons/Glyph/ChevronUpGlyph';
import MoveGlyph from '../../../assets/icons/Glyph/MoveGlyph';
import CloseGlyph from '../../../assets/icons/Glyph/CloseGlyph';
import classNames from 'classnames';
import { Color, DroppableHeaderProps } from '../nestedDraggable.models';
import ChevronDownGlyph from '../../../assets/icons/Glyph/ChevronDownGlyph';
import { Button } from 'antd';
import EyeGlyph from '../../../assets/icons/Glyph/EyeGlyph';
import { ActionButtonType } from '../../actionButtons/models';
import '../nestedDraggable.style.scss';

export const glyphColor = (isCan: boolean | undefined) => {
  if (isCan) return '#FFFFFF';
  return '#5F7803';
};

const { EDIT } = ActionButtonType;

export const DroppableHeader = ({
  showCollapseAll = true,
  showAddButton = true,
  showCloneButton = false,
  buttonDroppableType = EDIT,
  isDragDisabled = false,
  ...props
}: DroppableHeaderProps) => {
  const selectColor = (): Color => {
    switch (props.color) {
      case 'GREEN':
      case 'GREY':
        return {
          textColor: 'color-white',
          glyphColor: '#FFFFFF'
        };
      case 'BLUE':
      default:
        return {
          textColor: 'color-dark',
          glyphColor: '#515253'
        };
    }
  };

  const onAdd = e => {
    e.stopPropagation();
    props.onAdd?.();
  };

  const onClone = e => {
    e.stopPropagation();
    props.onClone?.();
  };

  const onAltAdd = e => {
    e.stopPropagation();
    props.onAltAdd?.();
  };

  const onEdit = e => {
    e.stopPropagation();
    props.onEdit?.();
  };

  const onDelete = e => {
    e.stopPropagation();
    props.onDelete?.();
  };

  const HeaderGlyph = () => {
    return buttonDroppableType === EDIT ? (
      <>
        <EditGlyph color={selectColor().glyphColor} /> Edit
      </>
    ) : (
      <>
        <EyeGlyph color={selectColor().glyphColor} /> Details
      </>
    );
  };

  return (
    <div
      className={
        {
          GREY: 'drag-header--grey',
          GREEN: 'drag-header--green',
          BLUE: 'drag-header--blue',
          default: 'drag-header'
        }[props.color]
      }>
      <div className='flex-space-between flex-baseline'>
        <div>
          <span className='mr-15 pointer p-5'>
            {props.collapseAll ? (
              <ChevronUpGlyph color={selectColor().glyphColor} />
            ) : (
              <ChevronDownGlyph color={selectColor().glyphColor} />
            )}
          </span>
          <span className='header-badge mr-15'>A</span>
          {props.color === 'GREEN' && (
            <span className='mr-15'>
              <CanonicalKeyGlyph color={glyphColor(props.isCanonical)} />
            </span>
          )}
          <span
            className={classNames(`${selectColor().textColor}`, 'mr-15', {
              'text-strong': props.color === 'GREEN'
            })}>
            {props.title || 'Default Text'}
          </span>
          {props.color !== 'BLUE' && (
            <span>
              {props.onEdit !== undefined && (
                <>
                  <span className={`${selectColor().textColor} pointer mr-5`} onClick={e => onEdit(e)}>
                    <HeaderGlyph />
                  </span>
                  {showAddButton && props.onEdit && props.onDelete && <span className={`${selectColor().textColor} mr-5`}>·</span>}
                </>
              )}
              {showAddButton && props.onDelete !== undefined && (
                <span className='mr-5 drag-header__button' onClick={onDelete} aria-label='Remove button'>
                  <CloseGlyph color={selectColor().glyphColor} />
                </span>
              )}
            </span>
          )}
        </div>
        <div className='flex-align-center'>
          {showAddButton && props.altAddButtonText && props.onAltAdd && (
            <Button type='primary' size='small' className='mr-15 btn-gray-light' onClick={e => onAltAdd(e)} data-testid='alt-add-button'>
              <span className='mr-15'>
                <PlusGlyph color='#515253' />
              </span>
              {props.altAddButtonText}
            </Button>
          )}
          {showAddButton && showCloneButton && props.cloneButtonText && props.onClone && (
            <Button
              type='primary'
              size='small'
              className='mr-15 btn-green-primary'
              onClick={e => onClone(e)}
              data-testid='button-clone-characteristic-and-indicator'>
              {props.cloneButtonText}
            </Button>
          )}
          {showAddButton && props.addButtonText && props.onAdd && (
            <Button type='primary' size='small' className='mr-15 btn-green-primary' onClick={e => onAdd(e)} data-testid={props.dataTestId}>
              <span className='mr-15'>
                <PlusGlyph color={selectColor().glyphColor} />
              </span>
              {props.addButtonText}
            </Button>
          )}
          {props.collapseAll && showCollapseAll && (
            <Button
              type='default'
              size='small'
              className={`mr-15  ${
                {
                  GREY: 'btn-gray-primary',
                  BLUE: 'btn-gray-secondary'
                }[props.color]
              }`}>
              <span className='mr-15'>Collapse all</span>
              <span>
                <ChevronUpGlyph color={selectColor().glyphColor} />
              </span>
            </Button>
          )}
          {!isDragDisabled && <span className={`${selectColor().textColor} mr-15`}>MOVE</span>}
          {!isDragDisabled && <MoveGlyph color={selectColor().glyphColor} />}
        </div>
      </div>
    </div>
  );
};
