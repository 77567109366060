import { Characteristic, FeatureFlagEnum, FeatureFlagInverse, UUID } from '../../redux/models';
import { uuid } from '../../helpers';
import { getCharacteristicsIdsByMethodologyDeep } from '../../redux/methodologyDeep/services';
import { FilterCharacteristicsParams } from './models';

const { NO_HEATMAP, NO_TIMELINE } = FeatureFlagEnum;
const { GENERATE_HEATMAP, SHOW_TIMELINE } = FeatureFlagInverse;

export function getParameter(search, queryParameter) {
  const params = new URLSearchParams(search);
  const value = params.get(queryParameter);
  return value;
}

export const buildInitialDefaultIndicator = (companyId?: UUID) =>
  ({
    isNew: true,
    id: uuid(),
    name: { localized_strings: {} },
    default: true,
    description: { localized_strings: {} },
    feature_flags: undefined,
    diagnostics: [],
    output_type: null,
    return_variable: '',
    company_id: companyId,
    expression: {
      characteristics: [],
      output_type: undefined,
      script_body: undefined,
      smdl_rules: undefined,
      type: undefined,
      variables: undefined
    },
    short_description: { localized_strings: {} }
  } as any);

export const mapperFeatureFlagsToInverse = (featureFlags: FeatureFlagEnum[] | string[]) => {
  if (featureFlags) {
    const featureFlagsInverse: FeatureFlagInverse[] = [];
    !featureFlags.includes(NO_TIMELINE) && featureFlagsInverse.push(SHOW_TIMELINE);
    !featureFlags.includes(NO_HEATMAP) && featureFlagsInverse.push(GENERATE_HEATMAP);
    return featureFlagsInverse;
  }
};

export const mapperToFeatureFlags = (featureFlagsInverse: FeatureFlagInverse[] | string[]) => {
  if (featureFlagsInverse) {
    const featureFlags: FeatureFlagEnum[] = [];
    !featureFlagsInverse.includes(SHOW_TIMELINE) && featureFlags.push(NO_TIMELINE);
    !featureFlagsInverse.includes(GENERATE_HEATMAP) && featureFlags.push(NO_HEATMAP);
    return featureFlags;
  }
};

export const filterCharacteristics = ({
  allCharacteristics,
  phenomenon,
  methodologyDeep,
  indicator
}: FilterCharacteristicsParams): Characteristic[] => {
  let characteristics = allCharacteristics || [];
  const phenomenaForm = new Set(phenomenon || []);

  const indicatorCharacteristicIds = new Set(indicator?.expression?.characteristics ?? []);
  const methodologyCharacteristicIds = new Set(getCharacteristicsIdsByMethodologyDeep(methodologyDeep));

  if (indicatorCharacteristicIds.size > 0 || methodologyCharacteristicIds.size > 0) {
    characteristics = characteristics.filter(
      characteristic =>
        characteristic.id && (methodologyCharacteristicIds.has(characteristic.id) || indicatorCharacteristicIds.has(characteristic.id))
    );
  }

  characteristics = characteristics.filter(({ phenomenon_id }) => phenomenon_id && phenomenaForm.has(phenomenon_id));

  return characteristics;
};
