import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PageableQuery, TalosPayload, UUID } from '../app/basicModels';
import { Indicator, IndicatorPage } from './models';
import CONFIG from '../../settings';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

const indicatorUrl = CONFIG.apiUrl + ':' + CONFIG.demeterPort + '/api/v1/indicators';
const cloneIndicatorUrl = CONFIG.apiUrl + ':' + CONFIG.demeterPort + '/v1/indicators';

const validateIndicatorExprUrl = CONFIG.apiUrl + ':' + CONFIG.demeterPort + '/api/v1/indicator/verify';

export const getIndicators = (_query: PageableQuery, companyId: UUID, showCanonicals: boolean): AxiosObservable<IndicatorPage> => {
  if (companyId) {
    return axios.get<IndicatorPage>(
      `${indicatorUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&companyId=${companyId}&showCanonicals=${showCanonicals}`
    );
  } else {
    return axios.get<IndicatorPage>(
      `${indicatorUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&showCanonicals=${showCanonicals}`
    );
  }
};

export const getIndicator = (indicatorId: UUID): AxiosObservable<Indicator> => {
  return axios.get<Indicator>(`${indicatorUrl}/${indicatorId}`);
};

export const saveIndicator = (
  indicator: TalosPayload<Indicator>,
  isNew: boolean,
  defaultExpression: boolean
): AxiosObservable<Indicator> => {
  if (isNew) {
    if (defaultExpression) {
      return createDefaultIndicator(indicator);
    }
    return createIndicator(indicator);
  }
  return updateIndicator(indicator);
};

export const validateIndicatorSimpleExpression = (indicator: Indicator): any => {
  return axios.post<any>(`${validateIndicatorExprUrl}`, indicator).pipe(
    map(response => response && { ...response.data, status: 200 }),
    catchError(error => of(error && error.response && error.response.data))
  );
};

export const createIndicator = (indicator: any): AxiosObservable<Indicator> => {
  return axios.post<Indicator>(`${indicatorUrl}`, indicator);
};

export const createDefaultIndicator = (indicator: any): AxiosObservable<Indicator> => {
  return axios.post<Indicator>(`${indicatorUrl}/default-expression`, indicator);
};

export const updateIndicator = (indicator: any): AxiosObservable<Indicator> => {
  return axios.put<Indicator>(`${indicatorUrl}`, indicator);
};

export const deleteIndicator = (indicator: Indicator): AxiosObservable<Indicator> => {
  return axios.put<Indicator>(`${indicatorUrl}/remove`, indicator);
};

export const getIndicatorsForCompany = (_query: any): AxiosObservable<IndicatorPage> => {
  return axios.get<IndicatorPage>(
    `${indicatorUrl}?page=${_query.page.pageable.page}&size=${_query.page.pageable.size}&companyId=${
      _query.companyId
    }&showCanonicals=${true}`
  );
};

export const cloneIndicator = (indicatorId: UUID, companyId: UUID, enableCottonGrowth?: boolean): AxiosObservable<Indicator> => {
  return enableCottonGrowth
    ? axios.post(`${cloneIndicatorUrl}/${indicatorId}/clone-to-company/${companyId}`)
    : axios.post(`${indicatorUrl}/${indicatorId}/clone-to-company/${companyId}`);
};
