import * as amplitude from '@amplitude/analytics-browser';
import CONFIG from '../settings';

type AnalyticsInfo = {
  cropId?: string;
  cropName?: string;
  companyId?: string;
  companyName?: string;
  propertyId?: string;
  propertyName?: string;
  userId?: string;
  userName?: string;
  jobTitle?: string;
  clonedVariables?: number;
  clonedIndicators?: number;
  clonedPhenomena?: number;
  methodologyId?: string;
};

interface TrackingHookReturn {
  track: (message: string, additionalPayload?: AnalyticsInfo) => void;
  identify: (userId: string) => void;
  init: () => void;
}

export const trackingIdentify = (userId: string) => {
  amplitude.setUserId(userId);
};

export const trackingEvent = (action: string, additionalInfo: AnalyticsInfo = {}) => {
  const payload: AnalyticsInfo = {
    ...additionalInfo
  };

  amplitude.track(action, payload);
};

const useTrackingEvents = (): TrackingHookReturn => {
  const handleTrack = (action: string, additionalInfo: AnalyticsInfo = {}) => {
    trackingEvent(action, additionalInfo);
  };

  const handleInit = () => {
    amplitude.init(CONFIG.amplitudeKey as string, {
      defaultTracking: {
        attribution: false,
        pageViews: false,
        sessions: false,
        fileDownloads: false,
        formInteractions: false
      }
    });
  };

  const handleIdentify = (userId: string) => {
    trackingIdentify(userId);
  };

  return {
    track: handleTrack,
    identify: handleIdentify,
    init: handleInit
  };
};

export default useTrackingEvents;
