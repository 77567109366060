/* eslint-disable import/prefer-default-export */
import { RBACRole, jobTitleWithAccessToPropertiesList } from '../../components/inviteUserByEmailModalRbac/utils/rbacRoles';
import { protectorAppId } from '../../redux/cropwisePlans/models';
import { UserTypeEnum } from '../../redux/models';
import { getWorkspaceIdFromOrgList } from './rbac.functions';
import { RBACFormatToSendProps, RolesLevelAdminEnum, User } from './rbac.query.model';

export const setRBACPropertyRoles = ({
  data,
  orgId,
  workspaceId,
  userTypeSelected
}: {
  data: any;
  orgId: string;
  workspaceId: string;
  userTypeSelected: UserTypeEnum;
}): RBACFormatToSendProps[] => {
  const isUserTypeControleCerto = userTypeSelected === UserTypeEnum.CONTROLE_CERTO;
  const includesJobTitle = jobTitleWithAccessToPropertiesList.includes(data.job_title);
  const hasPropertiesRoles = !!data?.propertiesRoles?.length;

  if ((isUserTypeControleCerto && !includesJobTitle) || !hasPropertiesRoles) return [];

  const hasOwnershipEditor = !!data?.rolesRbac?.ownership?.[RBACRole.OrganizationAdministrator];
  const hasOwnershipView = !!data?.rolesRbac?.ownership?.[RBACRole.OrganizationCollaborator];

  return data?.propertiesRoles?.reduce((acc, value) => {
    if (value?.role?.[RBACRole.PropertyAdministrator] && !hasOwnershipEditor) {
      acc.push({
        org_id: orgId,
        role_context: 'system',
        role_id: RBACRole.PropertyAdministrator,
        propertyHashToVerify: `${RBACRole.PropertyAdministrator}-${value.id}`,
        workspace_id: workspaceId,
        property_id: value.id
      });
    }

    if (value?.role?.[RBACRole.PropertyCollaborator] && !hasOwnershipView) {
      acc.push({
        org_id: orgId,
        role_context: 'system',
        role_id: RBACRole.PropertyCollaborator,
        propertyHashToVerify: `${RBACRole.PropertyCollaborator}-${value.id}`,
        workspace_id: workspaceId,
        property_id: value.id
      });
    }

    return acc;
  }, []);
};

export const createFinalPermissionsToCreateRBACRoles = (data: Record<string, Record<string, any>>) => {
  if (!data?.rolesRbac) return [];

  const rolesRBAC = Object.values(data?.rolesRbac);
  const allowedPermissions = rolesRBAC.map(obj => {
    const keys = Object.keys(obj);

    return keys.filter(key => {
      return obj[key];
    });
  });

  return allowedPermissions.flat();
};

export const mapFinalPermissionsToCreateRBACRoles = ({
  data,
  workspace_id,
  companyID
}: {
  data: Record<string, Record<string, any>>;
  workspace_id: string;
  companyID: string;
}): RBACFormatToSendProps[] => {
  const finalPermissions = createFinalPermissionsToCreateRBACRoles(data);

  return finalPermissions.map(permission => {
    const isOrganizationRole = permission === RBACRole.OrganizationCollaborator || permission === RBACRole.OrganizationAdministrator;

    if (permission === RBACRole.WorkspaceOwner) {
      return {
        role_context: 'system',
        role_id: permission,
        workspace_id
      };
    }

    return {
      org_id: companyID,
      role_context: isOrganizationRole ? 'system' : `app:${protectorAppId}`,
      role_id: permission,
      workspace_id
    };
  });
};

export const createRoles = ({
  data,
  orgId,
  workspaceId,
  userTypeSelected,
  noAdminRole = false
}: {
  data: any;
  orgId: string;
  workspaceId: string;
  userTypeSelected: UserTypeEnum;
  noAdminRole?: boolean;
}) => {
  const rbacSelectedRole: RBACFormatToSendProps[] = createProtectorsAdminRoles(data, noAdminRole);

  const rbacPropertyRoles = setRBACPropertyRoles({
    data,
    orgId,
    workspaceId,
    userTypeSelected
  });

  const rbacRoles = mapFinalPermissionsToCreateRBACRoles({
    companyID: orgId,
    data,
    workspace_id: workspaceId
  });

  return [...rbacRoles, ...rbacPropertyRoles, ...rbacSelectedRole];
};

export const createRolesWithMultipleOrgs = async ({
  data,
  companiesOptions,
  orgId,
  workspaceId
}: {
  data: any;
  companiesOptions: any;
  orgId: string;
  workspaceId: string;
}) => {
  const createOrganizationsRoles = (orgData, companyID, workspace_id): RBACFormatToSendProps[] => {
    return mapFinalPermissionsToCreateRBACRoles({
      data: orgData,
      companyID,
      workspace_id
    });
  };

  const listCompaniesSelected = companiesOptions.filter(item =>
    data?.company ? data?.company.includes(item.id) : data?.companiesByServiceProvider?.includes(item.id)
  );

  const listOrganizations = await getWorkspaceIdFromOrgList(listCompaniesSelected);

  listOrganizations.push({ id: orgId, workspaceID: workspaceId });

  return listOrganizations?.reduce((acc, company) => {
    const organizationRoles = createOrganizationsRoles(data, company.id, company.workspaceID);
    const adminRoles = createProtectorsAdminRoles(data);

    if (!acc[company.id]) {
      acc[company.id] = organizationRoles.concat(adminRoles);
    }

    return acc;
  }, {});
};

export const adapterToRemoveAuthorities = (listUser: User[]) => {
  if (!listUser?.length) return [];

  return listUser?.map(user => {
    if (user?.hasOwnProperty('authorities')) {
      delete user.authorities;
    }
    return user;
  });
};

export const createProtectorsAdminRoles = (adminData, noAdminRole = false): RBACFormatToSendProps[] => {
  if (!adminData?.role?.length || noAdminRole) return [];

  return adminData.role.map(roleId => ({
    role_context: `app:${protectorAppId}`,
    role_id: roleId,
    ...(roleId !== RolesLevelAdminEnum.ProtectorAdminETDENG && { app_id: protectorAppId })
  }));
};
