import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../../../../redux/actions';
import { DroppableHeader } from '../../../../components/nestedDraggable/droppableHeader/droppableHeader';
import { UUID } from '../../../../redux/app/basicModels';
import { HeaderColor } from '../../../../components/nestedDraggable/nestedDraggable.models';
import { ActionButtonType } from '../../../../components/actionButtons/models';
const { toggleAddPhenomenonModal, toggleInspectionGroupModal, toggleAddExtraDimensionModal } = Actions;

interface Props {
  id: UUID;
  name?: string;
  canEditMethodology: boolean;
  systemFlags: any;
  handleToggleAddPhenomModal: Function;
  handleToggleAddExtraDimensionModal: Function;
  handleToggleInspectionGroupModal: Function;
  extraDimensionActive: boolean;
}
interface State {}

const ADD_BUTTON_TEXT = 'Add Phenomenon';
const { DETAILS, EDIT } = ActionButtonType;

class InspectionGroupHeader extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onAddPhenomenon = () => {
    const { handleToggleAddPhenomModal, id } = this.props;
    handleToggleAddPhenomModal(id);
  };

  onAddExtraDimension = () => {
    const { handleToggleAddExtraDimensionModal, id } = this.props;
    handleToggleAddExtraDimensionModal(id);
  };

  onEditInspectionGroup = () => {
    const { handleToggleInspectionGroupModal, id, name } = this.props;
    handleToggleInspectionGroupModal({
      inspectionGroupId: id,
      inspectionGroupName: name
    });
  };

  render() {
    const { id, name, canEditMethodology, systemFlags, extraDimensionActive } = this.props;
    return (
      <DroppableHeader
        id={id}
        title={name}
        color={HeaderColor.GREY}
        collapseAll={true}
        onAdd={this.onAddPhenomenon}
        {...systemFlags.enableExtraDimensions && extraDimensionActive && {altAddButtonText:'Add Resource'}}
        {...systemFlags.enableExtraDimensions && extraDimensionActive && {onAltAdd:this.onAddExtraDimension}}
        addButtonText={ADD_BUTTON_TEXT}
        showAddButton={canEditMethodology}
        dataTestId='button-add-phenomenon'
        onEdit={this.onEditInspectionGroup}
        buttonDroppableType={canEditMethodology ? EDIT : DETAILS}
      />
    );
  }
}

const mapStateToProps = state => ({
  canEditMethodology: state.App.accessFlags.canEditMethodology,
  systemFlags: state.App.systemFlags
});

const mapDispatchToProps = {
  handleToggleAddPhenomModal: toggleAddPhenomenonModal,
  handleToggleInspectionGroupModal: toggleInspectionGroupModal,
  handleToggleAddExtraDimensionModal: toggleAddExtraDimensionModal
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionGroupHeader);
