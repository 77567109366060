import { PageableQuery, UUID } from '../app/basicModels';
import { Crop, CropPage, CropSuccessResponse } from './models';

const actions = {
  LOAD_CROPS: '[Crop] Load Crops',
  LOAD_CROPS_SUCCESS: '[Crop] Load Crops Success',
  LOAD_CROPS_FAILURE: '[Crop] Load Crops Failure',
  SAVE_CROP: '[Crop] Save Crop',
  SAVE_CROP_SUCCESS: '[Crop] Save Crop Success',
  SAVE_CROP_FAILURE: '[Crop] Save Crop Failure',
  UPDATE_CROP: '[Crop] Update Crop',
  LOAD_CROP: '[Crop] Load Crop',
  LOAD_CROP_SUCCESS: '[Crop] Load Crop Success',
  LOAD_CROP_FAILURE: '[Crop] Load Crop Failure',
  DELETE_CROP: '[Crop] Delete Crop',
  DELETE_CROP_SUCCESS: '[Crop] Delete Crop Success',
  DELETE_CROP_FAILURE: '[Crop] Delete Crop Failure',
  CLEAR_CROP_SELECT: '[Crop] Clear Crop Select',
  SELECT_CROP: '[Crop] Select Crop',
  LOAD_COMPANY_CROPS: '[Crop] Load Company Crops',
  LOAD_COMPANY_CROPS_SUCCESS: '[Crop] Load Company Crops Success',
  LOAD_COMPANY_CROPS_FAILURE: '[Crop] Load Company Crops Failure',
  UNLOAD_COMPANY_CROPS: '[Crop] Unload Company Crops',

  loadCrops: (
    pageableQuery: PageableQuery,
    showCanonicals: boolean = true,
    companyId: string | null | undefined = null,
    location: string | undefined = 'BR'
  ) => ({
    type: actions.LOAD_CROPS,
    payload: {
      pageableQuery,
      companyId,
      showCanonicals,
      location
    }
  }),

  loadCropsSuccess: (payload: CropSuccessResponse) => ({
    type: actions.LOAD_CROPS_SUCCESS,
    payload
  }),

  loadCropsFailure: (error: string) => ({
    type: actions.LOAD_CROPS_FAILURE,
    payload: error
  }),

  saveCrop: (crop: Crop, locations?: readonly string[]) => ({
    type: actions.SAVE_CROP,
    payload: { crop, locations }
  }),

  saveCropSuccess: (crop: Crop) => ({
    type: actions.SAVE_CROP_SUCCESS,
    payload: crop
  }),

  saveCropFailure: (error: string) => ({
    type: actions.SAVE_CROP_FAILURE,
    payload: error
  }),

  updateCrop: (crop: Crop, save: boolean = false, locations?: readonly string[]) => ({
    type: actions.UPDATE_CROP,
    payload: {
      crop,
      save,
      locations
    }
  }),

  loadCrop: (cropId: UUID, companyId?: UUID) => ({
    type: actions.LOAD_CROP,
    payload: {
      crop_id: cropId,
      company_id: companyId
    }
  }),

  selectCrop: (crop: Crop) => ({
    type: actions.SELECT_CROP,
    payload: crop
  }),

  loadCropSuccess: (crop: Crop) => ({
    type: actions.LOAD_CROP_SUCCESS,
    payload: crop
  }),

  loadCropFailure: (error: string) => ({
    type: actions.LOAD_CROP_FAILURE,
    payload: error
  }),

  deleteCrop: (crop: Crop) => ({
    type: actions.DELETE_CROP,
    payload: crop
  }),

  deleteCropSuccess: (deleted: boolean) => ({
    type: actions.DELETE_CROP_SUCCESS,
    payload: deleted
  }),

  deleteCropFailure: (error: string) => ({
    type: actions.DELETE_CROP_FAILURE,
    payload: error
  }),

  clearCropSelect: () => ({
    type: actions.CLEAR_CROP_SELECT
  }),

  loadCompanyCrops: (pageableQuery: PageableQuery, companyId: UUID) => ({
    type: actions.LOAD_COMPANY_CROPS,
    payload: {
      pageableQuery,
      companyId
    }
  }),

  loadCompanyCropsSuccess: (crops: Crop[]) => ({
    type: actions.LOAD_COMPANY_CROPS_SUCCESS,
    payload: crops
  }),

  loadCompanyCropsFailure: (error: string) => ({
    type: actions.LOAD_COMPANY_CROPS_FAILURE,
    payload: error
  }),

  unloadCompanyCrops: () => ({
    type: actions.UNLOAD_COMPANY_CROPS
  })
};

export default actions;

export const CropActions = actions;
