import { UUID } from '../models';
import { ExtraDimension } from './models';

const actions = {
  LOAD_EXTRA_DIMENSIONS: '[ExtraDimension] Load Extra Dimensions',
  LOAD_EXTRA_DIMENSIONS_SUCCESS: '[ExtraDimension] Load Extra Dimensions Success',
  LOAD_EXTRA_DIMENSIONS_BY_COMPANY: '[ExtraDimension] Load Extra Dimensions By Company',
  LOAD_EXTRA_DIMENSIONS_BY_COMPANY_SUCCESS: '[ExtraDimension] Load Extra Dimensions By Company Success',
  LOAD_EXTRA_DIMENSIONS_BY_COMPANY_FAILURE: '[ExtraDimension] Load Extra Dimensions By Company Failure',
  SAVE_EXTRA_DIMENSION: '[ExtraDimension] Save ExtraDimension',
  SAVE_EXTRA_DIMENSION_SUCCESS: '[ExtraDimension] Save Extra Dimension Success',
  SAVE_EXTRA_DIMENSION_FAILURE: '[ExtraDimension] Save Extra Dimension Failure',
  DELETE_EXTRA_DIMENSION: '[ExtraDimension] Delete Extra Dimension',
  DELETE_EXTRA_DIMENSION_SUCCESS: '[ExtraDimension] Delete Extra Dimension Success',
  DELETE_EXTRA_DIMENSION_FAILURE: '[ExtraDimension] Delete Extra Dimension Failure',
  TOGGLE_EXTRA_DIMENSION_MODAL: '[ExtraDimension] Toggle development phase modal',
  EXTRA_DIMENSION_SELECT: '[ExtraDimension] Extra Dimension Select',
  CLEAR_EXTRA_DIMENSION_SELECT: '[ExtraDimension] Clear Extra Dimension Select',
  CLEAR_EXTRA_DIMENSIONS_ENTITY: '[ExtraDimension] Clear Extra Dimension Entity',

  loadExtraDimensions: () => ({
    type: actions.LOAD_EXTRA_DIMENSIONS
  }),

  loadExtraDimensionsSuccess: (extraDimensions: ExtraDimension[]) => ({
    type: actions.LOAD_EXTRA_DIMENSIONS_SUCCESS,
    payload: extraDimensions
  }),

  loadExtraDimensionsByCompany: companyId => ({
    type: actions.LOAD_EXTRA_DIMENSIONS_BY_COMPANY,
    payload: { companyId }
  }),

  loadExtraDimensionsByCompanySuccess: (extraDimensions: ExtraDimension[]) => ({
    type: actions.LOAD_EXTRA_DIMENSIONS_BY_COMPANY_SUCCESS,
    payload: extraDimensions
  }),

  loadExtraDimensionsByCompanyFailure: (error: any) => ({
    type: actions.LOAD_EXTRA_DIMENSIONS_BY_COMPANY_SUCCESS,
    error
  }),

  saveExtraDimension: (extraDimension: ExtraDimension, inspectionGroupId: UUID, isNew: boolean) => ({
    type: actions.SAVE_EXTRA_DIMENSION,
    payload: { extraDimension, inspectionGroupId, isNew }
  }),

  saveExtraDimensionSuccess: (extraDimension: ExtraDimension) => ({
    type: actions.SAVE_EXTRA_DIMENSION_SUCCESS,
    payload: extraDimension
  }),

  saveExtraDimensionFailure: error => ({
    type: actions.SAVE_EXTRA_DIMENSION_FAILURE,
    payload: error
  }),

  deleteExtraDimension: (extraDimension: ExtraDimension, companyId?: UUID) => ({
    type: actions.DELETE_EXTRA_DIMENSION,
    payload: {
      extraDimension,
      companyId
    }
  }),

  deleteExtraDimensionFailure: (error: Error) => ({
    type: actions.DELETE_EXTRA_DIMENSION_FAILURE,
    payload: error
  }),

  deleteExtraDimensionSuccess: (deleted: boolean) => ({
    type: actions.DELETE_EXTRA_DIMENSION_SUCCESS,
    payload: deleted
  }),

  toggleExtraDimensionModal: () => ({
    type: actions.TOGGLE_EXTRA_DIMENSION_MODAL
  }),

  extraDimensionSelect: (extraDimension: ExtraDimension | null) => ({
    type: actions.EXTRA_DIMENSION_SELECT,
    payload: extraDimension
  }),

  clearExtraDimensionSelect: () => ({
    type: actions.CLEAR_EXTRA_DIMENSION_SELECT
  }),

  clearExtraDimensionsEntity: () => ({
    type: actions.CLEAR_EXTRA_DIMENSIONS_ENTITY
  })
};

export default actions;

export const ExtraDimensionActions = actions;
