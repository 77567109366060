import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import CONFIG from '../../settings';
import { UUID } from '../models';

const developmentPhaseUrl = CONFIG.apiUrl + ':' + CONFIG.demeterPort;
export const createCustomDevelopmentPhase = (developmentPhaseReq: any, cropId: UUID): AxiosObservable<any> => {
  return axios.post<any>(`${developmentPhaseUrl}/api/v1/crops/${cropId}/custom-development-phases`, developmentPhaseReq);
};

export const createDevelopmentPhase = (developmentPhaseReq: any): AxiosObservable<any> => {
  return axios.post<any>(`${developmentPhaseUrl}'/api/v1/crops/development-phase'`, developmentPhaseReq);
};

export const editCustomDevelopmentPhase = (developmentPhaseReq: any, cropId: UUID, developmentPhaseId: UUID): AxiosObservable<any> => {
  return axios.put<any>(
    `${developmentPhaseUrl}/api/v1/crops/${cropId}/custom-development-phases/${developmentPhaseId}`,
    developmentPhaseReq
  );
};

export const editDevelopmentPhase = (developmentPhaseReq: any): AxiosObservable<any> => {
  return axios.put<any>(`${developmentPhaseUrl}'/api/v1/crops/development-phase'`, developmentPhaseReq);
};

export const deleteCustomDevelopmentPhase = (cropId: UUID, developmentPhaseId: UUID): AxiosObservable<any> => {
  return axios.delete<any>(`${developmentPhaseUrl}/api/v1/crops/${cropId}/custom-development-phases/${developmentPhaseId}`);
};

export const enableCustomDevelopmentPhase = (cropId: UUID, companyId: UUID, developmentPhaseId: UUID): AxiosObservable<any> => {
  let config = {
    headers: {
      'X-COMPANY-ID': companyId
    }
  };
  return axios.patch<any>(
    `${developmentPhaseUrl}/api/v1/crops/${cropId}/custom-development-phases/${developmentPhaseId}/enable`,
    {},
    config
  );
};

export const enableDevelopmentPhase = (cropId: UUID, companyId: UUID, developmentPhaseId: UUID): AxiosObservable<any> => {
  let config = {
    headers: {
      'X-COMPANY-ID': companyId
    }
  };
  return axios.patch<any>(`${developmentPhaseUrl}/api/v1/crops/${cropId}/development-phases/${developmentPhaseId}/enable`, {}, config);
};

export const deleteDevelopmentPhase = (cropId: UUID, developmentPhaseId: UUID, payload: any, companyId: UUID): AxiosObservable<any> => {
  let config;
  if (companyId) {
    config = {
      headers: {
        'X-COMPANY-ID': companyId
      }
    };
  }
  config = { ...config, data: payload };
  return axios.delete<any>(`${developmentPhaseUrl}/api/v1/crops/${cropId}/development-phases/${developmentPhaseId}`, config);
};
