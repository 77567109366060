import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { CommandRequest } from '../../helpers/request/models';
import CONFIG from '../../settings';
import { UUID } from '../models';
import { ExtraDimension } from './models';

const extraDimenstionsUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}`;
export const createExtraDimension = (request: CommandRequest<ExtraDimension>): AxiosObservable<any> => {
  const objToSave = {
    account: request.account,
    payload: {
      company_id: request.payload.company_id,
      id: request.payload.id,
      name: request.payload.name,
      description: request.payload.description,
      required: request.payload.required,
      values: request.payload.values
    }
  };
  return axios.post<any>(`${extraDimenstionsUrl}/api/v1/extra-dimensions`, objToSave);
};

export const updateExtraDimension = (request: CommandRequest<ExtraDimension>): AxiosObservable<any> => {
  const objToSave = {
    account: request.account,
    payload: {
      id: request.payload.id,
      company_id: request.payload.company_id,
      name: request.payload.name,
      description: request.payload.description,
      required: request.payload.required,
      values: request.payload.values
    }
  };
  return axios.put<any>(`${extraDimenstionsUrl}/api/v1/extra-dimensions`, objToSave);
};

export const loadAllExtraDimensions = (): AxiosObservable<any> => {
  return axios.get<any>(`${extraDimenstionsUrl}/api/v1/extra-dimensions`);
};

export const loadExtraDimension = (id: UUID): AxiosObservable<any> => {
  return axios.get<any>(`${extraDimenstionsUrl}/api/v1/extra-dimensions/${id}`);
};

export const loadAllExtraDimensionsByCompany = (companyId: UUID): AxiosObservable<any> => {
  return axios.get<any>(`${extraDimenstionsUrl}/api/v1/companies/${companyId}/extra-dimensions`);
};
