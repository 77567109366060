import { UserCredentials, UserToken, LoginError } from './models';

const actions = {
  LOGIN_REQUEST: '[Auth Service] Request Login',
  LOGIN_SUCCESS: '[Auth Service] Login Success',
  LOGIN_FAILURE: '[Auth Service] Login Failure',

  LOGOUT_REQUEST: '[Auth Service] Logout Request',
  LOGOUT_SUCCESS: '[Auth Service] Logout Success',
  LOGOUT_FAILURE: '[Auth Service] Logout Failure',

  CHECK_AUTHORIZATION: '[Auth Service] Check Authorization',

  loginRequest: (userCredentials: UserCredentials) => ({
    type: actions.LOGIN_REQUEST,
    payload: userCredentials
  }),

  loginSuccess: (token: UserToken) => ({
    type: actions.LOGIN_SUCCESS,
    payload: token
  }),

  loginFailure: (error: LoginError) => ({
    type: actions.LOGIN_FAILURE,
    payload: error
  }),

  logoutRequest: () => ({
    type: actions.LOGOUT_REQUEST
  }),

  logoutSuccess: () => ({
    type: actions.LOGOUT_SUCCESS
  }),

  logoutFailure: (error: LoginError) => ({
    type: actions.LOGOUT_FAILURE,
    payload: error
  }),

  checkAuthorization: () => ({
    type: actions.CHECK_AUTHORIZATION
  })
};

export default actions;
