import {  Action, UUID } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map, concatMap, catchError, delay, withLatestFrom } from 'rxjs/operators';
import { of, Observable, concat } from 'rxjs';
import { getVendors, getVendor, saveVendor, deleteVendor } from './service';
import { VendorPage, Vendor, LoadVendorQueryPayload } from './models';
import { notification } from '../../components';
import { injectMetadata } from '../request-control';
import actions from './actions';
import { ProductActions } from '../../redux/actions';
import { cloneDeep } from 'lodash';
import { Product } from '../models';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';

const { loadProductSuccess } = ProductActions;

export const handleLoadVendors = action$ =>
  action$.pipe(
    ofType(actions.LOAD_VENDORS),
    map((action: Action<LoadVendorQueryPayload>) => action.payload),
    concatMap((query: LoadVendorQueryPayload) =>
      getVendors(query.pageableQuery, query.companyId, query.showCanonicals).pipe(
        map(response => response.data),
        map((vendors: VendorPage) => {
          return actions.loadVendorsSuccess(vendors);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading vendors!');
          return of(actions.loadVendorsFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadVendor = action$ =>
  action$.pipe(
    ofType(actions.LOAD_VENDOR),
    map((action: Action<UUID>) => action.payload),
    concatMap((vendorId: UUID) =>
      getVendor(vendorId).pipe(
        map(response => response.data),
        map((vendor: Vendor) => {
          return actions.loadVendorSucces(vendor);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading vendor!');
          return of(actions.loadVendorFailure(errorMsg));
        })
      )
    )
  );

export const handleSaveVendor = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_VENDOR),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return {
            currentUser: state.User.currentUser,
            productSelect: state.Product.productSelect
          };
        })
      )
    ),
    concatMap(([action, state]: any) => {
      let vendor = action.payload;
      const vendorToSave = {
        account: {
          id: state.currentUser.id,
          name: state.currentUser.name
        },
        payload: {
          ...vendor
        }
      };

      vendor = vendorToSave;

      return saveVendor(vendor, vendor.payload.isNew).pipe(
        concatMap(response => {
          vendor.payload.isNew = false;
          const saveVendorSuccess = injectMetadata(actions.saveVendorSucces, { ...action.metadata, response });
          if (state.productSelect) {
            const mutableProduct: Product = cloneDeep(state.productSelect);
            notification('success', 'Vendor saved with success!');
            mutableProduct.vendor_id = vendor.payload.id;
            return concat([saveVendorSuccess(vendor.payload), loadProductSuccess(mutableProduct)]);
          }
          return concat([saveVendorSuccess(vendor.payload)]);
        }),
        catchError((error: any) => {
          const msg = error.response && error.response.data ? error.response.data.message : '';
          const saveVendorFailure = injectMetadata(actions.saveVendorFailure, { ...action.metadata, response: error.response });
          return of(saveVendorFailure(msg));
        })
      );
    })
  );

export const handleDeleteVendor = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_VENDOR),
    map((action: Action<Vendor>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      )
    ),
    map(([vendor, currentUser]: any) => {
      const vendorToSave = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          ...vendor
        }
      };
      return vendorToSave;
    }),
    concatMap((vendor: any) =>
      deleteVendor(vendor).pipe(
        map(response => {
          notification('success', 'Vendor deleted!');
          return actions.deleteVendorSucces(vendor.payload);
        }),
        catchError((error: any) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error deleting vendor!');
          return of(actions.deleteVendorFailure(errorMsg));
        })
      )
    )
  );

export const handleSaveVendorSuccess = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_VENDOR_SUCCESS),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.Vendor.editModalActive;
        })
      )
    ),
    concatMap(
      ([, editModalActive]): Observable<any> => {
        if (editModalActive) {
          return of(actions.toggleVendorEditModal());
        }
        return of();
      }
    )
  );
