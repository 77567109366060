import { Popconfirm } from 'antd';
import React from 'react';
import { GenericPopConfirmProps } from './model/genericPopConfirm.model';
import AlertGlyph from '../../assets/icons/Glyph/AlertGlyph';
import './genericPopConfirm.style.scss';

const GenericPopConfirm = ({
  placement,
  icon,
  iconColor,
  title,
  okText,
  okType,
  onConfirm,
  cancelText,
  onCancel,
  className,
  children,
  disabled
}: GenericPopConfirmProps) => {
  return (
    <div className='confirm-box d-flex'>
      <Popconfirm
        placement={placement || 'top'}
        icon={
          icon || (
            <div className='confirm-box__icon'>
              <AlertGlyph color={iconColor} />
            </div>
          )
        }
        title={title}
        okText={okText || 'Yes'}
        okType={okType || 'danger'}
        onConfirm={onConfirm}
        cancelText={cancelText || 'No'}
        onCancel={onCancel}
        overlayClassName={className}
        disabled={disabled || false}>
        <a>{children}</a>
      </Popconfirm>
    </div>
  );
};

export default GenericPopConfirm;
