import actions from './actions';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';
import { DevelopmentPhase, DevelopmentPhaseState } from './models';

const entity: EntityAdapter<DevelopmentPhase> = createEntityAdapter<DevelopmentPhase>();

const initialState: DevelopmentPhaseState = entity.getInitialState({
  isLoading: false,
  isSaving: false,
  error: null,
  isModalActive: false,
  developmentPhaseSelect: undefined,
  developmentPhases: []
});

export default (state = initialState, action): DevelopmentPhaseState => {
  switch (action.type) {
    case actions.TOGGLE_DEVELOPMENT_PHASE_MODAL:
      return {
        ...state,
        isModalActive: !state.isModalActive
      };

    case actions.DEVELOPMENT_PHASE_SELECT:
      return {
        ...state,
        developmentPhaseSelect: action.payload
      };

    case actions.LOAD_DEVELOPMENT_PHASES:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_DEVELOPMENT_PHASES_SUCCESS:
      return {
        ...state,
        developmentPhases: action.payload,
        isLoading: false,
        error: null
      };

    case actions.SAVE_DEVELOPMENT_PHASE:
      return {
        ...state,
        isSaving: true
      };

    case actions.SAVE_DEVELOPMENT_PHASE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        error: null,
        developmentPhaseSelect: action.payload,
        isModalActive: false
      };

    case actions.CLEAR_DEVELOPMENT_PHASE_SELECT:
      return {
        ...state,
        developmentPhaseSelect: undefined
      };
    default:
      return state;
  }
};
