import React, { Component } from 'react';
import Popconfirm from '../../components/feedback/popconfirm';
import Button from '../../components/uielements/button';
import BinOutline from '../../assets/icons/Glyph/BinOutline';
import IntlMessages from '../utility/intlMessages';

export interface Props {
  handleDelete: Function;
  title: string;
  smallButton?: boolean;
  name?: string;
  class?: string;
  color?: string;
  testId?: string;
}

export default class DeleteButton extends Component<Props> {
  render() {
    const { handleDelete, title, smallButton, name, color, testId } = this.props;

    return (
      <Popconfirm
        title={title}
        okText={<IntlMessages id='button.delete' />}
        cancelText={<IntlMessages id='button.no' />}
        onConfirm={() => handleDelete()}>
        {smallButton ? (
          <Button className={this.props.class} aria-label={'delete button'} data-testid={ testId || 'delete-button'} type='link'>
            <BinOutline color={color} />
          </Button>
        ) : (
          <Button className={this.props.class} type='danger' style={{ marginLeft: '10px' }}>
            {!name ? <IntlMessages id='button.delete' /> : <IntlMessages id={'button.' + name} />}
          </Button>
        )}
      </Popconfirm>
    );
  }
}

export { DeleteButton };
