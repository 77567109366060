export enum RBACRole {
  InventoryAdministrator = 'InventoryAdministrator',
  InventoryCollaborator = 'InventoryCollaborator',
  PanelMethodologyEdit = 'PanelMethodologyEdit',
  PanelMethodologyView = 'PanelMethodologyView',
  MethodologyCollaborator = 'MethodologyCollaborator',
  SugarcaneAdministrator = 'SugarcaneAdministrator',
  SugarcaneCollaborator = 'SugarcaneCollaborator',
  FinancialAdministrator = 'FinancialAdministrator',
  FinancialCollaborator = 'FinancialCollaborator',
  OrganizationCollaborator = 'OrganizationCollaborator',
  OrganizationAdministrator = 'OrganizationAdministrator',
  OpportunityMachineCollaborator = 'SugarcaneCollaborator',
  OpportunityMachineAdministrator = 'SugarcaneAdministrator',
  PropertyCollaborator = 'PropertyCollaborator',
  PropertyAdministrator = 'PropertyAdministrator',
  WorkspaceOwner = 'WorkspaceOwner',
  ProtectorAdminETDENG = 'ProtectorAdminETDENG',
  OrganizationFarmCreator = 'OrganizationFarmCreator',
  OrganizationGuest = 'OrganizationGuest',
  PropertyGuest = 'PropertyGuest'
}

export const rolesUsedOnlyInAdmin = [
  'InventoryAdministrator',
  'InventoryCollaborator',
  'PanelMethodologyEdit',
  'PanelMethodologyView',
  'MethodologyCollaborator',
  'SugarcaneAdministrator',
  'SugarcaneCollaborator',
  'FinancialAdministrator',
  'FinancialCollaborator',
  'OrganizationCollaborator',
  'OrganizationAdministrator',
  'PropertyCollaborator',
  'PropertyAdministrator',
  'WorkspaceOwner',
  'ProtectorAdminETDENG',
  'ProtectorAdminCC',
  'ProtectorAdminSupportAgent'
];

export const rolesAdminLevel = ['ProtectorAdminCC', 'ProtectorAdminSupportAgent', 'ProtectorAdminETDENG'];

export const rolesHasContentTypeApp: string[] = [
  RBACRole.PropertyAdministrator,
  RBACRole.PropertyCollaborator,
  RBACRole.OrganizationAdministrator,
  RBACRole.OrganizationCollaborator,
  RBACRole.OrganizationFarmCreator,
  RBACRole.OrganizationGuest,
  RBACRole.PropertyGuest
];

export const rolesHasPropertyId: string[] = [
  RBACRole.PropertyAdministrator,
  RBACRole.PropertyCollaborator,
  RBACRole.OrganizationAdministrator,
  RBACRole.OrganizationCollaborator,
  RBACRole.PropertyGuest
];

export const rolesOption = [
  { id: 'ProtectorAdminCC', name: 'ProtectorAdminCC' },
  { id: 'ProtectorAdminSupportAgent', name: 'ProtectorAdminSupportAgent' },
  { id: 'ProtectorAdminETDENG', name: 'ProtectorAdminETDENG' }
];

export const jobTitles = [
  { id: 'GROWER', name: 'Grower' },
  { id: 'MANAGER', name: 'Manager' },
  { id: 'SYNGENTA_INTERNAL', name: 'Syngenta Internal' },
  { id: 'SYNGENTA_GROUP', name: 'Syngenta Externo' },
  { id: 'ADMIN_CONSULTANCY', name: 'Admin Consultoria' },
  { id: 'DEALER', name: 'Dealer' },
  { id: 'CONSULTANT', name: 'Consultant' },
  { id: 'AGRONOMIST', name: 'Agronomist' },
  { id: 'SALES_REPRESENTATIVE', name: 'Sales Representative' },
  { id: 'FIELD_TECHNICIAN', name: 'Field Technician' },
  { id: 'OTHER', name: 'Other' }
];

export const jobTitleWithAccessToPropertiesList = ['GROWER', 'AGRONOMIST', 'CONSULTANT'];

export const jobTitleWithAccessToMultiOrgSelect = ['ADMIN_PROVIDER', 'RTV', 'DEALER', 'SYNGENTA_EXTERNO'];
export const jobTitleWithAccessServiceProvider = ['ADMIN_CONSULTANCY', 'FIELD_TECHNICIAN', 'ADMIN_CONSULTORIA'];

const defaultRolesByJobTitle = {
  inventory: {
    [RBACRole.InventoryAdministrator]: false,
    [RBACRole.InventoryCollaborator]: false
  },
  financial: {
    [RBACRole.FinancialCollaborator]: false
  },
  sugarcane: {
    [RBACRole.SugarcaneAdministrator]: false,
    [RBACRole.SugarcaneCollaborator]: false
  },
  methodology: {
    [RBACRole.MethodologyCollaborator]: true
  },
  ownership: {
    [RBACRole.OrganizationAdministrator]: false,
    [RBACRole.OrganizationCollaborator]: true
  },
  opportunityMachine: {
    [RBACRole.OpportunityMachineAdministrator]: false,
    [RBACRole.OpportunityMachineCollaborator]: false
  },
  workspace: {
    [RBACRole.WorkspaceOwner]: false
  }
};

export const rolesByJobTitle = {
  FIELD_TECHNICIAN: defaultRolesByJobTitle,
  ADMIN_CONSULTANCY: defaultRolesByJobTitle,
  DEALER: defaultRolesByJobTitle,
  SYNGENTA_GROUP: defaultRolesByJobTitle
};

export const rolesLevelOrg = [RBACRole.OrganizationCollaborator, RBACRole.OrganizationAdministrator, RBACRole.WorkspaceOwner];
