import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PageableQuery, UUID } from '../app/basicModels';
import { ProductPage, Product } from './models';
import CONFIG from '../../settings';

const productUrl = CONFIG.apiUrl + ':' + CONFIG.demeterPort + '/api/v1/products';

export const getProducts = (
  _query: PageableQuery,
  companyId: UUID,
  showCanonicals: boolean,
  type: string,
  location: string
): AxiosObservable<ProductPage> => {
  if (companyId) {
    return axios.get<ProductPage>(
      `${productUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&companyId=${companyId}&showCanonicals=${showCanonicals}${
        type ? `&type=${type}` : ''
      }`
    );
  } else {
    return axios.get<ProductPage>(
      `${productUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&showCanonicals=${showCanonicals}${
        type ? `&type=${type}` : ''
      }&location=${location}`
    );
  }
};

export const getProduct = (productId: UUID): AxiosObservable<Product> => {
  return axios.get<Product>(`${productUrl}/${productId}`);
};

export const saveProduct = (product: Product, isNew: boolean): AxiosObservable<Product> => {
  if (isNew) {
    return createProduct(product);
  }
  return updateProduct(product);
};

export const createProduct = (product: any): AxiosObservable<Product> => {
  return axios.post<Product>(`${productUrl}`, product);
};

export const updateProduct = (product: any): AxiosObservable<Product> => {
  return axios.put<Product>(`${productUrl}`, product);
};

export const deleteProduct = (product: Product): AxiosObservable<Product> => {
  return axios.put<Product>(`${productUrl}/remove`, product);
};
