export const hasPermission = (user, reqPermission, scope) => {
  return user.authorities
    ?.filter(auth => {
      return auth.context === 'USER';
    })
    ?.map(userAuth => {
      return userAuth.permissions.some(
        permission => permission.name === reqPermission && (permission.scope === scope || permission.scope === 'WRITE')
      );
    })
    ?.values()
    ?.next().value;
};

export const findOrgDevicePermission = (user, org) => {
  return user.authorities.find(userAuth => {
    return (
      userAuth.id === org.id &&
      userAuth.permissions.some(
        permission => permission.name === 'DEVICES' && (permission.scope === 'READ' || permission.scope === 'WRITE')
      )
    );
  });
};

export enum UserPermissions {
  METHODOLOGY = 'METHODOLOGY',
  FIXED_POINTS = 'FIXED_POINTS',
  CANONICAL_CROP = 'CANONICAL_CROP',
  CANONICAL_PHENOMENON = 'CANONICAL_PHENOMENON',
  CANONICAL_INDICATORS = 'CANONICAL_INDICATORS',
  CANONICAL_CHARACTERISTICS = 'CANONICAL_CHARACTERISTICS',
  CANONICAL_PHENOMENON_CATEGORY = 'CANONICAL_PHENOMENON',
  CANONICAL_MULTI_PHENOMENON_INDICATOR = 'CANONICAL_MULTI_PHENOMENON_INDICATOR',
  CANONICAL_PRODUCT = 'CANONICAL_PRODUCT',
  CANONICAL_PRODUCT_SET = 'CANONICAL_PRODUCT_SET',
  CANONICAL_VENDOR = 'CANONICAL_VENDOR',
  DEVICES = 'DEVICES',
  ADMIN_ACCESS = 'ADMIN_ACCESS',
  ADMIN_DEVICES = 'ADMIN_DEVICES',
  CUSTOM_INDICATORS = 'CUSTOM_INDICATORS',
  CUSTOM_CHARACTERISTICS = 'CUSTOM_CHARACTERISTICS',
  CUSTOM_PHENOMENON = 'CUSTOM_PHENOMENON',
  CUSTOM_CROPS = 'CUSTOM_CROPS'
}

export enum PermissionScope {
  READ = 'READ',
  WRITE = 'WRITE'
}
