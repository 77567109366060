import React from 'react';

const enUSA = () => (
  <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <rect width='16' height='12' fill='white' />
    <rect width='16' height='1' fill='#BD3D44' />
    <rect y='2' width='16' height='1' fill='#BD3D44' />
    <rect y='4' width='16' height='1' fill='#BD3D44' />
    <rect y='6' width='16' height='1' fill='#BD3D44' />
    <rect y='8' width='16' height='1' fill='#BD3D44' />
    <rect y='10' width='16' height='1' fill='#BD3D44' />
    <path fillRule='evenodd' clipRule='evenodd' d='M0 0H7V5H0V0Z' fill='#1449B9' />
  </svg>
);

export default enUSA;
