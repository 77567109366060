import { PageableQuery, Action, UUID } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map, concatMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import actions from './actions';
import { SeasonTree } from './models';
import { getSeasonsTreeByCompany } from '../company/service';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';

export const handleLoadSeasonsTree = action$ =>
  action$.pipe(
    ofType(actions.LOAD_SEASONS_TREE),
    map((action: Action<PageableQuery>) => action.payload),
    concatMap((companyId: UUID) =>
      getSeasonsTreeByCompany(companyId).pipe(
        map(response => response.data),
        map((seasonsTree: SeasonTree[]) => {
          return actions.loadSeasonsTreeSuccess(seasonsTree);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading seasons, season properties and season areas!');
          return of(actions.loadSeasonsTreeFailure(errorMsg));
        })
      )
    )
  );
