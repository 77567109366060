import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Action, Page, UUID } from '../app/basicModels';
import actions from './actions';
import {
  getServiceProviders,
  getCompaniesByServiceProvider,
  createServiceProvider,
  updateServiceProvider,
  deleteServiceProvider
} from './service';
import { ServiceProvider } from './models';
import { Company } from '../models';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';
import { notification } from '../../components';

export const handleGetServiceProviders = action$ =>
  action$.pipe(
    ofType(actions.LOAD_SERVICE_PROVIDERS),
    map((action: Action<any>) => action.payload),
    concatMap(() => {
      return getServiceProviders().pipe(
        map(response => response.data),
        map((response: Page<ServiceProvider>) => actions.loadServiceProvidersSuccess(response.content)),
        catchError(error => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading service providers!');
          return of(actions.loadServiceProvidersFailure(errorMsg));
        })
      );
    })
  );

export const handleGetCompaniesByServiceProvider = action$ =>
  action$.pipe(
    ofType(actions.LOAD_COMPANIES_BY_SERVICE_PROVIDER),
    map((action: Action<object>) => action.payload),
    concatMap((serviceProviderId: UUID) =>
      getCompaniesByServiceProvider(serviceProviderId).pipe(
        map(response => response.data),
        map((response: Page<Company>) => actions.loadCompaniesByServiceProviderSuccess(response.content)),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading companies by service provider!');
          return of(actions.loadCompaniesByServiceProviderFailure(errorMsg));
        })
      )
    )
  );

export const handleCreateServiceProvider = action$ =>
  action$.pipe(
    ofType(actions.CREATE_SERVICE_PROVIDER),
    map((action: Action<object>) => action.payload),
    concatMap((serviceProvider: ServiceProvider) =>
      createServiceProvider(serviceProvider).pipe(
        map(response => response.data),
        map((response: ServiceProvider) => {
          notification('success', 'Service Provider created!');
          return actions.createServiceProviderSuccess(response);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error creating Service Provider!');
          return of(actions.createServiceProviderFailure(errorMsg));
        })
      )
    )
  );

export const handleUpdateServiceProvider = action$ =>
  action$.pipe(
    ofType(actions.UPDATE_SERVICE_PROVIDER),
    map((action: Action<object>) => action.payload),
    concatMap((serviceProvider: ServiceProvider) =>
      updateServiceProvider(serviceProvider).pipe(
        map(response => response.data),
        map((response: ServiceProvider) => {
          notification('success', 'Service Provider updated!');
          return actions.updateServiceProviderSuccess(response);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error updating Service Provider!');
          return of(actions.updateServiceProviderFailure(errorMsg));
        })
      )
    )
  );

export const handleDeleteServiceProvider = action$ =>
  action$.pipe(
    ofType(actions.DELETE_SERVICE_PROVIDER),
    map((action: Action<object>) => action.payload),
    concatMap((serviceProviderId: UUID) =>
      deleteServiceProvider(serviceProviderId).pipe(
        map(() => {
          notification('success', 'Service Provider deleted!');
          return actions.deleteServiceProviderSuccess(serviceProviderId);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error deleting Service Provider!');
          return of(actions.updateServiceProviderFailure(errorMsg));
        })
      )
    )
  );
