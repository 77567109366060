import actions from './actions';
import { VendorState, Vendor, VendorPage } from './models';
import { initialPage, Page } from '../app/basicModels';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';

const entity: EntityAdapter<Vendor> = createEntityAdapter<Vendor>();

const initialState: VendorState = entity.getInitialState({
  page: {
    ...initialPage
  },
  isLoading: false,
  editModalActive: false,
  error: null,
  isSaving: false,
  vendorSelect: null,
  vendorSelectLoading: false
});

export default (state = initialState, action): VendorState => {
  switch (action.type) {
    case actions.LOAD_VENDORS:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_VENDORS_SUCCESS:
      return entity.addAll((action.payload as VendorPage).content, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null
      });

    case actions.LOAD_VENDORS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.SAVE_VENDOR:
      return {
        ...state,
        isSaving: true
      };

    case actions.SAVE_VENDOR_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isSaving: false,
        error: null,
        vendorSelect: action.payload
      });

    case actions.SAVE_VENDOR_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case actions.LOAD_VENDOR:
      return {
        ...state,
        vendorSelectLoading: true,
        vendorSelect: null
      };

    case actions.LOAD_VENDOR_SUCCESS:
      return {
        ...state,
        vendorSelectLoading: false,
        vendorSelect: action.payload
      };

    case actions.LOAD_VENDOR_FAILURE:
      return {
        ...state,
        vendorSelectLoading: false,
        error: action.payload
      };

    case actions.DELETE_VENDOR_SUCCESS:
      return entity.removeOne(action.payload.id, {
        ...state,
        error: null
      });

    case actions.DELETE_VENDOR_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case actions.TOGGLE_VENDOR_EDIT_MODAL:
      return {
        ...state,
        editModalActive: !state.editModalActive
      };

    case actions.CLEAR_VENDOR_SELECT:
      return {
        ...state,
        vendorSelect: null
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectVendorsIds = selectIds;
export const selectVendorEntities = selectEntities;
export const selectAllVendors = selectAll;
export const selectVendorTotal = selectTotal;
