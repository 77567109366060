import { EntityState } from 'redux-ngrx-entity';
import { PropertyUser, UUID } from '../models';

export interface Property {
  address?: string;
  city?: string;
  companyId: UUID;
  country?: string;
  id: UUID;
  name: string;
  referencePoint?: PropertyAddressPoints;
  state?: string;
  timeZone?: string;
  totalHectares?: number;
  zipCode?: string;
}

export interface PropertyAddressPoints {
  type: string;
  coordinates: ReadonlyArray<number>;
}

export interface PropertyState extends EntityState<Property> {
  isLoading: boolean;
  error?: string;
  propertySelect?: Property;
  isLoadingPropertySelect: boolean;
  isUploadingFile: boolean;
}

export const PropertyAdapter = (property: PropertyUser): Property => ({
  address: property.address,
  city: property.city,
  companyId: property.org_id,
  country: property.country,
  id: property.id,
  name: property.name,
  referencePoint: property.reference_point,
  state: property.state,
  timeZone: property.time_zone,
  totalHectares: property.declared_area,
  zipCode: property.zip_code
});
