import React from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'antd';
import ReactInputMask from 'react-input-mask';

interface InputMaskProps {
  name: string;
  mask?: string;
  placeholder?: string;
  disabled?: boolean;
  size?: 'small' | 'default' | 'large';
  control?: any;
  initialValue?: string;
}

const InputWithMaskItem = (props: InputMaskProps) => (
  <Controller
    name={props.name}
    as={
      <ReactInputMask {...props}>
        {() => (
          <Input
            id={props.name}
            name={props.name}
            disabled={props.disabled || undefined}
            size={props.size}
            placeholder={props.placeholder ? props.placeholder : ''}
          />
        )}
      </ReactInputMask>
    }
    control={props.control}
    defaultValue={props.initialValue}
  />
);

export default InputWithMaskItem;
