import React, { useState, useEffect } from 'react';
import { ColorOptions, TypeOptions } from '../typo/STTypo.models';
import STTypo from '../typo/STTypo';
import axios from 'axios';
import * as VERSION from '../../VERSION';

export const AppVersion = () => {
  const [version, setVersion] = useState('');
  useEffect(() => {
    axios
      .get(VERSION)
      .then(response => {
        if (response.data) {
          setVersion(response.data);
        }
      })
      .catch(() => {
        setVersion("Couldn't get Version");
      });
  }, []);
  return (
    <div className='system-version' data-testid='system-version'>
      {version && (
        <STTypo color={ColorOptions.SECONDARY} type={TypeOptions.C2}>
          {version}
        </STTypo>
      )}
    </div>
  );
};
