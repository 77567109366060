import React from 'react';

const ElipseGlyph = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      d='M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1'
      stroke='#009933'
      strokeWidth='2'
      strokeDasharray='3 3'
    />
  </svg>
);

export default ElipseGlyph;
