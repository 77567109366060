import actions from './actions';
import { AuthState } from './models';

const initialState: AuthState = {
  isLoading: false,
  isLogged: localStorage.getItem('striderToken') ? true : false,
  error: null
};

export default (state = initialState, action): AuthState => {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actions.LOGIN_SUCCESS:
      return {
        isLoading: false,
        isLogged: true,
        error: null
      };
    case actions.LOGIN_FAILURE:
      return {
        isLoading: false,
        isLogged: false,
        error: action.payload
      };
    case actions.LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actions.LOGOUT_SUCCESS:
      return {
        isLoading: false,
        isLogged: false,
        error: null
      };
    case actions.LOGOUT_FAILURE:
      return {
        isLoading: false,
        isLogged: true,
        error: action.payload
      };
    default:
      return state;
  }
};
