import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';
import { Property, PropertyState } from './models';
import actions from './actions';

const entity: EntityAdapter<Property> = createEntityAdapter<Property>();

const initialState: PropertyState = entity.getInitialState({
  isLoading: false,
  error: undefined,
  propertySelect: undefined,
  isLoadingPropertySelect: false,
  isUploadingFile: false

});

export default (state = initialState, action): PropertyState => {
  switch (action.type) {
    case actions.LOAD_PROPERTY_INFO:
      return {
        ...state,
        isLoadingPropertySelect: true,
        propertySelect: undefined
      };

    case actions.LOAD_PROPERTY_INFO_SUCCESS:
      return {
        ...state,
        isLoadingPropertySelect: false,
        propertySelect: action.payload
      };

    case actions.LOAD_PROPERTY_INFO_FAILURE:
    case actions.LOAD_PROPERTIES_BY_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingPropertySelect: false,
        error: action.payload
      };

    case actions.LOAD_PROPERTIES_BY_COMPANY_SUCCESS:
      return entity.addAll(action.payload, {
        ...state,
        isLoading: false,
        error: undefined
      });

    case actions.LOAD_PROPERTIES_BY_COMPANY:
      return entity.removeAll({
        ...state,
        isLoading: true,
        propertySelect: undefined,
        error: undefined
      });

    case actions.UNLOAD_PROPERTIES_BY_COMPANY:
      return entity.removeAll({
        ...state,
        isLoading: false,
        isLoadingPropertySelect: false,
        propertySelect: undefined,
        error: undefined
      });

    default:
      return state;
  }
};
