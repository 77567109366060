import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import actions from './actions';
import {
  Action,
  DeleteTrapMeasurementsRequestParams,
  EditTrapMeasurementsRequestBody,
  EditTrapMeasurementsRequestParams,
  LoadTrapMeasurementsRequestParams,
  TrapMeasurement
} from '../models';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { deleteTrapMeasurement, editTrapMeasurementValue, getTrapMeasurements, getTrapStations } from './service';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';
import { AppState } from '../redux.model';
import { buildTrapMeasurements } from './utils';
import { Observable, of } from 'rxjs';

import { notification } from '../../components';

export const handleLoadTrapMeasurementsByProperty = (
  action$: ActionsObservable<Action<LoadTrapMeasurementsRequestParams>>,
  state$: StateObservable<AppState>
) => {
  return action$.pipe(
    ofType(...[actions.LOAD_TRAP_MEASUREMENTS_BY_PROPERTY, actions.LOAD_NEXT_PAGE_TRAP_MEASUREMENTS_BY_PROPERTY]),
    map((action: Action<LoadTrapMeasurementsRequestParams>) =>
      action.payload ? action.payload : ({} as LoadTrapMeasurementsRequestParams)
    ),
    concatMap(params =>
      getTrapMeasurements(params).pipe(
        withLatestFrom(
          state$.pipe(
            map(state => ({
              fields: state.Field.entities,
              lastPropertyId: state.Field.lastPropertyId,
              lastCompanyId: state.Phenomenon.lastCompanyId,
              users: state.User.entities,
              phenomenonsTree: state.Phenomenon.phenomenonsTree,
              lastOrgId: state.User.lastOrgId,
              regions: state.Region.regions,
              stations: state.TrapMeasurement.stations
            }))
          )
        ),
        concatMap(([trapMeasurements, state]) => {
          if (state.stations.length > 0 && state.stations[0].property_id === params.propertyId) {
            return of(
              ...handleSuccessLoadTrapMeasurementsByProperty(
                buildTrapMeasurements(
                  trapMeasurements.trap_monitorings,
                  state.stations,
                  state.phenomenonsTree,
                  Object.values(state.users),
                  Object.values(state.fields),
                  state.regions
                ),
                params,
                trapMeasurements.range_key
              )
            );
          }

          return getTrapStations(params.propertyId).pipe(
            concatMap(stations => {
              return of(
                actions.loadTrapStationsByPropertySuccess(stations.content),
                ...handleSuccessLoadTrapMeasurementsByProperty(
                  buildTrapMeasurements(
                    trapMeasurements.trap_monitorings,
                    stations.content,
                    state.phenomenonsTree,
                    Object.values(state.users),
                    Object.values(state.fields),
                    state.regions
                  ),
                  params,
                  trapMeasurements.range_key
                )
              );
            })
          );
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading properties by company');
          return of(actions.loadTrapMeasurementsByPropertyFailure(errorMsg));
        })
      )
    )
  );
};

const handleSuccessLoadTrapMeasurementsByProperty = (
  trapMeasurements: TrapMeasurement[],
  params: LoadTrapMeasurementsRequestParams,
  rangeKey?: string
) => {
  const actionsReturn: unknown[] = [];

  if (!params.rangeKey) {
    actionsReturn.push(actions.loadTrapMeasurementsByPropertySuccess(trapMeasurements));
  } else {
    actionsReturn.push(actions.loadNextPageTrapMeasurementsByPropertySuccess(trapMeasurements));
  }

  if (rangeKey) {
    const newParams = {
      ...params,
      rangeKey
    };
    actionsReturn.push(actions.loadNextPageTrapMeasurementsByProperty(newParams));
  }

  return actionsReturn;
};

const getEditTrapMeasurementRequestParams = (measurement: TrapMeasurement) => {
  const params: EditTrapMeasurementsRequestParams = {
    actionId: measurement.actionId,
    staticPointId: measurement.staticPointId
  };
  return params;
};

const getEditTrapMeasurementRequestBody = (measurement: TrapMeasurement) => {
  const body: EditTrapMeasurementsRequestBody = {
    characteristic_id: measurement.characteristicId,
    value: measurement.value
  };

  return body;
};

export const handleEditValueTrapMeasurements = action$ =>
  action$.pipe(
    ofType(actions.EDIT_TRAP_MEASUREMENT_VALUE),
    map((action: Action<TrapMeasurement>) => action.payload),
    concatMap(
      (measurement: TrapMeasurement): Observable<any> =>
        editTrapMeasurementValue(getEditTrapMeasurementRequestParams(measurement), getEditTrapMeasurementRequestBody(measurement)).pipe(
          concatMap(() => {
            notification('success', 'Measurement was edited successfully!');
            return of(actions.editTrapMeasurementValueSuccess(measurement), actions.toggleTrapMeasurementModal(measurement.id));
          }),
          catchError((error: any) => {
            const errorMsg = notifyErrorOrFallback(error, 'Error editing measurement!');
            return of(actions.editTrapMeasurementValueFailure(errorMsg));
          })
        )
    )
  );

export const handleDeleteTrapMeasurement = action$ =>
  action$.pipe(
    ofType(actions.DELETE_TRAP_MEASUREMENT),
    map((action: Action<DeleteTrapMeasurementsRequestParams>) => action.payload),
    concatMap((deleteMeasurementRequestParams: DeleteTrapMeasurementsRequestParams) =>
      deleteTrapMeasurement(deleteMeasurementRequestParams).pipe(
        concatMap(response => {
          notification('success', 'Measurement was deleted successfully!');
          const { measurementId } = deleteMeasurementRequestParams;
          return of(actions.deleteTrapMeasurementSuccess(measurementId), actions.toggleTrapMeasurementModal(measurementId));
        }),
        catchError((error: any) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error deleting measurement!');
          return of(actions.deleteTrapMeasurementFailure(errorMsg));
        })
      )
    )
  );
