import {
  FixedPoint,
  Characteristic,
  UUID,
  Indicator,
  FixedPointType,
  CustomIndicator,
  FeatureFlagEnum,
  OrderedInspectionGroup,
  OrderedCategory,
  OrderedPhenomenon,
  OrderedComponent,
  PhenomenonFixedPoint,
  InspectionLayout,
  AnalyticContext,
  IntegrationSource
} from '../../redux/models';
import { getObjectById } from '../../redux/methodologyDeep/utils';
import { ClassName, ComponentDeep, IndicatorDeep, CharacteristicDeep } from '../../redux/methodologyDeep/models';
import { CharacteristicsIndicatorsProps } from './fixedPointsCategoriesIndicatorsCharacteristcs';
import { buildCharacteristicDeep, buildIndicatorDeep } from '../../redux/methodologyDeep/services';
import { findObjectById } from '../../helpers/utility';
import { uuid } from '../../helpers';
import { cloneDeep } from 'lodash';
import { FixedPointFormData } from './fixedPointsCategoriesEdit';
import { getComponentName } from '../MethodologyContainer/utils';
import { getValueLanguage } from '../../redux/language/utils';

const { NO_HEATMAP, NO_TIMELINE } = FeatureFlagEnum;
const { GENERIC } = FixedPointType;

export const convertPhenomenaFixedPointToProps = (fixedPoint: FixedPoint, phenomena: PhenomenonFixedPoint[]): PhenomenonFixedPoint[] => {
  const response: PhenomenonFixedPoint[] = [];
  const customIndicators = fixedPoint.analytic_context_dto?.custom_indicator_dtos ?? [];
  const inspectionLayout = fixedPoint.inspection_layout_dto ? fixedPoint.inspection_layout_dto : fixedPoint.inspection_layout;
  if (fixedPoint.class_name !== GENERIC && inspectionLayout && inspectionLayout.ordered_inspection_groups) {
    inspectionLayout.ordered_inspection_groups.forEach(oig => {
      oig.ordered_categories?.forEach(orderedCategory => {
        orderedCategory.ordered_phenomenons?.forEach(op => {
          const phenomenon = getObjectById(phenomena, op.component_id);
          if (phenomenon) {
            phenomenon.selected_indicator_characteristics = [];
            const defaultIndicatorsPhenomenon = (phenomenon.characteristics || [])
              .filter(c => c.default_indicator_id)
              .map(c => c.default_indicator_id);
            response.push(phenomenon);
            customIndicators.forEach(customIndicator => {
              const phenomenonIndicator = getObjectById<Indicator>(phenomenon.indicators ?? [], customIndicator.indicator_id);
              if (phenomenonIndicator && !defaultIndicatorsPhenomenon.includes(phenomenonIndicator.id)) {
                phenomenon.selected_indicator_characteristics!.unshift(
                  convertIndicatorToCharacteristicIndicatorProps(phenomenonIndicator, phenomenon)
                );
              }
            });
            op.ordered_components?.forEach(oc => {
              if (oc.class_name === ClassName.CHARACTERISTIC) {
                const characteristic = getObjectById(phenomenon.characteristics ?? [], oc.component_id);
                if (characteristic) {
                  phenomenon.selected_indicator_characteristics!.push(
                    convertCharacteristicToCharacteristicIndicatorProps(characteristic, phenomenon)
                  );
                }
              }
            });
          }
        });
      });
    });
  }
  return response;
};

const convertCharacteristicToCharacteristicIndicatorProps = (
  characteristic: Characteristic,
  phenomenon: PhenomenonFixedPoint
): CharacteristicsIndicatorsProps => {
  const characteristicDeep = buildCharacteristicDeep(characteristic, undefined);
  let indicatorDeep: IndicatorDeep | undefined;
  if (phenomenon.indicators && characteristic.default_indicator_id) {
    const indicatorObject = getObjectById(phenomenon.indicators, characteristic.default_indicator_id);
    if (indicatorObject) indicatorDeep = buildIndicatorDeep(indicatorObject, undefined);
  }
  const componentDeep: ComponentDeep = { characteristic: characteristicDeep, indicator: indicatorDeep || undefined };
  return convertCharacteristcsIndicatorsToProps(componentDeep, phenomenon.id!, characteristic.company_id);
};

const convertIndicatorToCharacteristicIndicatorProps = (indicator: Indicator, phenomenon: PhenomenonFixedPoint) => {
  const indicatorDeep = buildIndicatorDeep(indicator, undefined);
  const componentDeep: ComponentDeep = { indicator: indicatorDeep };
  return convertCharacteristcsIndicatorsToProps(componentDeep, phenomenon.id!, indicator.company_id);
};

export const buildCustomIndicator = (indicatorDeep: IndicatorDeep): CustomIndicator => {
  const featureFlags: any[] = [];
  if (!indicatorDeep.showOnHeatmap) featureFlags.push({ label: NO_HEATMAP });
  if (!indicatorDeep.showOnTimeline) featureFlags.push({ label: NO_TIMELINE });
  return {
    indicator_id: indicatorDeep.indicatorId,
    diagnostics: indicatorDeep.diagnostics,
    feature_flags: featureFlags
  };
};

export const buildOrderedInspectionGroupByFixedPoint = (fixedPoint: FixedPoint): OrderedInspectionGroup => {
  return {
    id: uuid(),
    name: fixedPoint.name,
    ordered_categories: [],
    ordered_extra_dimensions: [],
    priority: 0
  };
};

const buildOrderedComponent = (componentId: UUID, priority: number) => {
  return {
    id: uuid(),
    component_id: componentId,
    class_name: ClassName.CHARACTERISTIC,
    priority
  };
};

const buildOrderedCategory = (componentId: UUID, orderedPhenomenon: OrderedPhenomenon, priority: number) => {
  return {
    id: uuid(),
    component_id: componentId,
    ordered_phenomenons: [orderedPhenomenon],
    priority
  };
};

const buildOrderedPhenomenon = (componentId: UUID, priority: number) => {
  return {
    id: uuid(),
    component_id: componentId,
    ordered_components: [],
    priority
  };
};

export const convertCharacteristcsIndicatorsToProps = (characteristicIndicator: ComponentDeep, phenomenonId: UUID, orgId?: UUID | null) => {
  const { indicator, characteristic } = characteristicIndicator;
  if (characteristic) {
    return {
      id: characteristic.id,
      title: getComponentName(characteristicIndicator),
      canonical: characteristic.comesFromCanonical,
      characteristicId: characteristic.characteristicId,
      indicatorId: indicator ? indicator.indicatorId : null,
      timeline: indicator ? indicator.showOnTimeline : false,
      inspectionLayout: indicator ? indicator.showOnTimeline : false,
      phenomenonId,
      company: { id: orgId, name: '' },
      thresholds: indicator ? indicator.diagnostics && indicator.diagnostics[0] && indicator.diagnostics[0].thresholds : undefined,
      hideMove: true
    } as any;
  }
  if (indicator)
    return {
      id: indicator.id,
      title: getValueLanguage(indicator.name),
      canonical: indicator.comesFromCanonical,
      indicatorId: indicator.indicatorId,
      timeline: indicator.showOnTimeline,
      inspectionLayout: indicator.showOnInspectionLayout,
      phenomenonId,
      company: { id: orgId, name: '' },
      thresholds: indicator.diagnostics && indicator.diagnostics[0] && indicator.diagnostics[0].thresholds
    } as any;
};

export const createNewFixedPoint = (companyId?: UUID) => {
  return {
    analytic_context_dto: undefined,
    area_ids: [],
    class_name: undefined,
    cohese: true,
    company_id: companyId,
    description: {
      localized_strings: {
        en: ''
      }
    },
    icon_type: '',
    id: uuid(),
    inspection_layout_dto: undefined,
    integration_source: undefined,
    instalation_steps: undefined,
    instaled_at: null,
    name: {
      localized_strings: {
        en: ''
      }
    },
    sampling_behaviour: '',
    time_for_renewal: 0,
    isNew: true,
    type: ''
  };
};
export const removePhenomenonOnFixedPoint = (fixedPoint: FixedPoint, phenomenon: PhenomenonFixedPoint): FixedPoint => {
  const fixedPointMutable = cloneDeep(fixedPoint);
  if (fixedPointMutable && fixedPointMutable.inspection_layout_dto && fixedPointMutable.inspection_layout_dto.ordered_inspection_groups) {
    fixedPointMutable.inspection_layout_dto.ordered_inspection_groups.forEach(oig => {
      oig.ordered_categories.forEach(oc => {
        oc.ordered_phenomenons = oc.ordered_phenomenons.filter(op => op.component_id !== phenomenon.id);
      });
    });
  }

  if (fixedPointMutable.analytic_context_dto) {
    const indicatorsIdsOfPhenomenon = phenomenon.indicators!.map(i => i.id);
    fixedPointMutable.analytic_context_dto.custom_indicator_dtos = fixedPointMutable.analytic_context_dto.custom_indicator_dtos.filter(
      (customIndicator: CustomIndicator) => !indicatorsIdsOfPhenomenon.includes(customIndicator.indicator_id)
    );
  }

  if (fixedPointMutable.inspection_layout_dto && fixedPointMutable.inspection_layout_dto.ordered_inspection_groups) {
    fixedPointMutable.inspection_layout_dto.ordered_inspection_groups.forEach(oig => {
      oig.ordered_categories = oig.ordered_categories.filter(oc => oc.ordered_phenomenons.length);
    });
  }

  return fixedPointMutable;
};

export const removeCharacteristicIndicatorsOnFixedPoint = (fixedPoint: FixedPoint, { characteristicId, indicatorId }) => {
  const fixedPointMutable = { ...fixedPoint };
  if (fixedPointMutable.analytic_context_dto) {
    fixedPointMutable.analytic_context_dto!.custom_indicator_dtos = fixedPointMutable.analytic_context_dto!.custom_indicator_dtos.filter(
      (customIndicator: CustomIndicator) => customIndicator.indicator_id !== indicatorId
    );
  }

  if (fixedPointMutable.inspection_layout_dto!.characteristic_ids) {
    fixedPointMutable.inspection_layout_dto!.characteristic_ids = fixedPointMutable.inspection_layout_dto!.characteristic_ids.filter(
      chId => chId !== characteristicId
    );
  }

  fixedPointMutable.inspection_layout_dto!.ordered_inspection_groups = getOrderedInspectionGroupsFilteringComponent(
    fixedPointMutable.inspection_layout_dto!.ordered_inspection_groups,
    characteristicId
  );
  return fixedPointMutable;
};

const getOrderedInspectionGroupsFilteringComponent = (orderedInspectionGroups: OrderedInspectionGroup[], orderedComponentId: UUID) => {
  return (
    orderedInspectionGroups &&
    orderedInspectionGroups.map((oig: OrderedInspectionGroup) => {
      oig.ordered_categories = [...getOrderedCategoriesFilteringComponent(oig.ordered_categories, orderedComponentId)];
      return oig;
    })
  );
};

const getOrderedCategoriesFilteringComponent = (orderedCategories: OrderedCategory[], orderedComponentId: UUID) => {
  return orderedCategories.map((oc: OrderedCategory) => {
    oc.ordered_phenomenons = [...getOrderedPhenomenonsFilteringComponent(oc.ordered_phenomenons, orderedComponentId)];
    return oc;
  });
};

const getOrderedPhenomenonsFilteringComponent = (orderedPhenomenons: OrderedPhenomenon[], orderedComponentId: UUID) => {
  return orderedPhenomenons.map((op: OrderedPhenomenon) => {
    op.ordered_components = [...filterComponents(op.ordered_components, orderedComponentId)];
    return op;
  });
};

const filterComponents = (orderedComponents: OrderedComponent[], id: UUID) => {
  return [...orderedComponents.filter((oc: OrderedComponent) => oc.component_id !== id)];
};

const insertIndicatorOnAnalyticContext = (customIndicator: CustomIndicator, analyticContext?: AnalyticContext) => {
  if (analyticContext) {
    analyticContext.custom_indicator_dtos = [...analyticContext.custom_indicator_dtos, customIndicator];
  } else {
    analyticContext = { id: uuid(), custom_indicator_dtos: [customIndicator] };
  }
  return analyticContext;
};

const insertComponentOnFixedPoint = (fixedPoint: FixedPoint, phenomenonId: UUID, componentDeep: ComponentDeep) => {
  const { characteristic, indicator } = componentDeep;
  if (characteristic) {
    fixedPoint.inspection_layout_dto!.ordered_inspection_groups.forEach(oig => {
      oig.ordered_categories.forEach(oc => {
        oc.ordered_phenomenons.forEach(op => {
          if (op.component_id === phenomenonId) {
            const orderedComponent = buildOrderedComponent(characteristic.characteristicId, op.ordered_components.length);
            op.ordered_components = [...op.ordered_components, orderedComponent];
          }
        });
      });
    });
  }
  if (indicator) {
    const customIndicator = buildCustomIndicator(indicator);
    fixedPoint.analytic_context_dto = insertIndicatorOnAnalyticContext(customIndicator, fixedPoint.analytic_context_dto);
  }
};

export const insertComponentsOnFixedPoint = (fixedPoint: FixedPoint, phenomenonId: UUID, components: ComponentDeep[]) => {
  const fixedPointMutable = cloneDeep(fixedPoint);
  components.forEach(c => insertComponentOnFixedPoint(fixedPointMutable, phenomenonId, c));
  return fixedPointMutable;
};

const buildNewInspectionLayout = (fixedPoint: FixedPoint) => {
  return {
    id: uuid(),
    ordered_inspection_groups: [buildOrderedInspectionGroupByFixedPoint(fixedPoint)],
    characteristic_ids: [],
    indicator_ids: [],
    independent_variables: []
  } as InspectionLayout;
};

const buildNewAnalyticContext = () => {
  return {
    id: uuid(),
    custom_indicator_dtos: []
  };
};

const haveNotInspectionGroup = (fixedPoint: FixedPoint) =>
  !fixedPoint.inspection_layout_dto!.ordered_inspection_groups || fixedPoint.inspection_layout_dto!.ordered_inspection_groups.length === 0;

const insertPhenomenonInOrderedCategory = (oig: OrderedInspectionGroup, phenomenon: PhenomenonFixedPoint) => {
  const phenomenonCategoryId = phenomenon.category!.id;
  if (!phenomenonCategoryId) throw new Error('Not Have Phenomenon Category Id!');
  const orderedPhenomenon: OrderedPhenomenon = buildOrderedPhenomenon(phenomenon.id!, 0);
  const categoriesIds = oig.ordered_categories.map(oc => oc.component_id);
  if (categoriesIds.includes(phenomenonCategoryId)) {
    oig.ordered_categories = oig.ordered_categories.map(oc => {
      if (oc.component_id === phenomenonCategoryId) {
        orderedPhenomenon.priority = oc.ordered_phenomenons.length;
        oc.ordered_phenomenons.push(orderedPhenomenon);
      }
      return oc;
    });
  } else {
    const orderedCategory: OrderedCategory = buildOrderedCategory(phenomenonCategoryId, orderedPhenomenon, oig.ordered_categories.length);
    oig.ordered_categories.push(orderedCategory);
  }
  return oig.ordered_categories;
};

const insertPhenomenonOnFixedPoint = (fixedPoint: FixedPoint, phenomenon: PhenomenonFixedPoint) => {
  if (!fixedPoint.inspection_layout_dto) {
    fixedPoint.inspection_layout_dto = buildNewInspectionLayout(fixedPoint);
  }
  if (haveNotInspectionGroup(fixedPoint)) {
    fixedPoint.inspection_layout_dto.ordered_inspection_groups = [buildOrderedInspectionGroupByFixedPoint(fixedPoint)];
  }
  fixedPoint.inspection_layout_dto.ordered_inspection_groups.forEach(
    oig => (oig.ordered_categories = insertPhenomenonInOrderedCategory(oig, phenomenon))
  );

  return fixedPoint;
};

export const insertPhenomenaOnFixedPoint = (fixedPoint: FixedPoint, phenomena: PhenomenonFixedPoint[]) => {
  const fixedPointMutable = cloneDeep(fixedPoint);
  phenomena.forEach(phenomenon => insertPhenomenonOnFixedPoint(fixedPointMutable, phenomenon));
  return fixedPointMutable;
};

export const insertFormDataOnFixedPoint = (data: FixedPointFormData, fixedPoint: FixedPoint | undefined) => {
  const integration_source = fixedPoint && fixedPoint.integration_source ? fixedPoint.integration_source : IntegrationSource.PROTECTOR;
  const fixedPointToSave = {
    ...fixedPoint,
    integration_source,
    name: { localized_strings: { en: data.name } },
    description: { localized_strings: { en: data.description } },
    company_id: data.company,
    icon_type: data.iconType,
    instalation_steps: data.instalationSteps,
    time_for_renewal: parseInt(data.renewalTime),
    sampling_behaviour: data.sampling_behaviour,
    class_name: data.class_name,
    analytic_context_dto: (fixedPoint && fixedPoint.analytic_context_dto) || buildNewAnalyticContext()
  };
  return fixedPointToSave;
};

export const buildComponentDeep = (characteristicDeep, indicatorDeep?) => ({
  indicator: indicatorDeep,
  characteristic: characteristicDeep,
  priority: undefined
});

export const getComponentsByPhenomenon = (phenomenonFixedPoint: PhenomenonFixedPoint | undefined) => {
  const components: ComponentDeep[] = [];
  const defaultIndicatorIds: UUID[] = [];
  const phenomenon = cloneDeep(phenomenonFixedPoint);
  let characteristics = (phenomenon && phenomenon.characteristics) || [];
  let indicators = (phenomenon && phenomenon.indicators) || [];

  if (phenomenon && phenomenon.selected_indicator_characteristics) {
    indicators = indicators
      .filter(i => !i.deleted_at)
      .filter(
        indicator =>
          !phenomenon
            .selected_indicator_characteristics!.filter(component => component.indicatorId)
            .map(selectedIndicators => selectedIndicators.indicatorId)
            .includes(indicator.id)
      );

    characteristics = characteristics
      .filter(c => !c.deleted_at)
      .filter(
        characteristic =>
          !phenomenon
            .selected_indicator_characteristics!.filter(component => component.characteristicId)
            .map(selectedCharacteristic => selectedCharacteristic.characteristicId)
            .includes(characteristic.id)
      );
  }

  characteristics.forEach((c, index) => {
    const characteristicDeep: CharacteristicDeep = buildCharacteristicDeep(c, undefined);
    if (c.default_indicator_id) {
      defaultIndicatorIds.push(c.default_indicator_id);
      if (phenomenon && phenomenon.indicators && phenomenon.indicators.length) {
        const indicator = phenomenon.indicators.find(i => i.id === c.default_indicator_id);
        if (indicator) {
          const indicatorDeep: IndicatorDeep = buildIndicatorDeep(indicator, undefined);
          components.push(buildComponentDeep(characteristicDeep, indicatorDeep));
        }
      }
    } else {
      components.push(buildComponentDeep(characteristicDeep));
    }
  });

  indicators.forEach(i => {
    if (!defaultIndicatorIds.includes(i.id!)) {
      const indicatorDeep = buildIndicatorDeep(i, undefined);
      components.push(buildComponentDeep(undefined, indicatorDeep));
    }
  });

  return components;
};

export const overwriteComponentIds = (component: ComponentDeep, clonedComponents: (Characteristic | Indicator)[]) => {
  const clonedComponentIds = clonedComponents.map(c => c.parent_id);
  if (component.characteristic && clonedComponentIds.includes(component.characteristic.characteristicId)) {
    const characteristicCloned = clonedComponents.find(c => c.parent_id === component.characteristic!.characteristicId);
    component.characteristic.characteristicId = characteristicCloned!.id!;
    if (component.indicator) {
      component.indicator.indicatorId = (characteristicCloned as Characteristic)!.default_indicator_id!;
    }
  } else if (!component.characteristic && component.indicator && clonedComponentIds.includes(component.indicator.indicatorId)) {
    const indicatorCloned = clonedComponents.find(c => c.parent_id === component.indicator!.indicatorId);
    component.indicator.indicatorId = indicatorCloned!.id!;
  }
  return component;
};

export const upsertComponentsIntoPhenomenon = (clonedComponents, phenomenaTree) => {
  clonedComponents.forEach(clonedComponent => {
    const phenomenonId = clonedComponent.phenomenon_id
      ? clonedComponent.phenomenon_id
      : clonedComponent.phenomenon_ids
      ? clonedComponent.phenomenon_ids[0]
      : null;

    const phenomenon = findObjectById(phenomenaTree, phenomenonId);
    if (clonedComponent.hasOwnProperty('phenomenon_id')) {
      phenomenon.characteristics.push(clonedComponent);
    } else {
      phenomenon.indicators.push(clonedComponent);
    }
  });
  return phenomenaTree;
};
