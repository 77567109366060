import { Controller } from 'react-hook-form';
import { Input } from 'antd';
import React from 'react';

interface InputPasswordIconProps {
  name: string;
  disabled?: boolean;
  size?: 'small' | 'default' | 'large';
  control: any;
  placeholder?: string;
}

const InputPasswordIcon = ({ name, disabled, size, control, placeholder }: InputPasswordIconProps) => (
  <Controller
    name={name}
    as={<Input.Password id={name} name={name} disabled={disabled} size={size} placeholder={placeholder} />}
    control={control}
  />
);

export default InputPasswordIcon;
