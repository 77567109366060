import React from 'react';

const PhoneGlyph = props => (
  <svg width='10' height='14' viewBox='0 0 10 14' fill='none' xmlns='https://www.w3.org/2000/svg' data-testid='phone-glyph'>
    <path
      d='M6 10C6 10.5523 5.55228 11 5 11C4.44772 11 4 10.5523 4 10C4 9.44771 4.44772 9 5 9C5.55228 9 6 9.44771 6 10Z'
      fill={props.color || 'currentColor'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 1C0 0.447715 0.447715 0 1 0H9C9.55229 0 10 0.447715 10 1V13C10 13.5523 9.55229 14 9 14H1C0.447715 14 0 13.5523 0 13V1ZM2 2V12H8V2H2Z'
      fill={props.color || 'currentColor'}
    />
  </svg>
);

export default PhoneGlyph;
