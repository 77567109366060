import { PageableQuery, Page, UUID } from '../app/basicModels';
import { Vendor, LoadVendorType, VendorPage } from './models';

const actions = {
  LOAD_VENDORS: '[Vendor] Load Vendors',
  LOAD_VENDORS_SUCCESS: '[Vendor] Load Vendors Success',
  LOAD_VENDORS_FAILURE: '[Vendor] Load Vendors Failure',
  TOGGLE_VENDOR_EDIT_MODAL: '[Vendor] Toggle vendor edit modal',
  SAVE_VENDOR: '[Vendor] Save Vendor',
  SAVE_VENDOR_SUCCESS: '[Vendor] Save Vendor Success',
  SAVE_VENDOR_FAILURE: '[Vendor] Save Vendor Failure',
  LOAD_VENDOR: '[Vendor] Load Vendor',
  LOAD_VENDOR_SUCCESS: '[Vendor] Load Vendor Success',
  LOAD_VENDOR_FAILURE: '[Vendor] Load Vendor Failure',
  DELETE_VENDOR: '[Vendor] Delete Vendor',
  DELETE_VENDOR_SUCCESS: '[Vendor] Delete Vendor Success',
  DELETE_VENDOR_FAILURE: '[Vendor] Delete Vendor Failure',
  CLEAR_VENDOR_SELECT: '[Vendor] Clear Vendor Select',

  loadVendors: (pageableQuery: PageableQuery, showCanonicals: boolean = true, companyId: string | null | undefined = null) => ({
    type: actions.LOAD_VENDORS,
    payload: {
      pageableQuery,
      companyId,
      showCanonicals
    }
  }),

  loadVendorsSuccess: (VendorPage: VendorPage) => ({
    type: actions.LOAD_VENDORS_SUCCESS,
    payload: VendorPage
  }),

  loadVendorsFailure: (error: string) => ({
    type: actions.LOAD_VENDORS_FAILURE,
    payload: error
  }),

  saveVendor: (vendor: Vendor) => ({
    type: actions.SAVE_VENDOR,
    payload: vendor
  }),

  saveVendorSucces: (vendor: Vendor) => ({
    type: actions.SAVE_VENDOR_SUCCESS,
    payload: vendor
  }),

  saveVendorFailure: (error: string) => ({
    type: actions.SAVE_VENDOR_FAILURE,
    payload: error
  }),

  loadVendor: (id: UUID) => ({
    type: actions.LOAD_VENDOR,
    payload: id
  }),

  loadVendorSucces: (vendor: Vendor) => ({
    type: actions.LOAD_VENDOR_SUCCESS,
    payload: vendor
  }),

  loadVendorFailure: (error: string) => ({
    type: actions.LOAD_VENDOR_FAILURE,
    payload: error
  }),

  deleteVendor: (vendor: Vendor) => ({
    type: actions.DELETE_VENDOR,
    payload: vendor
  }),

  deleteVendorSucces: (deleted: boolean) => ({
    type: actions.DELETE_VENDOR_SUCCESS,
    payload: deleted
  }),

  deleteVendorFailure: (error: string) => ({
    type: actions.DELETE_VENDOR_FAILURE,
    payload: error
  }),

  toggleVendorEditModal: () => ({
    type: actions.TOGGLE_VENDOR_EDIT_MODAL
  }),

  clearVendorSelect: () => ({
    type: actions.CLEAR_VENDOR_SELECT
  })
};

export default actions;

export const VendorActions = actions;
