import { PageableQuery, Action, UUID } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map, concatMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import actions from './actions';
import { getFixedPoint, getAllPluviometers } from '../fixedPoint/service';
import { FixedPoint } from '../fixedPoint/models';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';
import { TypesOfTraps } from '../../containers/FixedPointCategories/BasicInfo/basicInfoForm';

export const handleLoadPluviometers = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PLUVIOMETERS),
    map((action: Action<PageableQuery>) => action.payload),
    concatMap((query: PageableQuery) =>
      getAllPluviometers(query).pipe(
        map((fixedPoints: FixedPoint[]) => actions.loadPluviometersSuccess(fixedPoints)),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading fixed points pluviometer!');
          return of(actions.loadPluviometersFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadFixedPointPluviometer = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PLUVIOMETER),
    map((action: Action<UUID>) => action.payload),
    concatMap((fixedPointId: UUID) =>
      getFixedPoint(fixedPointId).pipe(
        map(response => response.data),
        map((fixedPoint: any) => {
          let fixedPointFilter = fixedPoint;
          if (fixedPoint.single_use) {
            fixedPointFilter = { ...fixedPoint, sampling_behaviour: TypesOfTraps.SINGLE_USE_ID };
          }
          return actions.loadPluviometerSuccess(fixedPointFilter);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading fixed point pluviometer!');
          return of(actions.loadPluviomenterFailure(errorMsg));
        })
      )
    )
  );
