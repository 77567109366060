import React from 'react';

const enGBR = () => (
  <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path d='M0 3.05176e-05V12H16V3.05176e-05H0Z' fill='#012169' />
    <path d='M0 3.05176e-05L16 12L0 3.05176e-05ZM16 3.05176e-05L0 12L16 3.05176e-05Z' fill='black' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.00003 6.00004L0 1.5V0H2L8.00003 4.50004L14 0H16V1.5L10 6.00004L16 10.5V12H14L8.00003 7.50004L2 12H0V10.5L6.00003 6.00004Z'
      fill='white'
    />
    <path d='M0 3.05176e-05L16 12L0 3.05176e-05ZM16 3.05176e-05L0 12L16 3.05176e-05Z' fill='black' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.66669 6.00005L0 1.00006V6.10352e-05H1.5L8.00002 5.00005L14.5 6.10352e-05H16V1.00006L9.33335 6.00005L16 11.0001V12.0001H14.5L8.00002 7.00005L1.5 12.0001H0V11.0001L6.66669 6.00005Z'
      fill='#C8102E'
    />
    <path d='M8 3.05176e-05V12V3.05176e-05ZM0 6.00003H16H0Z' fill='black' />
    <path d='M0 6.00003H16M8 3.05176e-05V12V3.05176e-05Z' stroke='white' strokeWidth='4' />
    <path d='M8 3.05176e-05V12V3.05176e-05ZM0 6.00003H16H0Z' fill='black' />
    <path d='M0 6.00003H16M8 3.05176e-05V12V3.05176e-05Z' stroke='#C8102E' strokeWidth='2.4' />
  </svg>
);

export default enGBR;
