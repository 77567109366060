import { Field } from 'react-hook-form';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Action, UUID } from '../app/basicModels';
import actions from './actions';
import { getFieldsByProperty } from './repository';

export const handleLoadFieldsByProperty = action$ =>
  action$.pipe(
    ofType(actions.LOAD_FIELDS_BY_PROPERTY),
    map((action: Action<UUID>) => action.payload),
    concatMap((propertyId: UUID) =>
      getFieldsByProperty(propertyId).pipe(
        map(response => response.data),
        map((fields: any) => {
          return actions.loadFieldsByPropertySuccess(fields);
        }),
        catchError((error: string) => {
          return of(actions.loadFieldsByPropertyFailure(error));
        })
      )
    )
  );
