import { createEntityAdapter, EntityAdapter } from 'redux-ngrx-entity';
import { initialPage } from '../app/basicModels';
import actions from './actions';
import { Product, ProductState } from './models';

const entity: EntityAdapter<Product> = createEntityAdapter<Product>();

const initialState: ProductState = entity.getInitialState({
  page: {
    ...initialPage
  },
  isLoading: false,
  error: null,
  isSaving: false,
  productSelect: null,
  productSelectLoading: false,
  isAvailable: false
});

export default (state = initialState, action): ProductState => {
  switch (action.type) {
    case actions.LOAD_PRODUCTS:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_PRODUCTS_SUCCESS:
      const deletedProducts = action.payload.content.filter(p => p.deleted_at);
      const products = action.payload.content.filter(p => !p.deleted_at);

      return entity.addAll(products, {
        ...state,
        deletedProducts,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null
      });

    case actions.ADD_PRODUCTS_SUCCESS:
      const deleted = action.payload.content.filter(p => p.deleted_at);
      const productsToAdd = action.payload.content.filter(p => !p.deleted_at && !Object.keys(state.entities).includes(p.id));

      return entity.addMany(productsToAdd, {
        ...state,
        deleted,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null,
        isAvailable: true
      });

    case actions.LOAD_PRODUCTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isAvailable: false
      };

    case actions.RESET_PRODUCTS:
      return initialState;

    case actions.SAVE_PRODUCT:
      return {
        ...state,
        isAvailable: false,
        isSaving: true
      };

    case actions.SAVE_PRODUCT_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isSaving: false,
        error: null,
        isAvailable: true
      });

    case actions.SAVE_PRODUCT_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload,
        isAvailable: false
      };

    case actions.LOAD_PRODUCT:
      return {
        ...state,
        productSelectLoading: true,
        productSelect: null
      };

    case actions.LOAD_PRODUCT_SUCCESS:
      return {
        ...state,
        productSelectLoading: false,
        productSelect: action.payload
      };

    case actions.LOAD_PRODUCT_FAILURE:
      return {
        ...state,
        productSelectLoading: false,
        error: action.payload,
        isAvailable: false
      };

    case actions.DELETE_PRODUCT_SUCCESS:
      return entity.removeOne(action.payload.id, {
        ...state,
        error: null
      });

    case actions.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case actions.CLEAR_PRODUCT_SELECT:
      return {
        ...state,
        productSelect: null
      };

    case actions.RESET_STATUS_ERROR:
      return {
        ...state,
        isAvailable: false
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectProductIds = selectIds;
export const selectProductEntities = selectEntities;
export const selectAllProducts = selectAll;
export const selectProductTotal = selectTotal;
