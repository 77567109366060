import { UUID } from '../app/basicModels';
import { RegionalRiskUpdateUserPermissionsPayload } from './models';

export interface UpdateRegionalRiskUserPermissionsActionPayload {
  companyId: UUID;
  userId: UUID | null;
  data: RegionalRiskUpdateUserPermissionsPayload;
}

const actions = {
  LOAD_REGIONAL_RISK_ORGS: '[RegionalRisk] Load Organizations',
  LOAD_REGIONAL_RISK_ORGS_SUCCESS: '[RegionalRisk] Load Organizations Success',
  LOAD_REGIONAL_RISK_ORGS_ERROR: '[RegionalRisk] Load Organizations Error',
  LOAD_REGIONAL_RISK_ORGS_BY_ORG: '[RegionalRisk] Load Organizations By Organization',
  LOAD_REGIONAL_RISK_ORGS_BY_ORG_SUCCESS: '[RegionalRisk] Load Organizations By Organization Success',
  LOAD_REGIONAL_RISK_ORGS_BY_ORG_ERROR: '[RegionalRisk] Load Organizations By Organization Error',
  UPDATE_REGIONAL_RISK_ORGS_BY_ORG: '[RegionalRisk] Update Organizations By Organization',
  UPDATE_REGIONAL_RISK_ORGS_BY_ORG_SUCCESS: '[RegionalRisk] Update Organizations By Organization Success',
  UPDATE_REGIONAL_RISK_ORGS_BY_ORG_ERROR: '[RegionalRisk] Update Organizations By Organization Error',
  LOAD_REGIONAL_RISK_TECHNICIANS: '[RegionalRisk] Load Technicians',
  LOAD_REGIONAL_RISK_TECHNICIANS_SUCCESS: '[RegionalRisk] Load Technicians Success',
  LOAD_REGIONAL_RISK_TECHNICIANS_ERROR: '[RegionalRisk] Load Technicians Error',
  LOAD_REGIONAL_RISK_USER_PERMISSIONS: '[RegionalRisk] Load User Permissions',
  LOAD_REGIONAL_RISK_USER_PERMISSIONS_SUCCESS: '[RegionalRisk] Load User Permissions Success',
  LOAD_REGIONAL_RISK_USER_PERMISSIONS_ERROR: '[RegionalRisk] Load User Permissions Error',
  UPDATE_REGIONAL_RISK_USER_PERMISSIONS: '[RegionalRisk] Update User Permissions',
  UPDATE_REGIONAL_RISK_USER_PERMISSIONS_SUCCESS: '[RegionalRisk] Update User Permissions Success',
  UPDATE_REGIONAL_RISK_USER_PERMISSIONS_ERROR: '[RegionalRisk] Update User Permissions Error',
  RESET_SAVED_ORGS: '[RegionalRisk] Reset Saved Orgs',
  RESET_SAVED_EXTERNAL_USERS: '[RegionalRisk] Reset Saved External Orgs',

  LoadRegionalRiskOrgs: () => ({
    type: actions.LOAD_REGIONAL_RISK_ORGS
  }),
  LoadRegionalRiskOrgsSuccess: data => ({
    type: actions.LOAD_REGIONAL_RISK_ORGS_SUCCESS,
    payload: data
  }),
  LoadRegionalRiskOrgsFailure: error => ({
    type: actions.LOAD_REGIONAL_RISK_ORGS_ERROR,
    payload: error
  }),
  LoadRegionalRiskOrgsbyOrg: (companyId: UUID) => ({
    type: actions.LOAD_REGIONAL_RISK_ORGS_BY_ORG,
    payload: companyId
  }),
  LoadRegionalRiskOrgsbyOrgSuccess: data => ({
    type: actions.LOAD_REGIONAL_RISK_ORGS_BY_ORG_SUCCESS,
    payload: data
  }),
  LoadRegionalRiskOrgsbyOrgFailure: error => ({
    type: actions.LOAD_REGIONAL_RISK_ORGS_BY_ORG_ERROR,
    payload: error
  }),
  updateRegionalRiskOrgsByOrg: (companyId: UUID, avizioOrgList: Array<string>, qualimetreOrgList: Array<string>) => ({
    type: actions.UPDATE_REGIONAL_RISK_ORGS_BY_ORG,
    payload: { companyId, avizioOrgList, qualimetreOrgList }
  }),
  updateRegionalRiskOrgsByOrgSuccess: () => ({
    type: actions.UPDATE_REGIONAL_RISK_ORGS_BY_ORG_SUCCESS
  }),
  updateRegionalRiskOrgsByOrgFailure: error => ({
    type: actions.UPDATE_REGIONAL_RISK_ORGS_BY_ORG_ERROR,
    payload: error
  }),
  LoadRegionalRiskTechnicians: (orgId: UUID) => ({
    type: actions.LOAD_REGIONAL_RISK_TECHNICIANS,
    payload: orgId
  }),
  LoadRegionalRiskTechniciansSuccess: data => ({
    type: actions.LOAD_REGIONAL_RISK_TECHNICIANS_SUCCESS,
    payload: data
  }),
  LoadRegionalRiskTechniciansFailure: error => ({
    type: actions.LOAD_REGIONAL_RISK_TECHNICIANS_ERROR,
    payload: error
  }),
  LoadRegionalRiskUserPermissions: (companyId: UUID, userId: UUID) => ({
    type: actions.LOAD_REGIONAL_RISK_USER_PERMISSIONS,
    payload: { companyId, userId }
  }),
  LoadRegionalRiskUserPermissionsSuccess: data => ({
    type: actions.LOAD_REGIONAL_RISK_USER_PERMISSIONS_SUCCESS,
    payload: data
  }),
  LoadRegionalRiskUserPermissionsFailure: error => ({
    type: actions.LOAD_REGIONAL_RISK_USER_PERMISSIONS_ERROR,
    payload: error
  }),
  UpdateRegionalRiskUserPermissions: (companyId: UUID, userId: UUID | null, data: RegionalRiskUpdateUserPermissionsPayload) => ({
    type: actions.UPDATE_REGIONAL_RISK_USER_PERMISSIONS,
    payload: { companyId, userId, data } as UpdateRegionalRiskUserPermissionsActionPayload
  }),
  UpdateRegionalRiskUserPermissionsSuccess: () => ({
    type: actions.UPDATE_REGIONAL_RISK_USER_PERMISSIONS_SUCCESS
  }),
  UpdateRegionalRiskUserPermissionsFailure: error => ({
    type: actions.UPDATE_REGIONAL_RISK_USER_PERMISSIONS_ERROR,
    payload: error
  }),
  ResetSavedOrgs: () => ({
    type: actions.RESET_SAVED_ORGS
  }),
  ResetSavedExternalUsers: () => ({
    type: actions.RESET_SAVED_EXTERNAL_USERS
  })
};

export default actions;

export const RegionalRiskActions = actions;
