import actions from './actions';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';
import { Characteristic, CharacteristicState, CharacteristicPage } from './models';
import { initialPage } from '../app/basicModels';

const entity: EntityAdapter<Characteristic> = createEntityAdapter<Characteristic>();

const initialState: CharacteristicState = entity.getInitialState({
  page: {
    ...initialPage
  },
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  error: null,
  editModalActive: false,
  characteristicSelect: null,
  characteristicSelectLoading: false,
  allCharacteristics: [],
  allCharacteristicsCanonicals: [],
  allCharacteristicsLoading: false,
  allCharacteristicsCanonicalLoading: false,
  categoricalModalVisible: false,
  category: null
});

export default (state = initialState, action): CharacteristicState => {
  switch (action.type) {
    case actions.TOGGLE_CHARACTERISTIC_EDIT_MODAL:
      return {
        ...state,
        editModalActive: !state.editModalActive
      };

    case actions.LOAD_CHARACTERISTICS:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_CHARACTERISTICS_SUCCESS:
      return entity.addAll((action.payload as CharacteristicPage).content, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null
      });

    case actions.LOAD_CHARACTERISTICS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.SAVE_CHARACTERISTIC:
      return {
        ...state,
        isSaving: true
      };

    case actions.SAVE_CHARACTERISTIC_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isSaving: false,
        error: null,
        characteristicSelect: null,
        allCharacteristics: [
          ...(state.allCharacteristics || []).filter(characteristic => characteristic.id !== action.payload.id),
          action.payload
        ]
      });

    case actions.SAVE_CHARACTERISTIC_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case actions.LOAD_CHARACTERISTIC:
      return {
        ...state,
        characteristicSelectLoading: true,
        characteristicSelect: null
      };

    case actions.LOAD_CHARACTERISTIC_SUCCESS:
      return {
        ...state,
        characteristicSelectLoading: false,
        characteristicSelect: action.payload
      };

    case actions.LOAD_CHARACTERISTIC_FAILURE:
      return {
        ...state,
        characteristicSelectLoading: false,
        error: action.payload
      };

    case actions.DELETE_CHARACTERISTIC:
      return {
        ...state,
        error: null,
        isDeleting: true
      };

    case actions.DELETE_CHARACTERISTIC_SUCCESS:
      return entity.removeOne(action.payload.id, {
        ...state,
        isDeleting: false
      });

    case actions.DELETE_CHARACTERISTIC_FAILURE:
      return {
        ...state,
        error: action.payload,
        isDeleting: false
      };

    case actions.UNLOAD_CHARACTERISTICS:
      return initialState;

    case actions.FIND_ALL_CHARACTERISTICS:
      return {
        ...state,
        allCharacteristicsLoading: true
      };

    case actions.FIND_ALL_CHARACTERISTICS_SUCCESS:
      return {
        ...state,
        allCharacteristics: [...action.payload],
        allCharacteristicsLoading: false,
        error: null
      };

    case actions.FIND_ALL_CHARACTERISTICS_FAILURE:
      return {
        ...state,
        allCharacteristicsLoading: false,
        error: action.payload
      };

    case actions.FIND_ALL_CHARACTERISTICS_CANONICALS:
      return {
        ...state,
        allCharacteristicsCanonicalLoading: true
      };

    case actions.FIND_ALL_CHARACTERISTICS_CANONICALS_SUCCESS:
      return {
        ...state,
        allCharacteristicsCanonicals: [...action.payload],
        allCharacteristicsCanonicalLoading: false,
        error: null
      };

    case actions.FIND_ALL_CHARACTERISTICS_CANONICALS_FAILURE:
      return {
        ...state,
        allCharacteristicsCanonicalLoading: false,
        error: action.payload
      };

    case actions.CLEAR_CHARACTERISTIC_SELECT:
      return {
        ...state,
        characteristicSelect: null
      };

    case actions.TOGGLE_CATEGORICAL_MODAL:
      return {
        ...state,
        category: action.payload || null,
        categoricalModalVisible: !state.categoricalModalVisible
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectCharacteristicIds = selectIds;
export const selectCharacteristicEntities = selectEntities;
export const selectAllCharacteristics = selectAll;
export const selectCharacteristicTotal = selectTotal;
