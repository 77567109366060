import { Action, UUID } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map, concatMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import actions from './actions';
import { loadMethodologyPlans, saveMethodologyPlans, deleteMethodologyPlans, updateMethodologyPlans } from './service';
import { MethodologyPlans, MethodologyPlansFlat } from './models';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';

export const handleLoadMethodologyPlans = action$ =>
  action$.pipe(
    ofType(actions.LOAD_METHODOLOGY_PLANS),
    concatMap(() =>
      loadMethodologyPlans().pipe(
        map(response => response.data),
        map((plans: MethodologyPlans[]) => {
          const plansHandled = plans.map(({ methodology_id, plan_ids }) => ({ methodology_id, plan_ids }))
          return actions.loadMethodologyPlansSuccess(plansHandled)
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading methodology template plans!');
          return of(actions.saveMethodologyPlansFailure(errorMsg));
        })
      )
    )
  );

export const handleSaveMethodologyPlans = action$ =>
  action$.pipe(
    ofType(actions.SAVE_METHODOLOGY_PLANS),
    map((action: Action<MethodologyPlansFlat>) => action.payload),
    concatMap((payload: MethodologyPlansFlat) =>
      saveMethodologyPlans(payload).pipe(
        map(response => response.data),
        map((plans: MethodologyPlans) => actions.saveMethodologyPlansSuccess(plans)),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error saving methodology template plans!');
          return of(actions.saveMethodologyPlansFailure(errorMsg));
        })
      )
    )
  );

export const handleDeleteMethodologyPlans = action$ =>
  action$.pipe(
    ofType(actions.DELETE_METHODOLOGY_PLANS),
    map((action: Action<UUID>) => action.payload),
    concatMap((payload: UUID) =>
      deleteMethodologyPlans(payload).pipe(
        map(() => actions.deleteMethodologyPlansSuccess()),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error deleting methodology template plans!');
          return of(actions.deleteMethodologyPlansFailure(errorMsg));
        })
      )
    )
  );

  export const handleUpdateMethodologyPlans = action$ =>
  action$.pipe(
    ofType(actions.UPDATE_METHODOLOGY_PLANS),
    map((action: Action<UUID>) => action.payload),
    concatMap(({ methodologyTemplateId, plans_ids}) =>
      updateMethodologyPlans(methodologyTemplateId, plans_ids).pipe(
        map(response => response.data),
        map((methodologyPlans: MethodologyPlans) => actions.updateMethodologyPlansSuccess(methodologyPlans)),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error updating methodology template plans!');
          return of(actions.updateMethodologyPlansFailure(errorMsg));
        })
      )
    )
  );




  