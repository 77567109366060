import React from 'react';

const CalendarGlyph = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='https://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 1C11 0.447715 10.5523 0 10 0C9.44771 0 9 0.447715 9 1H5C5 0.447715 4.55228 0 4 0C3.44772 0 3 0.447715 3 1C1.34315 1 0 2.34315 0 4V11C0 12.6569 1.34315 14 3 14H11C12.6569 14 14 12.6569 14 11V4C14 2.34315 12.6569 1 11 1ZM3 3C3 3.55228 3.44772 4 4 4C4.55228 4 5 3.55228 5 3H9C9 3.55228 9.44771 4 10 4C10.5523 4 11 3.55228 11 3C11.5523 3 12 3.44772 12 4V6H2V4C2 3.44772 2.44772 3 3 3ZM3 12H11C11.5523 12 12 11.5523 12 11V8H2V11C2 11.5523 2.44772 12 3 12Z'
        fill='#515253'
      />
    </svg>
  );
};

export default CalendarGlyph;
