import React from 'react';
import { Controller } from 'react-hook-form';
import { TreeSelect } from 'antd';
import { TreeNodeSimpleMode } from 'antd/lib/tree-select/interface';

interface TreeSelectItemProps {
  name: string;
  initialValue?: string;
  treeData?: TreeNodeSimpleMode[];
  disabled?: boolean;
  control: any;
}

const TreeSelectItem = ({ name, initialValue, treeData, disabled, control }: TreeSelectItemProps) => (
  <Controller
    name={name}
    as={
      <TreeSelect
        id={name}
        defaultValue={initialValue}
        treeData={treeData}
        disabled={disabled}
        treeCheckable={true}
        className={'st-tree'}
        showCheckedStrategy='SHOW_PARENT'
        treeNodeFilterProp='title'
        allowClear
      />
    }
    control={control}
    defaultValue={initialValue}
  />
);

export default TreeSelectItem;
