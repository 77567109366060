import { OrderedCategory, OrderedPhenomenon, OrderedComponent, Methodology } from './models';
import uuid from 'uuid/v4';
import { cloneDeep, groupBy } from 'lodash';
import { User, UUID, SeasonTree } from '../models';
import { ClassName, MethodologyUpdatePayload } from '../methodologyDeep/models';
import { commandRequest } from '../../helpers/request/utils';
import { CommandRequest } from '../../helpers/request/models';

export function newOrder(methodology) {
  const mutableMethodology = cloneDeep(methodology);
  if (mutableMethodology && mutableMethodology.inspection_layout && mutableMethodology.inspection_layout.ordered_inspection_groups) {
    const igs = mutableMethodology.inspection_layout.ordered_inspection_groups.map(ig => {
      if (!ig.phenomenons_selected_draft) return ig;
      const groupCategories: any = groupBy(ig.phenomenons_selected_draft, phenomenon => phenomenon.categoryId);

      const orderedCategories: OrderedCategory[] = [];

      const categoryOrder = 0;
      for (const id in groupCategories) {
        const gc = groupCategories[id];

        const category: OrderedCategory = {
          id: uuid(),
          component_id: id,
          priority: categoryOrder,
          ordered_phenomenons: []
        };

        gc.forEach(phenomenon => {
          const orderedPhenomenon: OrderedPhenomenon = {
            id: uuid(),
            component_id: phenomenon.id,
            priority: phenomenon.priority,
            ordered_components: []
          };
          phenomenon.items.forEach(item => {
            const component: OrderedComponent = {
              id: uuid(),
              component_id: item.id,
              priority: item.priority,
              class_name: item.isIndicator ? ClassName.INDICATOR : ClassName.CHARACTERISTIC
            };
            orderedPhenomenon.ordered_components.push(component);
          });
          category.ordered_phenomenons.push(orderedPhenomenon);
        });
        orderedCategories.push(category);
        ig.ordered_categories = orderedCategories;
      }

      ig.phenomenons_selected_draft = null;
      return ig;
    });

    mutableMethodology.inspection_layout.ordered_inspection_groups = igs;
    return mutableMethodology;
  }
  return methodology;
}

export function buildCommandRequest(methodology: Methodology, currentUser: User): CommandRequest<Methodology | MethodologyUpdatePayload> {
  const methodologyToSave = {
    id: methodology.id,
    name: methodology.name,
    parent_id: methodology.parent_id,
    company_id: methodology.company_id,
    description: methodology.description,
    crop_id: methodology.crop_id,
    product_set_id: methodology.product_set_id,
    season_ids: methodology.season_ids,
    season_property_ids: methodology.season_property_ids,
    season_area_ids: methodology.season_area_ids,
    analytic_context_dto: methodology.analytic_context_dto,
    inspection_layout: methodology.inspection_layout,
    default_methodology: methodology.default_methodology,
    admin_version: 1,
    cohese: true
  };

  const payload = methodology.isNew
    ? methodologyToSave
    : {
        id: methodology.id!,
        companyId: methodology.company_id!,
        methodology_dto: methodologyToSave
      };

  return commandRequest<Methodology | MethodologyUpdatePayload>(payload, currentUser);
}

export function mapSeasonsTreeSeasonPropertiesSeasonAreas(
  methodologyToSave: Methodology,
  seasonsTreeSelected: UUID[],
  seasonsTree: SeasonTree[]
) {
  methodologyToSave.season_ids = [];
  methodologyToSave.season_property_ids = [];
  methodologyToSave.season_area_ids = [];

  seasonsTree.forEach(st => {
    const seasons = seasonsTreeSelected.filter(sts => sts === st.season.id);

    if (seasons && seasons.length > 0) {
      methodologyToSave.season_ids.push(st.season.id);
    }

    st.seasonProperties.forEach(sp => {
      const seasonProperties = seasonsTreeSelected.filter(sts => sts === sp.id);
      if (seasonProperties && seasonProperties.length > 0) methodologyToSave.season_property_ids.push(sp.id);
      sp.seasonAreas.forEach(sa => {
        const seasonAreas = seasonsTreeSelected.filter(sts => sts === sa.id);
        if (seasonAreas && seasonAreas.length > 0) methodologyToSave.season_area_ids.push(sa.id);
      });
    });
  });
  return methodologyToSave;
}

export const downloadCSV = file => {
  try {
    if (!file) throw new Error('File undefined');

    const blob = new Blob([file], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'methodology.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
  }
};
