import actions from './actions';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';
import { ExtraDimensionState } from '../models';
import { ExtraDimension } from './models';

const entity: EntityAdapter<ExtraDimension> = createEntityAdapter<ExtraDimension>();

const initialState: any = entity.getInitialState({
  isLoading: false,
  isSaving: false,
  error: null,
  extraDimensionSelect: undefined,
  extraDimensions: undefined
});

export default (state = initialState, action): ExtraDimensionState => {
  switch (action.type) {
    case actions.EXTRA_DIMENSION_SELECT:
      return {
        ...state,
        extraDimensionSelect: action.payload
      };

    case actions.LOAD_EXTRA_DIMENSIONS:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_EXTRA_DIMENSIONS_BY_COMPANY:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_EXTRA_DIMENSIONS_BY_COMPANY_SUCCESS:
      return entity.addAll(action.payload, {
        ...state,
        isLoading: false,
        isSaving: false,
        error: null,
        extraDimensions: action.payload
      });

    case actions.LOAD_EXTRA_DIMENSIONS_SUCCESS:
      return {
        ...state,
        extraDimensions: action.payload,
        isLoading: false,
        error: null
      };

    case actions.SAVE_EXTRA_DIMENSION:
      return {
        ...state,
        isSaving: true
      };

    case actions.SAVE_EXTRA_DIMENSION_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isSaving: false,
        error: null
      });

    case actions.CLEAR_EXTRA_DIMENSION_SELECT:
      return {
        ...state,
        extraDimensionSelect: undefined
      };

    case actions.CLEAR_EXTRA_DIMENSIONS_ENTITY:
      return entity.removeAll({
        ...state
      });

    default:
      return state;
  }
};
