import { Dictionary, EntityState } from 'redux-ngrx-entity';
import { UUID, I18nMap, DemeterPage, PageableQuery, Action } from '../app/basicModels';
import { Indicator } from '../models';

export type CharacteristicPage = DemeterPage<Characteristic>;
export type LoadCharacteristicType = (pageableQuery: PageableQuery) => Action<PageableQuery>;

export type LoadCharacteristicSuccessType = (characteristicPage: CharacteristicPage) => Action<CharacteristicPage>;

export type LoadCharacteristicByPhenomenonType = (pageableQuery: PageableQuery, phenomenon_id: number) => Action<PageableQuery>;

export enum InputBehaviour {
  NON_ZERO = 'NON_ZERO',
  INTEGER = 'INTEGER'
}

export enum CharacteristicInputType {
  NUMERICAL_RULER = 'NUMERICAL_RULER',
  SEQUENTIAL = 'SEQUENTIAL',
  DROPDOWN = 'DROPDOWN',
  BUTTON = 'BUTTON'
}

export enum InputDefinitionType {
  SWITCH = 'SwitchInputDefinitionDTO',
  NUMPAD = 'NumpadInputDefinitionDTO',
  CATEGORICAL = 'CategoricalInputDefinitionDTO'
}

export interface LiteralValue {
  value: number;
  localized_literal: I18nMap;
}

export interface InputDefinition {
  id?: UUID;
  localized_unit?: I18nMap;
  input_type?: CharacteristicInputType | null;
  input_behaviours?: InputBehaviour[];
  class_name: InputDefinitionType;
  default_value?: any;
  minimum_value?: number;
  maximum_value?: number;
  switch_off_value?: number;
  switch_on_value?: number;
  switch_off_text?: I18nMap;
  switch_on_text?: I18nMap;
  values?: LiteralValue[];
  format_behavior?: number;
  step_size?: number;
  decimal_places?: number;
  monitoring_required?: boolean | null;
}

export interface Characteristic {
  aliases_ids: string[];
  isNew: boolean;
  id?: UUID;
  name: I18nMap;
  company_id?: UUID | null;
  parent_id?: UUID;
  phenomenon_id?: UUID | null;
  description?: I18nMap;
  tip?: I18nMap;
  input_definition: InputDefinition;
  cohese?: boolean;
  default_indicator_id?: UUID;
  crop_ids?: UUID[];
  created_at?: string;
  is_canonical?: boolean;
  deleted_at?: string | null;
}

export interface CharacteristicWithDefaultIndicator extends Characteristic {
  default_indicator: Indicator;
}

export interface IndependentVariable {
  readonly isNew: boolean;
  readonly id?: UUID;
  readonly name: I18nMap;
  readonly company_id?: UUID | null;
  readonly parent_id?: UUID;
  readonly description?: I18nMap;
  readonly tip?: I18nMap;
  readonly input_definition: InputDefinition;
  readonly cohese?: boolean;
  readonly crop_ids?: UUID[];
  readonly created_at?: string;
  readonly phenomenon_id?: null;
  readonly is_canonical?: boolean;
  readonly deleted_at?: string | null;
  readonly companyId?: UUID | null;
  readonly aliases_ids?: UUID[];
}

export interface IndependentVariableForm {
  // Basic Info:
  readonly name: string;
  readonly id: string;
  readonly description: string;
  readonly tip: string;

  // Numerical Type
  readonly numericalInputBehavior?: InputBehaviour[];
  readonly decimalPlaces?: number;
  readonly maxValue?: number;
  readonly minValue?: number;
  readonly numericalDefaultValue?: number;
  readonly numericalUnitOfMeasure?: string;
  readonly stepSize?: number;

  // Switch Type
  readonly switchInputBehavior?: InputBehaviour[];
  readonly switchDefaultValue?: DefaultValue;
  readonly offText?: string;
  readonly offValue?: number;
  readonly onText?: string;
  readonly onValue?: number;
  readonly switchUnitOfMeasure?: string;

  // Categorical Type
  readonly categoricalDefaultValue?: number;
  readonly categoricalUnitOfMeasure?: string;
  readonly defaultValueName?: string;
}

export interface CharacteristicWithPhenomenonInformations extends Characteristic {
  phenomenonName: I18nMap | string | undefined;
  phenomenonScientificName: I18nMap | string | undefined;
}

export interface CharacteristicState extends EntityState<Characteristic> {
  page: CharacteristicPage;
  isLoading: boolean;
  error: string | null;
  isSaving: boolean;
  editModalActive: boolean;
  characteristicSelect: Characteristic | null;
  characteristicSelectLoading: boolean;
  allCharacteristics: Characteristic[];
  allCharacteristicsCanonicals: Characteristic[];
  allCharacteristicsLoading: boolean;
  allCharacteristicsCanonicalLoading: boolean;
  categoricalModalVisible: boolean;
  category: any;
  isDeleting: boolean;
}

export interface ParseCharacteristic {
  name: string;
  company_id: UUID | null;
  id: UUID;
}

export interface LoadCharacteristicQueryPayload {
  pageableQuery: PageableQuery;
  companyId: UUID;
  showCanonicals: boolean;
  disableCache: boolean;
}

export interface CharacteristicCategory {
  id: UUID;
  value: number;
  name: string;
}

export enum DefaultValue {
  OFF = 'OFF',
  ON = 'ON',
  NO_DEFAULT_VALUE = 'NO DEFAULT VALUE'
}

export type FlatCharacteristic = {
  id: UUID;
  name: string;
  phenomenonId?: UUID;
  isCanonical: boolean;
};

export enum MonitoringRequiredValue {
  YES = 'true',
  NO = 'false'
}

export interface CustomVariableType {
  readonly class_name: AreaVariableType;
  readonly company_id: UUID;
  readonly created_at: string;
  readonly crop_id: UUID;
  readonly deleted_at: string | null;
  readonly description: {
    readonly localized_strings: Dictionary<string>;
  };
  readonly id: UUID;
  readonly key: string;
  readonly modified_at: string;
  readonly name: {
    readonly localized_strings: Dictionary<string>;
  };
  readonly value: number;
}

export enum AreaVariableType {
  AREA = 'AreaVariableDoubleDTO',
  CUSTOM = 'CustomVariableDoubleDTO',
  CUSTOM_VARIABLE = 'CustomDoubleVariable'
}

export interface SaveCharacteristicParams {
  characteristic: Characteristic;
  defaultIndicator?: Indicator;
  methodologyId?: UUID;
  orderedComponentId?: UUID;
  orderedPhenomenonId?: UUID;
}
