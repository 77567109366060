import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PageableQuery, UUID } from '../app/basicModels';
import { CropPage, Crop } from './models';
import CONFIG from '../../settings';
import { CommandRequest } from '../../helpers/request/models';

const cropUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}/api/v1/crops`;

export const getCrops = (
  _query: PageableQuery,
  companyId: UUID,
  showCanonicals: boolean,
  location: string | undefined = 'BR',
  enableLocationCrop = false
): AxiosObservable<CropPage> => {
  const locationQueryParam = enableLocationCrop ? `&location=${location}` : '';
  if (companyId) {
    return axios.get<CropPage>(
      `${cropUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&companyId=${companyId}&showCanonicals=${showCanonicals}${locationQueryParam}`
    );
  }
  return axios.get<CropPage>(
    `${cropUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&showCanonicals=${showCanonicals}${locationQueryParam}`
  );
};

export const getCrop = (cropId: UUID, companyId?: UUID): AxiosObservable<Crop> => {
  return companyId
    ? axios.get<Crop>(`${cropUrl}/${cropId}?show_deleted_development_phases=true`, {
        headers: {
          'X-COMPANY-ID': companyId
        }
      })
    : axios.get<Crop>(`${cropUrl}/${cropId}`);
};

export const saveCrop = (crop: CommandRequest<Crop>, isNew: boolean): AxiosObservable<Crop> => {
  if (isNew) {
    return createCrop(crop);
  }
  return updateCrop(crop);
};

export const createCrop = (crop: any): AxiosObservable<Crop> => {
  return axios.post<Crop>(`${cropUrl}`, crop);
};

export const updateCrop = (crop: any): AxiosObservable<Crop> => {
  return axios.put<Crop>(`${cropUrl}`, crop);
};

export const deleteCrop = (crop: Crop): AxiosObservable<Crop> => {
  return axios.put<Crop>(`${cropUrl}/remove`, crop);
};
