import React from 'react';
const TranslateGlyph = props => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      d='M8.63944 10.2525C8.74311 9.9825 8.67646 9.675 8.46913 9.465L6.92149 7.92L6.94371 7.8975C8.23217 6.4425 9.15038 4.77 9.69094 3H11.1275C11.5274 3 11.8606 2.6625 11.8606 2.2575V2.2425C11.8606 1.8375 11.5274 1.5 11.1275 1.5H6.67713V0.75C6.67713 0.3375 6.34391 0 5.93663 0C5.52936 0 5.19614 0.3375 5.19614 0.75V1.5H0.73309C0.333223 1.5 0 1.8375 0 2.2425C0 2.655 0.333223 2.985 0.73309 2.985H8.284C7.78787 4.44 7.00295 5.8125 5.93663 7.0125C5.33683 6.345 4.8333 5.6175 4.41121 4.8525C4.29273 4.635 4.07799 4.5 3.83363 4.5C3.32269 4.5 2.99687 5.0625 3.24864 5.5125C3.71515 6.36 4.28533 7.17 4.95177 7.92L1.71581 11.1525C1.41961 11.445 1.41961 11.925 1.71581 12.2175C2.0046 12.51 2.47112 12.51 2.76731 12.2175L5.93663 9L7.43243 10.515C7.81009 10.8975 8.45432 10.755 8.63944 10.2525ZM12.2308 7C11.7865 7 11.3867 7.2775 11.2312 7.705L8.51356 15.055C8.33584 15.5125 8.67647 16 9.15779 16C9.44658 16 9.70575 15.82 9.80942 15.5425L10.4685 13.75H13.9858L14.6523 15.5425C14.7559 15.8125 15.0151 16 15.3039 16C15.7852 16 16.1258 15.5125 15.9555 15.055L13.2379 7.705C13.075 7.2775 12.6751 7 12.2308 7ZM11.0312 12.25L12.2308 9.0025L13.4304 12.25H11.0312Z'
      fill={props.color || 'currentColor'}
    />
  </svg>
);

export default TranslateGlyph;
