import React from 'react';
import './style/actionButtons.style.scss';

export interface ActionButtonsProps {
  children: React.ReactNode;
}

export const ActionButtons = (props: ActionButtonsProps) => <div className={'action-buttons'}>{props.children}</div>;

export default ActionButtons;
