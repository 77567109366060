import React from 'react';
import { InputType, ModeEnum, SizeEnum } from '../../components/formBuilder/models';
import STTypo from '../../components/typo/STTypo';
import { ColorOptions } from '../../components/typo/STTypo.models';
import { UserTypeEnum } from '../../redux/models';
import { ActionTypesEnum } from './rbac.query.model';

export const MAX_ROLES_SENT_INVITE_REGULAR = 30;
export const MAX_ROLES_SENT = 99;

const getInputsTypeAdd = dataInput => {
  return [
    {
      label: <STTypo color={ColorOptions.PRIMARY}>Name</STTypo>,
      name: 'name',
      disabled: dataInput.handleDisabled,
      initialValue: dataInput.name,
      size: SizeEnum.LARGE,
      type: InputType.INPUT
    },
    {
      label: <STTypo color={ColorOptions.PRIMARY}>Phone</STTypo>,
      name: 'phone',
      initialValue: dataInput.phone,
      mask: dataInput.phoneMask,
      placeholder: 'Insert a number',
      disabled: dataInput.handleDisabled,
      type: InputType.INPUT_MASK,
      size: SizeEnum.LARGE,
      required: false
    },
    {
      label: <STTypo color={ColorOptions.PRIMARY}>Country</STTypo>,
      name: 'country_code',
      initialValue: '',
      placeholder: 'Select a country',
      options: dataInput.countriesOptions,
      disabled: dataInput.handleDisabled,
      type: InputType.SELECT,
      size: SizeEnum.LARGE
    },
    {
      label: <STTypo color={ColorOptions.PRIMARY}>Job Title</STTypo>,
      name: 'job_title',
      initialValue: '',
      placeholder: 'Select a job title',
      options: dataInput.jobTitles,
      disabled: dataInput.handleDisabled,
      type: InputType.SELECT,
      size: SizeEnum.LARGE
    },
    {
      label: <STTypo color={ColorOptions.PRIMARY}>Role</STTypo>,
      name: 'role',
      defaultValue: dataInput.actualUserRoles,
      options: dataInput.rolesOption,
      placeholder: 'Roles do Protector Admin',
      disabled: dataInput.handleDisabled,
      type: InputType.SELECT,
      size: SizeEnum.LARGE,
      mode: ModeEnum.MULTIPLE,
      required: false
    }
  ];
};

export const inputsEquals = dataInput => {
  return [
    {
      label: <STTypo color={ColorOptions.PRIMARY}>Phone</STTypo>,
      name: 'phone',
      initialValue: dataInput.phone,
      mask: dataInput.phoneMask,
      placeholder: 'Insert a number',
      disabled: false,
      type: InputType.INPUT_MASK,
      size: SizeEnum.LARGE,
      required: false
    },
    {
      label: <STTypo color={ColorOptions.PRIMARY}>Job Title</STTypo>,
      name: 'job_title',
      initialValue: '',
      placeholder: 'Select a job title',
      options: dataInput.jobTitles,
      disabled: false,
      type: InputType.SELECT,
      size: SizeEnum.LARGE
    },
    {
      label: <STTypo color={ColorOptions.PRIMARY}>Country</STTypo>,
      name: 'country_code',
      initialValue: '',
      placeholder: 'Select a country',
      options: dataInput.countriesOptions,
      disabled: false,
      type: InputType.SELECT,
      size: SizeEnum.LARGE
    }
  ];
};

export const allTypesInputs = dataInput => {
  return {
    [UserTypeEnum.REGULAR]: {
      [ActionTypesEnum.ADD]: getInputsTypeAdd(dataInput),
      [ActionTypesEnum.EDIT]: [
        {
          label: <STTypo color={ColorOptions.PRIMARY}>User name</STTypo>,
          name: 'email',
          initialValue: dataInput.email,
          type: InputType.INPUT,
          disabled: true
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Language</STTypo>,
          name: 'language',
          initialValue: dataInput.language,
          options: dataInput.languagesOptions,
          size: SizeEnum.LARGE,
          type: InputType.SELECT
        },
        ...inputsEquals(dataInput),
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Role</STTypo>,
          name: 'role',
          defaultValue: dataInput.actualUserRoles,
          options: dataInput.rolesOption,
          placeholder: 'Select a role',
          disabled: false,
          type: InputType.SELECT,
          size: SizeEnum.LARGE,
          mode: ModeEnum.MULTIPLE,
          required: false
        }
      ]
    },
    [UserTypeEnum.MANAGED]: {
      [ActionTypesEnum.ADD]: [
        {
          label: <STTypo color={ColorOptions.PRIMARY}>User name</STTypo>,
          name: 'email',
          initialValue: dataInput.email,
          type: InputType.INPUT,
          disabled: false
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Password</STTypo>,
          name: 'password',
          initialValue: '',
          type: InputType.INPUT_PASSWORD_ICON,
          disabled: false
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Phone</STTypo>,
          name: 'phone',
          initialValue: dataInput.phone,
          mask: dataInput.phoneMask,
          placeholder: 'Insert a number',
          disabled: false,
          type: InputType.INPUT_MASK,
          size: SizeEnum.LARGE,
          required: false
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Language</STTypo>,
          name: 'language',
          initialValue: dataInput.language,
          disabled: false,
          options: dataInput.languagesOptions,
          type: InputType.SELECT
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Country</STTypo>,
          name: 'country_code',
          initialValue: '',
          placeholder: 'Select a country',
          options: dataInput.countriesOptions,
          disabled: false,
          type: InputType.SELECT,
          size: SizeEnum.LARGE
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Job Title</STTypo>,
          name: 'job_title',
          initialValue: '',
          placeholder: 'Select a job title',
          options: dataInput.jobTitles,
          disabled: false,
          type: InputType.SELECT,
          size: SizeEnum.LARGE
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Role</STTypo>,
          name: 'role',
          defaultValue: dataInput.actualUserRoles,
          options: dataInput.rolesOption,
          placeholder: 'Select a role',
          disabled: false,
          type: InputType.SELECT,
          size: SizeEnum.LARGE,
          mode: ModeEnum.MULTIPLE,
          required: false
        }
      ],
      [ActionTypesEnum.EDIT]: [
        {
          label: <STTypo color={ColorOptions.PRIMARY}>User name</STTypo>,
          name: 'login',
          initialValue: dataInput.login,
          type: InputType.INPUT,
          size: SizeEnum.LARGE,
          disabled: true
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Language</STTypo>,
          name: 'language',
          initialValue: dataInput.language,
          options: dataInput.languagesOptions,
          type: InputType.SELECT,
          size: SizeEnum.LARGE,
          disabled: false
        },
        ...inputsEquals(dataInput),
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Role</STTypo>,
          name: 'role',
          defaultValue: dataInput.actualUserRoles,
          options: dataInput.rolesOption,
          placeholder: 'Select a role',
          required: false,
          disabled: false,
          type: InputType.SELECT,
          size: SizeEnum.LARGE,
          mode: ModeEnum.MULTIPLE
        }
      ]
    },
    [UserTypeEnum.CONTROLE_CERTO]: {
      [ActionTypesEnum.ADD]: getInputsTypeAdd(dataInput),
      [ActionTypesEnum.EDIT]: [
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Email</STTypo>,
          name: 'email',
          initialValue: dataInput.email,
          type: InputType.INPUT,
          disabled: true
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Language</STTypo>,
          name: 'language',
          initialValue: dataInput.language,
          disabled: false,
          options: dataInput.languagesOptions,
          type: InputType.SELECT
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Country</STTypo>,
          name: 'country_code',
          initialValue: '',
          placeholder: 'Select a country',
          options: dataInput.countriesOptions,
          disabled: false,
          type: InputType.SELECT,
          size: SizeEnum.LARGE
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Name</STTypo>,
          name: 'name',
          disabled: false,
          initialValue: dataInput.name,
          size: SizeEnum.LARGE,
          type: InputType.INPUT
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Phone</STTypo>,
          name: 'phone',
          initialValue: dataInput.phone,
          mask: dataInput.phoneMask,
          placeholder: 'Insert a number',
          disabled: false,
          type: InputType.INPUT_MASK,
          size: SizeEnum.LARGE,
          required: false
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Job Title</STTypo>,
          name: 'job_title',
          initialValue: '',
          placeholder: 'Select a job title',
          options: dataInput.jobTitles,
          disabled: false,
          type: InputType.SELECT,
          size: SizeEnum.LARGE
        },
        {
          label: <STTypo color={ColorOptions.PRIMARY}>Role</STTypo>,
          name: 'role',
          defaultValue: dataInput.actualUserRoles,
          options: dataInput.rolesOption,
          placeholder: 'Select a role',
          disabled: false,
          type: InputType.SELECT,
          size: SizeEnum.LARGE,
          mode: ModeEnum.MULTIPLE
        }
      ]
    }
  };
};
