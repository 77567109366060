export type UUID = string;
export type Timestamp = number;
export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC'
}
export interface Action<T = undefined> {
  type: string;
  payload?: T;
}

export type RequestControlStatus = 'LOADING' | 'SUCCESS' | 'ERROR' | undefined;

export type ActionParams<T = undefined> = Omit<Action<T>, 'type'>;

export interface AsyncAction<T = any, M = any> extends Action<T> {
  metadata: M;
}
export interface AsyncActionParams<T = any, M = any> extends ActionParams<T> {
  metadata: M;
}

export interface ControlledAction {
  id: string;
  action: () => AsyncAction;
  redirectOnSuccess: boolean;
  status?: RequestControlStatus;
  lockScreen?: boolean;
}

export interface QueryParams {
  params: Map<string, string>;
}
export interface Pageable {
  page: number;
  size: number;
  sort?: Sort;
}

/* Interface to use when requesting pageable data from server
 */
export interface PageableQuery {
  query?: QueryParams;
  pageable: Pageable;
}
export interface Sort {
  direction: Direction;
  orders: Order[];
}
export interface Order {
  direction: Direction;
  property: string;
}
export interface Audit {
  createdAt: Timestamp;
  deletedAt?: Timestamp;
  updatedAt?: Timestamp;
  createdBy: UUID;
  deletedBy?: UUID;
  revision: number;
}

export interface DemeterPage<T> {
  total_pages: number;
  size: number;
  pages: number;
  total: number;
  content: T[];
}

export interface Page<T> {
  number: number;
  size: number;
  pages: number;
  total: number;
  content: T[];
}

export interface CursorPage<T> {
  size: number;
  cursor?: string;
  is_last?: boolean;
  content: T[];
}

export const initialPage = {
  total_pages: 0,
  number: 0,
  size: 0,
  pages: 0,
  total: 0,
  content: []
};

export const initialPageableQuery = {
  pageable: {
    page: 0,
    size: 99999
  }
};

export interface I18nMap {
  localized_strings: {};
}

export interface TalosPayload<T> {
  account: { id: UUID; name: string };
  payload: T;
}

export interface TalosResponse {
  _links: {
    self: {
      href: string;
    };
  };
}
