import actions from './actions';
import { PhenomenonState, Phenomenon, PhenomenonPage } from './models';
import { initialPage } from '../app/basicModels';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';

const entity: EntityAdapter<Phenomenon> = createEntityAdapter<Phenomenon>();

const initialState: PhenomenonState = entity.getInitialState({
  page: {
    ...initialPage
  },
  isLoading: false,
  error: null,
  isSaving: false,
  phenomenonSelect: null,
  phenomenonSelectLoading: false,
  phenomenonsTree: [],
  phenomenonsTreeLoading: false,
  phenomenonsSimple: [],
  editModalActive: false,
  lastCompanyId: null,
  isCloningPhenomena: false,
  errorsCloningPhenomena: []
});

export default (state = initialState, action): PhenomenonState => {
  switch (action.type) {
    case actions.LOAD_PHENOMENONS:
    case actions.LOAD_PHENOMENONS_BY_METHODOLOGY:
    case actions.LOAD_PHENOMENONS_SIMPLE:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_PHENOMENONS_SUCCESS:
      return entity.addAll((action.payload.phenomena as PhenomenonPage).content, {
        ...state,
        page: {
          ...action.payload.phenomena
        },
        isLoading: false,
        lastCompanyId: action.payload.companyId,
        error: null
      });

    case actions.LOAD_PHENOMENONS_BY_METHODOLOGY_SUCCESS:
      return entity.addAll(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });

    case actions.LOAD_PHENOMENONS_SIMPLE_SUCCESS:
      return {
        ...state,
        phenomenonsSimple: action.payload,
        isLoading: false,
        error: null
      };

    case actions.LOAD_PHENOMENONS_FAILURE:
    case actions.LOAD_PHENOMENONS_BY_METHODOLOGY_FAILURE:
    case actions.LOAD_PHENOMENONS_SIMPLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.SAVE_PHENOMENON:
      return {
        ...state,
        isSaving: true
      };

    case actions.SAVE_PHENOMENON_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isSaving: false,
        error: null,
        methodologySelect: action.payload
      });

    case actions.SAVE_PHENOMENON_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case actions.LOAD_PHENOMENON:
      return {
        ...state,
        phenomenonSelectLoading: true
      };

    case actions.LOAD_PHENOMENON_SUCCESS:
      return {
        ...state,
        phenomenonSelectLoading: false,
        phenomenonSelect: action.payload
      };

    case actions.LOAD_PHENOMENON_FAILURE:
      return {
        ...state,
        phenomenonSelectLoading: false,
        error: action.payload
      };

    case actions.DELETE_PHENOMENON_SUCCESS:
      const phenomenonsTree = state.phenomenonsTree.filter(p => p.id !== action.payload.id);
      return entity.removeOne(action.payload.id, {
        ...state,
        error: null,
        phenomenonsTree
      });

    case actions.DELETE_PHENOMENON_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case actions.LOAD_PHENOMENONS_TREE:
      return {
        ...state,
        phenomenonsTreeLoading: true,
        lastCompanyId: action.payload.companyId
      };

    case actions.LOAD_PHENOMENONS_TREE_SUCCESS:
      return {
        ...state,
        phenomenonsTree: [...action.payload],
        phenomenonsTreeLoading: false,
        error: null
      };

    case actions.LOAD_PHENOMENONS_TREE_FAILURE:
      return {
        ...state,
        phenomenonsTreeLoading: false,
        error: action.payload
      };

    case actions.SELECT_PHENOMENON:
      return {
        ...state,
        phenomenonSelect: action.payload
      };

    case actions.TOGGLE_PHENOMENON_EDIT_MODAL:
      return {
        ...state,
        editModalActive: !state.editModalActive
      };

    case actions.UNSELECT_PHENOMENON:
      return {
        ...state,
        phenomenonSelect: null
      };
    case actions.SET_LAST_COMPANY_ID_LOAD_PHENOMENONS_TREE:
      return {
        ...state,
        lastCompanyId: action.payload
      };

    case actions.UPDATE_PHENOMENON_TREE:
      return {
        ...state,
        phenomenonsTree: action.payload
      };

    case actions.CLONE_CHARACTERISTICS_INDICATORS_TO_PHENOMENON:
      return {
        ...state,
        isCloningPhenomena: true,
        errorsCloningPhenomena: []
      };

    case actions.CLONE_CHARACTERISTICS_INDICATORS_TO_PHENOMENON_SUCCESS:
      return {
        ...state,
        isCloningPhenomena: false,
        errorsCloningPhenomena: action.payload.errors
      };

    case actions.CLONE_CHARACTERISTICS_INDICATORS_TO_PHENOMENON_FAILURE:
      return {
        ...state,
        isCloningPhenomena: false,
        errorsCloningPhenomena: action.payload
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();

export const selectPhenomenonIds = selectIds;
export const selectPhenomenonEntities = selectEntities;
export const selectAllPhenomenons = selectAll;
export const selectPhenomenonTotal = selectTotal;
