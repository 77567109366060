import { Action, UUID } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map, concatMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import actions from './actions';
import { getCrops, getCrop, saveCrop, deleteCrop } from './service';
import { CropPage, Crop, LoadCropQueryPayload, UpdateCropPayload } from './models';
import { notification } from '../../components';
import uuid from 'uuid/v4';
import { getCompanyCrops } from '../company/service';
import { commandRequest } from '../../helpers/request/utils';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';

export const handleLoadCrops = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_CROPS),
    map((action: Action<LoadCropQueryPayload>) => action.payload),
    withLatestFrom(state$.pipe(map((state: any) => state.App.systemFlags.enableLocationCrop))),
    concatMap(([payload, enableLocationCrop]: any) =>
      getCrops(payload.pageableQuery, payload.companyId, payload.showCanonicals, payload.location, enableLocationCrop).pipe(
        map(response => response.data),
        map((crops: CropPage) => {
          return actions.loadCropsSuccess({
            page: crops,
            location: payload.location
          });
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading crops!');
          return of(actions.loadCropsFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadCrop = action$ =>
  action$.pipe(
    ofType(actions.LOAD_CROP),
    map((action: Action<UUID>) => action.payload),
    concatMap((payload: any) =>
      getCrop(payload.crop_id, payload.company_id).pipe(
        map(response => response.data),
        map((crop: Crop) => {
          return actions.loadCropSuccess(crop);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading crop!');
          return of(actions.loadCropFailure(errorMsg));
        })
      )
    )
  );

export const handleSaveCrop = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_CROP),
    withLatestFrom(state$.pipe(map((state: any) => state.User.currentUser))),
    concatMap(([action, currentUser]: any) => {
      const cropPayload = action.payload.crop;
      const cropToSave = commandRequest({ ...cropPayload, cohese: true }, currentUser);
      if (cropPayload.isNew && !cropToSave.payload.id) {
        cropToSave.payload.id = uuid();
      }
      return saveCrop(cropToSave, cropToSave.payload.isNew).pipe(
        concatMap(() => {
          notification('success', 'Crop saved!');
          return of(actions.saveCropSuccess(cropToSave.payload));
        }),
        catchError((error: any) => {
          const msg = notifyErrorOrFallback(error, 'Error saving crop!');
          return of(actions.saveCropFailure(msg));
        })
      );
    })
  );

export const handleUpdateCrop = action$ =>
  action$.pipe(
    ofType(actions.UPDATE_CROP),
    map((action: Action<UpdateCropPayload>) => action.payload),
    map((payload: { crop: Crop; locations: ReadonlyArray<string>; save: boolean }) => {
      if (payload.save) {
        return actions.saveCrop(payload.crop, payload.locations);
      } else {
        return actions.selectCrop(payload.crop);
      }
    })
  );

export const handleDeleteCrop = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_CROP),
    map((action: Action<Crop>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      )
    ),
    map(([crop, currentUser]: any) => {
      const cropToSave = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          ...crop
        }
      };
      return cropToSave;
    }),
    concatMap((crop: any) =>
      deleteCrop(crop).pipe(
        map(response => {
          notification('success', 'Crop deleted!');
          return actions.deleteCropSuccess(crop.payload);
        }),
        catchError((error: any) => {
          const msg = notifyErrorOrFallback(error, 'Error deleting crop!');
          return of(actions.deleteCropFailure(msg));
        })
      )
    )
  );

export const handleLoadCompanyCrops = action$ =>
  action$.pipe(
    ofType(actions.LOAD_COMPANY_CROPS),
    map((action: Action<LoadCropQueryPayload>) => action.payload),
    concatMap((payload: any) =>
      getCompanyCrops(payload.pageableQuery, payload.companyId).pipe(
        map(response => {
          return actions.loadCompanyCropsSuccess(response.data.content);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading company crops!');
          return of(actions.loadCompanyCropsFailure(errorMsg));
        })
      )
    )
  );
