import { UUID } from '../app/basicModels';
import { EntityState } from 'redux-ngrx-entity';
import { ServiceProvider } from '../serviceProviders/models';
import { PermissionScope } from '../../helpers/userAuth/userAuth';
import { ContentRolesRBAC } from '../../querys/rbac/rbac.query.model';

export interface UserState extends EntityState<User> {
  isLoading: boolean;
  isLoadingUsersList: boolean;
  isLoadingProvidersCompanies: boolean;
  isSaving: boolean;
  error: string | null;
  isErrorLoadRolesRbac?: string;
  currentUser: User | null;
  userInvited: InviteUserRequestParams | null;
  inviteUserByEmailActive: boolean;
  isUserModalActive: boolean;
  isControleCertoUserModalActive: boolean;
  userTypeSelected: UserTypeEnum;
  isSugarcaneActive: boolean;
  userId: UUID | null;
  lastOrgId: UUID | null;
  roles: Roles[] | null;
  emailUser: OrgUser | null;
  passwordStatus: string | null;
  lastUserEdited: User | null;
  is_using_rbac?: boolean | null;
}

export interface PropertyUser {
  id: UUID;
  org_id: UUID;
  root_region_id: UUID | null;
  name: string;
  time_zone: string;
  country: string;
  state: string;
  city: string;
  address: string;
  zip_code: string;
  place_id: string;
  declared_area: number;
  reference_point: any;
}

export interface Permission {
  scope: PermissionScope;
  name: string;
}

export interface Authority {
  id: UUID;
  context: ContextAuthorization;
  permissions: Permission[];
}

export interface NewAuthority {
  id: UUID;
  context: ContextAuthorization;
  scopes: string[];
  ownership_authority_scopes?: string[] | PermissionScope[];
  inventory_authority_scopes?: PermissionScope[];
  financial_authority_scopes?: PermissionScope[];
  sugarcane_authority_scopes?: PermissionScope[];
  regional_risk_authority_scopes?: PermissionScope[];
  panel_methodology_authority_scopes?: PermissionScope[];
}

export interface User {
  id: UUID;
  name: string;
  login: string;
  email: string;
  type: AccountType;
  role: Role;
  ssn: string;
  phone: string;
  authorities: Authority[] & ContentRolesRBAC[];
  orgs: Organization[];
  locale: string;
  default_licensing_account_id?: UUID;
  is_using_rbac?: boolean;
  country_code: string;
  job_title?: string;
}

export interface ControleCertoUser {
  id?: UUID;
  name: string;
  email: string;
  type: 'USER';
  country_code: string;
  password: string;
  role:
    | 'FARMER'
    | 'AGRONOMIST'
    | 'FIELD_TECHNICIAN'
    | 'ADMIN_CONSULTANCY'
    | 'PROVIDER_ADVISOR'
    | 'ADMIN_PROVIDER'
    | 'RTV'
    | 'ADMIN_SYT_BRBH';
  authorities: NewAuthority[];
  locale: string;
  phone: string;
  default_licensing_account_id?: UUID;
}

export interface OrgUser {
  id: UUID;
  name: string;
  type: string;
  role: string;
  ssn: string;
  phone: string;
  login: string;
  authorities: NewAuthority[];
  locale?: string;
  language?: string;
  orgs: Organization[];
  country_code: string;
  email: string;
  auto_created_org_id: UUID;
  job_title?: string;
}

export interface BasicInfoUser {
  userName: string;
  password: string;
  name: string;
  type: string;
  role: string;
  ssn?: string;
  phone?: string;
  language: string;
  default_licensing_account_id?: UUID;
  country_code: string;
}

export interface CreateUserRequestParams {
  login?: string;
  password?: string;
  name: string;
  type: string;
  role?: string;
  ssn?: string;
  phone?: string;
  authorities: any;
  locale?: string;
  country_code: string;
}

export interface EditUserRequestParams {
  id: UUID;
  name: string;
  role?: string;
  ssn?: string;
  type: string;
  phone?: string;
  locale?: string;
  authorities: any;
  default_licensing_account_id?: UUID;
  country_code: string;
}

export interface Organization {
  id: UUID;
  name: string;
  address: string | null;
  city: string;
  country: string;
  currency: string;
  email: string | null;
  phone1: string | null;
  phone2: string | null;
  purchase_potential: string | null;
  state: string;
  provider?: ServiceProvider | null;
  subscriptions: Subscription[];
  zip_code: string | null;
}

export interface InviteUserRequestParams {
  name: string;
  email_address: string;
  type: AccountType;
  role: Role;
  phone: string;
  country_code: string;
  org_id: UUID;
  authorities: Authority[];
  language: InviteLanguages;
}

export enum InviteLanguages {
  EN = 'en',
  PT = 'pt-BR',
  ES = 'es',
  FR = 'fr',
  DE = 'de'
}

export interface BasicInfoControleCertoData {
  name: string;
  email: string;
  language: string;
  phone: string;
  password: string;
  country_code: string;
  role: string;
  company: UUID | UUID[];
  serviceProvider: string;
  companiesByServiceProvider: UUID[];
  userOwnershipScopes: PermissionScope[];
  userInventoryScopes: PermissionScope[];
  userFinancialScopes: PermissionScope[];
  userSugarcaneScopes: PermissionScope[];
  userMethodologyScopes: PermissionScope[];
  propertiesAuthorities: NewAuthority[];
  userRegionalRiskScopes: PermissionScope[];
}

export interface EditAuthorityRequestParams {
  updates: AuthorityUpdate[];
}

export interface AuthorityUpdate {
  operation: AuthorityUpdateOperation;
  authorities: Authority[];
}

export interface Subscription {
  companyId: UUID;
  product: string;
  users: UUID[];
  areaInHectares: number;
  accessKey: AccessKey;
  id: UUID;
}

export interface AccessKey {
  cod: string;
  notes: string;
  id: UUID;
}

export interface Roles {
  name: string;
}

export enum ContextAuthorization {
  ORGANIZATION = 'ORGANIZATION',
  PROPERTY = 'PROPERTY',
  USER = 'USER'
}

export enum PropertyPermissionName {
  TASKS = 'TASKS',
  EQUIPMENTS = 'EQUIPMENTS',
  FIELDS = 'FIELDS',
  PROPERTY = 'PROPERTY',
  INFORMATION = 'INFORMATION'
}

export enum OrganizationPermissionName {
  SEASONS = 'SEASONS',
  ORG = 'ORG',
  USERS = 'USERS',
  PRODUCTS = 'PRODUCTS',
  PROPERTIES = 'PROPERTIES',
  EXECUTORS = 'EXECUTORS'
}

export enum Role {
  FARMER = 'FARMER',
  AGRONOMIST = 'AGRONOMIST',
  FIELD_TECHNICIAN = 'FIELD_TECHNICIAN',
  ADMIN_CONSULTANCY = 'ADMIN_CONSULTANCY',
  PROVIDER_ADVISOR = 'PROVIDER_ADVISOR',
  ADMIN_PROVIDER = 'ADMIN_PROVIDER',
  RTV = 'RTV',
  PROTECTOR_USER = 'PROTECTOR_USER',
  PROTECTOR_FULL_USER = 'PROTECTOR_FULL_USER',
  ADMIN_SYT_BRBH = 'ADMIN_SYT_BRBH',
  OTHER = 'OTHER',
  ETD = 'ETD',
  ENGINEERING = 'ENGINEERING'
}

export enum AccountType {
  USER = 'USER',
  MANAGED = 'MANAGED'
}

export enum AuthorityUpdateOperation {
  ADD = 'add',
  REMOVE = 'remove'
}

export const rolesDictionary = {
  FARMER: 'Produtor',
  AGRONOMIST: 'Agrônomo',
  FIELD_TECHNICIAN: 'Técnico Consultoria',
  ADMIN_CONSULTANCY: 'Admin Consultoria',
  PROVIDER_ADVISOR: 'Consultor Revenda',
  ADMIN_PROVIDER: 'Admin Revenda',
  RTV: 'Syngenta Externo',
  ADMIN_SYT_BRBH: 'Admin Strider',
  OTHER: 'Default',
  PROTECTOR_USER: 'Protector User',
  PROTECTOR_FULL_USER: 'Protector Full User',
  ETD: 'ETD',
  ENGINEERING: 'Engineering'
};

export interface BasicUser {
  id: UUID;
  name: string;
}

export enum UserTypeEnum {
  CONTROLE_CERTO = 'CONTROLE_CERTO',
  MANAGED = 'MANAGED',
  REGULAR = 'REGULAR'
}

export interface ToggleAddEditUserModalProps {
  open: boolean;
  userId?: string;
  userType?: UserTypeEnum;
}
