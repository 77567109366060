import React from 'react';

export interface EyeProps {
  readonly color?: string;
}

const EyeGlyph = (props: EyeProps) => (
  <svg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.17988 9.66662C10.8665 9.57329 13.0065 6.33329 13.5799 5.33329C13.699 5.12702 13.699 4.87289 13.5799 4.66662C13.1532 3.92662 10.8066 0.213288 6.81988 0.333288C3.13322 0.426622 0.999883 3.66662 0.419883 4.66662C0.300794 4.87289 0.300794 5.12702 0.419883 5.33329C0.839883 6.05995 3.08655 9.66662 7.01322 9.66662H7.17988ZM12.2199 4.99996C11.5332 6.07329 9.81322 8.26662 7.14655 8.33329C4.27322 8.39996 2.39988 5.93996 1.81322 4.99996C2.47988 3.92662 4.21988 1.73329 6.88655 1.66662C9.74655 1.59329 11.6265 4.05995 12.2199 4.99996ZM6.99988 2.66662C8.28855 2.66662 9.33322 3.71129 9.33322 4.99995C9.33322 6.28862 8.28855 7.33329 6.99988 7.33329C5.71122 7.33329 4.66655 6.28862 4.66655 4.99995C4.66655 3.71129 5.71122 2.66662 6.99988 2.66662ZM7.99988 4.99996C7.99988 5.55224 7.55217 5.99996 6.99988 5.99996C6.4476 5.99996 5.99988 5.55224 5.99988 4.99996C5.99988 4.44767 6.4476 3.99996 6.99988 3.99996C7.55217 3.99996 7.99988 4.44767 7.99988 4.99996Z'
      fill={props.color ? props.color : '#707374'}
    />
  </svg>
);

export default EyeGlyph;
