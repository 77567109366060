import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import CONFIG from '../../settings';
import { UUID } from '../app/basicModels';
import { DevicesPage, DatabasesPage, Mayday } from './models';

const baseUrl = `${CONFIG.apiUrl}:${CONFIG.basePort}`;
const interactionUrl = `${baseUrl}/v1/interaction`;
const maydayUrl = `${baseUrl}/v1/mayday`;

export interface GetDevicesPayload {
  userId: UUID;
  size: number;
  page: number;
}

export const getDevices = ({ userId, size, page }: GetDevicesPayload): AxiosObservable<DevicesPage> =>
  axios.get<DevicesPage>(`${interactionUrl}?user=${userId}&size=${size}&page=${page}`);

export const getDatabases = (userId: UUID): AxiosObservable<DatabasesPage> =>
  axios.get<DatabasesPage>(`${maydayUrl}/request?user=${userId}`);

export const sendMayday = ({ deviceId, requesterId, userId }: Mayday): AxiosObservable<string> =>
  axios.post<string>(`${maydayUrl}/request?device=${deviceId}&requester=${requesterId}&user=${userId}`);
