import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputForm, InputType, ModeEnum } from '../../../components/formBuilder/models';
import FormBuilder from '../../../components/formBuilder/formBuilder';
import { ColorOptions } from '../../../components/typo/STTypo.models';
import STTypo from '../../../components/typo/STTypo';
import '../styles/fixedPointCategory.style.scss';
import IntlMessages from '../../../components/utility/intlMessages';
import { Dictionary } from 'redux-ngrx-entity';
import { Company } from '../../../redux/company/models';
import { registerInputs } from '../../../components/formBuilder/utils';
import { FixedPoint, FixedPointType } from '../../../redux/models';
import { getValueLanguage } from '../../../redux/language/utils';
import { AppState } from '../../../redux/redux.model';

export interface BasicInfoProps {
  form: any;
  formObject: FixedPoint;
  companies: Dictionary<Company>;
  fixedPointsTypes: any;
  iconTypes: any;
  isClone?: boolean;
}

export enum TypesOfTraps {
  ACCUMULATIVE_ID = 'ACCUMULATIVE',
  ALWAYSRESET_ID = 'ALWAYSRESET',
  SINGLE_USE_ID = 'SINGLE_USE'
}
const { GENERIC, TRAP, PLUVIOMETER } = FixedPointType;

const BasicInfo: FC<BasicInfoProps> = ({ form, formObject, companies, fixedPointsTypes, iconTypes, isClone }) => {
  const { register, errors, control, reset, watch } = form;
  const [specificInputs, setSpecificInputs] = useState<InputForm[]>([]);
  const enableCompanyVirtualSelect = useSelector((state: AppState) => state.App.systemFlags.enableCompanyVirtualSelect);
  const companyOptions = Object.values(companies).map(company => ({ id: company.id!, name: company.name })) || [];
  const iconTypesFiltered = iconTypes.filter(it => it.type === form.watch().class_name);
  const flags = useSelector((state: AppState) => state.App.systemFlags);

  const inputs: InputForm[] = [
    {
      label: (
        <STTypo color={ColorOptions.PRIMARY}>
          <IntlMessages id='form.name' />
        </STTypo>
      ),
      name: 'name',
      type: InputType.INPUT
    },
    {
      label: (
        <STTypo color={ColorOptions.PRIMARY}>
          <IntlMessages id='form.company' />
        </STTypo>
      ),
      name: 'company',
      type: enableCompanyVirtualSelect ? InputType.VIRTUAL_SELECT : InputType.AUTOCOMPLETE,
      initialValue: formObject && formObject.company_id,
      options: companyOptions,
      disabled: formObject && !formObject.isNew && !isClone,
      ...{ ...(isClone && { mode: ModeEnum.MULTIPLE }) }
    },
    {
      label: (
        <STTypo color={ColorOptions.PRIMARY}>
          <IntlMessages id='form.description' />
        </STTypo>
      ),
      name: 'description',
      type: InputType.TEXTAREA
    },
    {
      label: (
        <STTypo color={ColorOptions.PRIMARY}>
          <IntlMessages id='form.instalationSteps' />
        </STTypo>
      ),
      name: 'instalationSteps',
      type: InputType.TEXTAREA
    }
  ];

  const inputClassName: InputForm[] = [
    {
      label: (
        <STTypo color={ColorOptions.PRIMARY}>
          <IntlMessages id='form.fp.type' />
        </STTypo>
      ),
      name: 'class_name',
      type: InputType.SELECT,
      initialValue: formObject && formObject.class_name,
      options: fixedPointsTypes,
      disabled: formObject && !formObject.isNew
    }
  ];

  const iconTypeField = {
    label: (
      <STTypo color={ColorOptions.PRIMARY}>
        <IntlMessages id='form.iconType' />
      </STTypo>
    ),
    name: 'iconType',
    type: InputType.SELECT,
    options: iconTypesFiltered,
    initialValue: formObject && formObject.icon_type,
    disabled: formObject && !formObject.isNew && !isClone
  };

  const genericFields = [iconTypeField];

  const renewalTimeField = {
    label: <STTypo color={ColorOptions.PRIMARY}>Renewal time (days)</STTypo>,
    name: 'renewalTime',
    type: InputType.INPUT,
    required: true
  };

  const pluviometerFields = [iconTypeField, renewalTimeField];

  useEffect(() => {
    setSpecificInputs(inputClassName);
  }, []);

  useEffect(() => {
    const trapFields =
      flags.singleUseTrap || control.defaultValuesRef.current.sampling_behaviour === TypesOfTraps.SINGLE_USE_ID
        ? [
            ...pluviometerFields,
            {
              label: (
                <STTypo color={ColorOptions.PRIMARY}>
                  <IntlMessages id='form.type' />
                </STTypo>
              ),
              name: 'sampling_behaviour',
              type: InputType.RADIO,
              options: [
                {
                  id: TypesOfTraps.ACCUMULATIVE_ID,
                  name: <IntlMessages id='traps.acccumulative' />
                },
                {
                  id: TypesOfTraps.ALWAYSRESET_ID,
                  name: <IntlMessages id='traps.non_accumulative' />
                },
                {
                  id: TypesOfTraps.SINGLE_USE_ID,
                  name: <IntlMessages id='traps.single_use' />
                }
              ]
            }
          ]
        : [
            ...pluviometerFields,
            {
              label: (
                <STTypo color={ColorOptions.PRIMARY}>
                  <IntlMessages id='form.type' />
                </STTypo>
              ),
              name: 'sampling_behaviour',
              type: InputType.RADIO,
              options: [
                {
                  id: TypesOfTraps.ACCUMULATIVE_ID,
                  name: <IntlMessages id='traps.acccumulative' />
                },
                {
                  id: TypesOfTraps.ALWAYSRESET_ID,
                  name: <IntlMessages id='traps.non_accumulative' />
                }
              ]
            }
          ];
    const fpType = watch('class_name') || formObject.class_name;
    if (fpType === GENERIC) {
      setSpecificInputs([...inputClassName, ...genericFields]);
    } else if (fpType === TRAP) {
      setSpecificInputs([...inputClassName, ...trapFields]);
    } else if (fpType === PLUVIOMETER) {
      setSpecificInputs([...inputClassName, ...pluviometerFields]);
    }
  }, [watch('class_name')]);

  useEffect(() => {
    if (formObject) {
      reset({
        ...watch(),
        name: watch('name') || getValueLanguage(formObject.name),
        company: watch('company') || formObject.company_id,
        description: watch('description') || getValueLanguage(formObject.description),
        instalationSteps: watch('instalationSteps') || formObject.instalation_steps
      });
    }
  }, [formObject]);

  useEffect(() => {
    reset({
      ...watch(),
      renewalTime: watch('renewalTime') || formObject.time_for_renewal,
      sampling_behaviour: watch('sampling_behaviour') || formObject.sampling_behaviour,
      iconType: formObject.icon_type || '',
      class_name: watch('class_name') || formObject.class_name
    });
  }, [specificInputs]);

  // eslint-disable-next-line quotes
  const handleCompanyValidate = companyId => companyOptions.some(company => company.id === companyId) || "This company doesn't exist!";

  const customValidations = {
    company: {
      required: 'company is required!',
      validate: value => handleCompanyValidate(value)
    }
  };

  useEffect(() => {
    if (isClone) {
      registerInputs([...inputs, ...specificInputs], register);
      return;
    }
    registerInputs([...inputs, ...specificInputs], register, customValidations);
  }, [register]);

  return (
    <div className='fixedPointsEdit-basic-info__form' data-testid='fixedPointsEdit-basic-info-form'>
      <FormBuilder inputs={inputs} errors={errors} control={control} fieldsClass='fp-input-wrap' />

      <span className='fp-line' />

      <FormBuilder inputs={specificInputs} errors={errors} control={control} fieldsClass='fp-input-wrap' />
    </div>
  );
};

export default BasicInfo;
