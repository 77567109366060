import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { notification } from '../../components';
import { commandRequest } from '../../helpers/request/utils';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';
import { Action, UUID } from '../app/basicModels';
import { injectMetadata } from '../request-control';
import actions from './actions';
import { LoadProductQueryPayload, Product, ProductPage } from './models';
import { deleteProduct, getProduct, getProducts, saveProduct } from './service';

export const handleLoadProducts = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PRODUCTS),
    map((action: Action<LoadProductQueryPayload>) => action.payload),
    concatMap((query: any) =>
      getProducts(query.pageableQuery, query.companyId, query.showCanonicals, query.type, query.location).pipe(
        map(response => response.data),
        map((products: ProductPage) => {
          return query.appendProducts ? actions.addProductsSuccess(products) : actions.loadProductsSuccess(products);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading products!');
          return of(actions.loadProductsFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadProduct = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PRODUCT),
    map((action: Action<UUID>) => action.payload),
    concatMap((productId: UUID) =>
      getProduct(productId).pipe(
        map(response => response.data),
        map((product: Product) => {
          return actions.loadProductSuccess(product);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading product!');
          return of(actions.loadProductFailure(errorMsg));
        })
      )
    )
  );

export const handleSaveProduct = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_PRODUCT),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      )
    ),
    concatMap(([action, currentUser]: any) => {
      let product = action.payload;
      let payload = {};
      if (product.isNew) {
        payload = {
          ...product,
          cohese: true
        };
      } else {
        payload = {
          id: product.id,
          company_id: null,
          product_dto: {
            ...product,
            cohese: true
          }
        };
      }
      product = commandRequest(payload, currentUser);
      return saveProduct(product, product.payload.isNew).pipe(
        map(response => {
          const p = product.payload && product.payload.product_dto ? product.payload.product_dto : product.payload;
          const saveProductSuccess = injectMetadata(actions.saveProductSuccess, { ...action.metadata, response });
          notification('success', 'Product saved!');
          return saveProductSuccess(p);
        }),
        catchError((error: any) => {
          const isMessageErrorAvailable = !!(
            error.response &&
            error.response.data &&
            error.response.data.detail &&
            error.response.data.detail.length > 0
          );

          const msg = isMessageErrorAvailable ? error.response.data.detail : '';

          const saveProductFailure = injectMetadata(actions.saveProductFailure, {
            ...action.metadata,
            response: msg
          });

          notification('error', 'Error to save product!', msg);
          return of(saveProductFailure(msg));
        })
      );
    })
  );

export const handleDeleteProduct = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_PRODUCT),
    map((action: Action<Product>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      )
    ),
    map(([product, currentUser]: any) => {
      const productToSave = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          ...product
        }
      };
      return productToSave;
    }),
    concatMap((product: any) =>
      deleteProduct(product).pipe(
        map(_ => {
          notification('success', 'Product deleted!');
          return actions.deleteProductSuccess(product.payload);
        }),
        catchError((error: any) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error deleting product!');
          return of(actions.deleteProductFailure(errorMsg));
        })
      )
    )
  );
