import { EntityState } from 'redux-ngrx-entity';
import { UUID, Page, Action, PageableQuery, I18nMap, DemeterPage } from '../app/basicModels';
import { InspectionLayout, AnalyticContext } from '../methodology/models';
import { PhenomenonInfo } from '../methodologyDeep/models';

export type FixedPointPage = Page<FixedPoint>;
export type FixedPointDemeterPage = DemeterPage<FixedPoint>;

export type LoadFixedPointType = (pageableQuery: PageableQuery) => Action<PageableQuery>;

export type LoadFixedPointSuccesType = (FixedPointPage: FixedPointPage) => Action<FixedPointPage>;

export interface LoadFixedPointModel {
  pageableQuery: PageableQuery;
  onlyGeneric: boolean;
}

export enum IntegrationSource {
  PROTECTOR = 'PROTECTOR',
  TRAPVIEW = 'TRAPVIEW'
}
export interface FixedPoint {
  isNew?: boolean;
  type?: string;
  id?: UUID;
  name: I18nMap;
  description: I18nMap | null;
  icon_type: string;
  inspection_layout?: InspectionLayout;
  inspection_layout_dto?: InspectionLayout;
  analytic_context_dto?: AnalyticContext;
  time_for_renewal?: number;
  company_id?: UUID;
  sampling_behaviour: string;
  instalation_steps?: string;
  instaled_at?: number | null;
  integration_source?: IntegrationSource;
  phenomena_to_add?: any[];
  class_name?: string;
  remove_phenomena?: UUID[];
  remove_characteristics?: UUID[];
  remove_indicators?: any[];
  indicators_to_add?: UUID[];
}

export interface FixedPointState extends EntityState<FixedPoint> {
  page: FixedPointPage;
  isLoading: boolean;
  isLoadingPhenomenonsForFixedPoint: boolean;
  error: string | null;
  isSaving: boolean;
  fixedPointTypes: any;
  iconTypes: any[];
  fixedPointSelect: FixedPoint | null;
  fixedPointSelectLoading: boolean;
  editPhenomenaModalActive: boolean;
  phenomenonsOfFixedPoint: any[];
  characteristicsOfFixePoint: any[];
  isLoadingCharacteristicsOfFixedPoint: boolean;
  indicatorsModalVisible: boolean;
  isDrafting: boolean;
  phenomenonInfo: PhenomenonInfo | null;
  addCharacteristicIndicatorActive: boolean;
}

export interface UpdateFixedPointPayload {
  fixedPoint: FixedPoint;
  save: boolean;
}

export interface IconType {
  name: string;
  value: string;
  type: string;
}

export enum FixedPointType {
  GENERIC = 'generic',
  TRAP = 'trap',
  PLUVIOMETER = 'pluviometer'
}
