import { notification } from '../../components';
import { getCurrentLanguage } from '../../containers/LanguageSwitcher/config';
import { getValueLanguage } from '../../redux/language/utils';
import { Methodology, SeasonArea, SeasonProperty, SeasonTree } from '../../redux/models';

export interface ErrorMsg {
  readonly status: number;
  readonly message: string;
  readonly response: any;
}

export interface MethodologyReferenceError {
  referenceType: string;
  methodologyId: string;
  elementId: string;
}

const getMessage = (start: string, name: string, methodology: Methodology | undefined, locale: string): string => {
  const methodologyText = methodology ? `"${getValueLanguage(methodology.name, locale)}"` : 'another';
  return `${start} "${name}" is referenced by ${methodologyText} methodology. Inspection groups, name and description were saved successfully.`;
};

export const notifyMethodologyErrorOrFallback = (
  error: ErrorMsg,
  fallbackMsg: string,
  methodologies: Methodology[],
  seasonsTree: SeasonTree[]
): string => {
  if (!error.response || !error.response.data || !error.response.data.referenceType) {
    return notifyErrorOrFallback(error, fallbackMsg);
  }
  const currentLanguage = getCurrentLanguage();
  const reference = error.response.data as MethodologyReferenceError;
  const methodology = methodologies.find(el => el.id === reference.methodologyId);
  if (reference.referenceType === 'SEASON') {
    const season = seasonsTree.find(el => el.season.id === reference.elementId);
    const message = getMessage('Season', season ? season.season.name : '', methodology, currentLanguage.locale);
    notification('error', message);
    return message;
  }
  if (reference.referenceType === 'SEASON_PROPERTY') {
    const seasonProperty = getSeasonProperty(seasonsTree, reference);
    const message = getMessage('Season property', seasonProperty ? seasonProperty.property.name : '', methodology, currentLanguage.locale);
    notification('error', message);
    return message;
  }
  if (reference.referenceType === 'SEASON_AREA') {
    const seasonArea = getSeasonArea(seasonsTree, reference);
    const message = getMessage('Season area', seasonArea ? seasonArea.area.name : '', methodology, currentLanguage.locale);
    notification('error', message);
    return message;
  }
  return 'Unknown error!';
};

export const getErrorOrFallbackMessage = (error: ErrorMsg, fallbackMsg: string): string => {
  let errorMsg = '';
  if (error?.message?.length > 0) {
    errorMsg = error.status < 500 ? error.message : fallbackMsg;
  } else if (error?.response?.data) {
    errorMsg = error.response.data.message;
  } else {
    errorMsg = fallbackMsg;
  }
  return errorMsg;
};

export const notifyErrorOrFallback = (error: ErrorMsg, fallbackMsg: string): string => {
  const errorMsg = getErrorOrFallbackMessage(error, fallbackMsg);
  notification('error', errorMsg);
  return errorMsg;
};

function getSeasonProperty(seasonsTree: SeasonTree[], reference: MethodologyReferenceError): SeasonProperty | undefined {
  let seasonProperty: SeasonProperty | null = null;
  seasonsTree.forEach(s => {
    s.seasonProperties.forEach(sp => {
      if (sp.id === reference.elementId) {
        seasonProperty = sp;
      }
    });
  });
  return seasonProperty || undefined;
}

function getSeasonArea(seasonsTree: SeasonTree[], reference: MethodologyReferenceError): SeasonArea | undefined {
  let seasonArea: SeasonArea | null = null;
  seasonsTree.forEach(s => {
    s.seasonProperties.forEach(sp => {
      sp.seasonAreas.forEach(sa => {
        if (sa.id === reference.elementId) {
          seasonArea = sa;
        }
      });
    });
  });
  return seasonArea || undefined;
}
