import { Action, PageableQuery, UUID } from '../app/basicModels';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import actions from './actions';
import {
  deleteMethodology,
  deleteMethodologyOnBase,
  getMethodologies,
  getMethodologiesByCompanyPaginated,
  getOrgsThatUserHasAccess
} from './services';
import { Methodology, MoveIndicator, OrderedCategory, OrderedComponent, OrderedPhenomenon } from './models';
import { notification } from '../../components';
import uuid from 'uuid/v4';
import _, { cloneDeep, groupBy } from 'lodash';
import characteristicActions from '../characteristic/actions';
import phenomenonActions from '../phenomenon/actions';
import indicatorActions from '../indicator/actions';
import methodologyTemplateActions from '../methodologyTemplate/actions';
import { buildCommandRequest, newOrder } from './utils';
import { ClassName } from '../methodologyDeep/models';
import { CommandRequest } from '../../helpers/request/models';
import { Role, User } from '../user/models';
import { templateCompanyId } from '../company/models';
import { Dictionary } from 'redux-ngrx-entity';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';
import { getWorkspaceOperationsObservable } from '../../querys/rbac/rbac.query';
import { RBACActions, RBACPermissionTypesEnum } from '../../querys/rbac/rbac.query.model';
import { protectorAppId } from '../cropwisePlans/models';
import { AppState } from '../redux.model';

export const handleLoadMethodologies = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_METHODOLOGIES),
    map((action: Action<PageableQuery>) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: any) => state.User.currentUser)),
      state$.pipe(map((state: any) => state.Methodology.entities)),
      state$.pipe(map((state: any) => state.Methodology.companyId)),
      state$.pipe(map((state: any) => state.App.systemFlags))
    ),
    concatMap(
      async ([query, currentUser, methodologiesEntities, companyId, systemFlags]: [
        PageableQuery,
        User,
        Dictionary<Methodology>,
        UUID,
        Dictionary<boolean>
      ]) => {
        const availableRBAC = systemFlags?.P40_29585_integrate_web_panel_with_RBAC;
        let isAdmin: boolean;

        if (currentUser?.is_using_rbac && availableRBAC) {
          const { data } = await getWorkspaceOperationsObservable({
            operations: [
              {
                action: `${RBACActions.ADMIN_DATA_MANAGEMENT}:${RBACPermissionTypesEnum.WRITE}`,
                resource: `crn:app:${protectorAppId}`
              }
            ]
          }).toPromise();

          isAdmin = !!data?.allowed_operations?.length;
          return [query, currentUser, methodologiesEntities, companyId, isAdmin];
        }

        isAdmin = currentUser?.role === Role.ADMIN_SYT_BRBH;
        return [query, currentUser, methodologiesEntities, companyId, isAdmin];
      }
    ),
    concatMap(([query, currentUser, methodologiesEntities, companyId, isAdmin]) => {
      return getOrgsThatUserHasAccess(query).pipe(
        map(response => response.data),
        map(orgs => {
          const orgsIds = orgs.content.map(o => o.id);

          return [query, currentUser, methodologiesEntities, companyId, isAdmin, orgsIds];
        })
      );
    }),
    concatMap(
      ([query, currentUser, methodologiesEntities, companyId, isAdmin, orgsIds]: [
        PageableQuery,
        User,
        Dictionary<Methodology>,
        UUID,
        boolean,
        UUID[]
      ]) => {
        if (_.isEmpty(methodologiesEntities) || companyId) {
          return getMethodologies(query).pipe(
            map(response => response.data),
            map(methodologies => {
              // TODO: Create endpoint to filter the methodologies on backend
              const currentUserOrganizations = isAdmin ? methodologies.content.map(m => m.company_id) : orgsIds.concat(templateCompanyId);

              const currentUserMethodologies = methodologies.content.filter(m => currentUserOrganizations.includes(m.company_id!));

              return actions.loadMethodologiesSuccess(currentUserMethodologies);
            }),
            catchError((error: ErrorMsg) => {
              const errorMsg = notifyErrorOrFallback(error, 'Error loading methodologies!');
              return of(actions.loadMethodologiesFailure(errorMsg));
            })
          );
        }
        return of(actions.toggleIsLoading());
      }
    )
  );

interface ActionPayload {
  companyId: UUID;
  pageableQuery: PageableQuery;
}

export const handleLoadMethodologiesByCompany = (action$, state$) =>
  action$.pipe(
    ofType(actions.LOAD_METHODOLOGIES_BY_COMPANY),
    map((action: Action<ActionPayload>) => action.payload),
    withLatestFrom(state$.pipe(map((state: any) => state.User.currentUser)), state$.pipe(map((state: any) => state.App.systemFlags))),
    concatMap(async ([payload, currentUser, systemFlags]: [ActionPayload, User, Dictionary<Methodology>]) => {
      const availableRBAC = systemFlags?.P40_29585_integrate_web_panel_with_RBAC;
      let isAdmin: boolean;

      if (currentUser?.is_using_rbac && availableRBAC) {
        const { data } = await getWorkspaceOperationsObservable({
          operations: [
            {
              action: `${RBACActions.ADMIN_DATA_MANAGEMENT}:${RBACPermissionTypesEnum.WRITE}`,
              resource: `crn:app:${protectorAppId}`
            }
          ]
        }).toPromise();

        isAdmin = !!data?.allowed_operations?.length;
        return [payload, currentUser, isAdmin];
      }

      isAdmin = currentUser?.role === Role.ADMIN_SYT_BRBH;

      return [payload, currentUser, isAdmin];
    }),
    concatMap(([payload, currentUser, isAdmin]: [ActionPayload, User, boolean]) => {
      return getOrgsThatUserHasAccess(payload.pageableQuery).pipe(
        map(response => response.data),
        map(orgs => {
          const orgsIds = orgs.content.map(o => o.id);

          return [payload, currentUser, isAdmin, orgsIds];
        })
      );
    }),
    concatMap(([payload, currentUser, isAdmin, orgsIds]: [ActionPayload, User, boolean, string[]]) => {
      return getMethodologiesByCompanyPaginated(payload.companyId).pipe(
        map((methodologies: Methodology[]) => {
          // TODO: Create endpoint to filter the methodologies on backend
          const currentUserOrganizations = isAdmin ? methodologies.map(m => m.company_id) : orgsIds.concat(templateCompanyId);

          const currentUserMethodologies = methodologies.filter(m => currentUserOrganizations.includes(m.company_id!));

          return actions.loadMethodologiesByCompanySuccess(currentUserMethodologies);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading methodologies by company!');
          return of(actions.loadMethodologiesByCompanyFailure(errorMsg));
        })
      );
    })
  );

export const handleUpdateOrder = (action$, state$) =>
  action$.pipe(
    ofType(actions.UPDATE_ORDER),
    map((action: Action<Methodology>) => action.payload),
    map((methodology: Methodology) => {
      const mutableMethodology = newOrder(methodology);
      return actions.updateMethodologyDraft(mutableMethodology);
    })
  );

export const handleDeleteMethodology = (action$: ActionsObservable<Action<Methodology>>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(actions.DELETE_METHODOLOGY),
    map((action: Action<Methodology>) => action.payload ?? ({} as Methodology)),
    withLatestFrom(state$.pipe(map((state: AppState) => state.User.currentUser))),
    map(([methodology, currentUser]) => {
      if (!currentUser) {
        throw new Error('User is required!');
      }
      return buildCommandRequest(methodology, currentUser) as CommandRequest<Methodology>;
    }),
    concatMap((methodology: CommandRequest<Methodology>) => {
      if (!methodology.payload.id) {
        throw new Error('Methodology id is required!');
      }

      const methodologyId = methodology.payload.id;
      return deleteMethodologyOnBase(methodologyId).pipe(
        concatMap(() =>
          deleteMethodology(methodology).pipe(
            concatMap(() => {
              notification('success', 'Methodology deleted!');
              return concat([
                methodologyTemplateActions.deleteMethodologyPlans(methodologyId),
                actions.deleteMethodologySuccess(methodology.payload)
              ]);
            })
          )
        ),
        catchError(error => {
          const msg = notifyErrorOrFallback(error, 'Error deleting methodology!');
          return of(actions.loadErrorMethodologyFailure(msg));
        })
      );
    })
  );

export const handleNewOrderPhenomenons = (action$, state$) =>
  action$.pipe(
    ofType(actions.NEW_ORDER_PHENOMENONS),
    map((action: Action<any>) => action.payload),
    withLatestFrom(state$.pipe(map((state: any) => state.Methodology.methodologySelect))),
    concatMap(([payload, methodology]) => {
      const mutableMethodology = cloneDeep(methodology);
      const { phenomenons } = payload;
      const { inspectionGroupId } = payload;

      const groupCategories: any = groupBy(phenomenons, phenomenon => phenomenon.categoryId);
      const orderedCategories: OrderedCategory[] = [];

      const categoryOrder = 0;
      for (const id in groupCategories) {
        const gc = groupCategories[id];

        const category: OrderedCategory = {
          id: uuid(),
          component_id: id,
          priority: categoryOrder,
          ordered_phenomenons: []
        };

        gc.forEach(phenomenon => {
          const orderedPhenomenons: OrderedPhenomenon = {
            id: uuid(),
            component_id: phenomenon.id,
            priority: phenomenon.priority,
            ordered_components: []
          };
          phenomenon.items.forEach(item => {
            const component: OrderedComponent = {
              id: uuid(),
              component_id: item.id,
              priority: item.priority,
              class_name: item.isIndicator ? ClassName.INDICATOR : ClassName.CHARACTERISTIC
            };
            orderedPhenomenons.ordered_components.push(component);
          });
          category.ordered_phenomenons.push(orderedPhenomenons);
        });
        orderedCategories.push(category);
      }

      const valueIndex = mutableMethodology.inspection_layout.ordered_inspection_groups.map(ig => ig.id).indexOf(inspectionGroupId);
      if (valueIndex !== -1) {
        mutableMethodology.inspection_layout.ordered_inspection_groups[valueIndex].ordered_categories = orderedCategories;
      }

      return of(actions.updateMethodologyDraft(mutableMethodology));
    })
  );

export const handleAddNewComponent = (action$, state$) =>
  action$.pipe(
    ofType(characteristicActions.SAVE_CHARACTERISTIC_SUCCESS, indicatorActions.SAVE_INDICATOR_SUCCESS),
    map((action: Action<PageableQuery>) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: any) => state.Methodology.methodologySelect)),
      state$.pipe(map((state: any) => state.Methodology.inspectionGroupTabActive)),
      state$.pipe(map((state: any) => state.Phenomenon.phenomenonsTree))
    ),
    concatMap(([component, methodology, inspectionGroupTabActive, phenomenonsTree]) => {
      const componentType = component.input_definition ? 'characteristic' : 'indicator';
      if (methodology && component.isNew) {
        const mutableMethodology = cloneDeep(methodology);
        if (mutableMethodology.inspection_layout && mutableMethodology.inspection_layout.ordered_inspection_groups) {
          mutableMethodology.inspection_layout.ordered_inspection_groups.forEach(ig => {
            if (ig.id === inspectionGroupTabActive && ig.ordered_categories) {
              ig.ordered_categories.forEach(oc => {
                if (oc.ordered_phenomenons) {
                  oc.ordered_phenomenons.forEach(op => {
                    if (
                      (component.phenomenon_id && op.component_id === component.phenomenon_id) ||
                      (component.phenomenon_ids && component.phenomenon_ids.includes(op.component_id))
                    ) {
                      if (!op.ordered_components) {
                        op.ordered_components = [];
                      }
                      op.ordered_components.push({
                        id: uuid(),
                        component_id: component.id,
                        class_name: componentType,
                        priority: op.ordered_components.length + 1
                      });
                    }
                  });
                }
              });
            }
          });
        }

        if (phenomenonsTree) {
          phenomenonsTree.forEach(phenomenon => {
            if (
              (component.phenomenon_id && phenomenon.id === component.phenomenon_id) ||
              (component.phenomenon_ids && component.phenomenon_ids.includes(phenomenon.id))
            ) {
              if (componentType === 'characteristic') {
                phenomenon.characteristics.push({
                  id: component.id,
                  name: component.name,
                  company_id: component.company_id,
                  default_indicator_id: component.default_indicator_id
                });
              } else {
                phenomenon.indicators.push({
                  id: component.id,
                  name: component.name,
                  company_id: component.company_id
                });
              }
            }
          });
        }

        return concat([phenomenonActions.loadPhenomenonsTreeSuccess(phenomenonsTree), actions.updateMethodologyDraft(mutableMethodology)]);
      }
      if (methodology && !component.isNew) {
        if (phenomenonsTree) {
          const mutablePhenomenonsTree = cloneDeep(phenomenonsTree);
          mutablePhenomenonsTree.forEach(phenomenon => {
            if (componentType === 'characteristic') {
              phenomenon.characteristics.forEach(c => {
                if (c.id === component.id) {
                  c.name = component.name;
                }
              });
            } else {
              phenomenon.indicators.forEach(i => {
                if (i.id === component.id) {
                  i.name = component.name;
                }
              });
            }
          });
          return of(phenomenonActions.loadPhenomenonsTreeSuccess(mutablePhenomenonsTree));
        }
      }
      return of();
    })
  );

export const handleAddNewPhenomenon = (action$, state$) =>
  action$.pipe(
    ofType(phenomenonActions.SAVE_PHENOMENON_SUCCESS),
    map((action: Action<PageableQuery>) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: any) => state.Methodology.methodologySelect)),
      state$.pipe(map((state: any) => state.Methodology.inspectionGroupTabActive)),
      state$.pipe(map((state: any) => state.Phenomenon.phenomenonsTree))
    ),
    concatMap(([phenomenon, methodology, inspectionGroupTabActive, phenomenonsTree]) => {
      if (methodology && phenomenon.isNew) {
        const mutableMethodology = cloneDeep(methodology);
        if (mutableMethodology.inspection_layout && mutableMethodology.inspection_layout.ordered_inspection_groups) {
          mutableMethodology.inspection_layout.ordered_inspection_groups.forEach(ig => {
            if (ig.id === inspectionGroupTabActive && ig.ordered_categories) {
              const valueIndex = ig.ordered_categories.map(ig => ig.component_id).indexOf(phenomenon.category_i);
              const newPhenomenon = {
                id: uuid(),
                component_id: phenomenon.id,
                priority: 0,
                ordered_components: []
              };
              if (valueIndex !== -1) {
                ig.ordered_categories[valueIndex].ordered_phenomenons.push(newPhenomenon);
              } else {
                ig.ordered_categories.push({
                  id: uuid(),
                  component_id: phenomenon.category_id,
                  priority: ig.ordered_categories.length,
                  ordered_phenomenons: [newPhenomenon]
                });
              }
            }
          });
        }
        phenomenonsTree.push({
          id: phenomenon.id,
          category_id: phenomenon.category_id,
          name: phenomenon.name,
          characteristics: [],
          indicators: []
        });

        return concat([phenomenonActions.loadPhenomenonsTreeSuccess(phenomenonsTree), actions.updateMethodologyDraft(mutableMethodology)]);
      }
      if (methodology && !phenomenon.isNew) {
        if (phenomenonsTree) {
          const mutablePhenomenonsTree = cloneDeep(phenomenonsTree);
          mutablePhenomenonsTree.forEach(phenomenonTree => {
            if (phenomenonTree.id === phenomenon.id) {
              phenomenonTree.name = phenomenon.name;
              phenomenonTree.category_id = phenomenon.category_id;
            }
          });
          return of(phenomenonActions.loadPhenomenonsTreeSuccess(mutablePhenomenonsTree));
        }
      }
      return of();
    })
  );

export const handleAddIndicatorInAnalyticContext = (action$, state$) =>
  action$.pipe(
    ofType(actions.ADD_INDICATOR_IN_ANALYTIC_CONTEXT),
    map((action: Action<MoveIndicator>) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: any) => state.Methodology.methodologySelect)),
      state$.pipe(map((state: any) => state.Indicator.indicatorsForCompany))
    ),
    concatMap(([moveIndicator, methodology, indicatorEntities]) => {
      if (indicatorEntities.length > 0) {
        const mutableMethodology = cloneDeep(methodology);

        if (!mutableMethodology.analytic_context_dto || !mutableMethodology.analytic_context_dto.custom_indicator_dtos) {
          mutableMethodology.analytic_context_dto = {
            id: uuid(),
            custom_indicator_dtos: []
          };
        }

        const indicatorAsList = indicatorEntities.filter(i => i.id == moveIndicator.indicatorId);
        let indicator: any = null;
        if (indicatorAsList.length > 0) {
          indicator = indicatorAsList[0];
        }
        const hasIndicator =
          mutableMethodology.analytic_context_dto.custom_indicator_dtos.filter(ci => ci.indicator_id === moveIndicator.indicatorId).length >
          0;

        if (indicator && !hasIndicator && moveIndicator.destination == 'analyticContext') {
          mutableMethodology.analytic_context_dto.custom_indicator_dtos.push({
            indicator_id: indicator.id,
            feature_flags: indicator.feature_flags,
            diagnostics: indicator.diagnostics
          });
        } else if (indicator && hasIndicator) {
          mutableMethodology.analytic_context_dto.custom_indicator_dtos =
            mutableMethodology.analytic_context_dto.custom_indicator_dtos.filter(ci => ci.indicator_id !== moveIndicator.indicatorId);
        }
        return of(actions.updateMethodologyDraft(mutableMethodology));
      }
      return of();
    })
  );
