import React, { useState, useEffect } from 'react';
import CONFIG from '../../settings';

import PlaceholderNoImage from '../../image/placeholder-no-image.jpg';

import './styles/imageCell.style.scss';

export type ImageCellProps = {
  bucket: string;
  id: string;
  reload: boolean;
};

const ImageCell: React.FC<ImageCellProps> = ({ bucket, id, reload = false }) => {
  const [imageHash, setImageHash] = useState(0);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setImageHash(new Date().getTime());
  }, [reload]);

  const handleOnError = () => !imageError && setImageError(true);

  return (
    <img
      src={imageError ? PlaceholderNoImage : `${CONFIG.s3Url}${bucket}/${id}.png?time=${imageHash}`}
      onError={handleOnError}
      className='image-cell'
    />
  );
};

export default ImageCell;
