import React from 'react';

export interface BinOutlineProps {
  readonly color?: string;
}

const BinOutline = (props: BinOutlineProps) => (
  <svg width='16' height='16' viewBox='0 0 14 14' fill='none' xmlns='https://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.9997 3.00012C13.3679 3.00012 13.6663 3.2986 13.6663 3.66679C13.6663 4.03498 13.3679 4.33346 12.9997 4.33346H12.333V11.6668C12.333 12.7714 11.4376 13.6668 10.333 13.6668H3.66634C2.56177 13.6668 1.66634 12.7714 1.66634 11.6668V4.33346H0.999674C0.631485 4.33346 0.333008 4.03498 0.333008 3.66679C0.333008 3.2986 0.631485 3.00012 0.999674 3.00012H4.33301V1.88679C4.36557 0.998189 5.11098 0.303464 5.99967 0.333455H7.99967C8.88836 0.303464 9.63378 0.998189 9.66634 1.88679V3.00012H12.9997ZM5.66634 1.88679V3.00012H8.33301V1.88679C8.33301 1.78012 8.19301 1.66679 7.99967 1.66679H5.99967C5.80634 1.66679 5.66634 1.78012 5.66634 1.88679ZM10.9997 4.33346V11.6668C10.9997 12.035 10.7012 12.3335 10.333 12.3335H3.66634C3.29815 12.3335 2.99967 12.035 2.99967 11.6668V4.33346H10.9997ZM4.99967 10.3335C4.63148 10.3335 4.33301 10.035 4.33301 9.66679V7.00012C4.33301 6.63193 4.63148 6.33346 4.99967 6.33346C5.36786 6.33346 5.66634 6.63193 5.66634 7.00012V9.66679C5.66634 10.035 5.36786 10.3335 4.99967 10.3335ZM8.33301 9.66679C8.33301 10.035 8.63148 10.3335 8.99967 10.3335C9.36786 10.3335 9.66634 10.035 9.66634 9.66679V7.00012C9.66634 6.63193 9.36786 6.33346 8.99967 6.33346C8.63148 6.33346 8.33301 6.63193 8.33301 7.00012V9.66679Z'
      fill={props.color ? props.color : '#707374'}
    />
  </svg>
);

export default BinOutline;
