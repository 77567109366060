import * as React from 'react';

import basicStyle from '../../settings/basicStyle';
import { Spin } from 'antd';

export class Loading extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.props.afterLoad instanceof Function && this.props.afterLoad();
    }
  }

  render() {
    return this.props.loading ? (
      <div style={{ ...basicStyle.noClickWallStyle, position: 'fixed', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin />
      </div>
    ) : null;
  }
}

export default Loading;
