import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import asyncComponent from './helpers/AsyncFunc';
import Switch from 'react-router-dom/Switch';
import { App } from './containers/App/App';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path='/signin' component={asyncComponent(() => import('./containers/Page/signin'))} />
        <RestrictedRoute path='/' component={App} isLoggedIn={isLoggedIn} />
      </Switch>
    </ConnectedRouter>
  );
};
const mapStateToProps = state => ({
  isLoggedIn: state.Auth.isLogged
});

export default connect(mapStateToProps)(PublicRoutes);
