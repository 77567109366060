export default {
  env: process.env.REACT_APP_ENV,
  basePort: process.env.REACT_APP_BASE_PORT,
  demeterPort: process.env.REACT_APP_DEMETER_PORT,
  archePort: process.env.REACT_APP_ARCHE_PORT,
  apiUrl: process.env.REACT_APP_API_URL,
  s3Url: process.env.REACT_APP_S3_URL,
  baseS3BucketName: process.env.REACT_APP_S3_BASE,
  rolloutKey: process.env.REACT_APP_ROLLOUT_KEY,
  amplitudeKey: process.env.REACT_APP_AMPLITUDE_KEY,
  cropwiseApiUrl: process.env.REACT_APP_CROPWISE_API_URL,
  protectorApiUrl: process.env.REACT_APP_PROTECTOR_API_URL
};

const siteConfig = {
  siteName: 'Protector Admin',
  footerText: 'Strider ©2019'
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};

const language = 'english';

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey'
};

export { siteConfig, language, themeConfig, jwtConfig };
