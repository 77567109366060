import { Action, UUID } from '../app/basicModels';
import { ofType } from 'redux-observable';
import { map, concatMap, catchError, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import actions from './actions';
import { getPhenomenonCategories, savePhenomenonCategory, getPhenomenonCategory, deletePhenomenonCategory } from './service';
import { PhenomenonCategoryPage, PhenomenonCategory, LoadPhenomenonCategoryQueryPayload } from './models';
import { notification } from '../../components';
import uuid from 'uuid/v4';
import { injectMetadata } from '../request-control';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';

export const handleLoadPhenomenonCategories = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PHENOMENON_CATEGORIES),
    map((action: Action<LoadPhenomenonCategoryQueryPayload>) => action.payload),
    concatMap((query: LoadPhenomenonCategoryQueryPayload) =>
      getPhenomenonCategories(query.pageableQuery, query.companyId, query.showCanonicals).pipe(
        map(response => response.data),
        map((phenomenonCategories: PhenomenonCategoryPage) => {
          return actions.loadPhenomenonCategoriesSuccess(phenomenonCategories);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading categories!');
          return of(actions.loadPhenomenonCategoriesFailure(errorMsg));
        })
      )
    )
  );

export const handleLoadPhenomenonCategory = action$ =>
  action$.pipe(
    ofType(actions.LOAD_PHENOMENON_CATEGORY),
    map((action: Action<UUID>) => action.payload),
    concatMap((phenomenonCategoryId: UUID) =>
      getPhenomenonCategory(phenomenonCategoryId).pipe(
        map(response => response.data),
        map((phenomenonCategory: PhenomenonCategory) => {
          return actions.loadPhenomenonCategorySuccess(phenomenonCategory);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading category!');
          return of(actions.loadPhenomenonCategoryFailure(errorMsg));
        })
      )
    )
  );

export const handleSavePhenomenonCategory = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_PHENOMENON_CATEGORY),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      )
    ),
    concatMap(([action, currentUser]: any) => {
      let phenomenonCategory = action.payload;
      const phenomenonCategoryToSave = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          ...phenomenonCategory,
          cohese: true
        }
      };
      if (phenomenonCategory.isNew && !phenomenonCategoryToSave.payload.id) {
        phenomenonCategoryToSave.payload.id = uuid();
      }

      phenomenonCategory = phenomenonCategoryToSave;

      return savePhenomenonCategory(phenomenonCategory, phenomenonCategory.payload.isNew).pipe(
        map(response => {
          const savePhenomenonCategorySuccess = injectMetadata(actions.savePhenomenonCategorySuccess, { ...action.metadata, response });
          return savePhenomenonCategorySuccess(phenomenonCategory.payload);
        }),
        catchError((error: any) => {
          const msg = error.response && error.response.data ? error.response.data.message : '';
          const savePhenomenonCategoryFailure = injectMetadata(actions.savePhenomenonCategoryFailure, {
            ...action.metadata,
            response: error.response
          });
          return of(savePhenomenonCategoryFailure(msg));
        })
      );
    })
  );

export const handleSavePhenomenonCategorySuccess = (action$, state$) =>
  action$.pipe(
    ofType(actions.SAVE_PHENOMENON_CATEGORY_SUCCESS),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.PhenomenonCategory.editModalActive;
        })
      )
    ),
    concatMap(
      ([, editModalActive]): Observable<any> => {
        if (editModalActive) {
          return of(actions.togglePhenomenonCategoryEditModal());
        }
        return of();
      }
    )
  );

export const handleDeletePhenomenonCategory = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_PHENOMENON_CATEGORY),
    map((action: Action<PhenomenonCategory>) => action.payload),
    withLatestFrom(
      state$.pipe(
        map((state: any) => {
          return state.User.currentUser;
        })
      )
    ),
    map(([phenomenonCategory, currentUser]: any) => {
      const phenomenonCategoryToSave = {
        account: {
          id: currentUser.id,
          name: currentUser.name
        },
        payload: {
          ...phenomenonCategory
        }
      };
      return phenomenonCategoryToSave;
    }),
    withLatestFrom(state$.pipe(map((state: any) => state.PhenomenonCategory.entities))),
    concatMap(([phenomenonCategory, entities]) => {
      phenomenonCategory.payload = entities[phenomenonCategory.payload.id];
      return deletePhenomenonCategory(phenomenonCategory).pipe(
        map(response => {
          notification('success', 'Category deleted!');
          return actions.deletePhenomenonCategorySuccess(phenomenonCategory.payload);
        }),
        catchError((error: any) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading category!');        
          return of(actions.deletePhenomenonCategoryFailure(errorMsg));
        })
      );
    })
  );
