import React from 'react';
import TrashGlyph from '../../../../assets/icons/Glyph/TrashGlyph';
import InputItem from '../inputItem/inputItem';

const InputActionItem = ({ input, control }) => (
  <div className='input-action'>
    <InputItem {...input} control={control} />
    <div className='input-action_icon' onClick={() => input.callback()}>
      <TrashGlyph color='#8E9293' />
    </div>
  </div>
);

export default InputActionItem;
