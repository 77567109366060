import { UUID, PageableQuery } from '../app/basicModels';
import { ProductSet, ProductSetPage } from './models';

const actions = {
  LOAD_PRODUCT_SETS: '[ProductSet] Load Product sets',
  LOAD_PRODUCT_SETS_SUCCESS: '[ProductSet] Load Product sets Success',
  LOAD_PRODUCT_SETS_FAILURE: '[ProductSet] Load Product sets Failure',
  TOGGLE_PRODUCT_SET_EDIT_MODAL: '[ProductSet] Toggle product set edit modal',
  SAVE_PRODUCT_SET: '[ProductSet] Save Product Set',
  SAVE_PRODUCT_SET_SUCCESS: '[ProductSet] Save Product Set Success',
  SAVE_PRODUCT_SET_FAILURE: '[ProductSet] Save Product Set Failure',
  LOAD_PRODUCT_SET: '[ProductSet] Load Product Set',
  LOAD_PRODUCT_SET_SUCCESS: '[ProductSet] Load Product Set Success',
  LOAD_PRODUCT_SET_FAILURE: '[ProductSet] Load Product Set Failure',
  DELETE_PRODUCT_SET: '[ProductSet] Delete Product Set',
  DELETE_PRODUCT_SET_SUCCESS: '[ProductSet] Delete Product Set Success',
  DELETE_PRODUCT_SET_FAILURE: '[ProductSet] Delete Product Set Failure',
  CLEAR_PRODUCT_SET_SELECT: '[ProuctSet] Clear Product Set Select',

  loadProductSets: (pageableQuery: PageableQuery, showCanonicals: boolean = true, companyId: string | null | undefined = null) => ({
    type: actions.LOAD_PRODUCT_SETS,
    payload: {
      pageableQuery,
      companyId,
      showCanonicals
    }
  }),

  loadProductSetsSuccess: (productPage: ProductSetPage) => ({
    type: actions.LOAD_PRODUCT_SETS_SUCCESS,
    payload: productPage
  }),

  loadProductSetsFailure: (error: string) => ({
    type: actions.LOAD_PRODUCT_SETS_FAILURE,
    payload: error
  }),

  toggleProductSetEditModal: () => ({
    type: actions.TOGGLE_PRODUCT_SET_EDIT_MODAL
  }),

  saveProductSet: (product: ProductSet) => ({
    type: actions.SAVE_PRODUCT_SET,
    payload: product
  }),

  saveProductSetSuccess: (product: ProductSet) => ({
    type: actions.SAVE_PRODUCT_SET_SUCCESS,
    payload: product
  }),

  saveProductSetFailure: (error: string) => ({
    type: actions.SAVE_PRODUCT_SET_FAILURE,
    payload: error
  }),

  loadProductSet: (id: UUID) => ({
    type: actions.LOAD_PRODUCT_SET,
    payload: id
  }),

  loadProductSetSuccess: (product: ProductSet) => ({
    type: actions.LOAD_PRODUCT_SET_SUCCESS,
    payload: product
  }),

  loadProductSetFailure: (error: string) => ({
    type: actions.LOAD_PRODUCT_SET_FAILURE,
    payload: error
  }),

  deleteProductSet: (product: ProductSet) => ({
    type: actions.DELETE_PRODUCT_SET,
    payload: product
  }),

  deleteProductSetSuccess: (deleted: boolean) => ({
    type: actions.DELETE_PRODUCT_SET_SUCCESS,
    payload: deleted
  }),

  deleteProductSetFailure: (error: string) => ({
    type: actions.DELETE_PRODUCT_SET_FAILURE,
    payload: error
  }),
  clearProductSetSelect: () => ({
    type: actions.CLEAR_PRODUCT_SET_SELECT
  })
};

export default actions;

export const ProductSetActions = actions;
