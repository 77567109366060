import actions from './actions';
import { initialPage } from '../app/basicModels';
import { EntityAdapter, createEntityAdapter } from 'redux-ngrx-entity';
import { ProductSet, ProductSetState, ProductSetPage } from './models';

const entity: EntityAdapter<ProductSet> = createEntityAdapter<ProductSet>();

const initialState: ProductSetState = entity.getInitialState({
  isLoading: false,
  error: null,
  editModalActive: false,
  productSetSelect: null,
  isSaving: false,
  productSetSelectLoading: false
});

export default (state = initialState, action): ProductSetState => {
  switch (action.type) {
    case actions.LOAD_PRODUCT_SETS:
      return {
        ...state,
        isLoading: true
      };

    case actions.LOAD_PRODUCT_SETS_SUCCESS:
      return entity.addAll((action.payload as ProductSetPage).content, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        error: null
      });

    case actions.LOAD_PRODUCT_SETS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.TOGGLE_PRODUCT_SET_EDIT_MODAL:
      return {
        ...state,
        editModalActive: !state.editModalActive
      };

    case actions.SAVE_PRODUCT_SET:
      return {
        ...state,
        isSaving: true
      };

    case actions.SAVE_PRODUCT_SET_SUCCESS:
      return entity.upsertOne(action.payload, {
        ...state,
        isSaving: false,
        error: null,
        productSetSelect: action.payload
      });

    case actions.SAVE_PRODUCT_SET_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };

    case actions.LOAD_PRODUCT_SET:
      return {
        ...state,
        productSetSelectLoading: true,
        productSetSelect: null
      };

    case actions.LOAD_PRODUCT_SET_SUCCESS:
      return {
        ...state,
        productSetSelectLoading: false,
        productSetSelect: action.payload
      };

    case actions.LOAD_PRODUCT_SET_FAILURE:
      return {
        ...state,
        productSetSelectLoading: false,
        error: action.payload
      };

    case actions.DELETE_PRODUCT_SET_SUCCESS:
      return entity.removeOne(action.payload.id, {
        ...state,
        error: null
      });

    case actions.DELETE_PRODUCT_SET_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case actions.CLEAR_PRODUCT_SET_SELECT:
      return {
        ...state,
        productSetSelect: null
      };

    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectProductSetIds = selectIds;
export const selectProductSetEntities = selectEntities;
export const selectAllProductSets = selectAll;
export const selectProductSetTotal = selectTotal;
