import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox } from 'antd';
import { Option } from '../../models';

interface SelectItemProps {
  name: string;
  initialValue?: string;
  options?: ReadonlyArray<Option>;
  control: any;
}

const CheckBoxGroupItem = ({ name, initialValue, options, control }: SelectItemProps) => (
  <>
    <Controller
      data-testid={name}
      name={name}
      control={control}
      defaultValue={initialValue}
      as={({ onChange, value }) => (
        <Checkbox.Group value={value} onChange={values => onChange(values)}>
          {options &&
            [...options].map(object => (
              <Checkbox 
                disabled={object.disabled}
                value={object.id}
                key={object.id}>
                {object.name}
              </Checkbox>
            ))}
        </Checkbox.Group>
      )}
    />
  </>
);

export default CheckBoxGroupItem;
