import React from 'react';
import { Form } from 'antd';
import { FormBuilderProps, InputForm, InputType } from './models';
import SelectItem from './formItems/selectItem/selectItem';
import InputItem from './formItems/inputItem/inputItem';
import InputActionItem from './formItems/inputItem/inputActionItem';
import InputWithMaskItem from './formItems/inputWithMaskItem/inputWithMaskItem';
import TreeSelectItem from './formItems/treeSelectItem/treeSelectItem';
import DateItem from './formItems/dateItem/dateItem';
import TextareaItem from './formItems/textareaItem/textareaItem';
import TranslatableItem from './formItems/translatableItem/translatableItem';
import RadioItem from './formItems/radioItem/radioItem';
import InputPasswordIcon from './formItems/inputPasswordIcon/inputPasswordIcon';
import CheckBoxGroupItem from './formItems/checkBoxGroupItem/checkBoxGroupItem';
import AutocompleteItem from './formItems/autocompleteItem/autocompleteItem';
import VirtualSelect from './formItems/virtualSelect/virtualSelect';
import './formBuilder.style.scss';

const FormBuilder = ({ inputs, errors, control, fieldsClass }: FormBuilderProps) => {
  const defineInputType = (inputType, input) => {
    switch (inputType) {
      case InputType.SELECT:
        return <SelectItem {...input} control={control} />;
      case InputType.RADIO:
        return <RadioItem {...input} control={control} />;
      case InputType.TRANSLATABLE:
        return <TranslatableItem input={input} control={control} />;
      case InputType.TRANSLATABLE_AREA:
        return <TranslatableItem input={input} control={control} area_input />;
      case InputType.INPUT:
        return <InputItem {...input} control={control} />;
      case InputType.INPUT_ACTION:
        return <InputActionItem input={input} control={control} />;
      case InputType.INPUT_NUMBER:
        return <InputItem {...input} control={control} inputType='number' />;
      case InputType.INPUT_PASSWORD:
        return <InputItem {...input} control={control} inputType='password' />;
      case InputType.INPUT_PASSWORD_ICON:
        return <InputPasswordIcon {...input} control={control} />;
      case InputType.INPUT_MASK:
        return <InputWithMaskItem {...input} control={control} />;
      case InputType.TREE_SELECT:
        return <TreeSelectItem {...input} control={control} />;
      case InputType.DATE:
        return <DateItem {...input} control={control} />;
      case InputType.TEXTAREA:
        return <TextareaItem {...input} control={control} />;
      case InputType.AUTOCOMPLETE:
        return <AutocompleteItem {...input} control={control} />;
      case InputType.CHECKBOX_GROUP:
        return <CheckBoxGroupItem {...input} control={control} />;
      case InputType.VIRTUAL_SELECT:
        return <VirtualSelect {...input} control={control} />;
      default:
        return null;
    }
  };

  const defineClassNameFormItem = (input: InputForm): string => {
    if ((input.type === InputType.TRANSLATABLE || input.type === InputType.TRANSLATABLE_AREA) && !input.className) {
      return 'translate';
    }

    if (input.className) {
      return input.className;
    }

    return '';
  };

  return (
    <>
      {inputs.map((input, index) => {
        return (
          <div key={`${index}-${input.label}-${input.name}`} className={fieldsClass}>
            <Form.Item
              label={input.label}
              hasFeedback
              required={input.hasOwnProperty('required') ? input.required : true}
              validateStatus={errors[input.name] && 'error'}
              help={errors[input.name] && errors[input.name].message}
              htmlFor={input.name}
              className={`${defineClassNameFormItem(input)}`}>
              {defineInputType(input.type, input)}
            </Form.Item>
          </div>
        );
      })}
    </>
  );
};

export default FormBuilder;
