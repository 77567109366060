import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { ColorOptions, STTypoProps, TypeOptions } from './STTypo.models';
import './STTypo.styles.scss';
import { Tooltip } from 'antd';

export const parseMaxlengthString = (data: string | ReactNode, maxlength: number, textIsBig: boolean): string | ReactNode => {
  if (typeof data === 'string' && textIsBig) {
    // 3 is "..." length
    const firstPart = (2 / 3) * maxlength;
    const lastPart = maxlength - firstPart;
    return data.substr(0, firstPart) + '...' + data.substr(data.length - lastPart, data.length);
  } else {
    return data;
  }
};

export const isAValidColor = (hexColor: string): boolean => /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(hexColor);

const STTypo: React.FC<STTypoProps> = ({
  type = TypeOptions.P1,
  bold = false,
  italic = false,
  color = 'inherit',
  nowrap = false,
  uppercase = false,
  capitalize = false,
  tagged = false,
  forcewrap = false,
  maxlength,
  children,
  testId,
  onClick
}) => {
  let isCustomColor = false;
  let customColor = color;
  if (!isAValidColor(color) && color !== 'inherit') {
    customColor = 'inherit';
    console.log('[STTypo] `color` must be a valid hex color, fallbacked to `inherit`');
  }

  if (color !== ColorOptions.PRIMARY && color !== ColorOptions.SECONDARY) {
    isCustomColor = true;
  }
  const textIsBig = !!(maxlength && typeof children === 'string' && children.length > maxlength + 3);
  const resultNode = parseMaxlengthString(children, maxlength as number, textIsBig);

  const typoClasses = classNames({
    'st-typo': true,
    'st-typo--uppercase': uppercase,
    'st-typo--capitalize': capitalize,
    'st-typo--tagged': tagged,
    'st-typo--bold': bold,
    'st-typo--italic': italic,
    'st-typo--primary': color === ColorOptions.PRIMARY,
    'st-typo--secondary': color === ColorOptions.SECONDARY,
    'st-typo--white': color === ColorOptions.WHITE,
    'st-typo--nowrap': nowrap,
    'st-typo--forcewrap': forcewrap,
    'st-typo--clickable': !!onClick,
    [type]: true
  });

  return (
    <span className={typoClasses} style={isCustomColor ? { color: customColor } : undefined} data-testid={testId ? testId : null} onClick={onClick}>
      {textIsBig ? <Tooltip title={children}>{resultNode}</Tooltip> : resultNode}
    </span>
  );
};

export default STTypo;
