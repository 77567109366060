import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { TypeOptions } from '../typo/STTypo.models';
import { languages as languagesDictionary } from '../../redux/language/utils';
import STTypo from '../typo/STTypo';
import CheckFilledGlyph from '../../assets/icons/Glyph/CheckFilledGlyph';
import ElipseGlyph from '../../assets/icons/Glyph/ElipseGlyph';
import CircleGlyph from '../../assets/icons/Glyph/CircleGlyph';
import { LanguageStage, TranslationStages } from '../../redux/language/models';
import './translatableTooltip.style.scss';

export interface TranslatableTooltipProps {
  languages: LanguageStage[];
  placement: 'left' | 'right';
}

const TranslatableTooltip: FC<TranslatableTooltipProps> = ({ languages, placement, ...props }) => {
  const buildTooltip = () => {
    const renderElement = (el: LanguageStage) => {
      const glyph =
        el.stage === TranslationStages.COMPLETE ? (
          <CheckFilledGlyph />
        ) : el.stage === TranslationStages.PARTIAL ? (
          <ElipseGlyph />
        ) : (
          <CircleGlyph />
        );

      const name = languagesDictionary[el.language] ? languagesDictionary[el.language].name : `(${el.language})`;

      return (
        <div className='translate-tooltip__content-inner' key={name}>
          {glyph}
          <STTypo color='#F5F8FC' type={TypeOptions.P2}>
            {name}
          </STTypo>
        </div>
      );
    };

    return (
      <div className='translate-tooltip__content'>
        {languages.length > 0 ? languages.map(el => renderElement(el)) : <STTypo>No translations available</STTypo>}
      </div>
    );
  };

  return (
    <Tooltip
      placement={placement === 'left' ? 'leftTop' : 'rightTop'}
      title={buildTooltip()}
      overlayClassName='translate-tooltip'
      mouseEnterDelay={0.5}>
      {props.children}
    </Tooltip>
  );
};

export default TranslatableTooltip;
