import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import CONFIG from '../../settings';
import { LoadMeasurementsRequestParams, Measurement } from './models';

const archeUrl = `${CONFIG.apiUrl}:${CONFIG.archePort}`;
const SIZE_MONITORINGS = 20;

export const getMeasurements = (params: LoadMeasurementsRequestParams) => {
  const searchScouter = params.scouterId ? `&scouter_id=${params.scouterId}` : '';
  const searchRangeKey = params.rangeKey ? `&range_key=${params.rangeKey}` : '';
  const searchDescend = params.descendDate ? `&descend=${params.descendDate}` : '';
  const searchCharacteristic = params.characteristicId ? `&characteristic_id=${params.characteristicId}&size=${SIZE_MONITORINGS}` : '';
  const searchField = params.fieldId ? `&area_id=${params.fieldId}` : '';
  const searchRegion = params.regionId ? `&region_id=${params.regionId}` : '';

  const monitoringQuantity = '&monitoring_quantity=99999';
  const timeLimit = '&start_time=00:00:00&end_time=23:59:59';

  const filterString = `&start=${params.dates.start}&end=${params.dates.end}&options=DONT_VALIDATE_CHARACTERISTIC_ALIASES${searchScouter}${searchRangeKey}${searchDescend}${monitoringQuantity}${timeLimit}${searchCharacteristic}${searchField}${searchRegion}`;
  const urlEndpoint = `${archeUrl}/v1/monitoring/by-property?property_id=${params.propertyId}${filterString}`;

  return axios.get(urlEndpoint);
};

const internalUrl = `${archeUrl}/__internal/monitoring`;

export const editMeasurementValue = ({ monitoringId, measurementId, propertyId, startDate, value }): AxiosObservable<Measurement> => {
  return axios.patch<Measurement>(
    `${internalUrl}/${monitoringId}/measurement/${measurementId}/value?property_id=${propertyId}&start_date=${startDate}`,
    value.toString(),
    { headers: { 'Content-Type': 'application/json' } }
  );
};

export const deleteMeasurement = ({ monitoringId, measurementId, propertyId, startDate }): AxiosObservable<Measurement> => {
  return axios.delete(`${internalUrl}/${monitoringId}/measurement/${measurementId}?property_id=${propertyId}&start_date=${startDate}`);
};
