import { UUID } from '../models';

import { Property } from './models';

const actions = {
  LOAD_PROPERTY_INFO: '[Property] Load Property Info',
  LOAD_PROPERTY_INFO_SUCCESS: '[Property] Load Property Info Success',
  LOAD_PROPERTY_INFO_FAILURE: '[Property] Load Property Info Failure',
  LOAD_PROPERTIES_BY_COMPANY: '[Property] Load Properties By Company',
  LOAD_PROPERTIES_BY_COMPANY_SUCCESS: '[Property] Load Properties By Company Success',
  LOAD_PROPERTIES_BY_COMPANY_FAILURE: '[Property] Load Properties By Company Failure',
  UNLOAD_PROPERTIES_BY_COMPANY: '[Property] Unload Properties By Company',

  loadPropertiesByCompany: (id: UUID) => ({
    type: actions.LOAD_PROPERTIES_BY_COMPANY,
    payload: id
  }),

  loadPropertiesByCompanySuccess: (properties: Property[]) => ({
    type: actions.LOAD_PROPERTIES_BY_COMPANY_SUCCESS,
    payload: properties
  }),

  loadPropertiesByCompanyFailure: (error: string) => ({
    type: actions.LOAD_PROPERTIES_BY_COMPANY_FAILURE,
    payload: error
  }),

  unloadPropertiesByCompany: () => ({
    type: actions.UNLOAD_PROPERTIES_BY_COMPANY
  }),

  loadPropertyInfo: (propertyId: UUID) => ({
    type: actions.LOAD_PROPERTY_INFO,
    payload: propertyId
  }),

  loadPropertyInfoSuccess: (info: Property) => ({
    type: actions.LOAD_PROPERTY_INFO_SUCCESS,
    payload: info
  }),

  loadPropertyInfoFailure: (error: string) => ({
    type: actions.LOAD_PROPERTY_INFO_FAILURE,
    payload: error
  })
};

export default actions;

export const PropertyActions = actions;
