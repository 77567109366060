const actions = {
  TOGGLE_DRAWER: '[LANGUAGE] Toggle drawer',
  SET_CURRENT_LANGUAGE: '[LANGUAGE] Set current language',

  toggleDrawer: (context: string) => ({
    type: actions.TOGGLE_DRAWER,
    payload: context
  }),

  setCurrentLanguage: language => ({
    type: actions.SET_CURRENT_LANGUAGE,
    payload: language
  })
};

export default actions;

export const LanguageActions = actions;
