import { PageableQuery, UUID } from '../app/basicModels';
import { Product, ProductPage } from './models';

const actions = {
  LOAD_PRODUCTS: '[Product] Load Products',
  LOAD_PRODUCTS_SUCCESS: '[Product] Load Products Success',
  ADD_PRODUCTS_SUCCESS: '[Products] Add Products Success',
  LOAD_PRODUCTS_FAILURE: '[Product] Load Products Failure',
  SAVE_PRODUCT: '[Product] Save Product',
  SAVE_PRODUCT_SUCCESS: '[Product] Save Product Success',
  SAVE_PRODUCT_FAILURE: '[Product] Save Product Failure',
  LOAD_PRODUCT: '[Product] Load Product',
  LOAD_PRODUCT_SUCCESS: '[Product] Load Product Success',
  LOAD_PRODUCT_FAILURE: '[Product] Load Product Failure',
  DELETE_PRODUCT: '[Product] Delete Product',
  DELETE_PRODUCT_SUCCESS: '[Product] Delete Product Success',
  DELETE_PRODUCT_FAILURE: '[Product] Delete Product Failure',
  CLEAR_PRODUCT_SELECT: '[Product] Clear Product Select',
  RESET_PRODUCTS: '[Product] Reset Products',
  RESET_STATUS_ERROR: '[Product] Reset Status Error',

  loadProducts: (
    pageableQuery: PageableQuery,
    showCanonicals: boolean = true,
    companyId: string | null | undefined = null,
    type: string | null | undefined = null,
    location: string = 'BR',
    appendProducts: boolean | undefined = false
  ) => ({
    type: actions.LOAD_PRODUCTS,
    payload: {
      pageableQuery,
      showCanonicals,
      companyId,
      type,
      location,
      appendProducts
    }
  }),

  loadProductsSuccess: (productPage: ProductPage) => ({
    type: actions.LOAD_PRODUCTS_SUCCESS,
    payload: productPage
  }),

  addProductsSuccess: (productPage: ProductPage) => ({
    type: actions.ADD_PRODUCTS_SUCCESS,
    payload: productPage
  }),

  loadProductsFailure: (error: string) => ({
    type: actions.LOAD_PRODUCTS_FAILURE,
    payload: error
  }),

  resetProductState: () => ({
    type: actions.RESET_PRODUCTS
  }),

  resetStatusErrorState: () => ({
    type: actions.RESET_STATUS_ERROR
  }),

  saveProduct: (product: Product) => ({
    type: actions.SAVE_PRODUCT,
    payload: product
  }),

  saveProductSuccess: (product: Product) => ({
    type: actions.SAVE_PRODUCT_SUCCESS,
    payload: product
  }),

  saveProductFailure: (error: string) => ({
    type: actions.SAVE_PRODUCT_FAILURE,
    payload: error
  }),

  loadProduct: (id: UUID) => ({
    type: actions.LOAD_PRODUCT,
    payload: id
  }),

  loadProductSuccess: (product: Product) => ({
    type: actions.LOAD_PRODUCT_SUCCESS,
    payload: product
  }),

  loadProductFailure: (error: string) => ({
    type: actions.LOAD_PRODUCT_FAILURE,
    payload: error
  }),

  deleteProduct: (product: Product) => ({
    type: actions.DELETE_PRODUCT,
    payload: product
  }),

  deleteProductSuccess: (deleted: boolean) => ({
    type: actions.DELETE_PRODUCT_SUCCESS,
    payload: deleted
  }),

  deleteProductFailure: (error: string) => ({
    type: actions.DELETE_PRODUCT_FAILURE,
    payload: error
  }),

  clearProductSelect: () => ({
    type: actions.CLEAR_PRODUCT_SELECT
  })
};

export default actions;

export const ProductActions = actions;
