import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'antd';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import appActions from '../../redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import themes from '../../settings/themes';
import CONFIG, { themeConfig } from '../../settings';
import AppHolder from './commonStyle';
import Rox from 'rox-browser';
import './global.css';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppState } from '../../redux/redux.model';
import useTrackingEvents from '../../helpers/useTrackingEvents';

const { Content } = Layout;
const { toggleAll, setSystemFlags } = appActions;

const delaysInSeconds = [1, 2, 3, 5, 8];
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: delaysInSeconds.length,
      retryDelay: numberOfRetries => delaysInSeconds[numberOfRetries] * 1000,
      cacheTime: Infinity
    },
    mutations: {
      retry: delaysInSeconds.length,
      retryDelay: numberOfRetries => delaysInSeconds[numberOfRetries] * 1000
    }
  }
});

const flags = {
  enableTutorial: new Rox.Flag(),
  enableCottonGrowth: new Rox.Flag(),
  enableIndependentVariables: new Rox.Flag(),
  enableCustomVariables: new Rox.Flag(),
  enableAliases: new Rox.Flag(),
  locationFlag: new Rox.Flag(),
  characteristicIndicatorFormFlag: new Rox.Flag(),
  enableLocationCrop: new Rox.Flag(),
  useVirtualSelectMethodologyModal: new Rox.Flag(),
  enableCompanyVirtualSelect: new Rox.Flag(),
  enableExtraDimensions: new Rox.Flag(),
  enableServiceProvidersPage: new Rox.Flag(),
  addCharacteristicsIndicator: new Rox.Flag(),
  enablingNewLanguages: new Rox.Flag(),
  cloneTrap: new Rox.Flag(),
  filterFixedPointsByCompany: new Rox.Flag(),
  filterMethodologiesByCompany: new Rox.Flag(),
  enableNewFieldsInCharacteristicForScoutingUSA: new Rox.Flag(),
  newFieldsToSMDLRules: new Rox.Flag(),
  enableIndicatorSeparation: new Rox.Flag(),
  singleUseTrap: new Rox.Flag(),
  enableMonitoringDays: new Rox.Flag(),
  showWarningCloningMethodologyAreaVariable: new Rox.Flag(),
  enableMethodologyReferenceError: new Rox.Flag(),
  userManagementDiseaseRisk: new Rox.Flag(),
  positiveDiagnostic: new Rox.Flag(),
  readMethodology: new Rox.Flag(),
  specialCharactersOnCategories: new Rox.Flag(),
  ReactQueryDevTools: new Rox.Flag(),
  P40_19905_SHOW_PERMISSION_QUALIMETRE: new Rox.Flag(),
  P40_27983_SHOW_MAINTENANCE_TAB_LAYOUT: new Rox.Flag(false),
  P40_29585_integrate_web_panel_with_RBAC: new Rox.Flag(false),
  P40_14587_variables_validation: new Rox.Flag(false),
  P40_29584_USE_RBAC_CONTROLE_CERTO: new Rox.Flag(false),
  P40_33556_LIMIT_CHARACTERISTICS_NUMBER: new Rox.Flag(true),
  P40_37155_SAVE_CHARACTERISTIC_WITH_INDICATOR: new Rox.Flag(false),
  P40_38140_PREVENT_DUPLICITY_OF_INDICATOR_WITHOUT_ASSOCIATED_CHARACTERISTICS: new Rox.Flag(false),
  P40_36618_DEFAULT_VALUE: new Rox.Flag(),
  P40_39706_CLONE_CHARACTERISTICS_INDICATORS: new Rox.Flag(false)
};

async function initRollout() {
  const options = {};

  if (!Rox.flags.length) {
    Rox.register('', flags);
  }

  await Rox.setup(CONFIG.rolloutKey || '', options);
}

export const App = ({ match }: any) => {
  const url = match.url as string;
  const dispatch = useDispatch();
  const height = useSelector((state: AppState) => state.App.height);
  const appHeight = window.innerHeight;
  const tracking = useTrackingEvents();

  function configureFlags() {
    dispatch(
      setSystemFlags({
        enableCottonGrowth: flags.enableCottonGrowth.isEnabled(),
        enableIndependentVariables: flags.enableIndependentVariables.isEnabled(),
        enableCustomVariables: flags.enableCustomVariables.isEnabled(),
        enableAliases: flags.enableAliases.isEnabled(),
        locationFlag: flags.locationFlag.isEnabled(),
        characteristicIndicatorFormFlag: flags.characteristicIndicatorFormFlag.isEnabled(),
        enableLocationCrop: flags.enableLocationCrop.isEnabled(),
        useVirtualSelectMethodologyModal: flags.useVirtualSelectMethodologyModal.isEnabled(),
        enableCompanyVirtualSelect: flags.enableCompanyVirtualSelect.isEnabled(),
        enableExtraDimensions: flags.enableExtraDimensions.isEnabled(),
        enableServiceProvidersPage: flags.enableServiceProvidersPage.isEnabled(),
        addCharacteristicsIndicator: flags.addCharacteristicsIndicator.isEnabled(),
        enablingNewLanguages: flags.enablingNewLanguages.isEnabled(),
        cloneTrap: flags.cloneTrap.isEnabled(),
        filterFixedPointsByCompany: flags.filterFixedPointsByCompany.isEnabled(),
        filterMethodologiesByCompany: flags.filterMethodologiesByCompany.isEnabled(),
        enableNewFieldsInCharacteristicForScoutingUSA: flags.enableNewFieldsInCharacteristicForScoutingUSA.isEnabled(),
        newFieldsToSMDLRules: flags.newFieldsToSMDLRules.isEnabled(),
        enableIndicatorSeparation: flags.enableIndicatorSeparation.isEnabled(),
        singleUseTrap: flags.singleUseTrap.isEnabled(),
        enableMonitoringDays: flags.enableMonitoringDays.isEnabled(),
        showWarningCloningMethodologyAreaVariable: flags.showWarningCloningMethodologyAreaVariable.isEnabled(),
        enableMethodologyReferenceError: flags.enableMethodologyReferenceError.isEnabled(),
        userManagementDiseaseRisk: flags.userManagementDiseaseRisk.isEnabled(),
        positiveDiagnostic: flags.positiveDiagnostic.isEnabled(),
        readMethodology: flags.readMethodology.isEnabled(),
        specialCharactersOnCategories: flags.specialCharactersOnCategories.isEnabled(),
        ReactQueryDevTools: flags.ReactQueryDevTools.isEnabled(),
        P40_19905_SHOW_PERMISSION_QUALIMETRE: flags.P40_19905_SHOW_PERMISSION_QUALIMETRE.isEnabled(),
        P40_27983_SHOW_MAINTENANCE_TAB_LAYOUT: flags.P40_27983_SHOW_MAINTENANCE_TAB_LAYOUT.isEnabled(),
        P40_29585_integrate_web_panel_with_RBAC: flags.P40_29585_integrate_web_panel_with_RBAC.isEnabled(),
        P40_14587_variables_validation: flags.P40_14587_variables_validation.isEnabled(),
        P40_29584_USE_RBAC_CONTROLE_CERTO: flags.P40_29584_USE_RBAC_CONTROLE_CERTO.isEnabled(),
        P40_33556_LIMIT_CHARACTERISTICS_NUMBER: flags.P40_33556_LIMIT_CHARACTERISTICS_NUMBER.isEnabled(),
        P40_37155_SAVE_CHARACTERISTIC_WITH_INDICATOR: flags.P40_37155_SAVE_CHARACTERISTIC_WITH_INDICATOR.isEnabled(),
        P40_38140_PREVENT_DUPLICITY_OF_INDICATOR_WITHOUT_ASSOCIATED_CHARACTERISTICS:
          flags.P40_38140_PREVENT_DUPLICITY_OF_INDICATOR_WITHOUT_ASSOCIATED_CHARACTERISTICS.isEnabled(),
        P40_36618_DEFAULT_VALUE: flags.P40_36618_DEFAULT_VALUE.isEnabled(),
        P40_39706_CLONE_CHARACTERISTICS_INDICATORS: flags.P40_39706_CLONE_CHARACTERISTICS_INDICATORS.isEnabled()
      })
    );
  }

  initRollout().then(() => configureFlags());

  tracking.init();

  const toggleButtonRenderProps = {
    style: {
      marginBottom: '70px'
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time='1000' handler='onResize'>
              <WindowResizeListener onResize={windowSize => dispatch(toggleAll(windowSize.windowWidth, windowSize.windowHeight))} />
            </Debounce>
            <Topbar />
            <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
              <Sidebar url={url} />
              <Layout
                className='isoContentMainLayout'
                data-testid='main-layout'
                style={{
                  height
                }}>
                <Content
                  className='isomorphicContent'
                  style={{
                    padding: '70px 0 0',
                    flexShrink: '0',
                    background: '#f1f3f6',
                    position: 'relative'
                  }}>
                  {flags.ReactQueryDevTools && <ReactQueryDevtools initialIsOpen={false} toggleButtonProps={toggleButtonRenderProps} />}
                  <AppRouter url={url} />
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
