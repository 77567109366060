import axios from 'axios-observable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import CONFIG from '../../settings';
import { Page, PropertyUser, UUID } from '../models';

import { Property, PropertyAdapter } from './models';

const baseUrl = CONFIG.protectorApiUrl;
const baseUrl2 = `${CONFIG.apiUrl}:${CONFIG.basePort}`;
const companyBaseUrlv2 = `${baseUrl2}/v2/orgs`;

export const getPropertyData = (propertyId: UUID): Observable<Property> => {
  return axios.get<Property>(`${baseUrl}/v1/properties/${propertyId}`).pipe(map(response => response.data));
};

export const getPropertiesByCompany = (organizationId: UUID): Observable<Property[]> => {
  return axios.get<Page<PropertyUser>>(`${companyBaseUrlv2}/${organizationId}/properties`).pipe(
    map(response => response.data),
    map(response => {
      if (!response.content || !response.content.length) {
        return [];
      }
      return response.content.map(propertyUser => PropertyAdapter(propertyUser));
    })
  );
};
