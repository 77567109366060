import React from 'react';
import ImageCellComponent from './imageCell';
import DeleteCell from './deleteCell';
import EditableCell from './editableCell';
import FilterDropdown from './filterDropdown';

const DateCell = data => <p>{data.toLocaleString()}</p>;
const ImageCell = (bucket, id, reload) => <ImageCellComponent bucket={bucket} id={id} reload={reload} key={id} />;
const LinkCell = (link, href) => <a href={href ? href : '#'}>{link}</a>;
const TextCell = text => <p>{text}</p>;

export { DateCell, ImageCell, LinkCell, TextCell, EditableCell, DeleteCell, FilterDropdown };
