import { CharacteristicActions } from '../characteristic/actions';
import { CompanyActions } from '../company/actions';
import { CropActions } from '../crop/actions';
import { CropwisePlansActions } from '../cropwisePlans/actions';
import { DevelopmentPhaseActions } from '../developmentPhase/actions';
import { ExtraDimensionActions } from '../extraDimensions/actions';
import { FieldActions } from '../field/actions';
import { FixedPointActions } from '../fixedPoint/actions';
import { TrapsActions } from '../traps/actions';
import { FixedPointPluviometerActions } from '../pluviometer/actions';
import { IndicatorActions } from '../indicator/actions';
import { LanguageActions } from '../language/actions';
import { MaydayActions } from '../mayday/actions';
import { MethodologyActions } from '../methodology/actions';
import { MethodologyDeepActions } from '../methodologyDeep/actions';
import { MethodologyTemplateActions } from '../methodologyTemplate/actions';
import { MeasurementActions } from '../measurement/actions';
import { PhenomenonActions } from '../phenomenon/actions';
import { PhenomenonCategoryActions } from '../phenomenonCategory/actions';
import { ProductActions } from '../product/actions';
import { ProductSetActions } from '../productSet/actions';
import { PropertyActions } from '../property';
import { RequestControlActions } from '../request-control/actions';
import { SeasonActions } from '../season/actions';
import { TrapMeasurementActions } from '../trapMeasurement/actions';
import { ServiceProvidersActions } from '../serviceProviders/actions';
import { UserActions } from '../user/actions';
import { VendorActions } from '../vendor/actions';
import { FixedPointGenericActions } from '../generic/actions';
import { RegionActions } from '../region/actions';
import { VariableValidationActions } from '../variableValidation/actions';

export { CharacteristicActions } from '../characteristic/actions';
export { CompanyActions } from '../company/actions';
export { CropActions } from '../crop/actions';
export { CropwisePlansActions } from '../cropwisePlans/actions';
export { DevelopmentPhaseActions } from '../developmentPhase/actions';
export { FieldActions } from '../field/actions';
export { FixedPointActions } from '../fixedPoint/actions';
export { TrapsActions } from '../traps/actions';
export { FixedPointPluviometerActions } from '../pluviometer/actions';
export { FixedPointGenericActions } from '../generic/actions';
export { IndicatorActions } from '../indicator/actions';
export { LanguageActions } from '../language/actions';
export { MaydayActions } from '../mayday/actions';
export { MethodologyActions } from '../methodology/actions';
export { MethodologyDeepActions } from '../methodologyDeep/actions';
export { MethodologyTemplateActions } from '../methodologyTemplate/actions';
export { PhenomenonActions } from '../phenomenon/actions';
export { PhenomenonCategoryActions } from '../phenomenonCategory/actions';
export { ProductActions } from '../product/actions';
export { ProductSetActions } from '../productSet/actions';
export { PropertyActions } from '../property';
export { RequestControlActions } from '../request-control/actions';
export { SeasonActions } from '../season/actions';
export { TrapMeasurementActions } from '../trapMeasurement/actions';
export { ServiceProvidersActions } from '../serviceProviders/actions';
export { UserActions } from '../user/actions';
export { VendorActions } from '../vendor/actions';
export { RegionActions } from '../region/actions';
export { VariableValidationActions } from '../variableValidation/actions';

export const Actions = {
  ...CharacteristicActions,
  ...CompanyActions,
  ...CropActions,
  ...CropwisePlansActions,
  ...DevelopmentPhaseActions,
  ...ExtraDimensionActions,
  ...FieldActions,
  ...FixedPointActions,
  ...TrapsActions,
  ...FixedPointPluviometerActions,
  ...FixedPointGenericActions,
  ...IndicatorActions,
  ...LanguageActions,
  ...MaydayActions,
  ...MeasurementActions,
  ...MethodologyActions,
  ...MethodologyDeepActions,
  ...MethodologyTemplateActions,
  ...PhenomenonActions,
  ...PhenomenonCategoryActions,
  ...ProductActions,
  ...ProductSetActions,
  ...PropertyActions,
  ...RequestControlActions,
  ...TrapMeasurementActions,
  ...SeasonActions,
  ...ServiceProvidersActions,
  ...UserActions,
  ...VendorActions,
  ...RegionActions,
  ...VariableValidationActions
};

export default Actions;
