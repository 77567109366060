/**
 * @H1: 42px
 * @H2: 30px
 * @H3: 24px
 * @H4: 20px
 * @H5: 16px
 * @P1: 14px
 * @P2: 12px
 * @C1: 12px
 * @C2: 10px
 */
export enum TypeOptions {
  H1 = 'st-typo--heading-1',
  H2 = 'st-typo--heading-2',
  H3 = 'st-typo--heading-3',
  H4 = 'st-typo--heading-4',
  H5 = 'st-typo--heading-5',
  P1 = 'st-typo--paragraph-1',
  P2 = 'st-typo--paragraph-2',
  C1 = 'st-typo--caption-1',
  C2 = 'st-typo--caption-2'
}

export enum ColorOptions {
  PRIMARY = '#2f3031',
  SECONDARY = '#707374',
  SUCCESS = '#198746',
  DANGER = '#F74141',
  WHITE = '#FFFFFF'
}

export interface STTypoProps {
  readonly type?: TypeOptions;
  readonly color?: ColorOptions | string;
  readonly bold?: boolean;
  readonly italic?: boolean;
  readonly maxlength?: number;
  readonly uppercase?: boolean;
  readonly capitalize?: boolean;
  readonly tagged?: boolean;
  readonly nowrap?: boolean;
  readonly forcewrap?: boolean;
  readonly testId?: string;
  readonly onClick?: () => void;
}
