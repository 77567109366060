import { CropwisePlanFlat } from './models';

const actions = {
  LOAD_CROPWISE_PLANS: '[Cropwise Plans] Load plans',
  LOAD_CROPWISE_PLANS_SUCCESS: '[Cropwise Plans] Load plans success',
  LOAD_CROPWISE_PLANS_FAILURE: '[Cropwise Plans] Load plans failure',

  loadCropwisePlans: () => ({
    type: actions.LOAD_CROPWISE_PLANS
  }),

  loadCropwisePlansSuccess: (plans: Array<CropwisePlanFlat>) => ({
    type: actions.LOAD_CROPWISE_PLANS_SUCCESS,
    payload: plans
  }),

  loadCropwisePlansFailure: (error: string) => ({
    type: actions.LOAD_CROPWISE_PLANS_FAILURE,
    payload: error
  })
};

export default actions;

export const CropwisePlansActions = actions;
