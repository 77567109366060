import actions from './actions';
import { CropwisePlansState } from './models';

const initialState: CropwisePlansState = {
  isLoading: false,
  error: null,
  plans: [],
}

export default (state = initialState, action): CropwisePlansState => {
  switch (action.type) {
    case actions.LOAD_CROPWISE_PLANS:
      return {
        ...state,
        isLoading: true,
      };

    case actions.LOAD_CROPWISE_PLANS_SUCCESS:
      return {
        ...state,
        plans: [...action.payload],
        isLoading: false,
        error: null
      };

    case actions.LOAD_CROPWISE_PLANS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
