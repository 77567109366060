import React, { FC, useState } from 'react';
import CanonicalKeyGlyph from '../../assets/icons/Glyph/CanonicalKeyGlyph';
import EditGlyph from '../../assets/icons/Glyph/EditGlyph';
import CloseGlyph from '../../assets/icons/Glyph/CloseGlyph';
import MoveGlyph from '../../assets/icons/Glyph/MoveGlyph';
import TimelineGlyph from '../../assets/icons/Glyph/TimelineGlyph';
import PhoneGlyph from '../../assets/icons/Glyph/PhoneGlyph';
import RedDotGlyph from '../../assets/icons/Glyph/RedDotGlyph';
import YellowDotGlyph from '../../assets/icons/Glyph/YellowDotGlyph';
import classNames from 'classnames';
import { CharacteristicsIndicatorsProps } from './models/characteristicsIndicators.model';
import GenericPopConfirm from '../genericPopConfirm/genericPopConfirm';
import STTypo from '../typo/STTypo';
import { Button, Tooltip } from 'antd';
import { CONTROL, DAMAGE } from '../../redux/indicator/models';
import { ActionButtons } from '../actionButtons/actionButtons';
import './characteristicsIndicators.style.scss';
import GlyphRedirect from '../button/glyphRedirect';
import { ColorOptions } from '../typo/STTypo.models';
import { useSelector } from 'react-redux';
import EyeGlyph from '../../assets/icons/Glyph/EyeGlyph';
import { getValueLanguage } from '../../redux/language/utils';
import { AppState } from '../../redux/redux.model';
import { IntlMessages } from '../../helpers';

enum colors {
  active = '#515253',
  inactive = '#C1C5C8'
}
const controlName = getValueLanguage(CONTROL);
const damageName = getValueLanguage(DAMAGE);

const { DANGER, PRIMARY } = ColorOptions;

export const CharacteristicsIndicators: FC<CharacteristicsIndicatorsProps> = ({
  id,
  deleteFn,
  deleteIndicatorFn,
  editFn,
  onTimelineClick,
  onLayoutClick,
  multiphenomenon,
  customButton,
  wrongConfiguration,
  isClone,
  ...props
}) => {
  const [hovering, setHovering] = useState(false);

  const flags = useSelector((state: AppState) => state.App.systemFlags);

  const handleHover = () => setHovering(true);

  const handleStopHover = () => setHovering(false);

  /* istanbul ignore else */
  const handleDelete = () => deleteFn && deleteFn(id);

  const handleDeleteIndicator = () => deleteIndicatorFn && deleteIndicatorFn(id);

  const handleEdit = () => editFn && editFn(id);

  const handleTimelineClick = () => onTimelineClick && onTimelineClick();

  const handleLayoutClick = () => onLayoutClick && onLayoutClick();

  const validateThresholds = (): boolean => {
    if (props.thresholds) {
      const thresholdNames = props.thresholds.map(threshold => getValueLanguage(threshold.label));
      return thresholdNames.includes(controlName) && thresholdNames.includes(damageName);
    }
    return false;
  };

  const { canEditCustomCharacteristic, canEditCustomIndicator } = useSelector((state: AppState) => state.App.accessFlags);

  const thresholdValid = validateThresholds();
  const canEdit = canEditCustomCharacteristic || canEditCustomIndicator;

  const defineText = args => {
    return `
    ${args.title} - ${args.characteristicId ? args.characteristicId.slice(0, 8) : ''}
    ${args.indicatorId && args.characteristicId ? '/' : ''}
    ${args.indicatorId ? args.indicatorId.slice(0, 8) : ''}
    `;
  };

  return (
    <div className='content-box'>
      <div className='content-box__title'>
        <span className='content-box__title__canonical-key'>
          <CanonicalKeyGlyph color={props.canonical ? colors.active : colors.inactive} />
        </span>
        <div className='content-box__title__name'>
          <STTypo color={wrongConfiguration ? DANGER : PRIMARY}>
            {wrongConfiguration ? (
              <Tooltip placement='topLeft' title='This indicator possibly has some wrong configuration!'>
                {props.title}
              </Tooltip>
            ) : (
              defineText(props)
            )}
          </STTypo>
        </div>
      </div>
      <div className={classNames('content-box__actions', { 'multiphenomenon-box__actions': multiphenomenon })}>
        <div className='content-box__layout-timeline'>
          {props.indicatorId ? (
            <>
              <Button onClick={() => handleTimelineClick()} disabled={!canEdit}>
                <TimelineGlyph color={props.timeline ? colors.active : colors.inactive} />
              </Button>
              {!multiphenomenon && (
                <Button onClick={() => handleLayoutClick()} disabled={wrongConfiguration || !canEdit}>
                  <PhoneGlyph color={props.inspectionLayout ? colors.active : colors.inactive} />
                </Button>
              )}
            </>
          ) : (
            <div style={{ width: '84px' }} />
          )}
        </div>
        <div
          className={classNames('content-box__threshold-type-group', {
            'multiphenomenon-box__threshold': multiphenomenon
          })}>
          <div className='content-box__threshold'>
            {props.thresholds ? (
              <>
                {thresholdValid ? (
                  <div className='content-box__threshold-valid'>
                    <span aria-label='control value' data-testid='control-value'>
                      <YellowDotGlyph />{' '}
                      {props.thresholds.find(threshold => getValueLanguage(threshold.label) === controlName)!.start_value}
                    </span>
                    <span aria-label='damage value' data-testid='damage-value'>
                      <RedDotGlyph /> {props.thresholds.find(threshold => getValueLanguage(threshold.label) === damageName)!.start_value}
                    </span>
                  </div>
                ) : (
                  <div>Threshold not found</div>
                )}
              </>
            ) : (
              <STTypo aria-label='no threshold' nowrap>
                No Threshold
              </STTypo>
            )}
          </div>
          {!multiphenomenon && (
            <div className='content-box__type'>
              <span
                className={classNames('content-box__threshold__text-box content-box__threshold__text-box--blue', {
                  hide: !props.characteristicId
                })}>
                Characteristic
              </span>
              <span
                onMouseEnter={handleHover}
                onMouseLeave={handleStopHover}
                className={classNames('content-box__threshold__text-box content-box__threshold__text-box--green', {
                  hide: !props.indicatorId
                })}>
                Indicator
                {flags.enableIndicatorSeparation && hovering && props.characteristicId && (
                  <div data-testid='indicator-remove-icon' className='content-box__threshold__text-box__remove'>
                    <GenericPopConfirm
                      onConfirm={handleDeleteIndicator}
                      title={<IntlMessages id='characteristic.default.indicator.remove' />}>
                      <CloseGlyph color='#515253' />
                    </GenericPopConfirm>
                  </div>
                )}
              </span>
            </div>
          )}
        </div>
        <ActionButtons>
          {customButton}
          {editFn && (
            <GlyphRedirect
              onClick={() => handleEdit()}
              testId='charac-indicator-edit'
              textId={canEdit ? 'button.edit' : 'button.details'}
              glyph={canEdit ? <EditGlyph /> : <EyeGlyph />}
              dot={canEdit}
            />
          )}
          {!isClone && canEdit && (
            <span>
              <GenericPopConfirm onConfirm={handleDelete} title={<IntlMessages id='characteristic.methodology_list.remove' />}>
                <CloseGlyph color='#515253' />
              </GenericPopConfirm>
            </span>
          )}
          <span
            className={classNames('content-box__action-buttons__move-button', {
              hide: !props.characteristicId || (props.characteristicId && props.hideMove)
            })}>
            <MoveGlyph color='#D7DDE6' />
          </span>
        </ActionButtons>
      </div>
    </div>
  );
};

export default CharacteristicsIndicators;
